import { ROUTES } from '~/lib/routes'
import { DEFAULT_HUB_SLUGS } from '~/lib/constants'
import getMergedSkuSlug from '~/api/services/get-merged-sku-slug'
import getProductsBySku from '~/api/services/get-products-by-sku'
import { getCountryFromLocale, applyPrefix } from '~/lib/helpers'
import { Context } from '@nuxt/types'
import { ECountries } from '~/types/app/Countries'
import { ELogLevels, ELoggers } from '~/types/app/Logger'

export default async function ({
  redirect,
  app,
  route,
  store,
}: Context): Promise<ReturnType<typeof redirect>> {
  const locale = app.i18n.locale
  const country = getCountryFromLocale(locale) as ECountries
  const hubSlug = DEFAULT_HUB_SLUGS[country]
  const productSKU = route.params.slug
  let redirectPath = app.localePath(ROUTES.HOME)

  let productSlug

  try {
    if (!productSKU) {
      throw new Error('Product sku was not passed as slug')
    }

    const products = await getProductsBySku({
      client: app.$apiDiscovery,
      logger: app.$logger,
      locale,
      hubSlug: store.state.hub?.slug || hubSlug,
      skus: [productSKU],
    })

    productSlug = products[0]?.slug

    // TODO: The code below is temporary and can be removed when google has finished crawl of all the PDP
    if (!productSlug) {
      productSlug = await getMergedSkuSlug({
        client: app.$apiDiscovery,
        logger: app.$logger,
        locale,
        hubSlug: store.state.hub?.slug || hubSlug,
        sku: productSKU,
      })
    }

    if (!productSlug) {
      throw new Error('Product slug could not be found')
    }
  } catch (error) {
    app.$logger({
      level: ELogLevels.ERROR,
      msg: 'Deeplink Redirect Error',
      error,
      logger: ELoggers.PRODUCT,
      func: 'deeplinkRedirectProduct',
    })
  }

  if (productSlug && productSKU) {
    const pageSlug = `${productSlug}-${productSKU}`

    redirectPath = app.localePath({
      name: ROUTES.PRODUCT,
      params: { slug: pageSlug },
    })
  }

  return redirect(applyPrefix(redirectPath))
}
