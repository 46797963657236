// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/prime/profile-card.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".prime-cart-banner[data-v-b007ff34]{display:flex;align-items:center;overflow:hidden;background-size:cover;background-repeat:no-repeat;padding-top:0.5rem;padding-bottom:0.5rem;padding-left:0.75rem;padding-right:1rem;text-align:left;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.logo[data-v-b007ff34]{margin-right:1rem;margin-bottom:0.25rem;margin-left:-0.25rem;width:5.3125rem}.text[data-v-b007ff34]{margin-right:auto;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgba(255, 255, 255, var(--tw-text-opacity))}.btn[data-v-b007ff34]{margin-left:auto;padding-left:1rem;padding-right:1rem;padding-top:0.5rem;padding-bottom:0.5rem;font-size:0.75rem;line-height:1rem;--tw-text-opacity:1;color:rgba(0, 40, 85, var(--tw-text-opacity));border-radius:2.25rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
