import { omit } from 'lodash-es'
import IProduct, { IProductRaw, IProductPriceRaw } from '~/types/app/Product'

export function transformProduct(product: IProductRaw): IProduct {
  const maxQuantity = !product.max_single_order_quantity
    ? product.quantity
    : Math.min(Number(product.max_single_order_quantity), product.quantity)

  let deposit = product.deposit
  if (!deposit && product.pfand) {
    deposit = {
      amount: +product.pfand,
      currency: product.price?.currency || 'EUR',
    }
  }

  let packagingFee
  if (product.packaging_fee?.amount) {
    packagingFee = {
      amount: product.packaging_fee.amount,
      currency: product.packaging_fee.currency,
    }
  }

  let originalPrice
  if (product.price?.discount) {
    originalPrice = {
      amount: product.price.discount.original_amount,
      currency: product.price.currency,
    }
  }

  let pricePerUnit
  if (product.base_price && product.base_unit) {
    pricePerUnit = {
      price: {
        amount: product.base_price.amount,
        currency: product.base_price.currency,
      },
      unit: {
        unit: product.base_unit.unit,
        value: product.base_unit.amount,
      },
    }
  }

  return {
    id: product.id,
    sku: product.sku,
    name: product.name,
    slug: product.slug,
    quantity: product.quantity,
    thumbnail: product.thumbnail,
    tags: product.tags,
    maxQuantity,
    price: omit(product.price, 'discount'),
    deposit,
    discount: product.price?.discount?.percentage,
    originalPrice,
    pricePerUnit,
    packagingFee,
    context: product.productContext,
    promotions: product.promotions,
    oosExperimentVariant: product.oos_experiment_variant,
    isOutOfStock: product.is_out_of_stock,
  }
}

export default function transformProducts(
  products: IProductRaw[] | undefined = []
): IProduct[] {
  return products.map(transformProduct)
}

export function updateProductPricing(
  product: IProduct,
  pricing: IProductPriceRaw
): IProduct {
  let originalPrice
  if (pricing.metadata.discount_applied) {
    originalPrice = pricing.metadata.original_price
  }

  return {
    ...product,
    price: pricing.price,
    originalPrice,
    discount: pricing.metadata.discount_percentage,
    pricePerUnit: pricing.metadata.price_per_unit,
  }
}
