import { ComputedRef, useStore, computed } from '@nuxtjs/composition-api'
import { IAppState } from '~/types/app/State'

interface IResult {
  toggleOnboardingStatus: () => void
  toggleAddressBook: (shown: boolean) => Promise<void>
  toggleOnboardingModal: (shown: boolean) => Promise<void>
  isLocationSelectionVisible: ComputedRef<boolean>
}

export default function useLocationOnboarding(
  hideInitialOnboarding = false
): IResult {
  const store = useStore<IAppState>()

  const isLocationSelectionVisible = computed<boolean>(() => {
    return store.getters.getIsOnboardingVisible(hideInitialOnboarding)
  })

  function toggleOnboardingStatus() {
    if (store.getters['account/userHasSavedAddresses']) {
      toggleAddressBook(true)
    } else {
      toggleOnboardingModal(true)
    }
  }

  async function toggleAddressBook(shown: boolean): Promise<void> {
    await store.dispatch('account/toggleAddressBook', shown)
  }

  async function toggleOnboardingModal(shown: boolean): Promise<void> {
    if (shown) {
      await showOnboardingModal()
    } else {
      await hideOnboardingModal()
    }
  }

  async function showOnboardingModal(): Promise<void> {
    await store.dispatch('toggleOnboardingModal', true)
  }

  async function hideOnboardingModal(): Promise<void> {
    await store.dispatch('toggleOnboardingModal', false)
  }

  return {
    toggleOnboardingStatus,
    isLocationSelectionVisible,
    toggleAddressBook,
    toggleOnboardingModal,
  }
}
