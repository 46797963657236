var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delivery-banner-eta"},[(_vm.isAddressDefined && _vm.deliveryEta)?_c('div',{staticClass:"delivery-banner-content",attrs:{"data-testid":"delivery-banner"},on:{"click":function($event){return _vm.emit('hide-onboarding-hint')}}},[_c('div',{class:[
        'delivery-eta-wrapper',
        {
          '--hidden': _vm.isPickTimeButtonAvailable,
          'cursor-pointer': _vm.isClickable,
        } ],on:{"click":function($event){_vm.isClickable && _vm.emit('open-timeslot-selection')}}},[_c('div',{class:[
          'delivery-eta',
          { 'pick-a-time': _vm.isPickTimeButtonAvailable, closed: !_vm.isOpen } ]},[(_vm.isPickTimeButtonAvailable)?_c('button',{staticClass:"btn flink-btn flink-btn--secondary",on:{"click":function($event){return _vm.emit('open-timeslot-selection')}}},[_vm._v("\n          "+_vm._s(_vm.$t('planned_orders_pick_a_time'))+"\n        ")]):(_vm.isOpen)?_c('button',{class:[
            'time',
            'notranslate',
            {
              'cursor-auto': !_vm.store.state.plannedOrders.isClickable,
            } ],attrs:{"disabled":!_vm.store.state.plannedOrders.isClickable}},[_c('VIcon',{staticClass:"delivery-eta-icon",attrs:{"name":_vm.isShowTimeSlot ? 'clock' : 'bicycle'}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.schedulingText || _vm.deliveryEtaFallback))])],1):_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('generic_closed'))+"\n        ")])]),_vm._v(" "),(_vm.isClickable)?_c('VIcon',{staticClass:"expand-icon",attrs:{"name":"arrow-right"}}):_vm._e()],1),_vm._v(" "),(_vm.openingHoursLabel)?_c('div',{staticClass:"open-time",attrs:{"data-testid":"opening-hours-label"}},[_vm._v("\n      "+_vm._s(_vm.openingHoursLabel)+"\n    ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }