var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card-grid",class:("card-" + _vm.cardSize),attrs:{"role":"grid"}},_vm._l((_vm.products),function(product,index){return _c('div',{key:product.id,staticClass:"product-card-grid-item"},[_c('ProductCard',_vm._b({attrs:{"max-order-quantity":product.maxQuantity,"position-in-group":index + 1,"role":"gridcell"}},'ProductCard',{
        product: product,
        listName: _vm.listName,
        categoryId: _vm.categoryId,
        categoryName: _vm.categoryName,
        subCategoryId: _vm.subCategoryId,
        subCategoryName: _vm.subCategoryName,
        productPlacement: _vm.productPlacement,
        searchQueryId: _vm.searchQueryId,
        size: _vm.cardSize,
      },false))],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }