import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { getProductsAmountsBySKU } from '~/api/services/get-products-amounts-by-sku'
import ICartLine from '~/types/app/CartLine'
import { LoggerFunction } from '~/types/app/Logger'
import IProduct, { IProductAmountBySKU } from '~/types/app/Product'

interface IParameters {
  client: NuxtAxiosInstance
  locale: string
  hubSlug?: string
  cartLines: ICartLine[]
}

export interface IConflictingCartLine {
  sku: IProduct['sku']
  requested: number
  available: number
}

interface IResult {
  allValid: boolean
  conflictingCartLines: IConflictingCartLine[]
}

export default async function areAllProductsAvailable(
  { client, locale, hubSlug, cartLines }: IParameters,
  logger: LoggerFunction
): Promise<IResult> {
  const availableProductAmounts = await getProductsAmountsBySKU(
    {
      client,
      locale,
      hubSlug,
      productSKUs: cartLines.map((product) => product.product_sku),
    },
    logger
  )

  const mappedProductAmountsToSKUs: Record<string, IProductAmountBySKU> = {}
  availableProductAmounts.forEach((product) => {
    mappedProductAmountsToSKUs[product.product_sku] = product
  })

  const conflictingCartLines = cartLines.reduce((result, product) => {
    const requested = product.quantity
    const available =
      mappedProductAmountsToSKUs[product.product_sku]?.quantity ?? 0

    if (requested > available) {
      result.push({
        sku: product.product_sku,
        requested,
        available,
      })
    }

    return result
  }, [] as { sku: IProduct['sku']; requested: number; available: number }[])

  return {
    allValid: !conflictingCartLines.length,
    conflictingCartLines,
  }
}
