var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.shouldShow)?_c('button',{staticClass:"cart-btn flink-btn",class:'--' + _vm.variant,attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.handleCartClicked}},[_c('VIcon',{attrs:{"name":_vm.icon}}),_vm._v(" "),_c('span',{staticClass:"label",class:{ 'sr-only': _vm.variant === 'inline' }},[_vm._t("default",[_vm._v("\n        "+_vm._s(_vm.$t('checkout_button_label'))+"\n      ")])],2),_vm._v(" "),_c('span',{staticClass:"details"},[_c('span',{staticClass:"block mt-1",class:{ 'pt-2 pb-2 pl-8': _vm.store.getters.getIsClickAndCollect }},[_vm._v("\n        "+_vm._s(_vm.$formatCurrency({
            amount: _vm.payButton
              ? _vm.store.getters.getOrderTotalPrice
              : _vm.store.getters.getCartSubTotalPrice,
            currency: _vm.store.state.currency,
          }))+"\n      ")]),_vm._v(" "),(
          !_vm.payButton &&
          !_vm.store.getters.getIsClickAndCollect &&
          !_vm.store.getters.getIsKiosk
        )?_c('span',{staticClass:"delivery-fee"},[_vm._v("\n        +"+_vm._s(_vm.$formatCurrency({
            amount: _vm.store.getters.getDeliveryFee,
            currency: _vm.store.state.currency,
          }))+"\n        "+_vm._s(_vm.$t('cart_button_delivery_notice'))+"\n      ")]):_vm._e()])],1):_vm._e(),_vm._v(" "),(_vm.showItemsNotAvailableErrorModal)?_c('OutOfStockModal',{attrs:{"shown":"","updated-products":_vm.conflictingLines},on:{"refresh":_vm.onRefreshCartAmounts}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }