import { omit } from 'lodash-es'
import { ROUTES } from '~/lib/routes'
import { applyPrefix } from '~/lib/helpers'
import { ROUTE_LOCALE_SEPARATOR } from '~/lib/constants'

export default function ({ redirect, app, route }) {
  const isUserAuthenticated = app?.store?.getters['account/isUserAuthenticated']
  if (!isUserAuthenticated) {
    const targetRoute = {
      name: route.name?.split(ROUTE_LOCALE_SEPARATOR)[0] || ROUTES.HOME,
      ...(route.params?.slug && { params: route.params }),
      ...(route.query && { query: omit(route.query, ['intent', 'slug']) }),
    }
    app.store.dispatch('account/setAuthRedirectTarget', targetRoute)

    return redirect(
      applyPrefix(
        app.localePath({
          name: ROUTES.GET_STARTED,
          query: {
            intent: targetRoute.name,
            ...(targetRoute.params?.slug && { slug: targetRoute.params.slug }),
            ...route.query,
          },
        })
      )
    )
  }
}
