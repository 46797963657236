










import { ref, useStore, computed, watch } from '@nuxtjs/composition-api';
import IRootState from '~/types/app/State';
const __sfc_main = {
  name: 'LogoutSuccesOverlay'
};

__sfc_main.setup = (__props, __ctx) => {
  const showOverlay = ref(false);
  const store = useStore<IRootState>();
  const isUserAuthenticated = computed<boolean>(() => store.getters['account/isUserAuthenticated']);
  watch(() => isUserAuthenticated.value, isUserAuthenticated => {
    if (!isUserAuthenticated) {
      showOverlay.value = true;
    }
  });

  function hideOverlay() {
    showOverlay.value = false;
  }

  return {
    showOverlay,
    hideOverlay
  };
};

export default __sfc_main;
