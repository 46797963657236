export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_MULTIPLE_TO_CART = 'ADD_MULTIPLE_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const RESET_CART = 'RESET_CART'
export const SET_HOMEPAGE_SECTIONS = 'SET_HOMEPAGE_SECTIONS'
export const SET_PRODUCT_VIEWED_TRACKING_DETAILS =
  'SET_PRODUCT_VIEWED_TRACKING_DETAILS'
export const SET_COLLECTION_PAGE_REFFER = 'SET_COLLECTION_PAGE_REFFER'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const ADD_PRODUCTS = 'ADD_PRODUCTS'
export const ADD_REPORT_ITEM = 'ADD_REPORT_ITEM'
export const RESET_REPORT = 'RESET_REPORT'
export const SET_CATEGORIES_LAST_FETCHED = 'SET_CATEGORIES_LAST_FETCHED'
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const SET_IS_HUB_UPDATE_NEEDED = 'SET_IS_HUB_UPDATE_NEEDED'
export const SET_HUB = 'SET_HUB'
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS'
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS'
export const SET_CART_ID = 'SET_CART_ID'
export const SET_PAYMENT_GATEWAY = 'SET_PAYMENT_GATEWAY'
export const SET_ACTIVE_SUB_CATEGORY = 'SET_ACTIVE_SUBCATEGORY'
export const SET_IS_INITIAL_ONBOARDING_DONE = 'SET_IS_INITIAL_ONBOARDING_DONE'
export const SET_USER_ONBOARDING_STATE = 'SET_USER_ONBOARDING_STATE'
export const SET_SCHEDULING_STATE = 'SET_SCHEDULING_STATE'
export const SET_SCHEDULING_STATE_ON_CHECKOUT =
  'SET_SCHEDULING_STATE_ON_CHECKOUT'
export const SET_DELIVERY_OPTIONS_ON_CHECKOUT =
  'SET_DELIVERY_OPTIONS_ON_CHECKOUT'
export const SET_SCHEDULING_TEXT = 'SET_SCHEDULING_TEXT'
export const SET_SCHEDULING_CLICKABLE = 'SET_SCHEDULING_CLICKABLE'
export const SET_SELECTED_TIMESLOT = 'SET_SELECTED_TIMESLOT'
export const SET_SCHEDULING_OPTIONS = 'SET_SCHEDULING_OPTIONS'
export const SET_ASAP_PDT = 'SET_ASAP_PDT'
export const SET_ASAP_TOKEN = 'SET_ASAP_TOKEN'
export const SET_SHOW_ONBOARDING_MODAL = 'SET_SHOW_ONBOARDING_MODAL'
export const SET_SHOW_ONBOARDING_HINT = 'SET_SHOW_ONBOARDING_HINT'
export const SET_SHOW_CART_CLEARED_MODAL = 'SET_SHOW_CART_CLEARED_MODAL'
export const SET_SHOW_NO_DELIVERY_TO_LOCATION_MODAL =
  'SET_SHOW_NO_DELIVERY_TO_LOCATION_MODAL'
export const SET_SHIPPING_ADDRESS = 'SET_SHIPPING_ADDRESS'
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD'
export const SET_DELIVERY_ETA = 'SET_DELIVERY_ETA'
export const SET_DELIVERY_COORDINATES = 'SET_DELIVERY_COORDINATES'
export const SET_DISCOUNT = 'SET_DISCOUNT'
export const SET_VERIFIED_AGE = 'SET_VERIFIED_AGE'
export const SET_VERIFIED_TOBACCO = 'SET_VERIFIED_TOBACCO'
export const SET_AGE_VERIFICATION = 'SET_AGE_VERIFICATION'
export const SET_CLOSING_SOON_MODAL_SHOWN = 'SET_CLOSING_SOON_MODAL_SHOWN'
export const SET_STORE_CLOSED_MODAL_SHOWN = 'SET_STORE_CLOSED_MODAL_SHOWN'
export const SET_PREVIOUS_CART = 'SET_PREVIOUS_CART'
export const SET_EMAIL = 'SET_EMAIL'
export const SET_FEATURE_ENABLED = 'SET_FEATURE_ENABLED'
export const SET_SHOWN_MESSAGE_ID = 'SET_SHOWN_MESSAGE_ID'
export const SET_MESSAGE = 'SET_MESSAGE'
export const RESET_MESSAGE = 'RESET_MESSAGE'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'
export const SET_DELIVERY_TIER_ID = 'SET_DELIVERY_TIER_ID'
export const SET_DELIVERY_TIERS = 'SET_DELIVERY_TIERS'
export const SET_REMOTE_CART = 'SET_REMOTE_CART'
export const SET_CART = 'SET_CART'
export const SET_APPLIED_PROMOTIONS = 'SET_APPLIED_PROMOTIONS'
export const SET_ROUTER_HISTORY = 'SET_ROUTER_HISTORY'
export const SET_TIMESLOT_START = 'SET_TIMESLOT_START'
export const SET_TIMESLOT_END = 'SET_TIMESLOT_END'
export const SET_TIMESLOT_TYPE = 'SET_TIMESLOT_TYPE'
export const SET_PREV_DELIVERY_TIER_INFO = 'SET_PREV_DELIVERY_TIER_INFO'
export const SET_PREPARE_CART_FOR_CHECKOUT_ERROR =
  'SET_PREPARE_CART_FOR_CHECKOUT_ERROR'
export const RESET_PREPARE_CART_FOR_CHECKOUT_ERROR =
  'RESET_PREPARE_CART_FOR_CHECKOUT_ERROR'

export const ACCOUNT_PREFIX = 'account'
export const SET_USER = 'SET_USER'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const LOG_OUT = 'LOG_OUT'
export const SET_AUTH_REDIRECT_TARGET = 'SET_AUTH_REDIRECT_TARGET'
export const SET_FIRST_AUTH_RAN = 'SET_FIRST_AUTH_RUN'
export const SET_ADDRESSES = 'SET_ADDRESSES'
export const SET_ADDRESS_BOOK_VERSION = 'SET_ADDRESS_BOOK_VERSION'
export const SET_SHOW_ADDRESS_BOOK = 'SET_SHOW_ADDRESS_BOOK'
export const SET_SEGMENT_ANONYMOUS_USER_ID = 'SET_SEGMENT_ANONYMOUS_USER_ID'
export const SET_LAST_BOUGHT_ITEMS = 'SET_LAST_BOUGHT_ITEMS'
export const SET_VOUCHER_WALLET = 'SET_VOUCHER_WALLET'
export const SET_DEFAULT_USER_ID = 'SET_DEFAULT_USER_ID'

export const SEARCH_PREFIX = 'search'
export const SET_SEARCH_LABELS_TITLE = 'SET_SEARCH_LABELS_TITLE'
export const SET_SEARCH_LABELS = 'SET_SEARCH_LABELS'

export const CHECKOUT_PREFIX = 'checkout'
export const SET_IS_STORAGE_FEE_MODAL_VISIBLE =
  'SET_IS_STORAGE_FEE_MODAL_VISIBLE'
export const SET_HAD_STORAGE_FEE = 'SET_HAD_STORAGE_FEE'
export const SET_IS_SUP_FEE_MODAL_VISIBLE = 'SET_IS_SUP_FEE_MODAL_VISIBLE'
export const SET_IS_FEE_OVERVIEW_MODAL_VISIBLE =
  'SET_IS_FEE_OVERVIEW_MODAL_VISIBLE'
export const SET_LATE_NIGHT_CONFIG = 'SET_LATE_NIGHT_CONFIG'
export const SET_CONFLICTING_LINES = 'SET_CONFLICTING_LINES'
export const SET_CART_UPDATE_CALLS_PENDING = 'SET_CART_UPDATE_CALLS_PENDING'

export const SET_CURRENT_LATENCY_TRACING = 'SET_CURRENT_LATENCY_TRACING'

export const SET_FOOTER_CONTENT = 'SET_FOOTER_CONTENT'

export const SET_SUBSCRIPTION_ELIGIBLE_STATUS =
  'SET_SUBSCRIPTION_ELIGIBLE_STATUS'
export const SET_SUBSCRIPTION_MINIMUM_ORDER_VALUE =
  'SET_SUBSCRIPTION_MINIMUM_ORDER_VALUE'
export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS'
export const SET_SUBSCRIPTION_DETAIL = 'SET_SUBSCRIPTION_DETAIL'
export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS'
export const SET_SUBSCRIPTION_SELECTED_PLAN = 'SET_SUBSCRIPTION_SELECTED_PLAN'
export const SET_SUBSCRIPTION_PAYMENT_METHODS =
  'SET_SUBSCRIPTION_PAYMENT_METHODS'
export const SET_SUBSCRIPTION_ORIGIN_COMPONENT =
  'SET_SUBSCRIPTION_ORIGIN_COMPONENT'
export const SET_EXPERIMENT_DATA = 'SET_EXPERIMENT_DATA'
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_SUGGESTION_CLICK_POSITION = 'SET_SUGGESTION_CLICK_POSITION'
export const SET_SUGGESTION_QUERY_ID = 'SET_SUGGESTION_QUERY_ID'
