import { ROUTES } from '~/lib/routes'
import { applyPrefix } from '~/lib/helpers'
import { Context } from '@nuxt/types'

export default function ({
  redirect,
  app,
  route,
}: Context): ReturnType<typeof redirect> {
  const collectionId = route.params.slug
  let redirectPath = app.localePath(ROUTES.HOME)

  if (!collectionId) {
    throw new Error('Collection ID was not passed as slug')
  }

  if (collectionId) {
    redirectPath = app.localePath({
      name: ROUTES.COLLECTION,
      params: { slug: collectionId },
    })
  }

  return redirect(applyPrefix(redirectPath))
}
