





















import IMenuItem from '~/types/app/MenuItem';
import { ref, onMounted } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'Menu'
};
__sfc_main.props = {
  heading: {
    type: String,
    default: ''
  },
  links: {
    type: (Array as () => IMenuItem[]),
    default: () => []
  },
  selectedItem: {
    type: String,
    default: ''
  },
  selectedSubItem: {
    type: String,
    default: ''
  },
  scrollable: {
    type: Boolean,
    default: false
  },
  routeName: {
    type: String,
    default: 'slug'
  },
  showChildren: {
    type: Boolean,
    default: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const menu = ref<HTMLElement | null>(null);
  onMounted(() => {
    if (props.scrollable && props.selectedItem && menu.value) {
      setTimeout(() => {
        if (menu.value) {
          const selectedElement = (menu.value.querySelector('li.--selected') as HTMLElement);
          menu.value.scrollTo({
            top: selectedElement?.offsetTop,
            behavior: 'auto'
          });
        }
      }, 100);
    }
  });
  return {
    menu
  };
};

export default __sfc_main;
