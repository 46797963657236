



















import { useStore, useContext, useRouter } from '@nuxtjs/composition-api';
import { ISearchSuggestion } from '~/api/services/get-search-suggestions';
import { ROUTES } from '~/lib/routes';
const __sfc_main = {
  name: 'SearchSuggestion'
};
__sfc_main.props = {
  suggestions: {
    type: (Array as () => ISearchSuggestion[]),
    required: true
  },
  loading: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const store = useStore();
  const router = useRouter();
  const {
    localePath
  } = useContext();

  function handleSuggestionClicked(index: number) {
    emit('suggetion-clicked');
    store.dispatch('catalog/setSuggestionClickPosition', index + 1);
    router.push({
      path: localePath(ROUTES.SEARCH),
      query: {
        q: props.suggestions[index].text
      }
    });
  }

  return {
    handleSuggestionClicked
  };
};

export default __sfc_main;
