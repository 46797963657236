import { Plugin } from '@nuxt/types'

const throwErrorPlugin: Plugin = (context, inject) => {
  const throwError = (statusCode: number, message: string) => {
    if (process.server) {
      context.app.context.res.statusCode = statusCode
    }

    throw context.error({ statusCode, message })
  }

  return inject('throwError', throwError)
}

export default throwErrorPlugin
