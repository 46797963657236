















import IPrice from '~/types/app/Price';
const __sfc_main = {};
__sfc_main.props = {
  price: {
    type: [(Object as () => IPrice), String],
    required: true
  },
  originalPrice: {
    type: [(Object as () => IPrice), String],
    default: null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

export default __sfc_main;
