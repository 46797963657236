/* eslint-disable camelcase */
import { computed, useStore, Ref } from '@nuxtjs/composition-api'
import IRootState from '~/types/app/State'
import { ExperimentKeys } from '~/types/app/experiments'

enum CardSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

enum ExperimentVariation {
  product_tiles_112 = 'product_tiles_112',
  product_tiles_168 = 'product_tiles_168',
}

const variationMappping: Record<ExperimentVariation, CardSize> = {
  product_tiles_112: CardSize.small,
  product_tiles_168: CardSize.large,
}

export default function useCardSizeFromExperiment(): Ref<CardSize> {
  const store = useStore<IRootState>()
  const cardSize = computed<CardSize>(() => {
    const variation = store.getters['experiments/getDecision'](
      ExperimentKeys.PRODUCT_TILES_SIZE
    )?.variation

    return variationMappping[variation as ExperimentVariation] || CardSize.small
  })

  return cardSize
}
