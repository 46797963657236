/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { ICoordinates } from '~/types/app/Location'
import { LoggerFunction } from '~/types/app/Logger'

export interface IDeliveryEstimate {
  pdt_minutes: string
  formatted_pdt?: string
}

interface IPayload {
  deliveryCoordinates: ICoordinates
  locale: string
  hubSlug: string
  cartId?: string
}

export default async function getDeliveryEstimate(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  { cartId, deliveryCoordinates, hubSlug, locale }: IPayload
): Promise<IDeliveryEstimate> {
  const headers = {
    locale,
  }

  const url = `/orders/delivery-estimate?hub_slug=${hubSlug}&delivery_coords=${
    deliveryCoordinates.latitude
  },${deliveryCoordinates.longitude}&cart_id=${cartId || ''}`

  try {
    const {
      data: { pdt_minutes, formatted_pdt },
    } = await get<IDeliveryEstimate>(client, logger, url, headers)

    return {
      ...(formatted_pdt && { formatted_pdt }),
      pdt_minutes: String(pdt_minutes),
    }
  } catch (error) {
    return {} as IDeliveryEstimate
  }
}
