import {
  V1Decision,
  V1Platform,
  UserTrackingServiceApi,
  V1ExperimentLookupRequest,
} from '@flink/experiment'
import {
  ExperimentKeys,
  FeatureFlags,
  UserAttributes,
} from '~/types/app/experiments'
import { debounce } from 'lodash-es'
import { IAppState } from '~/types/app/State'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getUserFlinkUID, getCountryFromLocale } from '~/lib/helpers'
import { isDecisionEnabled } from '~/lib/experiments'
import { SET_EXPERIMENT_DATA } from './mutation-types'

export interface IExperimentState {
  decisions: {
    [key: string]: V1Decision
  }
}

export const state = (): IExperimentState => ({
  decisions: {},
})

export const getters: GetterTree<IExperimentState, IAppState> = {
  getDecision:
    (state) =>
    (featureFlag: ExperimentKeys): V1Decision => {
      return state.decisions[featureFlag]
    },
  isFeatureEnabled:
    (state) =>
    (featureFlag: FeatureFlags): boolean =>
      isDecisionEnabled(state.decisions[featureFlag]),
}

export const mutations: MutationTree<IExperimentState> = {
  [SET_EXPERIMENT_DATA](state, experimentData) {
    state.decisions = {
      ...state.decisions,
      ...experimentData.decisions,
    }
  },
}

export const actions: ActionTree<IExperimentState, IAppState> = {
  fetchExperimentData: debounce(function ({ dispatch }, flags: string[]) {
    dispatch('fetchData', flags)
  }, 1000),
  async fetchData(
    { commit, dispatch, rootState, rootGetters },
    flags: string[]
  ): Promise<{ [key: string]: V1Decision }> {
    try {
      const api = new UserTrackingServiceApi(
        {},
        `${this.app.$config.apiBaseUrl}user-tracking-gateway/user-tracking-gateway`
      )
      const flinkUid = getUserFlinkUID(this.$cookies)
      const anonymousId = rootState.account.segmentAnonymousUserId
      const userId = rootState.account.user?.uid
      const country = getCountryFromLocale(this.$i18n.locale)

      const body: V1ExperimentLookupRequest = {
        anonymousId,
        userId,
        flags,
        userTrackingId: flinkUid,
        platform: V1Platform.EppoConsumer,
        meta: {
          attributes: {
            [UserAttributes.COUNTRY_ISO]: country,
            [UserAttributes.COUNTRY_OF_DELIVERY]: country,
            [UserAttributes.EMAIL]: rootState.account.user?.email || '',
            [UserAttributes.IS_LOGGED_IN]: `${rootGetters['account/isUserAuthenticated']}`,
            [UserAttributes.HUB_SLUG]: rootState.hub?.slug || '',
            [UserAttributes.PLATFORM]: 'web',
            [UserAttributes.LAST_CHAR_UID]: flinkUid.slice(-1).toLowerCase(),
            [UserAttributes.DELIVERY_ETA]: rootState.deliveryETA
              ? `${parseInt(rootState.deliveryETA)}`
              : '0',
          },
        },
      }

      const { data } = await api.userTrackingServiceExperimentLookup(body)
      commit(SET_EXPERIMENT_DATA, data)

      // set click and collect to true if it is a hub user and not already set as hub user
      if (
        !rootGetters['account/isHubUser'] &&
        isDecisionEnabled(data.decisions?.[FeatureFlags.WEB_HUB_USER])
      ) {
        dispatch('updateClickAndCollect', true, { root: true })
      }

      return data.decisions || {}
    } catch (e) {
      return {}
    }
  },
}
