

















import { useContext } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'StoreBadge'
};
__sfc_main.props = {
  storeUrl: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  imageUrl: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    $segmentEvent
  } = useContext();

  function trackOutboundAppClicked(): void {
    $segmentEvent.OUTBOUND_APP_CLICKED();
  }

  return {
    trackOutboundAppClicked
  };
};

export default __sfc_main;
