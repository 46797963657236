/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { put } from '~/api/controllers/put'
import { LoggerFunction } from '~/types/app/Logger'
import { ESchedulingOptionType } from '~/types/app/PlannedOrders'

interface IAddressPayload {
  address_id: string
  city: string
  country: string
  delivery_coordinates: {
    latitude: 0
    longitude: 0
  }
  delivery_notes: string
  postal_code: string
  street_address: string
  tag: string
}

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  hubSlug: string
  cartId: string
  billingAddress?: IAddressPayload
  shippingAddress?: IAddressPayload
  shippingMethodId?: string
  updatedSlot?: {
    id?: string
    type: ESchedulingOptionType
  }
}

interface IResponse {
  errorMessage?: string
}

export default async function updateDeliveryInformation({
  client,
  logger,
  locale,
  hubSlug,
  cartId,
  billingAddress,
  shippingAddress,
  shippingMethodId,
  updatedSlot,
}: IParameters): Promise<IResponse> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  try {
    const response = await put<IResponse>(
      client,
      logger,
      `/cart/${cartId}/delivery-information`,
      headers,
      {
        ...(billingAddress && {
          billing_address: billingAddress,
        }),
        ...(shippingAddress && {
          shipping_address: shippingAddress,
        }),
        ...(shippingMethodId && {
          shipping_method_id: shippingMethodId,
        }),
        ...(updatedSlot && {
          updated_slot: updatedSlot,
        }),
      }
    )

    return response.data
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error)
    return {
      errorMessage,
    }
  }
}
