import { get } from '~/api/controllers/get'
import { IPaymentStatusV2 } from '~/types/app/Cart'
import {
  EHTTPMethods,
  ELoggers,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'
import { AxiosError } from 'axios'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
  cartId: string
}

export default async function getPaymentStatus({
  client,
  logger,
  locale,
  hubSlug,
  cartId,
}: IParameters): Promise<IPaymentStatusV2 | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  let paymentStatus: IPaymentStatusV2 | undefined
  const url = `/cart/${cartId}/payment-status-in-store`

  try {
    const response = await get<IPaymentStatusV2>(client, logger, url, headers)
    paymentStatus = response.data

    logger({
      level: ELogLevels.INFO,
      msg: 'API Success: getPaymentStatus successful.',
      logger: ELoggers.CART,
      func: 'getPaymentStatus',
      'http.method': EHTTPMethods.GET,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      slug: 'checkout.api.get-payment-status.success',
      forceSubmit: false,
    })

    return paymentStatus
  } catch (error) {
    const statusCode = (error as AxiosError).response?.status || EStatus.FAILED

    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: getPaymentStatus failed.',
      error: error as string,
      logger: ELoggers.CART,
      func: 'getPaymentStatus',
      'http.method': EHTTPMethods.GET,
      'http.url': url,
      'http.status_code': statusCode as string,
      slug: 'checkout.api.get-payment-status.error',
    })
    throw error
  }
}
