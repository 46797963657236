import { render, staticRenderFns } from "./ProductCard.vue?vue&type=template&id=7920bcdb&scoped=true&"
import script from "./ProductCard.vue?vue&type=script&lang=ts&"
export * from "./ProductCard.vue?vue&type=script&lang=ts&"
import style0 from "./ProductCard.vue?vue&type=style&index=0&id=7920bcdb&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7920bcdb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DiscountLabel: require('/app/build/components/DiscountLabel/DiscountLabel.vue').default,FeatureFlag: require('/app/build/components/FeatureFlag/FeatureFlag.vue').default,VCloudinaryImage: require('/app/build/components/VCloudinaryImage/VCloudinaryImage.vue').default,QuantityControl: require('/app/build/components/QuantityControl/QuantityControl.vue').default,PriceTag: require('/app/build/components/PriceTag/PriceTag.vue').default,PromotionLabel: require('/app/build/components/ProductList/PromotionLabel.vue').default,ProductSubstitutionModal: require('/app/build/components/ProductSubstitutionModal/ProductSubstitutionModal.vue').default})
