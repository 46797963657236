






























































import { useContext, useRoute, useRouter, useStore } from '@nuxtjs/composition-api';
import IProduct from '~/types/app/Product';
import { EProductPlacement, getScreenName } from '~/lib/segment';
import { FeatureFlags } from '~/types/app/experiments';
import { ROUTES } from '~/lib/routes';
import { PROMOTION_LABEL_VARIANTS } from '~/lib/constants';
import PromotionLabel from './PromotionLabel.vue';
const __sfc_main = {};
__sfc_main.props = {
  product: {
    type: (Object as () => IProduct),
    required: true
  },
  clickable: {
    type: Boolean,
    default: false
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    default: EProductPlacement.pdp
  },
  hasAnAppliedPromotion: {
    type: Boolean,
    default: false
  },
  inlinePromotionLabel: {
    type: String,
    default: ''
  },
  inlinePromotionVariant: {
    type: (String as () => PROMOTION_LABEL_VARIANTS),
    default: PROMOTION_LABEL_VARIANTS.INITIAL
  },
  positionInGroup: {
    type: Number,
    required: false,
    default: undefined
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    localePath
  } = useContext();
  const router = useRouter();
  const store = useStore();
  const route = useRoute().value;
  const productPath = localePath({
    name: ROUTES.PRODUCT,
    params: {
      slug: `${props.product.slug}-${props.product.sku}`
    }
  });

  function handleProductClick() {
    const {
      clickable,
      product
    } = props;

    if (clickable) {
      store.dispatch('catalog/setProductViewedTrackingDetails', {
        listName: props.productPlacement,
        productPlacement: props.productPlacement,
        productPosition: props.positionInGroup,
        productContext: product.context,
        eventOrigin: getScreenName(route)
      });
      router.push({
        path: productPath
      });
    }
  }

  return {
    FeatureFlags,
    productPath,
    handleProductClick
  };
};

__sfc_main.components = Object.assign({
  PromotionLabel
}, __sfc_main.components);
export default __sfc_main;
