export const LOCALES = [
  {
    code: 'en',
    iso: 'en',
    file: 'en.json',
  },
  {
    code: 'de-DE',
    iso: 'de-DE',
    file: 'de.json',
  },
  {
    code: 'en-DE',
    iso: 'en-DE',
    file: 'en.json',
  },
  {
    code: 'nl-NL',
    iso: 'nl-NL',
    file: 'nl.json',
  },
  {
    code: 'en-NL',
    iso: 'en-NL',
    file: 'en.json',
  },
]

export const FALLBACK_LOCALE = 'en'

export const DATE_TIME_LOCALES = {
  en: {
    'short-without-year': {
      day: 'numeric',
      month: 'short',
    },
  },
  'de-DE': {
    'short-without-year': {
      day: 'numeric',
      month: 'short',
    },
  },
  'en-DE': {
    'short-without-year': {
      day: 'numeric',
      month: 'short',
    },
  },
  'nl-NL': {
    'short-without-year': {
      day: 'numeric',
      month: 'short',
    },
  },
  'en-NL': {
    'short-without-year': {
      day: 'numeric',
      month: 'short',
    },
  },
}
