import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { ICoordinates } from '~/types/app/Location'
import { LoggerFunction } from '~/types/app/Logger'
import { IDeliveryTiers, IDeliveryTiersDetails } from '~/types/app/Hub'
import { EShippingMethod } from '~/types/app/Cart'
import { ICentPrice } from '~/types/app/Price'

interface IResponse {
  checkoutMinimumOrderValue: ICentPrice
  deliveryTiers: IDeliveryTiers[]
  deliveryTiersDetails: IDeliveryTiersDetails[]
  deliveryTierId?: string
  shippingMethodId?: string
  smallOrderThreshold?: ICentPrice
}

export default async function getDeliveryTiers(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  locale: string,
  hubSlug: string,
  hubCountry: string,
  deliveryCoordinates: ICoordinates,
  shippingMethod: EShippingMethod,
  cartId?: string
): Promise<IResponse | Record<string, undefined>> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  hubCountry = hubCountry.toLowerCase()

  const isClickAndCollect: boolean =
    shippingMethod === EShippingMethod.CLICK_AND_COLLECT
  const isKiosk: boolean = shippingMethod === EShippingMethod.KIOSK

  let url: string

  if (isClickAndCollect || isKiosk) {
    const shippingMethodMap: Record<EShippingMethod, string> = {
      [EShippingMethod.CLICK_AND_COLLECT]: 'click-and-collect',
      [EShippingMethod.KIOSK]: 'in-store-payment',
      [EShippingMethod.DELIVERY]: 'delivery',
    }
    url = `/cart/delivery-tiers/${hubCountry}?shipping_method=${shippingMethodMap[shippingMethod]}`
  } else {
    url = `/cart/delivery-tiers/${hubCountry}?latitude=${deliveryCoordinates.latitude}&longitude=${deliveryCoordinates.longitude}`
  }

  url += cartId ? `&cart_id=${cartId}` : ''

  try {
    const response = await get<IResponse>(client, logger, url, headers)
    return response.data
  } catch (error) {
    return {}
  }
}
