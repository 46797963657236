import {
  Token,
  IPaymentGateway,
  IDefaultPaymentMethod,
  GetOrdersPaymentMethodsResponse,
} from '~/types/app/Payments'

const transformDefault = (data: Token): IDefaultPaymentMethod => {
  return {
    brand: data.brand || '',
    lastFour: data.cardLastFourDigits || '',
    name: data.name || '',
    pspToken: data.token,
    type: data.type,
  }
}

export default function transformPaymentMethodResponse(
  response: GetOrdersPaymentMethodsResponse
): IPaymentGateway | undefined {
  try {
    if (!response.clientConfig.sdkConfig) {
      return undefined
    }
    const config = JSON.parse(response.clientConfig.sdkConfig)
    const defaultPaymentReference =
      response.paymentMethods.default?.tokenUniqueReference
    const defaultToken = response.paymentMethods.tokens?.find(
      (t) => t.tokenUniqueReference === defaultPaymentReference
    )
    return {
      client_api_key: response.clientConfig.clientKey,
      name: 'Adyen',
      preferredMethods: [], // PLEASE NOTE: prefferedMethods are not returned with the payment BFF (used for e.g. Mastercard campaign)
      config,
      defaultPaymentMethod: defaultToken
        ? transformDefault(defaultToken)
        : undefined,
    }
  } catch (e) {
    return undefined
  }
}
