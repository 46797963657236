import { Route } from 'vue-router'
import { uniq } from 'lodash-es'
import { LOCALES, FALLBACK_LOCALE } from '~/localesConfig'
import { hasLocalePrefix } from '@/lib/helpers'
import { applyPrefix } from '~/lib/helpers'

function getQueryString(route: Route): string {
  const query = route.fullPath?.split('?')[1]
  return query ? `?${query}` : ''
}

export default function ({
  redirect,
  route,
}: {
  redirect: any
  route: Route
}): void {
  if (
    route.path &&
    !route.path.includes('/webview') &&
    !route.path.includes('/reset-password')
  ) {
    return
  }

  const [, firstSlug, ...rest] = route.path.split('/')

  if (!hasLocalePrefix(firstSlug)) {
    return
  }

  const acceptedLocales = LOCALES.map((locale) => locale.code)
  const query = getQueryString(route)

  if (!acceptedLocales.includes(firstSlug)) {
    const [lang, country] = firstSlug.split('-')
    const acceptedLanguages = uniq(
      acceptedLocales.map((locale) => locale.split('-')[0])
    )

    if (lang && acceptedLanguages.includes(lang)) {
      const countryPart =
        lang !== FALLBACK_LOCALE ? `-${lang.toUpperCase()}` : ''

      return redirect(
        301,
        applyPrefix(`/${lang}${countryPart}/${rest.join('/')}${query}`)
      )
    } else {
      const acceptedCountries = uniq(
        acceptedLocales.map((locale) => locale.split('-')[1])
      )

      const countryPart =
        country && acceptedCountries.includes(country)
          ? `-${country.toUpperCase()}`
          : ''

      return redirect(
        301,
        applyPrefix(
          `/${FALLBACK_LOCALE}${countryPart}/${rest.join('/')}${query}`
        )
      )
    }
  }
}
