/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'

interface IParameters {
  client: NuxtAxiosInstance
}

interface Response {
  is_sms_verified: boolean
  code?: string
  name?: string
  message?: string
}

export default async function isUserSMSVerified(
  { client }: IParameters,
  logger: LoggerFunction
): Promise<boolean> {
  const url = '/me/status'

  let resp = false

  try {
    const { data } = await get<Response>(client, logger, url)
    resp = data.is_sms_verified
  } catch (error) {
    const msg = error instanceof Error ? error.message : String(error)

    logger({
      level: ELogLevels.ERROR,
      msg,
      'http.method': EHTTPMethods.GET,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      logger: 'API Services',
      func: 'isUserSMSVerified',
    })
  }
  return resp
}
