import { render, staticRenderFns } from "./ClickCollectInput.vue?vue&type=template&id=4f2e0377&scoped=true&"
import script from "./ClickCollectInput.vue?vue&type=script&lang=ts&"
export * from "./ClickCollectInput.vue?vue&type=script&lang=ts&"
import style0 from "./ClickCollectInput.vue?vue&type=style&index=0&id=4f2e0377&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f2e0377",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/build/components/VIcon/VIcon.vue').default,Button: require('/app/build/components/Button/Button.vue').default,PrepTimeHint: require('/app/build/components/PrepTimeHint/PrepTimeHint.vue').default,ClickCollectDistance: require('/app/build/components/ClickCollectDistance/ClickCollectDistance.vue').default,GetDirections: require('/app/build/components/GetDirections/GetDirections.vue').default,DeliveryTimeStaticMap: require('/app/build/components/DeliveryTimeStaticMap/DeliveryTimeStaticMap.vue').default,DeliveryTimeOptionWrapper: require('/app/build/components/DeliveryTimeOptionsWrapper/DeliveryTimeOptionWrapper.vue').default,DeliveryAddressInput: require('/app/build/components/DeliveryAddressInput/DeliveryAddressInput.vue').default})
