




























































































import { EComponentContent, EComponentName, ETrackingType, getScreenName } from '~/lib/segment';
import { computed, ref, useContext, useRoute, useRouter, useStore, onBeforeUnmount, onMounted, watch } from '@nuxtjs/composition-api';
import { theme } from '~/tailwind.config';
import useWindowMinWidth from '~/hooks/useWindowMinWidth';
import { isDeliveryETAAboveOrEqualThreshold } from '~/lib/helpers';
import IRootState, { EOnboardingHintType } from '~/types/app/State';
import { eventBus as popoverEventBus } from '~/components/VPopover/event-bus';
import HighDeliveryTimeTooltip from '~/components/HighDeliveryTimeTooltip/HighDeliveryTimeTooltip.vue';
import LocationChangeHintPopover from '~/components/LocationChangeHintPopover/LocationChangeHintPopover.vue';
import DeliveryBannerGenericPopover from '~/components/DeliveryBannerGenericPopover/DeliveryBannerGenericPopover.vue';
type PopoverTargets = Record<EOnboardingHintType, HTMLDivElement | null>;
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({
  name: 'DeliveryBanner'
});

__sfc_main.props = {
  deliveryEta: {
    type: String,
    default: ''
  },
  deliveryAddress: {
    type: String,
    default: ''
  },
  openingHours: {
    type: String,
    default: ''
  },
  onboardingHintShown: {
    type: Boolean,
    default: false
  },
  canShowHighDeliveryTimeHint: {
    type: Boolean,
    default: true
  },
  canShowLocationChangeHint: {
    type: Boolean,
    default: true
  },
  showNoDeliveryToLocationPopover: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const highPDTThresholdMinutes = 45;
  const POPOVER_NAMES = {
    [EOnboardingHintType.INITIAL_ONBOARDING]: 'location-change-hint',
    [EOnboardingHintType.HIGH_PDT]: 'high-pdt-tooltip',
    [EOnboardingHintType.NO_LONGER_IN_DELIVERY]: 'no-longer-in-delivery-tooltip'
  };
  const props = __props;
  const emit = __ctx.emit;
  const {
    i18n,
    $segmentEvent,
    $segmentIdentify
  } = useContext();
  const store = useStore<IRootState>();
  const route = useRoute();
  const unsubscribe = ref<() => void>();
  onMounted(() => {
    calculateHighPDTThreshold();
    unsubscribe.value = store.subscribeAction({
      after: action => {
        if (action.type === 'updateDeliveryTiers') {
          $segmentIdentify();
        }
      }
    });
    setTimeout(() => {
      showLocationOnboardingHint();
    }, 5000);
  });
  onBeforeUnmount(() => {
    if (unsubscribe.value) unsubscribe.value();
  });
  const router = useRouter();
  const screenSizeMd = (`${parseInt(theme.screens.md, 10)}px` as `${number}px`);
  const isMd = useWindowMinWidth(screenSizeMd);
  const getIsOnboardSuccess = computed<boolean>(() => {
    return store.getters.getIsOnboardSuccess;
  });
  const isAddressDefined = computed<boolean>(() => {
    return getIsOnboardSuccess.value && !!props.deliveryAddress;
  });
  const openingHoursLabel = computed<string>(() => {
    let prefix = props.openingHours ? i18n.t('generic_open') : i18n.t('generic_closed');
    prefix = prefix.toString().toUpperCase();
    return props.openingHours ? `${prefix} ${props.openingHours}` : prefix;
  });

  function onDeliveryAddressClicked(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: getScreenName(route.value),
      componentName: EComponentName.addressLine,
      componentContent: isAddressDefined.value ? EComponentContent.address : undefined
    });
    emit('delivery-banner-clicked');
  }

  const deliveryBannerElement = ref<HTMLDivElement | null>(null);
  const deliveryEtaElement = ref<HTMLDivElement | null>(null);
  const deliveryEtaText = computed<string>(() => {
    return `${props.deliveryEta} ${i18n.t('generic_minutes')}`;
  });
  const popoverTargets = computed<PopoverTargets>(() => {
    return {
      [EOnboardingHintType.INITIAL_ONBOARDING]: deliveryBannerElement.value,
      [EOnboardingHintType.HIGH_PDT]: isMd.value ? deliveryBannerElement.value : deliveryEtaElement.value,
      [EOnboardingHintType.NO_LONGER_IN_DELIVERY]: deliveryBannerElement.value
    };
  });
  const popoverArrowAnchors = computed<PopoverTargets>(() => {
    return {
      [EOnboardingHintType.INITIAL_ONBOARDING]: isMd.value ? deliveryEtaElement.value : null,
      [EOnboardingHintType.HIGH_PDT]: deliveryEtaElement.value,
      [EOnboardingHintType.NO_LONGER_IN_DELIVERY]: deliveryBannerElement.value
    };
  });
  const deliveryBannerTargets = computed<string>(() => getTargetData(popoverTargets.value, deliveryBannerElement.value));
  const deliveryEtaTargets = computed<string>(() => getTargetData(popoverTargets.value, deliveryEtaElement.value));
  const deliveryEtaArrowAnchors = computed<string>(() => getTargetData(popoverArrowAnchors.value, deliveryEtaElement.value));

  function getTargetData(targets: PopoverTargets, element: HTMLDivElement | null): string {
    if (!element) {
      return '';
    }

    const accTargets = ([] as string[]);
    const entries = (Object.entries(targets) as Array<[EOnboardingHintType, PopoverTargets[keyof PopoverTargets]]>);
    entries.forEach(([key, value]) => {
      if (value === element) {
        accTargets.push(POPOVER_NAMES[key]);
      }
    });
    return accTargets.join(' ');
  }

  const isHighPDT = ref<boolean>(false);
  const highPDTThreshold = ref<number>();
  const currentOnboardingHint = ref<EOnboardingHintType>();

  function calculateHighPDTThreshold(): void {
    if (!props.onboardingHintShown) return;
    isHighPDT.value = isDeliveryETAAboveOrEqualThreshold(store.getters.getDeliveryETANumber, highPDTThresholdMinutes);
    highPDTThreshold.value = highPDTThresholdMinutes;
    showOnboardingHint();
  }

  function showOnboardingHint(): void {
    if (!props.onboardingHintShown) return;
    setCurrentOnboardingHint();
    const key = currentOnboardingHint.value;

    if (!key) {
      return;
    }

    popoverEventBus.$emit('popover:show', {
      target: popoverTargets.value[key],
      arrowAnchor: popoverArrowAnchors.value[key],
      name: POPOVER_NAMES[key]
    });
    emit('show-onboarding-hint', key);
  }

  function hideOnboardingHint(): void {
    const key = currentOnboardingHint.value;

    if (!key) {
      return;
    }

    popoverEventBus.$emit('popover:hide', {
      name: POPOVER_NAMES[key]
    });
    currentOnboardingHint.value = undefined;
  }

  function setCurrentOnboardingHint(): void {
    if (props.canShowHighDeliveryTimeHint && isHighPDT.value) {
      currentOnboardingHint.value = EOnboardingHintType.HIGH_PDT;

      if (highPDTThreshold.value) {
        $segmentEvent.TRACKING({
          trackingType: ETrackingType.sectionViewed,
          screenName: getScreenName(route.value),
          componentName: EComponentName.highPdtTooltip,
          componentContent: store.getters.getDeliveryETANumber
        });
      }

      return;
    }

    if (props.canShowLocationChangeHint) {
      currentOnboardingHint.value = EOnboardingHintType.INITIAL_ONBOARDING;
      return;
    }

    currentOnboardingHint.value = undefined;
  }

  watch(() => props.onboardingHintShown, () => {
    // only handle negative case, positive case needs to wait for experiment to finish
    if (!props.onboardingHintShown) {
      hideOnboardingHint();
    }
  });
  watch(isMd, showOnboardingHint);
  watch(() => props.showNoDeliveryToLocationPopover, showNoLongerInDeliveryPopup);

  function showNoLongerInDeliveryPopup(isVisible: boolean) {
    isVisible && popoverEventBus.$emit('popover:show', {
      target: popoverTargets.value[EOnboardingHintType.NO_LONGER_IN_DELIVERY],
      arrowAnchor: popoverArrowAnchors.value[EOnboardingHintType.NO_LONGER_IN_DELIVERY],
      name: POPOVER_NAMES[EOnboardingHintType.NO_LONGER_IN_DELIVERY]
    });
  }

  function showLocationOnboardingHint() {
    if (!isAddressDefined.value) {
      popoverEventBus.$emit('popover:show', {
        target: popoverTargets.value[EOnboardingHintType.INITIAL_ONBOARDING],
        arrowAnchor: popoverArrowAnchors.value[EOnboardingHintType.INITIAL_ONBOARDING],
        name: POPOVER_NAMES[EOnboardingHintType.INITIAL_ONBOARDING]
      });
    }
  }

  router.afterEach(() => {
    showLocationOnboardingHint();
  });
  return {
    EOnboardingHintType,
    POPOVER_NAMES,
    emit,
    isAddressDefined,
    openingHoursLabel,
    onDeliveryAddressClicked,
    deliveryBannerElement,
    deliveryEtaElement,
    deliveryEtaText,
    deliveryBannerTargets,
    deliveryEtaTargets,
    deliveryEtaArrowAnchors
  };
};

__sfc_main.components = Object.assign({
  LocationChangeHintPopover,
  HighDeliveryTimeTooltip,
  DeliveryBannerGenericPopover
}, __sfc_main.components);
export default __sfc_main;
