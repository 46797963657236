


















import { computed } from '@nuxtjs/composition-api';
import VIcon from '~/components/VIcon/VIcon.vue';
import { PROMOTION_LABEL_VARIANTS } from '~/lib/constants';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  label: {
    type: String,
    required: true
  },
  variant: {
    type: (String as () => PROMOTION_LABEL_VARIANTS),
    default: PROMOTION_LABEL_VARIANTS.INITIAL
  },
  fontClass: {
    type: String,
    required: false,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const labelClass = computed(() => {
    const classes = [];
    const defaultLabelClasses = 'text-xs font-medium';

    if (props.fontClass) {
      classes.push(props.fontClass);
    } else {
      classes.push(defaultLabelClasses);
    }

    if ([PROMOTION_LABEL_VARIANTS.INCOMPLETE, PROMOTION_LABEL_VARIANTS.OOS].includes(props.variant)) {
      classes.push('--secondary');
    }

    return classes.join(' ');
  });
  return {
    PROMOTION_LABEL_VARIANTS,
    labelClass
  };
};

__sfc_main.components = Object.assign({
  VIcon
}, __sfc_main.components);
export default __sfc_main;
