import { render, staticRenderFns } from "./DeliveryBannerETA.vue?vue&type=template&id=8c8ce7aa&scoped=true&"
import script from "./DeliveryBannerETA.vue?vue&type=script&lang=ts&"
export * from "./DeliveryBannerETA.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryBannerETA.vue?vue&type=style&index=0&id=8c8ce7aa&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c8ce7aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default})
