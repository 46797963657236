import { EProductPlacement } from '~/lib/segment'
import transformProducts from '../../transform-products'
import { SectionTransformResult } from '../shared'
import { EnhancedSwimlaneSectionRaw, V2HomeSectionRaw } from './types'

const check = (
  section: V2HomeSectionRaw
): section is EnhancedSwimlaneSectionRaw => 'enhancedSwimlane' in section

const transform = (
  section: EnhancedSwimlaneSectionRaw
): SectionTransformResult => {
  const { enhancedSwimlane } = section
  const products = transformProducts(enhancedSwimlane.previewProducts)

  let productPlacement = EProductPlacement.swimlane

  if (enhancedSwimlane.id === 'buy-again') {
    productPlacement = EProductPlacement.lastBought
  } else if (enhancedSwimlane.id === 'recommendation') {
    productPlacement = EProductPlacement.recommendation
  }

  return {
    type: 'swimlane',
    products,
    productPlacement,
    id: enhancedSwimlane.id,
    title: enhancedSwimlane.title,
    button: enhancedSwimlane.button,
    subtitle: enhancedSwimlane.subtitle,
    listName: enhancedSwimlane.trackingType,
    isHighlighted: enhancedSwimlane.isHighlighted,
  }
}

export const enhancedSwimlaneTransformer = { check, transform }
