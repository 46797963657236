import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { IVoucherWallet, IVoucherWalletRaw } from '~/types/app/VoucherWallet'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'
import { transformVoucherWallet } from '~/api/transformers/transform-voucher-wallet'
import {
  groupApplicableVouchers,
  prioritizeFreeDeliveryVouchers,
} from '~/lib/voucher-wallet/helpers'

interface IParameters {
  client: NuxtAxiosInstance
  locale: string
  cartId: string
}
export interface IVoucherWalletResponse {
  vouchers: IVoucherWalletRaw[]
}

export default async function getVoucherWallet(
  { client, locale, cartId }: IParameters,
  logger: LoggerFunction
): Promise<IVoucherWallet[]> {
  const headers = {
    locale,
  }

  const url = `/voucher/wallet?cart_id=${cartId}`

  try {
    const { data } = await get<IVoucherWalletResponse>(
      client,
      logger,
      url,
      headers
    )

    logger({
      level: ELogLevels.INFO,
      msg: 'Voucher applied successfully',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      logger: 'API Services',
      func: 'getVoucherWallet',
      slug: 'checkout.api.applyVoucher.success',
      forceSubmit: true,
    })

    const grouped = groupApplicableVouchers(data.vouchers)
    const reordered = [
      ...prioritizeFreeDeliveryVouchers(grouped.applicable),
      ...prioritizeFreeDeliveryVouchers(grouped.nonApplicable),
    ]

    return transformVoucherWallet(reordered)
  } catch (error: any) {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Adding voucher failed.',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: 'API Services',
      func: 'getVoucherWallet',
      slug: 'checkout.api.applyVoucher.error',
    })

    return []
  }
}
