export enum CtaTarget {
  navigateToLastBought = 'navigateToLastBought',
  navigateToBuyAgain = 'navigateToBuyAgain',
  navigateToDeals = 'navigateToDeals',
}

export interface ICTAButton {
  text: string
  onTapAction?: {
    type: CtaTarget
  }
}
