/* eslint-disable camelcase */
import { ICoordinates } from '~/types/app/Location'
import IPrice, { Currency, ICentPrice } from '~/types/app/Price'

export enum EClosureCase {
  REGULAR = 'REGULAR',
  TEMPORARY = 'TEMPORARY',
}

export interface IHubClosure {
  case: EClosureCase
  icon?: string
  title?: string
  message?: string
  button_title?: string
}

export interface IHubDetails {
  closed_days?: Array<number>
  closing: string
  frequently_searched?: Array<string>
  intercom_number?: string
  minimum_order_value?: IPrice
  opening: string
  shipping_fee?: IPrice
  is_closed?: boolean
  closed_message: string
  closure?: IHubClosure
}

export interface IHub {
  city?: string
  city_name?: string
  coordinates?: ICoordinates
  country?: string
  details: IHubDetails
  id?: string
  resolved_address?: string
  shop_token?: string
  shop_url?: string
  slug: string
  turfs?: Array<ICoordinates>
  is_default_hub?: boolean
  click_to_collect_address?: string
  delivers_to_requested_location?: boolean
}

export enum EHubAvailabilityState {
  loading = 'loading',
  success = 'success',
  failure = 'failure',
}

export interface IHubAvailabilityResult {
  hub: IHub
  addressAddition?: string
}

export interface IDeliveryTiers {
  minimumOrderValue: ICentPrice
  deliveryPrice: ICentPrice
  type: 'small-order' | 'delivery'
}

export interface IDeliveryTiersDetails {
  deliveryPrice: ICentPrice
  start: ICentPrice
  end: ICentPrice
}

export interface IDeliveryTierInfo {
  deliveryTierIndex: number
  minOrderReached: boolean
  deliveryFee: number
}

export interface IDynamicDeliveryFeeTextPayload {
  currency: Currency
  currentDeliveryFee: number
  getNextDeliveryTier: IDeliveryTiers
  cartTotalPrice: number
  nextMinimumOrderValue: number
}
