import { ICartItem } from '~/types/app/State'
import { IAddress, ICheckoutPayloadAddress } from '~/types/app/Location'
import { EAgeVerificationTag } from '~/types/app/AgeVerification'
import { IHub } from '~/types/app/Hub'
import { centToEuro, isMarketDefaultHub } from '~/lib/helpers'
import IProduct from '~/types/app/Product'
import ICartLine from '~/types/app/CartLine'
import IPrice from '~/types/app/Price'
import { ECartFeeType, ICartFee } from '~/types/app/Cart'

export const decorateShippingAddress = (
  fromState: IAddress,
  hub?: IHub
): ICheckoutPayloadAddress => ({
  address_id: fromState.id,
  company_name: fromState.company_name,
  street_address_1: fromState.street_address_1,
  street_address_2: fromState.street_address_2,
  city: fromState.city,
  postal_code: fromState.postal_code,
  country: hub ? hub.country : fromState.country,
  phone: fromState.phone ?? '',
})

export const getCartItem = (
  cart: ICartItem[],
  sku: string
): ICartItem | undefined =>
  cart.find((product: ICartItem) => product.sku === sku)

export const getCookieValue = (cookieName: string): string | undefined => {
  if (!process.browser) {
    return undefined
  }
  // \s used to match any possible whitespace, has to be escaped
  // eslint-disable-next-line no-useless-escape
  const cookieRegex = new RegExp(`${cookieName}\s*=\s*(.*?)(?:;|$)`)
  const match = document.cookie.match(cookieRegex)
  return match ? match[1] : undefined
}

export function updateProducts({
  oldProducts,
  updatedProducts,
}: {
  oldProducts?: IProduct[]
  updatedProducts?: IProduct[]
}): IProduct[] | undefined {
  if (!oldProducts?.length || !updatedProducts?.length) {
    return
  }

  const updatedProductsMap = updatedProducts.reduce((acc, product) => {
    return {
      ...acc,
      [product.id]: product,
    }
  }, {} as Record<IProduct['id'], IProduct>)

  return oldProducts.map((oldProduct) => {
    const updatedProduct = updatedProductsMap[oldProduct.id]
    return updatedProduct || oldProduct
  })
}

export const checkTagsForRestriction = (
  verifiedAgeStoreVal: string | undefined,
  verifiedTobacco: boolean,
  payload: (EAgeVerificationTag | undefined)[] | undefined
): EAgeVerificationTag | undefined => {
  const verifiedAge = verifiedAgeStoreVal ? Number(verifiedAgeStoreVal) : -1

  if ((verifiedTobacco && verifiedAge >= 18) || !payload?.length) {
    return undefined
  }

  let highestTag
  for (const tag of payload) {
    if (tag === EAgeVerificationTag.TOBACCO18) {
      highestTag = EAgeVerificationTag.TOBACCO18
      break
    }
    if (verifiedAge < 18 && tag === EAgeVerificationTag.ALCOHOL18) {
      highestTag = EAgeVerificationTag.ALCOHOL18
    }
    if (
      tag === EAgeVerificationTag.ALCOHOL16 &&
      verifiedAge < 16 &&
      highestTag !== EAgeVerificationTag.ALCOHOL18
    ) {
      highestTag = EAgeVerificationTag.ALCOHOL16
    }
  }
  return highestTag
}

export const compareCarts = (
  cart1: ICartItem[],
  cart2: ICartItem[]
): boolean => {
  let hasCartChanged = false
  if (cart1.length !== cart2.length) {
    hasCartChanged = true
  } else {
    for (const cartItem of cart2) {
      const prevCartItem: ICartItem | undefined = cart1.find(
        (pci) => pci.sku === cartItem.sku
      )
      if (!prevCartItem || prevCartItem.quantity !== cartItem.quantity) {
        hasCartChanged = true
        break
      }
    }
  }
  return !hasCartChanged
}

export const isHubDeliverable = (hub: IHub | undefined): boolean => {
  if (!hub) return false
  if (isMarketDefaultHub(hub)) return false
  return !hub.is_default_hub
}

export const cartLines = (cartItems: ICartItem[]): ICartLine[] => {
  return cartItems.map((cartItem: ICartItem) => {
    return {
      product_sku: cartItem.sku,
      quantity: cartItem.quantity,
    }
  })
}

export const setPriceAmountFromCentAmount = (
  price: IPrice | undefined
): IPrice => {
  if (!price) {
    return {
      amount: 0,
      cent_amount: 0,
      currency: 'EUR',
    }
  }
  const centAmount =
    price.centAmount !== undefined ? price.centAmount : price.cent_amount
  const amount = price.amount

  return {
    amount: centAmount !== undefined ? centToEuro(centAmount) : amount,
    cent_amount: centAmount,
    currency: price ? price.currency : 'EUR',
  }
}

export const pickFee = (
  fees: ICartFee[],
  feeType: ECartFeeType
): ICartFee | undefined => {
  return fees.find((fee) => fee.type === feeType)
}
