

























import { ref, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import VPopover from '~/components/VPopover/VPopover.vue';
import VIcon from '~/components/VIcon/VIcon.vue';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  name: {
    type: String,
    required: true
  },
  initialVisible: {
    type: Boolean,
    default: false
  },
  offsetY: {
    type: Number,
    default: 0
  },
  offsetX: {
    type: Number,
    default: 0
  },
  arrowSpacing: {
    type: String,
    default: ''
  },
  isAddressDefined: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const isScrolledToTop = ref(true);
  const show = debounce(function () {
    isScrolledToTop.value = window.scrollY === 0;
  }, 60);
  onMounted(() => {
    window.addEventListener('scroll', show);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('scroll', show);
  });
  return {
    emit,
    isScrolledToTop
  };
};

__sfc_main.components = Object.assign({
  VPopover,
  VIcon
}, __sfc_main.components);
export default __sfc_main;
