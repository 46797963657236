/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import { LoggerFunction } from '~/types/app/Logger'
import { IProductAmountBySKU } from '~/types/app/Product'

interface IParameters {
  client: NuxtAxiosInstance
  locale: string
  hubSlug?: string
  productSKUs?: string[]
}

interface IBody {
  product_ids?: string[]
  product_skus?: string[]
}

export default async function getProductsAmountsBySKU(
  { client, locale, hubSlug, productSKUs }: IParameters,
  logger: LoggerFunction
): Promise<IProductAmountBySKU[]> {
  const headers = {
    locale,
    hub: hubSlug,
    'hub-slug': hubSlug,
  }

  const body: IBody = {
    product_ids: [],
    product_skus: productSKUs,
  }

  let productAmounts: IProductAmountBySKU[] = []

  try {
    const response = await post<IProductAmountBySKU[]>(
      client,
      logger,
      '/v1/products/amounts-by-sku',
      headers,
      body
    )

    productAmounts = response.data
  } catch (error) {}

  return productAmounts
}
