import { cloneDeep, sortBy } from 'lodash-es'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  ICartFee,
  ECartAdditionalInfoType,
  ECartErrorCode,
  EPaymentStatus,
  EShippingMethod,
  ECartState,
  IGroupedCartItems,
} from '~/types/app/Cart'
import {
  COUNTRY_CURRENCIES,
  DEFAULT_DELIVERY_ETA,
  DEFAULT_MINIMUM_ORDER_VALUE,
  DISPLAY_NAME_DELIMITER,
  DUMMY_EMAIL_ADDRESS,
  VERIFIED_AGE_COOKIE_NAME,
  VERIFIED_TOBACCO_COOKIE_NAME,
} from '@/lib/constants'
import {
  centToEuro,
  getCountryFromLocale,
  localizeTimeString,
  validateMarket,
} from '@/lib/helpers'
import IProduct from '~/types/app/Product'
import { ESchedulingOptionType } from '~/types/app/PlannedOrders'
import IRootState, {
  IAppState,
  ELocationOnboardingState,
  ICartAddPayload,
  ICartItem,
  ICartRemovePayload,
  ICurrentLatencyTracing,
  StoreClosedInformation,
} from '~/types/app/State'
import { EClosureCase, IDeliveryTiers, IHub } from '~/types/app/Hub'
import getHub from '~/api/services/get-hub/get-hub'
import { IPaymentGateway } from '~/types/app/Payments'
import {
  IAddress,
  ICoordinates,
  ILocationInput,
  IOnboardingResult,
} from '~/types/app/Location'
import { getDeliveryEstimate } from '~/api/services/get-delivery-estimate'
import { ROUTES } from '~/lib/routes'
import { ELoggers, ELogLevels } from '~/types/app/Logger'
import { getScreenName, ETraceMoment } from '~/lib/segment'
import { getCart } from '~/api/services/get-cart'
import { IConflictingCartLine } from '~/api/services/are-all-products-available/are-all-products-available'
import getDeliveryTiers from '~/api/services/get-delivery-tiers/get-delivery-tiers'
import { updateDeliveryInformation } from '~/api/services/update-delivery-information'
import getStoreClosedInformation from '~/store/helpers/get-store-closed-modal-state'
import { getDefaultHubSlug } from '~/store/helpers/get-default-hub-slug'
import { createCart } from '~/api/services/create-cart'
import { updateCart } from '~/api/services/update-cart'
import { areAllProductsAvailable } from '~/api/services/are-all-products-available'
import { getOrdersPaymentMethods } from '~/api/services/get-orders-payment-methods'
import { removeVoucher } from '~/api/services/remove-voucher'
import ICartLine from '~/types/app/CartLine'
import { cartErrorHandler } from '~/lib/errorHandlers/cartErrorHandler'
import { AxiosError } from 'axios'
import { IDeliveryEstimate } from '~/api/services/get-delivery-estimate/get-delivery-estimate'
import { addRiderTip } from '~/api/services/add-rider-tip'
import { ICentPrice } from '~/types/app/Price'
import { ECountries } from '~/types/app/Countries'
import IUserDetails from '~/types/app/Checkout'
import CheckoutPageLogger from '~/lib/logger/checkoutPageLogger'
import { v4 as uuidv4 } from 'uuid'
import trackDeliveryTierChangedHelper from '~/lib/trackDeliveryTierChangedHelper'
import groupCartItemsByPromotions from '~/api/transformers/group-cart-by-promotions'
import SingleCallDebouncer from '~/lib/SingleCallDebouncer'
import {
  ADD_TO_CART,
  ADD_MULTIPLE_TO_CART,
  REMOVE_FROM_CART,
  RESET_CART,
  SET_ACTIVE_SUB_CATEGORY,
  SET_AGE_VERIFICATION,
  SET_CART_ID,
  SET_CLOSING_SOON_MODAL_SHOWN,
  SET_CURRENT_LOCATION,
  SET_DELIVERY_COORDINATES,
  SET_DELIVERY_ETA,
  SET_DELIVERY_TIER_ID,
  SET_DELIVERY_TIERS,
  SET_DISCOUNT,
  SET_EMAIL,
  SET_HUB,
  SET_IS_INITIAL_ONBOARDING_DONE,
  SET_PAYMENT_GATEWAY,
  SET_PAYMENT_METHOD,
  SET_PREVIOUS_CART,
  SET_REMOTE_CART,
  SET_SHIPPING_ADDRESS,
  SET_SHIPPING_METHOD,
  SET_SHOW_CART_CLEARED_MODAL,
  SET_SHOW_NO_DELIVERY_TO_LOCATION_MODAL,
  SET_SHOW_ONBOARDING_HINT,
  SET_SHOW_ONBOARDING_MODAL,
  SET_STORE_CLOSED_MODAL_SHOWN,
  SET_USER_ONBOARDING_STATE,
  SET_VERIFIED_AGE,
  SET_VERIFIED_TOBACCO,
  UPDATE_USER_DETAILS,
  SET_CART,
  SET_APPLIED_PROMOTIONS,
  SET_PAYMENT_STATUS,
  SET_ROUTER_HISTORY,
  SET_IS_HUB_UPDATE_NEEDED,
  SET_CURRENT_LATENCY_TRACING,
  SET_PREV_DELIVERY_TIER_INFO,
  SET_CONFLICTING_LINES,
  SET_CART_UPDATE_CALLS_PENDING,
} from './mutation-types'
import {
  cartLines,
  checkTagsForRestriction,
  compareCarts,
  decorateShippingAddress,
  getCartItem,
  getCookieValue,
  isHubDeliverable,
} from './helpers'

const CART_HEALTH_CHECK_RETRY_COUNT = 1
let CART_HEALTH_CHECK_RETRY_ATTEMPT = 0

const updateCartSingleDebounced = new SingleCallDebouncer(updateCart)

const defaultShippingAddress = {
  first_name: '',
  last_name: '',
  company_name: '',
  street_address_1: 'Lobeck Str. 30-35',
  city: 'Berlin',
  postal_code: '14055',
  country: 'DE',
  phone: '',
}

export const state = (): IRootState => ({
  cart: [],
  previousCart: [],
  appliedPromotions: [],
  previousDeliveryTierInfo: null,
  discount: 0,
  discountUnit: '',
  voucherCode: '',
  freeProductsSku: [],
  currentLocation: undefined,
  isHubUpdateNeeded: false,
  hub: undefined,
  deliveryCoordinates: undefined,
  shippingAddress: { ...defaultShippingAddress },
  email: '',
  deliveryETA: DEFAULT_DELIVERY_ETA,
  cartNotes: '',
  cartId: undefined,
  lastUsedCartId: undefined,
  currency: 'EUR',
  paymentGateway: undefined,
  paymentMethod: undefined,
  geocodeFallbackCoordinates: {
    latitude: 52.503145,
    longitude: 13.407914,
  },
  isInitialOnboardingDone: false,
  locationOnboardingState: ELocationOnboardingState.INITIAL,
  showOnboardingModal: false,
  showOnboardingHint: false,
  showCartClearedModal: false,
  showStoreClosedModal: false,
  showNoDeliveryToLocationModal: false,
  shippingMethodId: '',
  selectedSubCategory: '',
  verifiedAge: getCookieValue('verified-age'),
  verifiedTobacco: getCookieValue(VERIFIED_TOBACCO_COOKIE_NAME) === 'true',
  ageVerification: {
    isRequired: false,
    tag: undefined,
    payload: undefined,
  },
  closingSoonModalShown: false,
  deliveryTierId: '',
  deliveryTiers: [],
  remoteCart: undefined,
  paymentStatus: undefined,
  routerHistory: [],
  currentLatencyTracing: {
    actionId: undefined,
    startTimestamp: undefined,
    traceName: undefined,
  },
  checkoutMinimumOrderValue: undefined,
  deliveryTiersDetails: [],
  smallOrderThreshold: undefined,
})

export const mutations: MutationTree<IRootState> = {
  [SET_CART](state, cart) {
    state.cart = cart
  },
  [SET_APPLIED_PROMOTIONS](state, appliedPromotions) {
    state.appliedPromotions = appliedPromotions
  },
  [ADD_TO_CART](state, { sku, quantity = 1 }: ICartAddPayload): void {
    const itemInCart = getCartItem(state.cart, sku)
    if (!itemInCart) {
      state.cart.push({ sku, quantity })
    } else {
      itemInCart.quantity += quantity
    }
  },
  [ADD_MULTIPLE_TO_CART](state, productList: ICartAddPayload[]): void {
    productList.forEach((product) => {
      const itemInCart = getCartItem(state.cart, product.sku)

      if (!itemInCart) {
        state.cart.push({
          sku: product.sku,
          quantity: product.quantity as number,
        })
      } else {
        itemInCart.quantity += product.quantity as number
      }
    })
  },
  [REMOVE_FROM_CART](state, { sku, quantity = 1 }: ICartRemovePayload): void {
    const itemInCart = getCartItem(state.cart, sku)

    if (itemInCart) {
      itemInCart.quantity -= quantity
    }

    state.cart = state.cart.filter((p: ICartItem) => p.quantity > 0)
  },
  [SET_CURRENT_LOCATION](state, currentLocation: ILocationInput) {
    state.currentLocation = currentLocation
  },
  [SET_IS_HUB_UPDATE_NEEDED](state, isHubUpdateNeeded: boolean) {
    state.isHubUpdateNeeded = isHubUpdateNeeded
  },
  [SET_HUB](state, hub: IHub): void {
    state.hub = hub
  },
  [SET_CART_ID](state, cartId: string): void {
    state.cartId = cartId
    state.lastUsedCartId = cartId
  },
  [UPDATE_USER_DETAILS](state, userDetails: IUserDetails): void {
    state.email = userDetails.email

    if (!state.shippingAddress) {
      state.shippingAddress = { ...defaultShippingAddress }
    }

    state.shippingAddress = {
      ...state.shippingAddress,
      first_name: userDetails.firstName,
      last_name: userDetails.lastName,
      phone: `${userDetails.prefix} ${userDetails.phone}`,
      street_address_2: userDetails.additionalInformation,
    }
  },
  [SET_EMAIL](state, email: string): void {
    state.email = email
  },
  [RESET_CART](state): void {
    state.cart = []
    state.cartId = undefined
    state.paymentMethod = undefined
  },
  [SET_PAYMENT_GATEWAY](state, paymentGateway: IPaymentGateway) {
    state.paymentGateway = paymentGateway
  },
  [SET_IS_INITIAL_ONBOARDING_DONE](state, isInitialOnboardingDone) {
    state.isInitialOnboardingDone = isInitialOnboardingDone
  },
  [SET_USER_ONBOARDING_STATE](
    state,
    locationOnboardingState: ELocationOnboardingState
  ) {
    state.locationOnboardingState = locationOnboardingState
  },
  [SET_SHOW_ONBOARDING_MODAL](state, showOnboardingModal) {
    state.showOnboardingModal = showOnboardingModal
  },
  [SET_SHOW_ONBOARDING_HINT](state, showOnboardingHint: boolean) {
    state.showOnboardingHint = showOnboardingHint
  },
  [SET_SHOW_CART_CLEARED_MODAL](state, showCartClearedModal) {
    state.showCartClearedModal = showCartClearedModal
  },
  [SET_SHOW_NO_DELIVERY_TO_LOCATION_MODAL](state, isVisible) {
    state.showNoDeliveryToLocationModal = isVisible
  },
  [SET_STORE_CLOSED_MODAL_SHOWN](state, showStoreClosedModal) {
    state.showStoreClosedModal = showStoreClosedModal
  },
  [SET_SHIPPING_ADDRESS](state, shippingAddress: IAddress) {
    state.shippingAddress = shippingAddress
  },
  [SET_DELIVERY_ETA](state, deliveryETA: string) {
    state.deliveryETA = deliveryETA
  },
  [SET_ACTIVE_SUB_CATEGORY](state, slug: string) {
    state.selectedSubCategory = slug
  },
  [SET_DELIVERY_COORDINATES](state, coordinates: ICoordinates) {
    state.deliveryCoordinates = coordinates
  },
  [SET_VERIFIED_AGE](state, verifiedAge: string) {
    state.verifiedAge = verifiedAge
  },
  [SET_VERIFIED_TOBACCO](state, verifiedTobacco: boolean) {
    state.verifiedTobacco = verifiedTobacco
  },
  [SET_AGE_VERIFICATION](state, { tag, payload } = {}) {
    if (!tag) {
      state.ageVerification = {
        isRequired: false,
        tag: undefined,
        payload: undefined,
      }
    } else {
      state.ageVerification = {
        isRequired: true,
        tag,
        payload,
      }
    }
  },
  [SET_DISCOUNT](
    state,
    { amount, voucherCode, discountUnit, freeProductsSku } = {}
  ) {
    state.discount = amount
    state.voucherCode = voucherCode
    state.discountUnit = discountUnit
    state.freeProductsSku = freeProductsSku
  },
  [SET_CLOSING_SOON_MODAL_SHOWN](state, closingSoonModalShown) {
    state.closingSoonModalShown = closingSoonModalShown
  },
  [SET_PREVIOUS_CART](state, previousCart) {
    state.previousCart = previousCart
  },
  [SET_PREV_DELIVERY_TIER_INFO](state, previousDeliveryTierInfo) {
    state.previousDeliveryTierInfo = previousDeliveryTierInfo
  },
  [SET_PAYMENT_METHOD](state, paymentMethod) {
    state.paymentMethod = paymentMethod
  },
  [SET_DELIVERY_TIER_ID](state, deliveryTierId) {
    state.deliveryTierId = deliveryTierId
  },
  [SET_DELIVERY_TIERS](
    state,
    {
      checkoutMinimumOrderValue,
      deliveryTiers,
      deliveryTiersDetails,
      smallOrderThreshold,
    }
  ) {
    state.checkoutMinimumOrderValue = checkoutMinimumOrderValue
    state.deliveryTiers = deliveryTiers
    state.deliveryTiersDetails = deliveryTiersDetails
    state.smallOrderThreshold = smallOrderThreshold
  },
  [SET_REMOTE_CART](state, remoteCart) {
    if (remoteCart) {
      state.remoteCart = remoteCart
      state.cartId = remoteCart.id
      state.lastUsedCartId = remoteCart.id
    } else {
      state.remoteCart = undefined
      state.cartId = undefined
    }
  },
  [SET_PAYMENT_STATUS](state, paymentStatus) {
    state.paymentStatus = paymentStatus
  },
  [SET_ROUTER_HISTORY](state, routerHistory) {
    state.routerHistory = routerHistory
  },
  [SET_SHIPPING_METHOD](state, shippingMethodId) {
    state.shippingMethodId = shippingMethodId
  },
  [SET_CURRENT_LATENCY_TRACING](
    state,
    currentLatencyTracing: ICurrentLatencyTracing
  ) {
    state.currentLatencyTracing = currentLatencyTracing
  },
}

export const getters: GetterTree<IRootState, IRootState> = {
  getAddress: (state): string => {
    if (!state.shippingAddress) {
      return ''
    }

    return [
      state.shippingAddress.street_address_1,
      [state.shippingAddress.postal_code, state.shippingAddress.city].join(' '),
    ].join(', ')
  },
  getCartProducts: (state, getters): Array<IProduct> => {
    return state.cart.reduce((acc, { sku, quantity }) => {
      const foundProduct = getters['catalog/getProductBySKU'](sku)

      if (foundProduct) {
        acc.push({
          ...foundProduct,
          quantity,
        })
      }

      return acc
    }, [] as IProduct[])
  },
  getGroupedCartProducts: (state, getters): IGroupedCartItems => {
    const cartLineItems = state.cart.reduce((acc, { sku, quantity }) => {
      const foundProduct = getters['catalog/getProductBySKU'](sku)

      if (foundProduct) {
        acc.push({
          ...foundProduct,
          quantity,
        })
      }

      return acc
    }, [] as IProduct[])

    const enhancedCart = groupCartItemsByPromotions({
      cartItems: cartLineItems,
      promos: state.appliedPromotions ?? [],
    })

    return enhancedCart
  },
  getUpdatedCartLinesWithRemoved:
    (_, getters) =>
    (conflictingCartLines: IConflictingCartLine[]): IProduct[] | undefined => {
      if (!conflictingCartLines) return []
      return conflictingCartLines.map(({ sku, available }) => {
        const product = getters['catalog/getProductBySKU'](sku)
        return {
          ...product,
          quantity: available,
          maxQuantity: available,
        }
      })
    },
  getCartSubTotalPrice: (state, getters): number => {
    if (state.remoteCart && state.remoteCart?.lines.length !== 0) {
      return state.remoteCart?.sub_total_price?.amount
    }

    return getters.getCartProducts.reduce(
      (sum: number, p: IProduct) => sum + p.price.amount * p.quantity,
      0
    )
  },
  getCartSubTotalCentAmount: (state): number | undefined => {
    if (state.remoteCart?.lines.length === 0) {
      return 0
    }
    return state.remoteCart?.sub_total_price.cent_amount
  },
  getCartProductsCount: (state, getters): number => {
    if (state.remoteCart) {
      return state.remoteCart?.lines.length
    }

    return getters.getCartProducts.length
  },
  getCartTotalProductsCount: (state, getters): number => {
    function reducer(acc: number, curr: ICartLine): number {
      return acc + curr.quantity
    }

    if (state.remoteCart) {
      return state.remoteCart?.lines.reduce(reducer, 0)
    }

    return getters.getCartProducts.reduce(reducer, 0)
  },
  getCartProductQuantity:
    (state) =>
    (sku: string): number => {
      const itemInCart = getCartItem(state.cart, sku)

      return itemInCart?.quantity ?? 0
    },
  getOrderTotalPrice: (state): number => {
    return state.remoteCart?.total_price?.amount || 0
  },
  getTotalDeposit: (state): number => {
    return state.remoteCart?.recycling_deposit?.amount || 0
  },
  getDiscount: (state): number => {
    return state.remoteCart?.discount?.amount || 0
  },
  getCartSavings: (state) => {
    const totalSavings = state.appliedPromotions.reduce(
      (acc, { savings }) => acc + savings.centAmount,
      0
    )

    return centToEuro(totalSavings)
  },
  getDeliveryFee: (state, getters) => {
    if (state.remoteCart && state.remoteCart?.lines.length !== 0) {
      const { discountedDeliveryFee, shipping_price: shippingPrice } =
        state.remoteCart

      return discountedDeliveryFee?.amount ?? shippingPrice?.amount
    }

    if (getters.getCurrentDeliveryTier) {
      return getters.getCurrentDeliveryTier.deliveryPrice.centAmount / 100
    }

    return state.hub?.details?.shipping_fee?.amount
  },
  getCurrency: (state) => state.remoteCart?.total_price.currency || 'EUR',
  getMinOrderValue: (state) => {
    if (state.checkoutMinimumOrderValue) {
      return state.checkoutMinimumOrderValue?.centAmount / 100
    }
    return (
      state.hub?.details?.minimum_order_value?.amount ??
      DEFAULT_MINIMUM_ORDER_VALUE
    )
  },
  getMinOrderValueRemain: (_, getters) => {
    return (
      Math.round(
        (getters.getMinOrderValue - getters.getCartSubTotalPrice) * 100
      ) / 100
    )
  },
  getMinOrderValueReached: (_, getters) => {
    return getters.getCartSubTotalPrice >= getters.getMinOrderValue
  },
  getSmallOrderValueRemain: (state, getters) => {
    if (!state.smallOrderThreshold) {
      return
    }

    return (
      state.smallOrderThreshold.centAmount - getters.getCartSubTotalCentAmount
    )
  },
  getSmallOrderThresholdReached: (state, getters) => {
    if (!state.smallOrderThreshold) {
      return true
    }
    return (
      getters.getCartSubTotalCentAmount >= state.smallOrderThreshold.centAmount
    )
  },
  getIsHubClosed: (state): boolean => {
    if (!state.hub || !isHubDeliverable(state.hub)) {
      return false
    }
    const { details } = state.hub
    return details.is_closed || !!details.closure
  },
  getIsHubDeliverable: (state): boolean => {
    return !!state.hub && isHubDeliverable(state.hub)
  },
  getHubOpeningTime: (state): string => {
    return localizeTimeString(state.hub?.details?.opening)
  },
  getHubClosingTime: (state): string => {
    return localizeTimeString(state.hub?.details?.closing)
  },
  getHubOpeningHours: (state, getters): string => {
    const openingTime = getters.getHubOpeningTime
    const closingTime = getters.getHubClosingTime
    const isClosed =
      state.hub?.details?.is_closed || getters.getIsNotInDeliveryArea

    if (isClosed || !openingTime || !closingTime) {
      return ''
    }

    return `${openingTime} - ${closingTime}`
  },
  getHubClosedMessage: (state, getters): StoreClosedInformation => {
    if (!getters.getIsHubClosed) {
      return null
    }
    const { details } = state.hub as IHub
    if (details.closure) {
      return getStoreClosedInformation({
        ...details.closure,
      })
    }
    if (details.is_closed) {
      return getStoreClosedInformation({
        case: EClosureCase.REGULAR,
        message: details.closed_message,
      })
    }

    // should never get here since getIsHubClosed would return false
    return null
  },
  getDeliveryETANumber: (state): number => {
    const { asapPdt } = (state as IAppState).plannedOrders
    if (asapPdt) {
      return asapPdt
    }

    return state.deliveryETA ? parseInt(state.deliveryETA, 10) : 0
  },
  getDeliveryETAStr: (state): string => {
    const { asapPdt } = (state as IAppState).plannedOrders
    if (asapPdt) {
      return asapPdt.toString()
    }

    return state.deliveryETA?.toString() ?? ''
  },
  isDeliveryETAAboveOrEqualThreshold:
    (_, getters) =>
    (threshold: number): boolean => {
      return getters.getDeliveryETANumber >= threshold
    },
  getIsOnboardingVisible:
    (state) =>
    (hideInitialOnboarding: boolean): boolean => {
      return (
        (!hideInitialOnboarding &&
          state.locationOnboardingState === ELocationOnboardingState.INITIAL) ||
        (state.locationOnboardingState === ELocationOnboardingState.PENDING &&
          !state.showNoDeliveryToLocationModal) ||
        state.showOnboardingModal
      )
    },
  getIsOnboardSuccess: (state, getters) =>
    state.locationOnboardingState === ELocationOnboardingState.SUCCESS ||
    getters.getIsNotInDeliveryArea,
  getIsOnboardFailure: (state) =>
    state.locationOnboardingState === ELocationOnboardingState.FAILURE,
  getIsNotInDeliveryArea: (state) =>
    state.locationOnboardingState ===
    ELocationOnboardingState.NOT_IN_DELIVERY_AREA,
  isCartAllowed: (state, getters) => (currentCountry: ECountries) => {
    if (!state.shippingAddress) {
      return false
    }

    const isOnboardedToLocaleMarket = state.hub?.country === currentCountry
    const _isHubDeliverable = isHubDeliverable(state.hub)
    return (
      getters.getIsOnboardSuccess &&
      isOnboardedToLocaleMarket &&
      _isHubDeliverable
    )
  },
  getDeliveryTiers: (state) => {
    return sortBy(
      state.deliveryTiers,
      (deliveryTier: IDeliveryTiers) =>
        deliveryTier.minimumOrderValue.centAmount
    )
  },
  getFreeDeliveryTier: (state) => {
    return state.deliveryTiers?.find((dt) => dt.deliveryPrice.centAmount === 0)
  },
  getCurrentDeliveryTierIndex: (state, getters) => {
    if (state.deliveryTiers && state.deliveryTiers.length === 0) {
      return undefined
    }
    const deliveryTiers = getters.getDeliveryTiers
    const currentCartValue = getters.getCartSubTotalPrice * 100 // here we are comparing to Cents

    let currentDeliveryTier = 0

    for (let i = 0; i < deliveryTiers.length; i++) {
      const deliveryTier: IDeliveryTiers = state.deliveryTiers[i]
      const minOrderValue = deliveryTier.minimumOrderValue.centAmount
      if (currentCartValue > minOrderValue) {
        currentDeliveryTier = i
      }
    }

    return currentDeliveryTier
  },
  getCurrentDeliveryTier: (_, getters) => {
    return getters.getDeliveryTiers[getters.getCurrentDeliveryTierIndex]
  },
  getNextDeliveryTier: (_, getters) => {
    const i = getters.getCurrentDeliveryTierIndex
    if (i + 1 < getters.getDeliveryTiers.length) {
      return getters.getDeliveryTiers[i + 1]
    }

    return undefined
  },
  getNextMinimumOrderValue: (_, getters) => {
    return getters.getNextDeliveryTier?.minimumOrderValue.centAmount / 100
  },
  getShippingMethod: (state, getters) => {
    if (getters['account/isHubUser']) {
      return EShippingMethod.KIOSK
    } else if (state.remoteCart?.click_and_collect) {
      return EShippingMethod.CLICK_AND_COLLECT
    } else {
      return EShippingMethod.DELIVERY
    }
  },
  getIsDelivery: (_, getters) => {
    return getters.getShippingMethod === EShippingMethod.DELIVERY
  },
  getIsClickAndCollect: (_, getters) => {
    return getters.getShippingMethod === EShippingMethod.CLICK_AND_COLLECT
  },
  getIsKiosk: (_, getters) => {
    return getters.getShippingMethod === EShippingMethod.KIOSK
  },
  getCanClickAndCollect: (state) => {
    return !!state.hub?.click_to_collect_address
  },
  getTotalSavedAmount: (state, getters): number => {
    // PLEASE NOTE: Total saved amount = delivery fee + priority delivery fee + discount
    const savedFees = state.remoteCart?.additionalInfo
      ? state.remoteCart?.additionalInfo
          .filter(({ type }: { type: string }) => {
            return (
              type === ECartAdditionalInfoType.SAVED_DELIVERY_FEE_SUBSCRIBER ||
              type === ECartAdditionalInfoType.SAVED_PRIORITY_FEE_SUBSCRIBER
            )
          })
          .reduce(
            (total: number, fee: ICartFee) =>
              fee.price.centAmount ? total + fee.price.centAmount : total,
            0
          )
      : 0
    return centToEuro(savedFees) + getters.getDiscount
  },
}

export const actions: ActionTree<IRootState, IRootState> = {
  async addToCart(
    { state, commit, dispatch, getters },
    payload: ICartAddPayload
  ): Promise<void> {
    if (!getters.isCartAllowed(getCountryFromLocale(this.$i18n.locale))) {
      if (getters['account/userHasSavedAddresses']) {
        await dispatch('account/toggleAddressBook', true)
      } else {
        dispatch('updateUserOnboardingState', ELocationOnboardingState.PENDING)
      }
      return
    }

    const verificationTag = checkTagsForRestriction(
      state.verifiedAge,
      state.verifiedTobacco,
      payload.tags
    )
    if (verificationTag) {
      commit(SET_AGE_VERIFICATION, { tag: verificationTag, payload })
      return
    }

    const product = getters['catalog/getProductBySKU'](payload.sku) as IProduct
    const prevDeliveryTierValues = {
      deliveryTierIndex: getters.getCurrentDeliveryTierIndex,
      minOrderReached: getters.getMinOrderValueReached,
      deliveryFee: getters.getDeliveryFee,
    }
    commit(SET_PREV_DELIVERY_TIER_INFO, prevDeliveryTierValues)

    this.$segmentEvent.PRODUCT_ADDED({
      cartId: state.cartId as string,
      sku: product.sku,
      name: product.name,
      price: product.price.amount,
      currency: product.price.currency as string,
      isOutOfStock: product.isOutOfStock,
      productPlacement: payload.productPlacement,
      listPosition: payload.listPosition,
      listName: payload.listName,
      productPosition: payload.productPosition,
      categoryId: payload.categoryId,
      category: payload.categoryName,
      subCategoryId: payload.subCategoryId,
      subCategoryName: payload.subCategoryName,
      searchQueryId: payload.searchQueryId,
      eventOrigin: payload.eventOrigin,
      hubCity: state.hub?.city_name as string,
      hubSlug: state.hub?.slug as string,
      deliveryPostcode: state.shippingAddress?.postal_code as string,
      originalPrice: product.originalPrice?.amount || product.price.amount,
      productContext: product.context,
      discountApplied: !!product.discount,
      screenName:
        payload.screenName || getScreenName(this.$router.currentRoute),
      prismCampaignId: payload.prismCampaignId,
      prismCampaignName: payload.prismCampaignName,
    })

    commit(ADD_TO_CART, payload)
    await dispatch('createCart')
    const cartItems = [
      {
        sku: product.sku,
        quantity: getters.getCartProductQuantity(product.sku),
      },
    ]

    dispatch('updateCartLines', cartItems)
  },
  async addMultipleToCart(
    { state, commit, dispatch, getters },
    payload: ICartAddPayload[]
  ): Promise<void> {
    if (!getters.isCartAllowed(getCountryFromLocale(this.$i18n.locale))) {
      dispatch('updateUserOnboardingState', ELocationOnboardingState.PENDING)
      return
    }
    const allTags = payload.map((item) => item.tags).flat()

    const verificationTag = checkTagsForRestriction(
      state.verifiedAge,
      state.verifiedTobacco,
      allTags
    )

    if (verificationTag) {
      commit(SET_AGE_VERIFICATION, { tag: verificationTag, payload })
      return
    }

    for (const item of payload) {
      const product = getters['catalog/getProductBySKU'](item.sku) as IProduct

      this.$segmentEvent.PRODUCT_ADDED({
        cartId: state.cartId as string,
        sku: product.sku,
        name: product.name,
        price: product.price.amount,
        productContext: product.context,
        discountApplied: !!product.discount,
        currency: product.price.currency as string,
        originalPrice: product.originalPrice?.amount || product.price.amount,
        isOutOfStock: product.isOutOfStock,
        hubCity: state.hub?.city_name as string,
        hubSlug: state.hub?.slug as string,
        deliveryPostcode: state.shippingAddress?.postal_code as string,
        screenName: getScreenName(this.$router.currentRoute),
        listPosition: item.listPosition,
        productPlacement: item.productPlacement,
        productPosition: item.productPosition,
        listName: item.listName,
        prismCampaignId: item.prismCampaignId,
        prismCampaignName: item.prismCampaignName,
      })
    }

    commit(ADD_MULTIPLE_TO_CART, payload)
    await dispatch('createCart')

    const cartItems = [] as ICartItem[]
    payload.forEach((item) => {
      cartItems.push({
        sku: item.sku,
        quantity: getters.getCartProductQuantity(item.sku),
      })
    })

    dispatch('updateCartLines', cartItems)
  },
  removeFromCart(
    { commit, dispatch, getters },
    payload: ICartRemovePayload
  ): void {
    if (!getters.isCartAllowed(getCountryFromLocale(this.$i18n.locale))) {
      dispatch('updateUserOnboardingState', ELocationOnboardingState.PENDING)
      return
    }

    const product = getters['catalog/getProductBySKU'](payload.sku)
    const prevDeliveryTierValues = {
      deliveryTierIndex: getters.getCurrentDeliveryTierIndex,
      deliveryFee: getters.getDeliveryFee,
    }
    commit(SET_PREV_DELIVERY_TIER_INFO, prevDeliveryTierValues)
    this.$segmentEvent.PRODUCT_REMOVED({
      sku: product?.sku,
      name: product?.name,
      price: product?.price.amount,
      productPlacement: payload.productPlacement,
      listPosition: payload.listPosition,
      listName: payload.listName,
      productPosition: payload.productPosition,
      categoryId: payload.categoryId,
      category: payload.categoryName,
      subCategoryId: payload.subCategoryId,
      subCategoryName: payload.subCategoryName,
      originalPrice: product.originalPrice?.amount || product.price.amount,
      discountApplied: !!product.discount,
      eventOrigin: payload.eventOrigin,
      screenName:
        payload.screenName || getScreenName(this.$router.currentRoute),
      productContext: product.context,
      prismCampaignId: payload.prismCampaignId,
      prismCampaignName: payload.prismCampaignName,
    })

    commit(REMOVE_FROM_CART, payload)
    const cartItems = [
      {
        sku: product.sku,
        quantity: getters.getCartProductQuantity(product.sku),
      },
    ]
    dispatch('updateCartLines', cartItems)
  },
  async syncCart(
    { commit, dispatch },
    conflictingCartLines: IConflictingCartLine[]
  ) {
    conflictingCartLines.forEach(({ sku, available, requested }) => {
      const diff = requested - available
      commit(REMOVE_FROM_CART, {
        sku,
        quantity: diff,
      })
    })

    const cartLines = conflictingCartLines.map((conflictingCartLine) => {
      return {
        product_sku: conflictingCartLine.sku,
        quantity: conflictingCartLine.available,
      }
    })
    await dispatch('updateCart', { selectedCartLines: cartLines })
  },
  setCurrentLocation({ commit }, currentLocation: ILocationInput) {
    commit(SET_CURRENT_LOCATION, currentLocation)
  },
  async loadHub({ state, dispatch }): Promise<void> {
    let hub

    if (!state.deliveryCoordinates) {
      hub = {
        slug: getDefaultHubSlug(this.$i18n.locale),
        country: getCountryFromLocale(this.$i18n.locale),
      }
    } else {
      hub = await getHub(
        this.$hubLocator,
        this.$logger,
        state.deliveryCoordinates
      )
    }

    if (hub) {
      if (
        !hub.delivers_to_requested_location &&
        ![
          ELocationOnboardingState.INITIAL,
          ELocationOnboardingState.CANCELED,
        ].includes(state.locationOnboardingState)
      ) {
        dispatch('toggleNoDeliveryToLocationModal', true)
      }

      await dispatch('updateHub', { hub })
    } else {
      this.$logger({
        msg: `Could not load hub by delivery coordinates: ${JSON.stringify(
          state.deliveryCoordinates
        )}.`,
        level: ELogLevels.WARNING,
        logger: 'Store Actions',
        func: 'loadHub',
      })
    }
  },
  async reloadHub({ state, dispatch }): Promise<void> {
    await dispatch('loadHub', state.hub?.slug)
  },
  selectSubCategory({ commit }, slug) {
    commit(SET_ACTIVE_SUB_CATEGORY, slug)
  },
  updateUserOnboardingState({ commit }, state: ELocationOnboardingState) {
    commit(SET_USER_ONBOARDING_STATE, state)
  },
  updateIsHubUpdateNeeded({ commit }, isHubUpdateNeeded: boolean) {
    commit(SET_IS_HUB_UPDATE_NEEDED, isHubUpdateNeeded)
  },
  async updateHub(
    { commit, state, getters, dispatch },
    { hub, deliveryEta }: { hub: IHub; deliveryEta: IDeliveryEstimate }
  ) {
    const prevHub = state.hub
    commit(SET_HUB, hub)

    // properties that can change for the same hub
    if (deliveryEta) {
      commit(SET_DELIVERY_ETA, deliveryEta.pdt_minutes)
    } else {
      await dispatch('updateETA')
    }

    await dispatch('updateDeliveryTiers', {
      hubSlug: hub.slug,
      hubCountry: hub.country,
      shippingMethod: getters.getShippingMethod,
    })

    await dispatch('updateIsHubUpdateNeeded', false)

    if (prevHub && prevHub.id === hub.id) {
      return
    }

    await dispatch('adjustToNewHub')
  },
  async adjustToNewHub({ state, dispatch, getters }) {
    // new hub -> might be invalid / has other products
    if (!validateMarket(this.$i18n.locale, state.hub)) {
      await dispatch('catalog/resetHomepage')
      await this.$i18n.setLocale(`en-${state.hub?.country}`)
      await this.app?.router?.push(
        this.localePath(ROUTES.HOME, `en-${state.hub?.country}`)
      )
    }

    if (isHubDeliverable(state.hub)) {
      dispatch('updateUserOnboardingState', ELocationOnboardingState.SUCCESS)
    } else if (
      state.isInitialOnboardingDone &&
      !getters.getIsNotInDeliveryArea
    ) {
      dispatch('updateUserOnboardingState', ELocationOnboardingState.PENDING)
    } else if (!getters.getIsNotInDeliveryArea) {
      dispatch('updateUserOnboardingState', ELocationOnboardingState.INITIAL)
    }

    const hasOnboardedAndAddedProduct =
      state.isInitialOnboardingDone && state.cart.length > 0
    if (hasOnboardedAndAddedProduct) {
      dispatch('toggleCartClearedModal', true)
      await dispatch('resetCart')
    }
    if (state.remoteCart) {
      await dispatch('resetCart')
    }
  },
  updateShippingAddress({ commit }, shippingAddress: IAddress) {
    const address = JSON.parse(JSON.stringify(defaultShippingAddress))
    Object.assign(address, shippingAddress)
    commit(SET_SHIPPING_ADDRESS, address)
  },
  updateDeliveryCoordinates({ commit }, coordinates: ICoordinates) {
    commit(SET_DELIVERY_COORDINATES, coordinates)
  },
  updateUserDetails({ state, commit }, { authUser }) {
    const shippingAddress = JSON.parse(JSON.stringify(state.shippingAddress))
    const displayName = authUser.displayName
    if (displayName) {
      const name = displayName.split(DISPLAY_NAME_DELIMITER)
      shippingAddress.first_name = name[0]
      shippingAddress.last_name = name[1]
      commit(SET_SHIPPING_ADDRESS, shippingAddress, { root: true })
    }

    const email = authUser.email
    if (email) {
      commit(SET_EMAIL, email, { root: true })
    }
  },
  async updateETA({ commit, state }) {
    if (state.hub?.coordinates && state.deliveryCoordinates) {
      const response = await getDeliveryEstimate(
        this.$apiOrders,
        this.$logger,
        {
          deliveryCoordinates: state.deliveryCoordinates,
          locale: this.$i18n.locale,
          hubSlug: state.hub.slug,
          ...(state.cartId && { cartId: state.cartId }),
        }
      )
      if (response.pdt_minutes) {
        commit(SET_DELIVERY_ETA, response.pdt_minutes)
      }
    } else {
      this.$logger({
        level: ELogLevels.INFO,
        msg: 'Cannot update ETA because of missing hub or delivery coordinates',
        logger: 'Store Actions',
        func: 'updateETA',
      })
    }
  },
  acceptAgeVerification(
    { commit, dispatch },
    { age: verifiedAge, tobacco: verifiedTobacco, payload }
  ) {
    if (process.browser) {
      document.cookie = `${VERIFIED_AGE_COOKIE_NAME}=${verifiedAge}`
    }
    commit(SET_VERIFIED_AGE, verifiedAge)

    if (verifiedTobacco) {
      if (process.browser) {
        document.cookie = `${VERIFIED_TOBACCO_COOKIE_NAME}=${verifiedTobacco}`
      }
      commit(SET_VERIFIED_TOBACCO, verifiedTobacco)
    }

    commit(SET_AGE_VERIFICATION, undefined)
    if (Array.isArray(payload)) {
      dispatch('addMultipleToCart', payload)
    } else {
      dispatch('addToCart', payload)
    }
  },
  declineAgeVerification({ commit }) {
    commit(SET_AGE_VERIFICATION, undefined)
  },
  resetAgeVerification({ commit }) {
    if (process.browser) {
      document.cookie = `${VERIFIED_AGE_COOKIE_NAME}=''`
    }
    commit(SET_VERIFIED_AGE, undefined)
    if (process.browser) {
      document.cookie = `${VERIFIED_TOBACCO_COOKIE_NAME}=''`
    }
    commit(SET_VERIFIED_TOBACCO, undefined)
    commit(SET_AGE_VERIFICATION, undefined)
  },
  async performInitialOnboarding({ state, dispatch }) {
    await dispatch('loadHub')
    if (
      ![
        ELocationOnboardingState.PENDING,
        ELocationOnboardingState.INITIAL,
      ].includes(state.locationOnboardingState)
    ) {
      dispatch('updateIsInitialOnboardingDone', true)
    }
  },
  updateIsInitialOnboardingDone({ commit }, isInitialOnboardingDone: boolean) {
    commit(SET_IS_INITIAL_ONBOARDING_DONE, isInitialOnboardingDone)
  },
  async succeedOnboarding(
    { dispatch },
    onboardingResponse: Omit<IOnboardingResult, 'geocodedAddress'>
  ): Promise<void> {
    dispatch('updateUserOnboardingState', ELocationOnboardingState.SUCCESS)
    await dispatch('updateAddressCoordinatesAndHub', onboardingResponse)
  },
  failOnboarding({ dispatch }): void {
    dispatch('updateUserOnboardingState', ELocationOnboardingState.FAILURE)
  },
  async updateAddressCoordinatesAndHub(
    { dispatch },
    onboardingResponse: Omit<IOnboardingResult, 'geocodedAddress'>
  ): Promise<void> {
    dispatch('updateShippingAddress', onboardingResponse.address)
    dispatch('toggleOnboardingModal', false)
    await dispatch('updateDeliveryCoordinates', onboardingResponse.coordinates)
    await dispatch('setClosingSoonModalShown', false)
    // must be called after updateDeliveryCoordinates because of ETA update
    await dispatch('updateHub', {
      hub: onboardingResponse.hub,
      deliveryEta: onboardingResponse.deliveryEta,
    })
  },
  cancelOnboarding({ getters, dispatch }): void {
    dispatch('toggleOnboardingModal', false)

    if (getters.getIsOnboardSuccess || getters.getIsOnboardFailure) {
      return
    }

    dispatch('updateUserOnboardingState', ELocationOnboardingState.CANCELED)
  },
  toggleOnboardingModal({ commit }, isVisible) {
    commit(SET_SHOW_ONBOARDING_MODAL, isVisible)
  },
  setShowOnboardingHint({ commit }, showOnboardingHint: boolean) {
    commit(SET_SHOW_ONBOARDING_HINT, showOnboardingHint)
  },
  toggleCartClearedModal({ commit }, isVisible) {
    commit(SET_SHOW_CART_CLEARED_MODAL, isVisible)
  },
  toggleNoDeliveryToLocationModal({ commit, dispatch }, isVisible) {
    isVisible &&
      dispatch(
        'updateUserOnboardingState',
        ELocationOnboardingState.NOT_IN_DELIVERY_AREA
      )
    commit(SET_SHOW_NO_DELIVERY_TO_LOCATION_MODAL, isVisible)
  },
  testStoreOpeningHours({ getters, dispatch }) {
    dispatch('toggleStoreClosedModal', getters.getIsHubClosed)
  },
  toggleStoreClosedModal({ commit }, isVisible) {
    commit(SET_STORE_CLOSED_MODAL_SHOWN, isVisible)
  },
  addVoucher({ commit }, voucherDetails) {
    commit(SET_DISCOUNT, {
      amount: voucherDetails?.discount?.amount ?? 0,
      voucherCode: voucherDetails?.voucherCode,
      discountUnit: voucherDetails?.discount?.currency,
      freeProductsSku: voucherDetails?.free_products ?? [],
    })
  },
  setClosingSoonModalShown({ commit }, isVisible) {
    commit(SET_CLOSING_SOON_MODAL_SHOWN, isVisible)
  },
  hasCartChanged({ commit, state }) {
    const previousCart = state.previousCart
    const currentCart = state.cart
    const hasChanged = !compareCarts(previousCart, currentCart)
    commit(SET_PREVIOUS_CART, cloneDeep(currentCart))
    if (previousCart.length === 0) return false
    return hasChanged
  },
  async updateDeliveryTiers(
    { commit, state },
    { hubSlug, hubCountry, shippingMethod }
  ) {
    const {
      checkoutMinimumOrderValue,
      deliveryTiers,
      deliveryTiersDetails,
      smallOrderThreshold,
      deliveryTierId,
      shippingMethodId,
    } = await getDeliveryTiers(
      this.$apiCartV3,
      this.$logger,
      this.$i18n.locale,
      hubSlug,
      hubCountry,
      state.deliveryCoordinates || { latitude: 0, longitude: 0 },
      shippingMethod,
      state.cartId
    )
    commit(SET_DELIVERY_TIERS, {
      checkoutMinimumOrderValue,
      deliveryTiers,
      deliveryTiersDetails,
      smallOrderThreshold,
    })
    if (deliveryTierId) {
      commit(SET_DELIVERY_TIER_ID, deliveryTierId)
    }
    if (shippingMethodId) {
      commit(SET_SHIPPING_METHOD, shippingMethodId)
    }
  },
  async createCart({ state, commit, dispatch }, lines = []) {
    if (state.remoteCart) return state.remoteCart

    try {
      const cart = await createCart(
        {
          client: this.$apiCartV3,
          locale: this.$i18n.locale,
          hubSlug: state.hub?.slug,
          deliveryCoordinates: state.deliveryCoordinates,
          shippingAddress: decorateShippingAddress(
            state.shippingAddress as IAddress,
            state.hub
          ),
          email: state.email || DUMMY_EMAIL_ADDRESS,
          deliveryETA: state.deliveryETA,
          cartLines: [], // Always create a cart with empty lines. Certain conditions (e.g. OOS) can interfere with cart creation.
          notes: '',
          ...(state.deliveryTierId && {
            deliveryTierId: state.deliveryTierId,
          }),
          ...(state.shippingMethodId && {
            shippingMethodId: state.shippingMethodId,
          }),
        },
        this.$logger
      )

      commit(SET_REMOTE_CART, cart)

      // We are updating the cart after creation, because the BE handles OOS products among create and update differently.
      // It's more robust to create an empty cart and then update it
      if (lines.length > 0) {
        dispatch('updateCart', { selectedCartLines: lines })
      }

      return state.remoteCart
    } catch (error) {
      await cartErrorHandler(error as AxiosError, this)
    }
  },
  async updateCart(
    { state, commit, dispatch },
    { selectedCartLines = null, isProrityDelivery = false } = {}
  ) {
    if (!state.remoteCart) {
      const cart = await dispatch('createCart')
      if (!cart) return
    }

    try {
      const plannedOrders = (state as IAppState).plannedOrders
      const cart = await updateCart(
        {
          client: this.$apiCartV3,
          locale: this.$i18n.locale,
          hubSlug: state.hub?.slug,
          deliveryCoordinates: state.deliveryCoordinates,
          shippingAddress: decorateShippingAddress(
            state.shippingAddress as IAddress,
            state.hub
          ),
          email: state.email || DUMMY_EMAIL_ADDRESS,
          deliveryETA: state.deliveryETA,
          cartId: state.remoteCart?.id as string,
          cartLines: selectedCartLines || cartLines(state.cart),
          ...(!state.remoteCart?.click_and_collect &&
            state.deliveryTierId && {
              deliveryTierId: state.deliveryTierId,
            }),
          ...(plannedOrders?.selectedTimeSlot?.id && {
            selectedTimeSlot: {
              ...plannedOrders.selectedTimeSlot,
            },
          }),
          isProrityDelivery,
        },
        this.$logger
      )
      commit(SET_REMOTE_CART, cart)

      return state.remoteCart
    } catch (error) {
      await cartErrorHandler(error as AxiosError, this)
    }
  },
  async getCart({ commit, dispatch, state }, cartId) {
    if (!cartId) return await dispatch('createCart', cartLines(state.cart))
    const cart = await getCart({
      client: this.$apiCartV3,
      locale: this.$i18n.locale,
      hubSlug: state.hub?.slug,
      logger: this.$logger,
      cartId,
    })
    if (cart) {
      commit(SET_REMOTE_CART, cart)
      commit(SET_APPLIED_PROMOTIONS, cart.promotions?.campaigns ?? [])

      const skus = cart?.lines.map(
        (cartLine: ICartLine) => cartLine.product_sku
      )

      if (skus.length) {
        await dispatch('catalog/loadProductsBySku', { skus })
      }

      dispatch('repopulateCartQuantities', cart.lines)

      if (cart.order) {
        commit(SET_PAYMENT_STATUS, {
          status: EPaymentStatus.SUCCESS,
          action: () => {
            dispatch('handleOrderExists', cart.order?.id)
          },
        })
        CheckoutPageLogger.orderAlreadyExistByGetCart(this.$logger, cart.order)

        this.$segmentEvent.ORDER_COMPLETED({
          orderId: cart.order.id,
          orderDisplayNumber: cart.order.number,
          voucherCode: cart.voucher_code,
          cartId: cart.id,
          postalCode: cart.shipping_address?.postal_code,
          hubSlug: cart.hub_slug,
        })
      }

      if (cart.state === ECartState.PAID) {
        const action = () => {
          commit(SET_PAYMENT_STATUS, undefined)
        }
        commit(SET_PAYMENT_STATUS, {
          status: EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT,
          action,
        })
        CheckoutPageLogger.cartAlreadyPaidByGetCart(this.$logger, cart)
      }

      return state.remoteCart
    }
    return undefined
  },
  async reloadCart({ dispatch, state }) {
    if (!state.remoteCart) return
    await dispatch('getCart', state.remoteCart?.id)
  },
  async recreateCart({ dispatch, state, commit }) {
    commit(SET_REMOTE_CART, undefined)
    commit(SET_PAYMENT_STATUS, undefined)
    await dispatch('createCart', cartLines(state.cart))
  },
  async removeUnavailableProducts({ state, commit, dispatch }, cartLines) {
    const { allValid, conflictingCartLines } = await areAllProductsAvailable(
      {
        client: this.$apiDiscovery,
        locale: this.$i18n.locale,
        hubSlug: state.hub?.slug,
        cartLines,
      },
      this.$logger
    )

    if (!allValid) {
      const cart = cloneDeep(state.cart)
      for (let i = 0; i < conflictingCartLines.length; i++) {
        dispatch('removeFromCart', {
          sku: conflictingCartLines[i].sku,
          quantity: conflictingCartLines[i].available,
        })
      }
      commit(SET_CART, cart)
      await dispatch('updateCart')
    }

    if (state.remoteCart) {
      await dispatch('repopulateCartQuantities', state.remoteCart.lines)
    }
  },
  removeUnpublishedProducts(
    { state, commit, dispatch },
    itemsToRemove: Array<{ sku: string }>
  ) {
    const skusToRemove = itemsToRemove.map(({ sku }) => sku)

    const updatedCart = cloneDeep(state.cart).filter(
      (item) => !skusToRemove.includes(item.sku)
    )

    commit(SET_CART, updatedCart)

    skusToRemove.forEach((sku) =>
      dispatch('removeFromCart', { sku, quantity: 0 })
    )
  },
  repopulateCartQuantities({ commit, state }, cartLines) {
    const cart: ICartItem[] = cartLines.map((cl: ICartLine) => {
      return { sku: cl.product_sku, quantity: cl.quantity }
    })

    const pendingCalls = state.checkout?.cartUpdateCallsPending || 0
    if (pendingCalls === 0) {
      commit(SET_CART, cart)
    }
  },
  // There is always a chance that API calls fail.
  // There may be hick-ups that a cart can not be created or the payment gateway fails
  // If this happens, we try to recreate these entities.
  async cartHealthCheck({ state, dispatch }, caller) {
    CART_HEALTH_CHECK_RETRY_ATTEMPT += 1
    if (CART_HEALTH_CHECK_RETRY_ATTEMPT > CART_HEALTH_CHECK_RETRY_COUNT) {
      this.$logger({
        level: ELogLevels.ERROR,
        msg: `Health check failed.`,
        error: `Health check failed after ${CART_HEALTH_CHECK_RETRY_ATTEMPT} retries for ${caller}.`,
        logger: ELoggers.CART,
        func: 'cartHealthCheck',
      })
      CART_HEALTH_CHECK_RETRY_ATTEMPT = 0
      return
    }

    if (!state.remoteCart) {
      await dispatch('createCart', cartLines(state.cart))
    }

    if (state.remoteCart) {
      CART_HEALTH_CHECK_RETRY_ATTEMPT = 0
    }
  },
  async createPaymentGateway({ rootState, commit }) {
    const paymentGateway = await getOrdersPaymentMethods(
      {
        client: this.$apiPaymentBff,
        locale: this.$i18n.locale,
        hubSlug: rootState.hub?.slug,
      },
      this.$logger
    )

    commit(SET_PAYMENT_GATEWAY, paymentGateway)
    return paymentGateway
  },
  async reloadPaymentGateway({ commit, dispatch }, { cartId }) {
    if (!cartId) return
    commit(SET_PAYMENT_GATEWAY, undefined)
    await dispatch('createPaymentGateway', cartId)
  },
  async updateCartLines({ state, dispatch, commit, getters }) {
    dispatch('checkout/resetPrepareCartForCheckoutError')

    if (!state.remoteCart) {
      const cart = await dispatch('createCart', cartLines(state.cart))
      if (!cart) return
    }

    const localCart = state.cart
    // we should send quantity: 0 in order to remove products that are not in local cart anymore.
    const removedObjects = (state.remoteCart?.lines || [])
      .filter((item: ICartLine) => {
        return !localCart.find(
          (localCartItem: ICartItem) => localCartItem.sku === item.product_sku
        )
      })
      .map((item: ICartLine) => ({
        sku: item.product_sku,
        quantity: 0,
      }))

    const cartItems: ICartItem[] = [...localCart, ...removedObjects]

    try {
      let pendingCalls = state.checkout?.cartUpdateCallsPending || 0
      commit(`checkout/${SET_CART_UPDATE_CALLS_PENDING}`, pendingCalls + 1)

      const plannedOrders = (state as IAppState).plannedOrders
      const cart = await updateCartSingleDebounced.callApi(
        {
          client: this.$apiCartV3,
          locale: this.$i18n.locale,
          hubSlug: state.hub?.slug,
          cartId: state.remoteCart?.id as string,
          cartLines: cartLines(cartItems),
          ...(!state.remoteCart?.click_and_collect &&
            state.deliveryTierId && {
              deliveryTierId: state.deliveryTierId,
            }),
          ...(plannedOrders?.selectedTimeSlot?.id && {
            selectedTimeSlot: {
              ...plannedOrders.selectedTimeSlot,
            },
          }),
        },
        this.$logger
      )

      pendingCalls = state.checkout?.cartUpdateCallsPending || 0
      commit(`checkout/${SET_CART_UPDATE_CALLS_PENDING}`, pendingCalls - 1)

      if (cart) {
        commit(SET_REMOTE_CART, cart)
        commit(SET_APPLIED_PROMOTIONS, cart.promotions?.campaigns ?? [])
        dispatch('cartHealthCheck', 'updateCart')
        dispatch('repopulateCartQuantities', cart.lines)
        dispatch('checkout/updateHadStorageFee')
      }
      const newDeliveryTierValues = {
        deliveryTierIndex: getters.getCurrentDeliveryTierIndex,
        minOrderReached: getters.getMinOrderValueReached,
        deliveryFee: getters.getDeliveryFee,
      }
      if (state.previousDeliveryTierInfo) {
        trackDeliveryTierChangedHelper(
          state.previousDeliveryTierInfo,
          newDeliveryTierValues,
          this.$segmentEvent
        )
      }
      this.$segmentEvent.CART_UPDATED()

      return state.remoteCart
    } catch (error) {
      await cartErrorHandler(error as AxiosError, this)

      const pendingCalls = state.checkout?.cartUpdateCallsPending || 0
      commit(`checkout/${SET_CART_UPDATE_CALLS_PENDING}`, pendingCalls - 1)
    }
  },
  resetCart({ commit, dispatch }) {
    commit(SET_REMOTE_CART, undefined)
    commit(SET_PAYMENT_GATEWAY, undefined)
    commit(SET_PAYMENT_METHOD, undefined)
    commit(SET_DISCOUNT, {})
    commit(SET_CART, [])
    commit(SET_APPLIED_PROMOTIONS, [])
    dispatch('checkout/updateHadStorageFee', false)
  },
  async validateCartLineAmounts({ rootState, state, commit }) {
    const { allValid, conflictingCartLines } = await areAllProductsAvailable(
      {
        client: this.$apiDiscovery,
        locale: this.$i18n.locale,
        hubSlug: rootState.hub?.slug,
        cartLines: cartLines(state.cart),
      },
      this.$logger
    )

    if (!allValid) {
      commit(`checkout/${SET_CONFLICTING_LINES}`, conflictingCartLines)

      return {
        error: ECartErrorCode.ITEM_NOT_AVAILABLE,
        errorInfo: {
          conflictingCartLines,
        },
      }
    }

    return { error: null, errorInfo: null }
  },
  async removeVoucher({ state, dispatch }) {
    if (!state.remoteCart) return
    if (!state.remoteCart?.voucher_code) return
    await removeVoucher(
      {
        client: this.$apiVouchersWallet,
        locale: this.$i18n.locale,
        cartId: state.remoteCart?.id,
      },
      this.$logger
    )
    dispatch('addVoucher', {})
  },
  async handleOrderExists({ state, commit, dispatch }, orderId: string) {
    if (!orderId) return
    await this.$router.push({
      path: this.localePath({
        name: state.remoteCart?.click_and_collect
          ? ROUTES.ORDER_COLLECT
          : ROUTES.ORDER_STATUS,
        params: { slug: orderId },
      }),
    })
    commit(SET_PAYMENT_STATUS, undefined)
    dispatch('resetCart')
  },
  async updateClickAndCollect(
    { state, dispatch, getters },
    isClickAndCollect: boolean
  ) {
    if (!state.remoteCart) return

    const shippingMethod = isClickAndCollect
      ? EShippingMethod.CLICK_AND_COLLECT
      : EShippingMethod.DELIVERY

    try {
      await dispatch('updateDeliveryTiers', {
        hubSlug: state.hub?.slug,
        hubCountry: state.hub?.country,
        shippingMethod,
      })
      if (!isClickAndCollect) {
        await dispatch('plannedOrders/updateSchedulingCheckout')
      }

      const plannedOrders = (state as IAppState).plannedOrders
      await updateDeliveryInformation({
        client: this.$apiCartV3,
        logger: this.$logger,
        locale: this.$i18n.locale,
        hubSlug: state.hub?.slug || '',
        cartId: state.cartId || '',
        ...(plannedOrders.selectedTimeSlot && {
          updatedSlot: {
            id: plannedOrders.selectedTimeSlot.id,
            type: plannedOrders.selectedTimeSlot.type,
          },
        }),
        ...(isClickAndCollect && {
          updatedSlot: {
            id:
              getters['plannedOrders/getAsapOrExpressToken'] ||
              plannedOrders.selectedTimeSlot.id,
            type: ESchedulingOptionType.CLICK_AND_COLLECT,
          },
        }),
      })
      await dispatch('getCart', state.remoteCart?.id)

      if (isClickAndCollect && state.remoteCart.rider_tip?.amount > 0) {
        await dispatch('addRiderTip', {
          centAmount: 0,
          currency: COUNTRY_CURRENCIES[ECountries.DE],
        })
      }
    } catch (e) {
      this.$logger({
        msg: 'Error when setting shipping method (click and collect).',
        error: (e as AxiosError).toString(),
        level: ELogLevels.ERROR,
        logger: ELoggers.CART,
        func: 'updateClickAndCollect',
      })
    }
  },
  async addRiderTip({ state, dispatch }, price: ICentPrice): Promise<void> {
    if (!state.remoteCart) return
    try {
      await addRiderTip(this.$apiCartV3, this.$logger, {
        cartId: state.remoteCart.id,
        ...price,
      })
      dispatch('reloadCart')
    } catch (e) {}
  },
  onOnboardingDone({ dispatch }): void {
    dispatch('toggleOnboardingModal', false)
    if (this.$router.currentRoute.path.includes('/recipes')) {
      return
    }
    dispatch('setShowOnboardingHint', true)
  },
  addToRouterHistory({ state, commit }, currentRouteName: string): void {
    commit(SET_ROUTER_HISTORY, [...state.routerHistory, currentRouteName])
  },
  setLatencyTracing({ state, commit }, { traceName, traceMoment }): void {
    if (traceMoment === ETraceMoment.STARTED) {
      const actionId = uuidv4()
      const startTimestamp = new Date().toISOString()

      this.$segmentEvent.LATENCY_TRACING({
        traceName,
        traceMoment,
        actionId,
        startTimestamp,
      })

      commit(SET_CURRENT_LATENCY_TRACING, {
        traceName,
        actionId,
        startTimestamp,
      })
    } else if (traceMoment === ETraceMoment.COMPLETED) {
      const { actionId, startTimestamp } = state.currentLatencyTracing

      if (actionId && startTimestamp) {
        this.$segmentEvent.LATENCY_TRACING({
          traceName,
          traceMoment,
          actionId,
          startTimestamp,
          endTimestamp: new Date().toISOString(),
        })
      }

      commit(SET_CURRENT_LATENCY_TRACING, {
        actionId: undefined,
        startTimestamp: undefined,
        traceName: undefined,
      })
    }
  },
  async checkOpeningHours({ state, dispatch }): Promise<void> {
    if (!state.hub?.slug) return
    if (!state.deliveryCoordinates) return

    const hub = await getHub(
      this.$hubLocator,
      this.$logger,
      state.deliveryCoordinates
    )

    if (hub?.details.is_closed !== state.hub.details?.is_closed) {
      await dispatch('reloadHub')
    }
  },
  checkNoDeliveryToLocation({ getters, dispatch }): void {
    if (getters.getIsNotInDeliveryArea) {
      dispatch('toggleNoDeliveryToLocationModal', true)
    }
  },
  setRemoteCart({ commit }, cart): void {
    commit(SET_REMOTE_CART, cart)
  },
}
