import { get } from '~/api/controllers/get'
import { ILateNightConfig } from '~/types/app/Cart'
import { LoggerFunction } from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
}

export default async function getLateNightConfig({
  client,
  logger,
  locale,
  hubSlug,
}: IParameters): Promise<ILateNightConfig | null> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = '/cart/fee/late-night/config'

  try {
    const response = await get<ILateNightConfig>(client, logger, url, headers)
    return response.data
  } catch (error) {}

  return null
}
