import {
  IVoucherWallet,
  IVoucherWalletRaw,
  isVoucherWallet,
} from '~/types/app/VoucherWallet'
import { FREE_DELIVERY_VOUCHER_PREFIX } from '../constants'

export function buildVoucherWalletObject(
  voucherWallet: IVoucherWallet | IVoucherWalletRaw
): IVoucherWallet {
  if (isVoucherWallet(voucherWallet)) {
    return voucherWallet
  }

  const {
    title,
    terms,
    code,
    sub_title: subTitleRaw,
    is_applicable: isApplicableRaw,
    rejection_reason: rejectionReasonRaw,
    expires_at: expiresAtRaw,
  } = voucherWallet

  return {
    code,
    title,
    terms,
    subTitle: subTitleRaw,
    isApplicable: isApplicableRaw,
    rejectionReason: rejectionReasonRaw,
    expiresAt: expiresAtRaw,
  }
}

export function isFreeDeliveryVoucher(
  voucher: IVoucherWallet | IVoucherWalletRaw
): boolean {
  return voucher.code.startsWith(FREE_DELIVERY_VOUCHER_PREFIX)
}

type VouchersGroup = {
  applicable: IVoucherWalletRaw[]
  nonApplicable: IVoucherWalletRaw[]
}

export function groupApplicableVouchers(
  vouchers: IVoucherWalletRaw[]
): VouchersGroup {
  const groupedVouchers = vouchers.reduce(
    (acc, voucher) => {
      const targetVoucherGroup = voucher.is_applicable
        ? 'applicable'
        : 'nonApplicable'

      acc[targetVoucherGroup].push(voucher)

      return acc
    },
    { applicable: [], nonApplicable: [] } as VouchersGroup
  )

  return groupedVouchers
}

export function prioritizeFreeDeliveryVouchers(
  vouchers: IVoucherWalletRaw[]
): IVoucherWalletRaw[] {
  return vouchers.sort((v1, v2) => {
    if (isFreeDeliveryVoucher(v1) && !isFreeDeliveryVoucher(v2)) {
      return -1
    }

    if (!isFreeDeliveryVoucher(v1) && isFreeDeliveryVoucher(v2)) {
      return 1
    }

    return 0
  })
}

export function extractSKUFromFreeDeliveryVoucher(
  voucher: IVoucherWallet | IVoucherWalletRaw
): string {
  return voucher.code.split(FREE_DELIVERY_VOUCHER_PREFIX)[1]
}
