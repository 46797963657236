













import { ref, watch, computed, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import { eventBus } from './event-bus';
import { getPositionStyle } from './utils';
import { IDirectiveParams } from './directive';
import { IPositionStyle, EDirection } from './';
type PopoverEvent = IDirectiveParams & {
  target: Element;
  arrowAnchor?: Element | null;
  evt?: MouseEvent;
};
const __sfc_main = {};
__sfc_main.props = {
  name: {
    type: String,
    default: ''
  },
  underlay: {
    type: Boolean
  },
  underlaySelector: {
    type: String,
    default: 'body'
  },
  initialVisible: {
    type: Boolean,
    default: false
  },
  direction: {
    type: (String as () => EDirection),
    default: EDirection.down
  },
  offsetY: {
    type: Number,
    default: 0
  },
  offsetX: {
    type: Number,
    default: 0
  },
  arrowSpacing: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const visible = ref(props.initialVisible);
  const calculatedArrowSpacing = ref(props.arrowSpacing);
  const anchorPosition = ref('');
  const positionStyle = ref<IPositionStyle | null>(null);
  const underlayEl = ref<HTMLElement | null>(null);
  const arrowSizeInPx = ref(10);
  const popoverEl = ref<HTMLElement | null>(null);
  const cssVariables = computed<Record<string, string | undefined>>(() => ({
    '--arrow-spacing': calculatedArrowSpacing.value,
    '--arrow-size': `${arrowSizeInPx.value}px`
  }));
  const style = computed<Record<string, string | undefined>>(() => ({ ...positionStyle.value,
    ...cssVariables.value,
    visibility: visible.value ? '' : 'hidden'
  }));
  const anchorClassName = computed<string>(() => anchorPosition.value ? `--${anchorPosition.value}` : '');
  watch(() => visible.value, () => {
    if (!props.underlay) return;

    if (visible.value) {
      underlayEl.value = document.createElement('div');
      underlayEl.value.id = 'v-popover-underlay';

      if (props.underlaySelector === 'body') {
        underlayEl.value.className = '--fixed';
      }

      document.querySelector(props.underlaySelector)?.append(underlayEl.value);
    } else {
      underlayEl.value?.remove();
    }
  });

  function handleToggle({
    target,
    arrowAnchor,
    name,
    direction,
    value
  }: PopoverEvent): void {
    if (name !== props.name) return;

    if (visible.value && !props.initialVisible) {
      handleHide({
        name
      });
    } else {
      handleShow({
        target,
        arrowAnchor,
        name,
        direction,
        value
      });
    }
  }

  function handleShow({
    target,
    arrowAnchor,
    name,
    direction = props.direction,
    value = {}
  }: PopoverEvent): void {
    if (name !== props.name) return;
    if (!target) return;
    const targetRect: DOMRect = target.getBoundingClientRect();
    const {
      offsetX = props.offsetX,
      offsetY = props.offsetY
    } = value;
    const {
      style,
      anchorPosition: anchorStyle,
      arrowSpacing
    } = getPositionStyle(targetRect, {
      direction,
      offsetX,
      offsetY
    }, popoverEl.value?.offsetParent, arrowAnchor);
    positionStyle.value = style;
    anchorPosition.value = anchorStyle;

    if (arrowSpacing) {
      // this.arrowSizeInPx is half the width of the arrow
      calculatedArrowSpacing.value = `${arrowSpacing - arrowSizeInPx.value}px`;
    } else {
      calculatedArrowSpacing.value = props.arrowSpacing;
    }

    function handleDocumentClick(event: MouseEvent) {
      const eventTarget = (event.target as Element);
      const popover = document.querySelector(`[data-popover='${name}']`);

      if (target.contains(eventTarget) || popover?.contains(eventTarget)) {
        return;
      }

      eventBus.$emit('popover:hide', {
        name
      });
      document.removeEventListener('pointerup', handleDocumentClick);
    }

    visible.value = true;
    emit('show', {
      name
    });
    document.addEventListener('pointerup', handleDocumentClick);
  }

  function handleHide({
    name
  }: {
    name: PopoverEvent['name'];
  }): void {
    if (name !== props.name) return;
    visible.value = false;
    emit('hide', {
      name
    });
  }

  function anchorOnTarget(): void {
    if (!visible.value) {
      return;
    }

    const name = props.name;
    const target = document.querySelector(`[data-popover-target*="${name}"]`);
    const arrowAnchor = document.querySelector(`[data-popover-arrow-anchor*=${name}]`);
    const direction = props.direction;

    if (target && name) {
      handleShow({
        name,
        target,
        arrowAnchor,
        direction
      });
    } else {
      // eslint-disable-next-line no-console
      console.warn(`Popover anchor target for '${name}' is not found.`);
    }
  }

  onMounted(() => {
    anchorOnTarget();
    eventBus.$on('popover:toggle', handleToggle);
    eventBus.$on('popover:hide', handleHide);
    eventBus.$on('popover:show', handleShow);

    if (process.client) {
      window.addEventListener('resize', anchorOnTarget);
    }
  });
  onUnmounted(() => {
    eventBus.$off('popover:toggle', handleToggle);
    eventBus.$off('popover:hide', handleHide);
    eventBus.$off('popover:show', handleShow);
    underlayEl.value?.remove();

    if (process.client) {
      window.removeEventListener('resize', anchorOnTarget);
    }
  });
  return {
    visible,
    popoverEl,
    style,
    anchorClassName
  };
};

export default __sfc_main;
