







































import { ref, computed, useStore, PropType, useContext, onBeforeUnmount } from '@nuxtjs/composition-api';
import IProduct from '~/types/app/Product';
import { ICTAButton } from '~/types/app/CTAButton';
import { SET_VERIFIED_AGE } from '~/store/mutation-types';
import VSwimlane from '~/components/VSwimlane/VSwimlane.vue';
import { IAppState, ICartAddPayload } from '~/types/app/State';
import ProductCard from '~/components/ProductCard/ProductCard.vue';
import { EProductPlacement, EScreenName, EComponentName, ETrackingType } from '~/lib/segment';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: false,
    default: ''
  },
  isHighlighted: {
    type: Boolean,
    default: false
  },
  hasAddAll: {
    type: Boolean,
    default: false
  },
  button: {
    type: (Object as () => ICTAButton),
    default: null
  },
  products: {
    type: (Array as () => Array<IProduct>),
    default: () => []
  },
  swimlanePositionFromTop: {
    type: Number,
    require: false,
    default: undefined
  },
  productPlacement: {
    type: (String as PropType<EProductPlacement>),
    default: EProductPlacement.swimlane
  },
  listName: {
    type: String,
    default: ''
  },
  isRecipes: {
    type: Boolean,
    default: false
  },
  screenName: {
    type: String,
    default: EScreenName.home
  },
  componentName: {
    type: String,
    default: EComponentName.swimlane
  },
  componentVariant: {
    type: String,
    required: false,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  oosProduct: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const marginRight = 12;
  const hasProducts = computed<boolean>(() => props.products?.length > 0);
  const store = useStore<IAppState>();
  const {
    $segmentEvent
  } = useContext();
  const unsubscribe = ref<() => void>();

  const handleAddAllClick = () => {
    const tags = [];
    const cart: ICartAddPayload[] = props.products.map(item => {
      return store.getters['catalog/getProductBySKU'](item.sku);
    }).filter(item => item !== undefined).map(item => {
      tags.push(...(item.tags || []));
      return {
        sku: item.sku,
        tags: item.tags,
        productPlacement: EProductPlacement.recommendation,
        listName: props.listName,
        quantity: item.maxQuantity > 0 ? 1 : 0
      };
    });
    store.dispatch('addMultipleToCart', cart);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.addAllItems,
      componentVariant: props.listName,
      screenName: 'last_bought_detail'
    });

    if (!tags.length || !store.state.ageVerification.isRequired) {
      emit('all-added');
    } else {
      unsubscribe.value = store.subscribe(({
        type
      }) => {
        if (type === SET_VERIFIED_AGE) {
          emit('all-added');
        }
      });
    }
  };

  onBeforeUnmount(() => {
    if (unsubscribe.value) unsubscribe.value();
  });
  return {
    marginRight,
    hasProducts,
    handleAddAllClick
  };
};

__sfc_main.components = Object.assign({
  VSwimlane,
  ProductCard
}, __sfc_main.components);
export default __sfc_main;
