import transformProducts from '~/api/transformers/transform-products'
import { SectionTransformResult } from '../shared'
import { CollectionCardRaw, V2HomeSectionRaw } from './types'

const check = (section: V2HomeSectionRaw): section is CollectionCardRaw =>
  'cardSection' in section

const transform = (section: CollectionCardRaw): SectionTransformResult => {
  const type = 'cardSection'
  const { cardSection } = section
  const products = transformProducts(cardSection.previewProducts)
  const {
    id,
    slug,
    shortTitle,
    buttonComponent,
    thumbnailImageUrl,
    layoutTrackingId,
  } = cardSection

  return {
    id,
    type,
    slug,
    products,
    title: shortTitle,
    button: buttonComponent,
    listName: layoutTrackingId,
    imageURL: thumbnailImageUrl ?? '',
  }
}

export const collectionCardTransformer = { check, transform }
