import { SectionTransformResult } from '../shared'
import { DeeplinkedMarketingBannerSliderRaw, V2HomeSectionRaw } from './types'

const check = (
  section: V2HomeSectionRaw
): section is DeeplinkedMarketingBannerSliderRaw =>
  'deepLinkMarketingBannerSlider' in section

const transform = (
  section: DeeplinkedMarketingBannerSliderRaw
): SectionTransformResult => {
  const type = 'marketingBannerSlider'
  const { deepLinkMarketingBannerSlider } = section

  const { banners, decisionId, layoutTrackingId } =
    deepLinkMarketingBannerSlider

  return {
    type,
    decisionId,
    layoutTrackingId,
    marketingBanners: banners,
  }
}

export const deeplinkedMarketingBannerSliderTransformer = { check, transform }
