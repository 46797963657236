








import {
  useMeta,
  useStore,
  useRoute,
  useRouter,
  onMounted,
  useContext,
  onUnmounted,
  defineComponent,
  getCurrentInstance,
} from '@nuxtjs/composition-api'
import IRootState from '~/types/app/State'
import { PortalTarget } from 'portal-vue'
import {
  SET_HUB,
  ACCOUNT_PREFIX,
  SET_SEARCH_LABELS,
  SET_SEARCH_LABELS_TITLE,
  SET_COLLECTION_PAGE_REFFER,
  SET_SEGMENT_ANONYMOUS_USER_ID,
} from '~/store/mutation-types'
import { MetaInfo } from 'vue-meta'
import { ROUTES } from '~/lib/routes'
import { getRouteName, getPrefixedMutation } from '~/lib/helpers'
import { ExperimentKeys } from '~/types/app/experiments'

const PAGES_WITHOUT_HREFLANG = ['shop-product-slug', 'shop-category-slug']
const HREFLANG_TAG_RELATION = 'alternate'
const LOCAL_STORAGE_VISITED_KEY = 'flink.visited'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    PortalTarget,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore<IRootState>()
    const { $segmentEvent } = useContext()
    const vm = getCurrentInstance()?.proxy

    const unsubscribe = store.subscribe(({ type }) => {
      if (type === SET_HUB) {
        store.commit(`search/${SET_SEARCH_LABELS_TITLE}`, '')
        store.commit(`search/${SET_SEARCH_LABELS}`, [])
      }
      if (
        type ===
        getPrefixedMutation(ACCOUNT_PREFIX, SET_SEGMENT_ANONYMOUS_USER_ID)
      ) {
        if (store.state.account?.segmentAnonymousUserId) {
          store.dispatch('experiments/fetchData', [
            ExperimentKeys.PRODUCT_TILES_SIZE,
          ])
        }
      }
    })

    router.afterEach((to) => {
      const currentRoute = getRouteName(route.value) as ROUTES
      const nextRoute = getRouteName(to) as ROUTES

      if (
        currentRoute !== ROUTES.HOME ||
        ![
          ROUTES.COLLECTION,
          ROUTES.CATEGORY,
          ROUTES.DEALS,
          ROUTES.PRODUCT,
        ].includes(nextRoute)
      ) {
        store.commit(`catalog/${SET_COLLECTION_PAGE_REFFER}`, null)
      }
    })

    useMeta(() => {
      const isExcluded = PAGES_WITHOUT_HREFLANG.some((r) =>
        route.value.name?.startsWith(r)
      )
      const i18nHead = vm?.$nuxtI18nHead({ addSeoAttributes: true }) as MetaInfo

      return isExcluded
        ? {
            ...i18nHead,
            link: i18nHead.link?.filter(
              (link: any) => link.rel !== HREFLANG_TAG_RELATION
            ),
          }
        : i18nHead
    })

    onMounted(() => {
      const isFirstVisit = !window.localStorage.getItem(
        LOCAL_STORAGE_VISITED_KEY
      )

      if (isFirstVisit) {
        window.localStorage.setItem(LOCAL_STORAGE_VISITED_KEY, 'true')
      } else {
        $segmentEvent.WEB_OPENED()
      }
    })

    onUnmounted(() => {
      unsubscribe()
    })
  },
  head: {},
})
