import fetchIntercept from 'fetch-intercept'
import { xor } from 'lodash-es'
import * as Cookies from 'js-cookie'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

import { createIntercomHash } from '~/api/services/create-intercom-hash'
import { buildSegmentEvents, buildSegmentIdentify } from '~/lib/segment'
import { getCountryFromLocale, incrementPatchVersion } from '~/lib/helpers'

export const CONSENT_CONTAINER_ID = 'cookie-consent'
const LOCAL_STORAGE_UTM_KEY = 'utm-query'
const SEGMENT_DESTINATIONS = [
  'All',
  'Segment.io',
  'AdWords',
  'Google AdWords New',
  'Google Tag Manager',
  'Facebook App Events',
  'Facebook Pixel',
  'Facebook Pixel Server Side',
  'Google Analytics',
  'TV Squared',
  'Intercom',
  'Google Analytics 4 Web',
]
const SEGMENT_DOMAINS = ['//api.segment.io', '//cdn.segment.com']

fetchIntercept.register({
  responseError: function (error) {
    // Ignore requests blocked by ad blockers
    if (SEGMENT_DOMAINS.some((domain) => error.request.url.includes(domain))) {
      return Promise.resolve({ ok: true, json: () => Promise.resolve([]) })
    }
    return Promise.reject(error)
  },
})

// prettier-ignore
function loadAnalytics (writeKey){
  var i="analytics",analytics=window[i]=window[i]||[]; // NOSONAR
  if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n}; // NOSONAR
  analytics._writeKey=writeKey;
  analytics.SNIPPET_VERSION="5.2.1";
  analytics.page();}
}

function loadScript(src) {
  const script = document.createElement('script')
  script.src = src
  document.body.appendChild(script)
  return script
}

function setUpConsentManager() {
  loadScript(
    'https://unpkg.com/@segment/consent-manager@5.4.0/standalone/consent-manager.js'
  )

  const consentContainer = document.createElement('div')
  consentContainer.id = CONSENT_CONTAINER_ID
  document.body.appendChild(consentContainer)
}

const hasUtmTags = (query = {}) =>
  Object.keys(query).some((x) => x.startsWith('utm_'))

let trackingPrefs

function setTrackingPrefsFromCookie() {
  try {
    trackingPrefs = JSON.parse(Cookies.get('tracking-preferences'))
  } catch {}
}
setTrackingPrefsFromCookie()

const segmentClient = async (
  { i18n, $config, $axios, app, $logger },
  inject
) => {
  const writeKey = $config.isProd
    ? 'iJvTKWBjAbtF7J7pU7LQ6kkbwaMe86bn'
    : 'zO6GjauVYizwXAHKlXcIgDpEVFHCxB5U'

  loadAnalytics(writeKey)

  app.router.beforeEach((to, from, next) => {
    // Store the previous route on the router instance
    app.router.prevRoute = from
    next()
  })

  app.router.afterEach((to) => {
    // An arbitrary delay to let the page fully render to get the location and
    // title for the destination before sending it to Segment. Otherwise we risk
    // sending stale data for the page the navigation originated from.
    setTimeout(() => {
      const localUtmSearch = localStorage.getItem(LOCAL_STORAGE_UTM_KEY) ?? ''
      const pageEvent = {
        path: location.pathname,
        referrer: document.referrer,
        search: location.search || localUtmSearch,
        title: document.title,
        url: location.search ? location.href : location.href + localUtmSearch,
      }
      if (trackingPrefs) {
        window.analytics?.page(pageEvent, {
          traits: {
            country_code: getCountryFromLocale(app.i18n.locale) || 'unknown',
            city: app.store.state.shippingAddress?.city,
            hub_slug: app.store.state.hub?.slug,
            user_logged_in: app.store.getters['account/isUserAuthenticated'],
            app: {
              version: incrementPatchVersion($config.lastTag),
            },
          },
          integrations: {
            'Google AdWords New': true,
            'Google Tag Manager': true,
            'Google Analytics 4 Web': true,
          },
        })
        localStorage.removeItem(LOCAL_STORAGE_UTM_KEY)
        return
      }

      if (hasUtmTags(to.query)) {
        localStorage.setItem(LOCAL_STORAGE_UTM_KEY, location.search)
      }
    }, 200)
  })

  window.consentManagerConfig = function (exports) {
    const { React, inEU, openConsentManager, preferences } = exports
    const h = React.createElement

    const shouldRequireConsent = () =>
      inEU() &&
      !!xor(
        SEGMENT_DESTINATIONS,
        Object.keys(trackingPrefs?.destinations ?? {})
      ).length

    preferences.onPreferencesSaved(() => {
      setTrackingPrefsFromCookie()
      document.getElementById(CONSENT_CONTAINER_ID).style.display = 'none'
    })

    const bannerContent = h(
      'div',
      { class: 'banner-content' },
      h('span', { class: 'banner-content-text' }, [
        i18n.t('cookies_consent_content'),
        ' ',
        h(
          'a',
          {
            href: `/${i18n.locale}/privacy/`,
            target: '_blank',
            class: 'privacy-link',
          },
          i18n.t('cookies_consent_policy_link_text')
        ),
        '.',
      ])
    )

    const bannerActionsBlock = ({ acceptAll }) =>
      h(
        'div',
        { class: 'accept-manage-buttons' },
        h(
          'button',
          { class: 'manage-button', onClick: openConsentManager },
          i18n.t('cookies_consent_subcontent')
        ),
        h(
          'button',
          {
            class: 'accept-button',
            onClick: acceptAll,
          },
          i18n.t('cookies_consent_accept_all')
        )
      )

    const preferencesDialogTemplate = {
      headings: {
        allowValue: i18n.t('cookies_consent_headings_allow'),
        categoryValue: i18n.t('cookies_consent_headings_category'),
        purposeValue: i18n.t('cookies_consent_headings_purpose'),
        toolsValue: i18n.t('cookies_consent_headings_tools'),
      },
      checkboxes: {
        noValue: i18n.t('generic_no'),
        yesValue: i18n.t('generic_yes'),
      },
      actionButtons: {
        cancelValue: i18n.t('generic_cancel'),
        saveValue: i18n.t('generic_save'),
      },
      cancelDialogButtons: {
        cancelValue: i18n.t('cookies_consent_dialog_actions_cancel'),
        backValue: i18n.t('cookies_consent_dialog_actions_back'),
      },
      categories: [
        {
          key: 'functional',
          name: i18n.t('cookies_consent_functional_name'),
          description: i18n.t('cookies_consent_functional_description'),
          example: i18n.t('cookies_consent_functional_example'),
        },
        {
          key: 'marketing',
          name: i18n.t('cookies_consent_marketing_name'),
          description: i18n.t('cookies_consent_marketing_description'),
          example: i18n.t('cookies_consent_marketing_example'),
        },
        {
          key: 'advertising',
          name: i18n.t('cookies_consent_advertising_name'),
          description: i18n.t('cookies_consent_advertising_description'),
          example: i18n.t('cookies_consent_advertising_example'),
        },
        {
          key: 'essential',
          name: i18n.t('cookies_consent_essential_name'),
          description: i18n.t('cookies_consent_essential_description'),
          example: i18n.t('cookies_consent_essential_example'),
        },
      ],
    }

    return {
      container: `#${CONSENT_CONTAINER_ID}`,
      closeBehavior: 'accept',
      writeKey,
      bannerContent,
      bannerActionsBlock,
      shouldRequireConsent,
      bannerBackgroundColor: 'none',
      bannerTextColor: 'inherit',
      bannerSubContent: i18n.t('cookies_consent_subcontent'),
      preferencesDialogTitle: i18n.t('cookies_consent_dialog_title'),
      preferencesDialogContent: i18n.t('cookies_consent_dialog_content'),
      preferencesDialogTemplate,
      cancelDialogTitle: i18n.t('cookies_consent_dialog_cancel_title'),
      cancelDialogContent: i18n.t('cookies_consent_dialog_cancel_content'),
    }
  }

  let userId, userHash
  if (app.store.state.account.user?.uid) {
    userId = app.store.state.account.user?.uid
    const localApi = $axios.create()
    localApi.setBaseURL('/api')
    const hashResponse = await createIntercomHash(localApi, $logger, userId)
    userHash = hashResponse ? hashResponse.hash : ''
  }

  window.intercomSettings = {
    custom_launcher_selector: '[href="mailto:support@goflink.com"]',
    hide_default_launcher: true,
    ...(userId && { user_id: userId }),
    ...(userHash && { user_hash: userHash }),
  }

  setUpConsentManager()

  inject('segmentEvent', buildSegmentEvents(app))
  inject('segmentIdentify', buildSegmentIdentify(app))
}

export default defineNuxtPlugin(segmentClient)
