export default async function ({ route, app }) {
  // the cart-id is defined via URL parameter on this page. Cart loading is treated seperately there.
  if (route.path.includes('/process-payment')) {
    return
  }
  if (
    (route.path.includes('/shop/') ||
      route.path.includes('/recipes/') ||
      route.path.includes('/account/')) &&
    !app?.store?.state.remoteCart &&
    app?.store?.state.cartId
  ) {
    await app?.store?.dispatch('getCart', app?.store?.state.cartId)
  }
}
