export default async function ({ route, app }) {
  if (
    !app?.store?.state.hub &&
    (route.path.includes('/shop/') ||
      route.path.includes('/recipes/') ||
      route.path.includes('/account/'))
  ) {
    await app?.store?.dispatch('performInitialOnboarding')
  }
}
