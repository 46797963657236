import { render, staticRenderFns } from "./VIcon.vue?vue&type=template&id=ce5606dc&scoped=true&"
import script from "./VIcon.vue?vue&type=script&lang=ts&"
export * from "./VIcon.vue?vue&type=script&lang=ts&"
import style0 from "./VIcon.vue?vue&type=style&index=0&id=ce5606dc&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce5606dc",
  null
  
)

export default component.exports