import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signOut,
  setPersistence,
  onAuthStateChanged,
  confirmPasswordReset,
  verifyPasswordResetCode,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import { Plugin, NuxtConfig } from '@nuxt/types'
import { User } from '@firebase/auth'

import {
  firebaseStagingConfig,
  firebaseProductionConfig,
} from '~/firebase.config'
import { createIntercomHash } from '~/api/services/create-intercom-hash'
import { FireUser } from '~/store/account'

export interface IFirebasePlugin {
  signOut: () => void
  signInWithEmailAndPassword: (email: string, password: string) => Promise<void>
  verifyPasswordResetCode: (outOfBandCode: string) => Promise<string>
  resetPassword: (outOfBandCode: string, newPassword: string) => Promise<void>
  currentUser: FireUser | null
}

const firebasePlugin: Plugin = (
  { store, $axios, $logger, $cookies, $config },
  inject
) => {
  const config = $config.isProd
    ? firebaseProductionConfig
    : firebaseStagingConfig

  initializeApp(config)

  const auth = getAuth()
  setPersistence(auth, browserLocalPersistence)

  const fire: IFirebasePlugin = {
    async signOut() {
      await signOut(auth)
    },
    async signInWithEmailAndPassword(email: string, password: string) {
      await signInWithEmailAndPassword(auth, email, password)
    },
    async verifyPasswordResetCode(outOfBandCode: string) {
      return await verifyPasswordResetCode(auth, outOfBandCode)
    },
    async resetPassword(outOfBandCode: string, newPassword: string) {
      return await confirmPasswordReset(auth, outOfBandCode, newPassword)
    },
    currentUser: toFireUser(auth.currentUser || null),
  }

  onAuthStateChanged(auth, async (user) => {
    fire.currentUser = toFireUser(user)
    if (window.Intercom && user?.uid) {
      const localApi = $axios.create()
      const basePath = ($config as NuxtConfig)._app?.basePath || '/'
      localApi.setBaseURL(`${basePath}api`)
      const userHash = await createIntercomHash(localApi, $logger, user.uid)
      window.Intercom('update', {
        user_id: user.uid,
        user_hash: userHash?.hash || '',
      })

      const datadogRum = (window as any).DD_RUM
      datadogRum?.setUserProperty('id', user.uid)
    }

    if (user) {
      const idToken = await user.getIdToken()
      $cookies.set('authToken', idToken)
    }

    store.dispatch('account/onAuthStateChanged', {
      authUser: user,
    })

    store.dispatch('account/setFirstAuthRan')
  })

  inject('fire', fire)
}

function toFireUser(user: User | null): FireUser {
  return {
    email: user?.email || '',
    emailVerified: user?.emailVerified || false,
    uid: user?.uid || '',
    displayName: user?.displayName || '',
    phoneNumber: user?.phoneNumber || '',
    photoURL: user?.photoURL || '',
    getIdToken: async () => {
      return (await user?.getIdToken()) || ''
    },
  }
}

export default firebasePlugin
