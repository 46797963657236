import {
  useContext,
  useRouter,
  computed,
  ComputedRef,
} from '@nuxtjs/composition-api'
import ICart, {
  ICartOrder,
  IPaymentStatusV2,
  EPaymentStatusV2,
  IPaymentReceipt,
  EPaymentReceiptType,
  CartBlockingMessage,
  EPaymentStatus,
} from '~/types/app/Cart'
import { IPaymentGateway } from '~/types/app/Payments'
import { getCart } from '~/api/services/get-cart'
import { getPaymentStatus } from '~/api/services/get-payment-status'
import { sleep, textToPartiallyBoldHTML } from '~/lib/helpers'
import {
  EPaymentErrorCode,
  IAdyenDropinState,
} from '~/components/PaymentForm/payment-form-types'
import CheckoutPageLogger from '~/lib/logger/checkoutPageLogger'
import { ROUTES, PROFILE_PAGE_TABS } from '~/lib/routes'
import { ICheckoutPayloadAddress } from '~/types/app/Location'
import { ICartItem } from '~/types/app/State'
import { areAllProductsAvailable } from '~/api/services/are-all-products-available'
import { updateCart } from '~/api/services/update-cart'
import { DUMMY_EMAIL_ADDRESS } from '~/lib/constants'
import { checkoutCart } from '~/api/services/checkout-cart'
import { ICheckoutCartError } from '~/api/services/checkout-cart/checkout-cart'
import { createReturnUrl } from '~/components/PaymentForm/payment-utils/create-return-url'
import { AxiosError } from 'axios'
import { ITranslationInput } from '~/types/app/I18n'
import { SET_PAYMENT_STATUS } from '~/store/mutation-types'
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin'
import { UIElement } from '@adyen/adyen-web/dist/types/components/UIElement'

interface IParameters {
  state?: IAdyenDropinState
  component?: UIElement
  dropin?: DropinElement
  shippingAddress?: ICheckoutPayloadAddress
  email?: string
  cartNotes?: string
  isExpressCheckout?: boolean
}
interface IResult {
  loadPaymentGateway: (cartId: string) => Promise<IPaymentGateway>
  loadRemoteCart: (cartId: string) => Promise<ICart>
  onPaymentSuccess: ({
    cartId,
    order,
    anonymousId,
  }?: {
    cartId?: string | undefined
    order?: ICartOrder
    anonymousId?: string
  }) => Promise<void>
  onCheckoutInstoreSuccess: (
    status?: EPaymentStatusV2
  ) => Promise<IPaymentReceipt | undefined>
  handlePaymentSubmit: (parameters: IParameters) => Promise<boolean>
  cartBlockingMessage: ComputedRef<CartBlockingMessage>
  cartSavingsMessageAsHTML: ComputedRef<string>
}

const ORDER_VERIFICATION_RETRY_COUNT = 20
const INSTORE_ORDER_VERIFICATION_RETRY_COUNT = 20
const INSTORE_PAYMENT_STATUS_RETRY_COUNT = 60
const ORDER_VERIFICATION_WAIT_TIME = 2000
const ORDER_VERIFICATION_BACKOFF_INCREASE = 500 // For e-commerce payment, each retry gets time added, to reduce amount of total requests (linear backoff)

export default function useCheckout(): IResult {
  const {
    i18n,
    store,
    $apiCartV3,
    $apiDiscovery,
    $segmentEvent,
    localeRoute,
    $logger,
    localePath,
    $formatCurrency,
  } = useContext()

  const router = useRouter()

  const loadPaymentGateway = async (
    cartId: string
  ): Promise<IPaymentGateway> => {
    return await store.dispatch('createPaymentGateway', cartId)
  }

  const loadRemoteCart = async (cartId: string): Promise<ICart> => {
    return await store.dispatch('getCart', cartId)
  }

  const onPaymentSuccess = async ({
    cartId = store.state.cartId,
    order,
    anonymousId,
  }: {
    cartId?: string
    order?: ICartOrder
    anonymousId?: string
  } = {}): Promise<void> => {
    const increaseTime = store.getters.getIsKiosk
      ? 0
      : ORDER_VERIFICATION_BACKOFF_INCREASE

    let cart: ICart | null | undefined = null
    let sleepTime = ORDER_VERIFICATION_WAIT_TIME

    const retryCount = store.getters.getIsKiosk
      ? INSTORE_ORDER_VERIFICATION_RETRY_COUNT
      : ORDER_VERIFICATION_RETRY_COUNT

    if (!order) {
      for (let i = 0; i < retryCount; i++) {
        cart = await getCart({
          client: $apiCartV3,
          logger: $logger,
          locale: i18n.locale,
          cartId,
        })
        order = cart?.order

        if (order) {
          store.dispatch('setRemoteCart', cart)
          break
        }

        await sleep(sleepTime)

        sleepTime += increaseTime
      }
    }

    if (!order) {
      router.push({
        path: localePath(ROUTES.PROFILE),
        query: { tab: PROFILE_PAGE_TABS.ORDER_HISTORY },
      })

      await store.dispatch('resetCart')

      const closeModal = () => {
        store.commit(SET_PAYMENT_STATUS, undefined)
      }

      // If no order is created within the payment flow, we display the PaymentModal with
      // information about payment was successful and order will be created later.
      store.commit(SET_PAYMENT_STATUS, {
        status: EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT,
        action: closeModal,
      })
      const error = {
        error: EPaymentErrorCode.ERROR_PAYMENT_TIMEOUT,
        code: EPaymentErrorCode.ERROR_PAYMENT_TIMEOUT,
      }
      $segmentEvent.PAYMENT_FAILED(error)
      CheckoutPageLogger.cartWithoutOrderId($logger, store.getters.getIsKiosk)
      throw error
    }

    if (order.firstOrder) {
      $segmentEvent.FIRST_ORDER_PLACED({
        orderId: order.id,
        orderDisplayNumber: order.number,
        voucherCode: cart?.voucher_code,
        voucherValue: cart?.discount.amount,
        predictedUserCategory: order.userCategory,
      })

      if (order.isHighValueFirstOrder) {
        $segmentEvent.HIGH_VALUE_FIRST_ORDER_PLACED({
          orderDisplayNumber: order.number,
        })
      }
    }

    CheckoutPageLogger.paymentSuccess(
      $logger,
      order.id,
      store.state.paymentMethod
    )

    $segmentEvent.ORDER_COMPLETED({
      orderId: order.id,
      orderDisplayNumber: order.number,
      voucherCode: cart?.voucher_code,
      cartId: cart?.id,
      postalCode: cart?.shipping_address?.postal_code,
      anonymousId,
      hubSlug: cart?.hub_slug,
    })

    const routeName =
      store.getters.getIsClickAndCollect || store.getters.getIsKiosk
        ? ROUTES.ORDER_COLLECT
        : ROUTES.ORDER_STATUS

    await router.push({
      path: localePath({
        name: routeName,
        params: { slug: order.id },
      }),
    })
    await sleep(100) // sleep just for a few ms so UI doesn't change before redirect

    if (!store.getters.getIsKiosk) {
      // On kiosk mode, we need to reset the cart after the receipt was printed,
      // to have access to the order number that will be printed on the receipt.  (see, checkout.vue)
      await store.dispatch('resetCart')
    }
  }

  const onCheckoutInstoreSuccess = async (
    status?: EPaymentStatusV2
  ): Promise<IPaymentReceipt | undefined> => {
    if (!status) {
      for (let i = 0; i < INSTORE_PAYMENT_STATUS_RETRY_COUNT; i++) {
        const paymentStatus: IPaymentStatusV2 | undefined =
          await getPaymentStatus({
            client: $apiCartV3,
            logger: $logger,
            locale: i18n.locale,
            cartId: store.state.cartId,
          })
        status = paymentStatus?.status

        if (status === EPaymentStatusV2.FAILED) {
          const error = {
            error: EPaymentErrorCode.ERROR_PAYMENT_FAILED,
          }
          $segmentEvent.PAYMENT_FAILED(error)
          throw error
        }

        if (status === EPaymentStatusV2.PAID) {
          return paymentStatus?.receipts?.find(
            (receipt) => receipt.type === EPaymentReceiptType.CUSTOMER
          )
        }
        await sleep(ORDER_VERIFICATION_WAIT_TIME)
      }
    }

    if (status === EPaymentStatusV2.PENDING) {
      const error = {
        error: EPaymentErrorCode.ERROR_PAYMENT_TIMEOUT,
        code: EPaymentErrorCode.ERROR_PAYMENT_TIMEOUT,
      }
      $segmentEvent.PAYMENT_FAILED(error)
      throw error
    }
  }

  const handlePaymentSubmit = async ({
    state,
    component,
    shippingAddress,
    email,
    cartNotes,
    isExpressCheckout,
  }: IParameters): Promise<boolean> => {
    const cartLines = store.state.cart.map((cartItem: ICartItem) => ({
      quantity: cartItem.quantity,
      product_sku: cartItem.sku,
    }))

    const { allValid, conflictingCartLines } = await areAllProductsAvailable(
      {
        client: $apiDiscovery,
        locale: i18n.locale,
        hubSlug: store.state.hub?.slug,
        cartLines,
      },
      $logger
    )

    if (!allValid) {
      const error = {
        code: EPaymentErrorCode.ERROR_ITEM_NOT_AVAILABLE,
        info: { conflictingCartLines },
      }
      throw error
    }

    const cart = await updateCart(
      {
        client: $apiCartV3,
        locale: i18n.locale,
        hubSlug: store.state.hub?.slug,
        cartId: store.state.cartId,
        deliveryCoordinates: store.state.deliveryCoordinates,
        shippingAddress,
        email,
        deliveryETA: store.state.deliveryETA,
        cartLines,
        notes: cartNotes,
        ...(store.state.deliveryTierId && {
          deliveryTierId: store.state.deliveryTierId,
        }),
        ...(store.state.plannedOrders?.selectedTimeSlot && {
          selectedTimeSlot: {
            ...store.state.plannedOrders.selectedTimeSlot,
          },
        }),
      },
      $logger
    )

    if (cart?.email === DUMMY_EMAIL_ADDRESS) {
      const error = {
        code: EPaymentErrorCode.ERROR_DUMMY_EMAIL,
      }
      throw error
    }

    let stateData, paymentMethod

    if (isExpressCheckout && store.state.paymentGateway?.defaultPaymentMethod) {
      const { type, pspToken, brand } =
        store.state.paymentGateway.defaultPaymentMethod
      stateData = {
        paymentMethod: {
          type,
          storedPaymentMethodId: pspToken,
          brand,
        },
      }
      paymentMethod = type
    } else {
      stateData = state?.data
      paymentMethod = state?.data?.paymentMethod?.type
    }

    if (cart && stateData) {
      try {
        const response = await checkoutCart(
          {
            client: $apiCartV3,
            locale: i18n.locale,
            hubSlug: store.state.hub?.slug,
            price: {
              amount: store.getters.getOrderTotalPrice,
              currency: store.state.currency,
            },
            stateData,
            cartId: store.state.cartId,
            isWithDetails: false,
            returnUrl: createReturnUrl({
              isClientSide: !!process.client,
              location: window.location,
              route: localeRoute({
                name: ROUTES.PROCESS_PAYMENT,
              }) as Vue['$route'],
              cartId: store.state.cartId,
              anonymousId: store.state.account.segmentAnonymousUserId,
            }),
            paymentMethod,
          },
          $logger
        )

        const confirmationAction = response?.confirmationData
        let isCheckoutComplete = true

        if (confirmationAction) {
          component?.handleAction(confirmationAction as any)
          isCheckoutComplete = false
        }

        return isCheckoutComplete
      } catch (e) {
        const err = e as AxiosError

        let error: ICheckoutCartError = {
          code: EPaymentErrorCode.ERROR_CHECKOUT_FAILED,
          title: null,
          message: null,
          rejectionReason: null,
          info: e as AxiosError,
        }

        const checkoutCartError = err.response?.data as ICheckoutCartError

        if (checkoutCartError?.code) {
          error = checkoutCartError
        }

        throw error
      }
    } else {
      const error = { code: EPaymentErrorCode.ERROR_CART_NOT_UPDATED }
      throw error
    }
  }

  const cartBlockingMessage = computed<CartBlockingMessage>(() => {
    if (store.getters.getHubClosedMessage)
      return store.getters.getHubClosedMessage
    return cartMinimumOrderMessage.value
  })

  const cartMinimumOrderMessage = computed<CartBlockingMessage>(() => {
    if (store.getters.getMinOrderValueReached) {
      return null
    }

    return {
      message: {
        key: 'cart_popover_minimum_order_message_v2',
        params: {
          valueRemain: $formatCurrency({
            amount: store.getters.getMinOrderValueRemain,
            currency: 'EUR',
          }),
        },
      } as ITranslationInput,
    }
  })

  const cartSavingsMessageAsHTML = computed(() => {
    const amount = store.getters.getCartSavings

    if (!amount) return ''

    const currency = store.getters.getCurrency
    const savings = $formatCurrency({ amount, currency })
    const message = i18n.t('cart_savings_message', { savings })

    return textToPartiallyBoldHTML(message.toString(), savings)
  })

  return {
    loadPaymentGateway,
    loadRemoteCart,
    onPaymentSuccess,
    onCheckoutInstoreSuccess,
    handlePaymentSubmit,
    cartBlockingMessage,
    cartSavingsMessageAsHTML,
  }
}
