const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['cartMiddleware'] = require('../middleware/cartMiddleware.js')
middleware['cartMiddleware'] = middleware['cartMiddleware'].default || middleware['cartMiddleware']

middleware['checkoutAuth'] = require('../middleware/checkoutAuth.js')
middleware['checkoutAuth'] = middleware['checkoutAuth'].default || middleware['checkoutAuth']

middleware['closestSupportedLocaleRedirect'] = require('../middleware/closestSupportedLocaleRedirect.ts')
middleware['closestSupportedLocaleRedirect'] = middleware['closestSupportedLocaleRedirect'].default || middleware['closestSupportedLocaleRedirect']

middleware['deeplinkRedirectCategory'] = require('../middleware/deeplinkRedirectCategory.ts')
middleware['deeplinkRedirectCategory'] = middleware['deeplinkRedirectCategory'].default || middleware['deeplinkRedirectCategory']

middleware['deeplinkRedirectCollection'] = require('../middleware/deeplinkRedirectCollection.ts')
middleware['deeplinkRedirectCollection'] = middleware['deeplinkRedirectCollection'].default || middleware['deeplinkRedirectCollection']

middleware['deeplinkRedirectProduct'] = require('../middleware/deeplinkRedirectProduct.ts')
middleware['deeplinkRedirectProduct'] = middleware['deeplinkRedirectProduct'].default || middleware['deeplinkRedirectProduct']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['hubMiddleware'] = require('../middleware/hubMiddleware.js')
middleware['hubMiddleware'] = middleware['hubMiddleware'].default || middleware['hubMiddleware']

middleware['langRedirect'] = require('../middleware/langRedirect.js')
middleware['langRedirect'] = middleware['langRedirect'].default || middleware['langRedirect']

middleware['redirectMiddleware'] = require('../middleware/redirectMiddleware.js')
middleware['redirectMiddleware'] = middleware['redirectMiddleware'].default || middleware['redirectMiddleware']

middleware['routeHistory'] = require('../middleware/routeHistory.js')
middleware['routeHistory'] = middleware['routeHistory'].default || middleware['routeHistory']

middleware['seoMiddleware'] = require('../middleware/seoMiddleware.js')
middleware['seoMiddleware'] = middleware['seoMiddleware'].default || middleware['seoMiddleware']

middleware['subscriptions'] = require('../middleware/subscriptions.js')
middleware['subscriptions'] = middleware['subscriptions'].default || middleware['subscriptions']

export default middleware
