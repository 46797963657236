interface IParameters {
  isClientSide: boolean
  location: Location
  route: Vue['$route']
  cartId?: string
  anonymousId?: string
}

export function createReturnUrl({
  isClientSide,
  location,
  route,
  cartId,
  anonymousId,
}: IParameters): string {
  let url = ''

  if (isClientSide) {
    url = new URL(route.fullPath, location.origin).toString()
  }

  if (cartId) url += `?cartId=${cartId}`
  if (anonymousId) url += `&aId=${anonymousId}`

  return url
}
