import { ICartAddPayload } from '~/types/app/State'

export enum EAgeVerificationTag {
  ALCOHOL16 = 'alcohol-age-16',
  ALCOHOL18 = 'alcohol-age-18',
  TOBACCO18 = 'tobacco-age-18',
}

export interface IAgeVerificationState {
  isRequired: boolean
  tag: EAgeVerificationTag | undefined
  payload: ICartAddPayload | undefined
}
