import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import IProduct, { IProductRaw } from '~/types/app/Product'
import transformProducts from '~/api/transformers/transform-products'
import { LoggerFunction } from '~/types/app/Logger'

export interface SearchResponse {
  // eslint-disable-next-line camelcase
  query_id: string
  products?: IProductRaw[]
}

type TransformedResponse = {
  products: IProduct[]
  queryId: string
}

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  hubSlug: string
  query: string
  pageSize?: number
  suggestionQueryId?: string
  suggestionPosition?: string
}

export async function search({
  client,
  logger,
  locale,
  hubSlug,
  query,
  pageSize,
  suggestionQueryId,
  suggestionPosition,
}: IParameters): Promise<TransformedResponse> {
  let searchResult: TransformedResponse = { products: [], queryId: '' }
  try {
    query = encodeURIComponent(query)

    const response = await get<SearchResponse>(
      client,
      logger,
      `/v1/search?query=${query}&page_size=${pageSize || 50}`,
      {
        locale,
        'hub-slug': hubSlug,
        'flink-product-images': 'square-thumbnails',
        'Autocomplete-Query-Id': suggestionQueryId,
        'Autocomplete-Position': suggestionPosition,
      }
    )

    if (response.data.products) {
      searchResult = {
        products: transformProducts(response.data.products),
        queryId: response.data.query_id,
      }
    }
  } catch (error) {}

  return searchResult
}
