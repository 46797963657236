
































import { EProductPlacement } from '~/lib/segment';
import type { ICartPromotions } from '~/types/app/Promotions';
import { PROMOTION_LABEL_VARIANTS } from '~/lib/constants';
import PromotionLabel from './PromotionLabel.vue';
import ProductListItem from './ProductListItem.vue';
const __sfc_main = {};
__sfc_main.props = {
  promotions: {
    type: (Array as () => ICartPromotions[]),
    required: true
  },
  staticQuantity: {
    type: Boolean,
    default: false
  },
  allowClick: {
    type: Boolean,
    default: false
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    default: EProductPlacement.swimlane
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {
    PROMOTION_LABEL_VARIANTS
  };
};

__sfc_main.components = Object.assign({
  PromotionLabel,
  ProductListItem
}, __sfc_main.components);
export default __sfc_main;
