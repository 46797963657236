import contentfulClient from '~/lib/contentfulClient'
import { EntryCollection } from 'contentful'
import { IAppState } from '~/types/app/State'
import { ActionTree, MutationTree } from 'vuex'
import { ILpComponentFooterFields } from 'types/generated/contentful.d'
import { linksArr } from '~/lib/helpers'
import { SET_FOOTER_CONTENT } from './mutation-types'

export interface IContentState {
  footerContent: ILpComponentFooterFields | undefined
}

export const state = (): IContentState => ({
  footerContent: undefined,
})

export const mutations: MutationTree<IContentState> = {
  [SET_FOOTER_CONTENT](state, footerContent) {
    state.footerContent = footerContent
  },
}

export const actions: ActionTree<IContentState, IAppState> = {
  async loadFooterContent({ commit, state }, { query }): Promise<void> {
    if (state.footerContent) {
      return
    }

    const footer = (await contentfulClient(
      query.value.mode
    ).getEntries<ILpComponentFooterFields>({
      content_type: 'lpComponentFooter',
      locale: this.$i18n.locale,
    })) as EntryCollection<ILpComponentFooterFields>

    const content = footer.items[0].fields

    const data = {
      ...content,
      aboutLinks: linksArr(content.aboutLinks),
      appLinks: linksArr(content.appLinks),
      footerLinks: linksArr(content.footerLinks),
      socialLinks: linksArr(content.socialLinks),
    }

    commit(SET_FOOTER_CONTENT, data)
  },
}
