






import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { Inline, Text, BLOCKS, INLINES } from '@contentful/rich-text-types';
type HType = (nodeType: string, element: {
  key: string;
  attrs: {
    [key: string]: string;
  };
}, next: string) => string;
type NextType = (node: (Inline | Text)[], key: string, h: HType, next: NextType) => string;
type Renderer = (node: Inline, key: string, h: HType, next: NextType) => string;
type ElementRenderer = (nodeType: string, node: Inline, key: string, h: HType, next: NextType) => string;
const __sfc_main = {
  name: 'RichTextRendererWithAnchors'
};
__sfc_main.props = {
  document: {
    type: (Object as () => Document),
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  function renderNodes(): {
    [key: string]: Renderer;
  } {
    const generateId = (node: Inline): string => {
      const nodesWithContent = node.content.filter(c => !['\n', ''].includes((c as Text).value));
      return (nodesWithContent[0] as Text)?.value.replace(/[^A-Za-z\s]/g, '').toLowerCase().trim().replace(/\s/g, '-') || '';
    };

    const renderHeader: ElementRenderer = (nodeType, node, key, h, next) => {
      const id = generateId(node);
      return h(nodeType, {
        key,
        attrs: {
          id
        }
      }, next(node.content, key, h, next));
    };

    const renderLink: ElementRenderer = (nodeType, node, key, h, next) => {
      const id = generateId(node);
      const href = node.data.target.fields.file.url;
      return h(nodeType, {
        key,
        attrs: {
          id,
          href,
          target: '_blank',
          title: (node.content[0] as Text)?.value
        }
      }, next(node.content, key, h, next));
    };

    const renderList: ElementRenderer = (nodeType, node, key, h, next) => {
      const listStyle = nodeType === 'ul' ? 'list-disc' : 'list-decimal';
      return h(nodeType, {
        key,
        attrs: {
          class: listStyle
        }
      }, next(node.content, key, h, next));
    };

    return {
      [BLOCKS.UL_LIST]: (...args) => renderList('ul', ...args),
      [BLOCKS.OL_LIST]: (...args) => renderList('ol', ...args),
      [BLOCKS.HEADING_1]: (...args) => renderHeader('h1', ...args),
      [BLOCKS.HEADING_2]: (...args) => renderHeader('h2', ...args),
      [BLOCKS.HEADING_3]: (...args) => renderHeader('h3', ...args),
      [BLOCKS.HEADING_4]: (...args) => renderHeader('h4', ...args),
      [BLOCKS.HEADING_5]: (...args) => renderHeader('h5', ...args),
      [BLOCKS.HEADING_6]: (...args) => renderHeader('h6', ...args),
      [INLINES.ASSET_HYPERLINK]: (...args) => renderLink('a', ...args)
    };
  }

  return {
    renderNodes
  };
};

__sfc_main.components = Object.assign({
  RichTextRenderer
}, __sfc_main.components);
export default __sfc_main;
