






























import { ref, computed } from '@nuxtjs/composition-api';
import IProduct from '~/types/app/Product';
import { EProductPlacement } from '~/lib/segment';
import ProductListItem from '~/components/ProductList/ProductListItem.vue';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  staticQuantity: {
    type: Boolean,
    default: false
  },
  products: {
    type: (Array as () => IProduct[]),
    default: () => []
  },
  limitItems: {
    type: Number,
    default: Infinity
  },
  allowClick: {
    type: Boolean,
    default: false
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    default: EProductPlacement.swimlane
  },
  searchQueryId: {
    type: String,
    default: ''
  },
  displayInlinePromotionLabel: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const expanded = ref(false);
  const hasMore = props.limitItems > 0 && props.products.length > props.limitItems;
  const truncatedItems = computed(() => {
    const limit = expanded.value || !props.limitItems ? Infinity : props.limitItems;
    return props.products.slice(0, limit);
  });

  function handleTruncationToggle() {
    expanded.value = !expanded.value;
  }

  return {
    expanded,
    hasMore,
    truncatedItems,
    handleTruncationToggle
  };
};

__sfc_main.components = Object.assign({
  ProductListItem
}, __sfc_main.components);
export default __sfc_main;
