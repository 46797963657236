import ICategory from '~/types/app/Category'
import { EProductPlacement } from '~/lib/segment'
import IProduct from '~/types/app/Product'
import { transformers, V2HomeRaw, V2HomeSectionRaw } from './raw-transformers'
import {
  HomeV2TransformResult,
  HomeV2Section,
  SectionTransformResult,
  SmimlaneHomeSection,
} from './shared'

export const transformRawSection = (
  section: V2HomeSectionRaw
): SectionTransformResult => {
  if (transformers.categoryGrid.check(section)) {
    return transformers.categoryGrid.transform(section)
  }

  if (transformers.swimlane.check(section)) {
    return transformers.swimlane.transform(section)
  }

  if (transformers.enhancedSwimlane.check(section)) {
    return transformers.enhancedSwimlane.transform(section)
  }

  if (transformers.collectionSection.check(section)) {
    return transformers.collectionSection.transform(section)
  }

  if (transformers.collectionCard.check(section)) {
    return transformers.collectionCard.transform(section)
  }

  if (transformers.deeplinkedMarketingBannerSlider.check(section)) {
    return transformers.deeplinkedMarketingBannerSlider.transform(section)
  }

  return transformers.feedback.transform(section)
}

export const transformV2Home = (payload: V2HomeRaw): HomeV2TransformResult => {
  const categories: ICategory[] = []
  const products: IProduct[] = []
  const sections: HomeV2Section[] = []
  const subCollectionSwimlanes: SmimlaneHomeSection[] = []
  const MIN_SWIMLANES_COUNT = 10

  // TODO: fetch teaser from homepage service
  // sections.push({ type: 'teaser' })

  for (const raw of payload.sections) {
    const section = transformRawSection(raw)

    switch (section.type) {
      case 'categories':
        categories.push(...section.categories)
        sections.push(section)
        continue

      case 'swimlane':
        products.push(...section.products)
        sections.push(section)
        continue

      case 'collectionSection':
        sections.push(section)
        for (const swimlane of section.swimlanes) {
          products.push(...swimlane.products)
          subCollectionSwimlanes.push({
            ...swimlane,
            productPlacement: EProductPlacement.swimlane,
          })
        }
        continue

      case 'cardSection':
        sections.push(section)
        products.push(...section.products)
        continue

      case 'marketingBannerSlider':
      case 'feedback':
        sections.push(section)
        continue

      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = section
      }
    }
  }

  while (
    sections.length <= MIN_SWIMLANES_COUNT &&
    subCollectionSwimlanes.length > 0
  ) {
    const swimlane = subCollectionSwimlanes.shift() as SmimlaneHomeSection
    sections.push(swimlane)
  }

  return {
    sections,
    products,
    categories,
  }
}
