/* eslint-disable camelcase */
import { ICentPrice } from '~/types/app/Price'

export enum ESubscriptionStatus {
  SUBSCRIBED = 'subscribed',
  UNSUBSCRIBED = 'unsubscribed',
}

export enum ERegistrationsStatus {
  REJECTED = 'rejected',
  PENDING = 'pending',
  REGISTERED = 'registered',
  INITIATED = 'initiated',
}

export interface ISubscriptionStatusResponse {
  status: ESubscriptionStatus
}

export interface ISubscriptionEligibilityStatusResponse {
  eligible: boolean
  minimumOrderValue: ICentPrice
}

export interface ISubscriptionPlan {
  id: string
  nameSlug: string
  totalPrice: ICentPrice
  monthlyPrice: ICentPrice
  originalPrice: ICentPrice
  benefitAmount: number
}

export type ISubscriptionPlanShortened = Pick<
  ISubscriptionPlan,
  'id' | 'nameSlug' | 'totalPrice'
>

export interface IRegistrationsStatusResponse {
  status: ERegistrationsStatus
  plan: ISubscriptionPlanShortened
}

export interface ISubscriptionDetailPlan {
  totalPrice: ICentPrice
  benefits: string[]
}

export interface ISubscriptionDetailSavings {
  delivery: ICentPrice
}

export interface ISubscriptionDetailResponse {
  status: ESubscriptionStatus
  expiresAt: string
  renewsAt: string
  canceledAt: string
  plan: ISubscriptionDetailPlan
  savings: ISubscriptionDetailSavings
}
export interface ISubscriptionPaymentMethod {
  apiKey: string
  config: string
}

export interface ISubscriptionPaymentMethodsResponse {
  paymentMethod: ISubscriptionPaymentMethod
}

export interface ISubscriptionPaymentResponse {
  '3ds': string
}

export enum ESubscriptionPaymentAPIResponseErrorCode {
  SUBSCRIPTION_ALREADY_EXISTS = 'SUBSCRIPTION_ALREADY_EXISTS',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
}

export enum ESubscriptionPaymentErrorCode {
  GENERIC = 'GENERIC',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  ALREADY_SUBSCRIBED = 'ALREADY_SUBSCRIBED',
  FETCH_STATUS_FAILED = 'FETCH_STATUS_FAILED',
  REJECTED = 'REJECTED',
  TIMEOUT = 'TIMEOUT',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
}

export interface ISubscriptionPaymentError {
  code: ESubscriptionPaymentErrorCode
}

export enum EPrimeCancelationReason {
  EXPENSIVE = 'expensive',
  CUT_COSTS = 'cut_costs',
  USAGE = 'usage',
  BENEFITS = 'benefits',
  DELIVERY_ISSUES = 'delivery_issues',
  TECHNICAL_ISSUES = 'technical_issues',
  CUSTOMER_SERVICE = 'customer_service',
  GROCERY_SERVICE = 'grocery_service',
  SHOPPING_HABITS = 'shopping_habits',
  OTHER = 'other',
}

export enum ESubscriptionOriginComponent {
  CART = 'cart',
  HOME = 'home',
  CHECKOUT = 'checkout',
  PROFILE_OVERVIEW = 'profile_overview',
  DEEP_LINK = 'deep_link',
}

export interface ISubscriptionBillingAddress {
  street: string
  houseNumber: string
  postalCode: string
  city: string
  countryCode: string
}
