import { render, staticRenderFns } from "./ProductSubstitutionModal.vue?vue&type=template&id=c02e0332&scoped=true&"
import script from "./ProductSubstitutionModal.vue?vue&type=script&lang=ts&"
export * from "./ProductSubstitutionModal.vue?vue&type=script&lang=ts&"
import style0 from "./ProductSubstitutionModal.vue?vue&type=style&index=0&id=c02e0332&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c02e0332",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VCloudinaryImage: require('/app/build/components/VCloudinaryImage/VCloudinaryImage.vue').default,ProductsSwimlane: require('/app/build/components/ProductsSwimlane/ProductsSwimlane.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})
