import { render, staticRenderFns } from "./OutOfStockModal.vue?vue&type=template&id=49274755&scoped=true&"
import script from "./OutOfStockModal.vue?vue&type=script&lang=ts&"
export * from "./OutOfStockModal.vue?vue&type=script&lang=ts&"
import style0 from "./OutOfStockModal.vue?vue&type=style&index=0&id=49274755&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49274755",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductList: require('/app/build/components/ProductList/ProductList.vue').default,Button: require('/app/build/components/Button/Button.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})
