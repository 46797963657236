


























































































































import FlinkLogo from '~/assets/logo.svg?inline';
import { ROUTES } from '~/lib/routes';
import { ETrackingType, getScreenName, EComponentName } from '~/lib/segment';
import { ref, useContext, useStore, useRoute } from '@nuxtjs/composition-api';
import { IAppState } from '~/types/app/State';
import useLocationOnboarding from '~/hooks/onboarding/useLocationOnboarding';
const __sfc_main = {};
__sfc_main.props = {
  focusGrow: {
    type: Boolean,
    default: false
  },
  fixed: {
    type: Boolean,
    default: false
  },
  hideMenuButton: {
    type: Boolean,
    default: false
  },
  hideCartButton: {
    type: Boolean,
    default: false
  },
  hideSearch: {
    type: Boolean,
    default: false
  },
  hideLinks: {
    type: Boolean,
    default: false
  },
  hideProfile: {
    type: Boolean,
    default: false
  },
  hideDeliveryAddress: {
    type: Boolean,
    default: false
  },
  isKiosk: {
    type: Boolean,
    default: false
  },
  hideHorizontalRule: {
    type: Boolean,
    default: false
  },
  initialSearchText: {
    type: String,
    default: ''
  },
  isHamburgerMenu: {
    type: Boolean,
    default: false
  },
  invisibleRefreshButton: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const showNoDeliveryToLocationPopover = ref<boolean>(false);
  const homeRouteName = ROUTES.HOME;
  const {
    $segmentEvent,
    i18n
  } = useContext();
  const store = useStore<IAppState>();
  const {
    toggleOnboardingStatus
  } = useLocationOnboarding();
  const route = useRoute().value;
  const globalHeaderTitle = (i18n.t('global_header_title') as string);
  const emit = __ctx.emit;

  const trackLogoClick = () => {
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.flinkLogo,
      screenName
    });
  };

  const onHideNoDeliveryToLocationModal = () => {
    store.dispatch('toggleNoDeliveryToLocationModal', false);
    showNoDeliveryToLocationPopover.value = true;
  };

  const onDeliveryBannerClicked = () => {
    toggleOnboardingStatus();
  };

  const onOnboardingHintShown = () => {
    store.dispatch('updateIsInitialOnboardingDone', true);
  };

  const onHideOnboardingHint = () => {
    store.dispatch('setShowOnboardingHint', false);
    showNoDeliveryToLocationPopover.value = false;
  };

  const showMenu = () => {
    emit('menu');
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.categoryMenu,
      screenName
    });
  };

  const refreshPage = () => {
    location.reload();
  };

  return {
    showNoDeliveryToLocationPopover,
    homeRouteName,
    store,
    globalHeaderTitle,
    emit,
    trackLogoClick,
    onHideNoDeliveryToLocationModal,
    onDeliveryBannerClicked,
    onOnboardingHintShown,
    onHideOnboardingHint,
    showMenu,
    refreshPage
  };
};

__sfc_main.components = Object.assign({
  FlinkLogo
}, __sfc_main.components);
export default __sfc_main;
