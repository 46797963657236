import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { ISubscriptionPlan } from '~/types/app/Subscription'
import { LoggerFunction } from '~/types/app/Logger'

export default async function getSubscriptionPlans(
  client: NuxtAxiosInstance,
  logger: LoggerFunction
): Promise<ISubscriptionPlan[] | undefined> {
  const headers = {}
  const url = 'plans'
  const response = await get<ISubscriptionPlan[]>(client, logger, url, headers)

  return response.data
}
