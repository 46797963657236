import { IV2CategoryDetailsRaw, IParentCategory } from '~/types/app/Category'
import transformProducts from './transform-products'

export default function transformV2CategoryDetails(
  categoryDetailsRaw: IV2CategoryDetailsRaw
): IParentCategory {
  return {
    id: categoryDetailsRaw.id,
    name: categoryDetailsRaw.title,
    slug: categoryDetailsRaw.slug,
    sub_categories:
      categoryDetailsRaw.categories?.map((subCategory) => ({
        ...subCategory,
        products: transformProducts(subCategory.products.products),
      })) ?? [],
  }
}
