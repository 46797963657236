import { post } from '~/api/controllers/post'
import {
  ISubscriptionPaymentResponse,
  ISubscriptionBillingAddress,
} from '~/types/app/Subscription'
import { LoggerFunction } from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'
import { getCountryFromLocale } from '~/lib/helpers'
import { ECountries } from '~/types/app/Countries'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
  planId: string
  pspSdkData: string
  billingAddress: ISubscriptionBillingAddress
  discountId?: string
}

export default async function createSubscriptionPayment({
  client,
  logger,
  locale,
  hubSlug,
  planId,
  pspSdkData,
  billingAddress,
  discountId,
}: IParameters): Promise<ISubscriptionPaymentResponse | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = 'subscriptions'
  const country = getCountryFromLocale(locale) as ECountries

  const response = await post<ISubscriptionPaymentResponse>(
    client,
    logger,
    url,
    headers,
    {
      planId,
      pspSdkData,
      country,
      billingAddress,
      discountId,
    }
  )
  return response.data
}
