/* eslint-disable no-case-declarations */
import { SET_PAYMENT_STATUS } from '~/store/mutation-types'
import ICart, { EPaymentStatus } from '~/types/app/Cart'
import { AxiosError } from 'axios'
import { Store } from 'vuex'
import IRootState from '~/types/app/State'
import { Context } from '@nuxt/types'
import { ROUTES } from '~/lib/routes'
import { IConflictingCartLine } from '~/api/services/are-all-products-available/are-all-products-available'
import IProduct from '~/types/app/Product'
import ICartLine from '~/types/app/CartLine'
import CheckoutPageLogger from '~/lib/logger/checkoutPageLogger'
import SingleCallDebouncer from '~/lib/SingleCallDebouncer'

enum ECartAPIError {
  ORDER_ALREADY_EXISTS = 'order_already_exists',
  ALREADY_PAID = 'already_paid',
  HUB_CLOSED = 'hub_closed',
  HUB_MISMATCH = 'hub_mismatch',
  VERSION_MISMATCH = 'Version mismatch. Concurrent modification.',
}

// ATTENTION: This is a state flag, that indicates if the application is currently handling an error.
// We are recreating carts or update them in the error handler, therefore we want to prevent the error handler from acting again.
// If this flag is "true", we are already handling an error, therefore all other calls to the error handler should be ignored
// If this flag is "false", we want to set it to "true", perform the error handling logic, and at the end, reset it to "false" again.
let IS_HANDLING_ERROR = false

export const cartErrorHandler = async (
  error: AxiosError,
  context: Context | Store<IRootState>
): Promise<void> => {
  if (IS_HANDLING_ERROR) {
    return
  }

  if (error.message === SingleCallDebouncer.ERROR_MESSAGE) {
    return
  }

  // Needs to be set to false before returning!
  IS_HANDLING_ERROR = true

  // If OrderCreator error 409 (invalid cart)
  if (error.response?.status === 409) {
    let cart: ICart | undefined
    switch (error.response?.data.error?.code) {
      // If order already exists, the error data response will include order id
      case ECartAPIError.ORDER_ALREADY_EXISTS:
        cart = await context.app.store?.dispatch(
          'getCart',
          context.app.store?.state.cartId
        )
        const order = error.response?.data?.order

        context.app.store?.commit(SET_PAYMENT_STATUS, {
          status: EPaymentStatus.SUCCESS,
          action: () => {
            context.app.store?.dispatch('handleOrderExists', order?.id)
          },
        })

        CheckoutPageLogger.orderAlreadyExistByError(context.app.$logger, order)

        context.app.$segmentEvent.ORDER_COMPLETED({
          orderId: order?.id,
          orderDisplayNumber: order?.number,
          voucherCode: cart?.voucher_code,
          cartId: cart?.id,
          postalCode: cart?.shipping_address?.postal_code,
          hubSlug: cart?.hub_slug,
        })
        break
      case ECartAPIError.ALREADY_PAID:
        // If this error occurs, we have to re-fetch the cart and see, if order is appended
        cart = await context.app.store?.dispatch(
          'getCart',
          context.app.store?.state.cartId
        )
        if (!cart) break
        if (cart.order) {
          context.app.store?.commit(SET_PAYMENT_STATUS, {
            status: EPaymentStatus.SUCCESS,
            action: () => {
              context.app.store?.dispatch('handleOrderExists', cart?.order?.id)
            },
          })

          CheckoutPageLogger.cartAlreadyPaidByError(context.app.$logger, cart)

          context.app.$segmentEvent.ORDER_COMPLETED({
            orderId: cart.order.id,
            orderDisplayNumber: cart.order.number,
            voucherCode: cart.voucher_code,
            cartId: cart.id,
            postalCode: cart.shipping_address?.postal_code,
            hubSlug: cart.hub_slug,
          })
        } else {
          const action = () => {
            context.app.store?.commit(SET_PAYMENT_STATUS, undefined)
          }

          context.app.store?.commit(SET_PAYMENT_STATUS, {
            status: EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT,
            action,
          })

          context.app.store?.dispatch('resetCart')
        }
        break
      case ECartAPIError.VERSION_MISMATCH:
        cart = await context.app.store?.dispatch(
          'getCart',
          context.app.store?.state.cartId
        )
        if (cart) context.app.store?.dispatch('getCart', cart.id)
        break
      case ECartAPIError.HUB_MISMATCH:
        context.app.router?.push(context.localePath(ROUTES.HOME))
        context.app.store?.dispatch('recreateCart')
        break
      default:
        // all other 409 errors are invalid carts, therefore have to be recreated
        context.app.store?.dispatch('recreateCart')
    }
  }
  // Default trouble shooting pipeline
  else {
    const createdCart = await context.app.store?.dispatch('createCart')
    let updatedCart = null
    if (createdCart) {
      // Cart products are all IProducts are in the cart. These are only these products, that are in the catalog.
      await context.app.store?.dispatch('catalog/loadCartProducts')
      const cartProducts = context.app.store?.getters.getCartProducts || []

      // Perform basic amounts check to obtain conflicting line items, if available
      const cartValidationData = await context.app.store?.dispatch(
        'validateCartLineAmounts'
      )
      const conflictingCartLines =
        cartValidationData.errorInfo?.conflictingCartLines || []
      const conflictingSkus = conflictingCartLines.map(
        (conflictingCartLine: IConflictingCartLine) => {
          return conflictingCartLine.sku
        }
      )

      // Cart products are already filtered cart items that are available in the catalog.
      // These products are the basis for the considered items, which are then corrected for out of stock items
      const availableCartLines: ICartLine[] = cartProducts.reduce(
        (lineItems: ICartLine[], cartProduct: IProduct) => {
          let quantity = cartProduct.quantity

          // If a cart product has a conflicting sku, we adapt the quantity to the available amount
          if (conflictingSkus.includes(cartProduct.sku)) {
            const conflictingCartLine = conflictingCartLines.find(
              (confCartLine: IConflictingCartLine) => {
                return confCartLine.sku === cartProduct.sku
              }
            )
            quantity = conflictingCartLine.available
          }

          // We only want to add the product, if the quantity is > 0
          if (quantity > 0) {
            lineItems.push({
              product_sku: cartProduct.sku,
              quantity,
            })
          }
          return lineItems
        },
        []
      )

      updatedCart = await context.app.store?.dispatch('updateCart', {
        selectedCartLines: availableCartLines,
      })

      // If the above didn't work, something is really wrong and we should reset the cart
      // This is bad user experience, but at least we show a modal
      if (!updatedCart) {
        await context.app.store?.dispatch('resetCart')
        await context.app.router?.push(context.localePath(ROUTES.HOME))
        context.app.store?.dispatch('toggleCartClearedModal', true)
      }
    }
  }

  IS_HANDLING_ERROR = false
}
