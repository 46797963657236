import {
  SET_HUB,
  LOG_OUT,
  SET_USER,
  ACCOUNT_PREFIX,
  SET_FIRST_AUTH_RAN,
  SET_SEGMENT_ANONYMOUS_USER_ID,
} from '~/store/mutation-types'
import { FeatureFlags } from '~/types/app/experiments'
import { getPrefixedMutation } from '~/lib/helpers'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'

const experimentPlugin = defineNuxtPlugin(({ store }) => {
  const featureFlags = Object.values(FeatureFlags)
  if (featureFlags.length) {
    store?.subscribe(({ type }) => {
      if (
        [
          SET_HUB,
          getPrefixedMutation(ACCOUNT_PREFIX, SET_FIRST_AUTH_RAN),
          getPrefixedMutation(ACCOUNT_PREFIX, SET_USER),
          getPrefixedMutation(ACCOUNT_PREFIX, LOG_OUT),
          getPrefixedMutation(ACCOUNT_PREFIX, SET_SEGMENT_ANONYMOUS_USER_ID),
        ].includes(type)
      ) {
        if (store.state.account.segmentAnonymousUserId) {
          store.dispatch('experiments/fetchExperimentData', featureFlags)
        }
      }
    })
  }
})

export default experimentPlugin
