import { ActionTree, MutationTree } from 'vuex'
import IRootState from '~/types/app/State'
import { searchLabels } from '~/api/services/search-labels'
import { getDefaultHubSlug } from './helpers/get-default-hub-slug'

import { SET_SEARCH_LABELS_TITLE, SET_SEARCH_LABELS } from './mutation-types'

export interface ISearchState {
  searchLabelsTitle: string | null
  searchLabels: string[]
}

export const state = (): ISearchState => ({
  searchLabelsTitle: null,
  searchLabels: [],
})

export const mutations: MutationTree<ISearchState> = {
  [SET_SEARCH_LABELS_TITLE](state, title: string) {
    state.searchLabelsTitle = title
  },
  [SET_SEARCH_LABELS](state, labels: string[]) {
    state.searchLabels = labels
  },
}

export const actions: ActionTree<ISearchState, IRootState> = {
  async setSearchLabels({ commit, rootState }) {
    const response = await searchLabels(
      this.$apiSearchDiscovery,
      this.$logger,
      this.$i18n.locale,
      rootState.hub?.slug || getDefaultHubSlug(this.$i18n.locale)
    )

    commit(SET_SEARCH_LABELS_TITLE, response.title)
    commit(SET_SEARCH_LABELS, response.search_labels)
  },
}
