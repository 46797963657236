







import { PropType } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  discount: {
    key: "discount",
    required: true,
    type: null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

export default __sfc_main;
