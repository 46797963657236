

































import { defineProps } from '@vue/runtime-dom';
import { EStoreClosedModalIconType } from '~/types/app/State';
const __sfc_main = {};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  message: {
    type: String,
    default: ''
  },
  buttonTitle: {
    type: String,
    default: ''
  },
  icon: {
    type: (Object as () => {
      type?: string;
      src: string;
    }),
    default: () => ({
      src: ''
    })
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  function handleClose(): void {
    emit('close');
  }

  return {
    EStoreClosedModalIconType,
    handleClose
  };
};

export default __sfc_main;
