import { theme } from '../tailwind.config'
import { ECountries } from '../types/app/Countries'

export const BREAKPOINTS = Object.entries(theme.screens).reduce(
  (acc, [name, size]) => ({
    ...acc,
    [name]: parseInt(size, 10),
  }),
  {}
)

export const NEW_MOBILE_BREAKPOINT = 375
export const NEW_TABLET_BREAKPOINT = 480

export const CATEGORY_SWIMLANE_STYLES = {
  THUMBNAIL_WIDTH: 300,
  THUMBNAIL_HEIGHT: 400,
  MARGIN_RIGHT: 12,
}

export const CATEGORY_SWIMLANE_V2_STYLES = {
  THUMBNAIL_WIDTH: 438,
  THUMBNAIL_HEIGHT: 400,
  MARGIN_RIGHT: 12,
}

// This contains the default locale for each country we serve, i.e. each country only appears once.
export const COUNTRIES_DEFAULT_LOCALES = ['de-DE', 'nl-NL']
export const NO_INDEX_LOCALES = ['en']

export const DEFAULT_MINIMUM_ORDER_VALUE = 1

export const CF_NESTED_REFFERENCES = 4

export const DEFAULT_DELIVERY_ETA = '12'

export const VERIFIED_AGE_COOKIE_NAME = 'verified-age'
export const VERIFIED_TOBACCO_COOKIE_NAME = 'verified-tobacco'

export const DUMMY_EMAIL_ADDRESS = 'user@goflink.com'

export const DEFAULT_HUB_SLUGS: Record<ECountries, string> = {
  [ECountries.DE]: 'de_all_skus',
  [ECountries.NL]: 'nl_all_skus',
}

export const COUNTRY_CODES: Record<ECountries, string> = {
  [ECountries.DE]: '+49',
  [ECountries.NL]: '+31',
}

export const COUNTRY_CURRENCIES: Record<ECountries, string> = {
  [ECountries.DE]: 'EUR',
  [ECountries.NL]: 'EUR',
}

export const ALLOWED_PAYMENT_METHODS_PRODUCTION: Record<
  ECountries,
  Array<string>
> = {
  [ECountries.DE]: ['scheme', 'paypal', 'directEbanking', 'applepay'],
  [ECountries.NL]: ['scheme', 'paypal', 'ideal', 'applepay'],
}

export const ALLOWED_PAYMENT_METHODS_STAGING: Record<
  ECountries,
  Array<string>
> = {
  [ECountries.DE]: [
    'scheme',
    'paypal',
    'directEbanking',
    'applepay',
    'paywithgoogle',
  ],
  [ECountries.NL]: ['scheme', 'paypal', 'ideal', 'applepay', 'paywithgoogle'],
}

export const USER_ID_COOKIE = 'flink_uid'
export const DISPLAY_NAME_DELIMITER = '[flink]'

export const ROUTE_LOCALE_SEPARATOR = '___'

export const ZOOM_LEVEL_DETAIL = 18
export const ZOOM_LEVEL_OVERVIEW = 7

export const MAP_IDS = {
  deliveryMap: 'c747aae96f615b38',
  locationMap: '14a5f66e11917685',
}

export const GET_DIRECTIONS_URL =
  'https://www.google.com/maps/dir/?api=1&travelmode=walking&origin='

export const MAX_ENTRANCE_DISTANCE_METERS = 50

export const SKU_CHARACTER_LENGTH = 8

export const VOUCHER_ERROR_PHRASE_MAP: Record<string, string> = {
  voucher_min_spent_unreached: 'voucher_error_minimum_not_met',
  voucher_not_new_customer: 'voucher_error_new_customer_only',
  voucher_wrong_city: 'voucher_error_wrong_city',
  voucher_wrong_country: 'voucher_error_wrong_country',
  voucher_product_not_found: 'voucher_error_product_not_in_cart',
  voucher_exceeded_usage_limit: 'voucher_error_campaign_limit_reached',
  voucher_exceeded_campaign_limit: 'voucher_error_campaign_limit_reached',
  voucher_expired: 'voucher_error_campaign_expired',
  voucher_already_used: 'voucher_error_user_already_redeemed',
  voucher_not_valid_yet: 'voucher_error_start_date_future',
  voucher_recipient_mismatch: 'voucher_error_recipient_mismatch',
  voucher_not_found: 'voucher_error_not_found',
  voucher_incompatible_app_version: 'voucher_error_incompatible_app_version',
  voucher_discountable_items_not_found: 'voucher_error_no_discountable_items',
}

export const DEALS_JUST_FOR_YOU_SUBCOLLECTION_SLUG = 'deals-just-for-you'
export const DEALS_PERSONAL_PROMOS_SUBCOLLECTION_SLUG = 'deals-personal-promos'
export const FREE_DELIVERY_VOUCHER_PREFIX = 'FDSKU'

export enum PROMOTION_LABEL_VARIANTS {
  INITIAL = 'initial',
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  OOS = 'oos',
}

export const VALIDATION_REGEX = {
  HTML_INJECTION_CHARACTERS: /<\/?([a-zA-Z]+).*?>/,
}
