import { z } from 'zod'

/* eslint-disable camelcase */
export enum ExperimentKeys {
  PRODUCT_TILES_SIZE = 'sd_web_product_tiles_size',
}

export enum FeatureFlags {
  WEB_HUB_USER = 'cart--hub-users-in-store-payments',
  WEB_POST_ORDER_ADS = 'post_ads',
  WEB_HIDE_DISCOUNT_LABELS = 'prx_and_hide_discount_labels',
  WEB_DEALS_PERSONAL_PROMOTIONS = 'prx_deals_personalization_config',
  WEB_DEALS_JUST_FOR_YOU = 'prx_deals_sbp_personalization_config',
  SEARCH_SUGGESTIONS = 'sd_client_search_suggestions',
}

export enum VariationKeys {
  ON = 'on',
  OFF = 'off',
  TRUE = 'true',
  FALSE = 'false',
  UNALLOCATED = 'unallocated',
}

export enum UserAttributes {
  COUNTRY_ISO = 'country_iso',
  COUNTRY_OF_DELIVERY = 'country_of_delivery',
  EMAIL = 'email',
  IS_LOGGED_IN = 'is_logged_in',
  HUB_SLUG = 'hub_slug',
  DELIVERY_ETA = 'delivery_eta',
  PLATFORM = 'platform',
  LAST_CHAR_UID = 'last_character_user_tracking_id',
}

const localizedStringSchema = z.object({
  en: z.string(),
  de: z.string(),
  nl: z.string(),
})

const headerImageSchema = z.object({
  android: z.string().url(),
  ios: z.string().url(),
  web: z.string().url(),
})

const userConfigSchema = z.object({
  title: localizedStringSchema,
  subtitle: localizedStringSchema,
  headerImage: headerImageSchema,
})

export const relevantOffersSchema = z.object({
  loggedIn: userConfigSchema,
  guest: userConfigSchema,
})

export type RelevantOffersConfig = z.infer<typeof relevantOffersSchema>
