var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.itemsCount > 0),expression:"itemsCount > 0"}],staticClass:"swimlane",class:{ 'swimlane-enhanced': _vm.isHighlighted }},[_c('div',{staticClass:"heading",class:{
      'items-center': _vm.showTitleBig,
    }},[_c('div',[(_vm.showTitleBig)?_c('h2',{staticClass:"title-big",attrs:{"id":_vm.uid}},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e(),_vm._v(" "),(
          _vm.isHighlighted && _vm.componentName !== _vm.EComponentName.topDealsJustForYou
        )?_c('nuxt-link',{attrs:{"to":_vm.btnTarget},nativeOn:{"click":function($event){return _vm.trackClick()}}},[_c('h3',{staticClass:"enhanced"},[_vm._v(_vm._s(_vm.title))])]):_c('h3',{attrs:{"id":_vm.uid}},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),(_vm.isHighlighted)?_c('p',{staticClass:"text-body-s text-secondary-500"},[_vm._v("\n        "+_vm._s(_vm.subtitle)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',[(_vm.isHighlighted && _vm.ctaButton)?_c('div',{staticClass:"text-right pb-4"},[_c('Button',{attrs:{"to":_vm.btnTarget,"variant":"tonal","size":"x-small"},nativeOn:{"click":function($event){return _vm.trackClick('see_all')}}},[_vm._v("\n          "+_vm._s(_vm.ctaButton.text)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.showControls)?_c('div',{staticClass:"controls",class:{
          'my-8 md:my-6': _vm.showTitleBig,
          'mr-4 md:mr-8': _vm.showMarginArrows,
        }},[_c('button',{staticClass:"scroll",attrs:{"aria-label":"Arrow Left","disabled":_vm.prevDisabled},on:{"click":function($event){return _vm.prevSet()}}},[_c('VIcon',{attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('button',{staticClass:"scroll",attrs:{"aria-label":"Arrow Right","disabled":_vm.nextDisabled},on:{"click":function($event){return _vm.nextSet()}}},[_c('VIcon',{attrs:{"name":"arrow-right"}})],1)]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"items-wrapper",class:{ recipes: _vm.recipeRecs }},[_c('div',{ref:"slider",staticClass:"barless-scroll-x",attrs:{"role":"list","aria-labelledby":_vm.uid},on:{"scroll":_vm.debouncedHandleScroll}},[_vm._t("default")],2)]),_vm._v(" "),_vm._t("swimlane-footer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }