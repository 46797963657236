import { Loader } from '@googlemaps/js-api-loader'

export default ({ store, $config }, inject) => {
  const loader = new Loader({
    libraries: ['places', 'geometry'],
    apiKey: $config.googleMapsApiKey,
  })
  inject(
    'google',
    loader.load().catch((err) => {
      console.error(err)
    })
  )
}
