import { EProductPlacement } from '~/lib/segment'
import transformProducts from '../../transform-products'
import { SectionTransformResult } from '../shared'
import { SwimlaneSectionRaw, V2HomeSectionRaw } from './types'

const check = (section: V2HomeSectionRaw): section is SwimlaneSectionRaw =>
  'swimlane' in section

const transform = (section: SwimlaneSectionRaw): SectionTransformResult => {
  const { swimlane } = section
  const products = transformProducts(swimlane.products.products)

  let productPlacement = EProductPlacement.swimlane

  if (swimlane.id === 'last-bought') {
    productPlacement = EProductPlacement.lastBought
  } else if (swimlane.id === 'recommendation') {
    productPlacement = EProductPlacement.recommendation
  }

  return {
    type: 'swimlane',
    products,
    subtitle: '',
    id: swimlane.id,
    productPlacement,
    title: swimlane.title,
    listName: swimlane.trackingType,
  }
}

export const swimlaneTransformer = { check, transform }
