/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'

import IPrice, { Currency } from '~/types/app/Price'

interface IParameters {
  client: NuxtAxiosInstance
  locale: string
  cartId: string
}

export interface IResponseError {
  code: string
  name: string
  message: string
}

export interface IVoucherResponse {
  currency: Currency
  amount: number
  sub_total_price: IPrice
  shipping_price: IPrice
  discount: IPrice
  errorMessage?: string
}

interface IBody {
  cart_id: string
}

export default async function removeVoucher(
  { client, locale, cartId }: IParameters,
  logger: LoggerFunction
): Promise<IVoucherResponse | IResponseError> {
  const headers = {
    locale,
  }

  const url = '/voucher/remove'

  const body: IBody = {
    cart_id: cartId,
  }

  try {
    const { data } = await post<IVoucherResponse>(
      client,
      logger,
      url,
      headers,
      body
    )

    logger({
      level: ELogLevels.INFO,
      msg: 'Voucher removed successfully',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      logger: 'API Services',
      func: 'removeVoucher',
      slug: 'checkout.api.removeVoucher.success',
    })

    return data
  } catch (error: any) {
    const { data } = error.response ?? {}
    logger({
      level: ELogLevels.ERROR,
      msg: 'Removing voucher failed.',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: data?.message || error.message,
      logger: 'API Services',
      func: 'removeVoucher',
      slug: 'checkout.api.removeVoucher.error',
    })

    return data
  }
}
