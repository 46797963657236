import { render, staticRenderFns } from "./landing.vue?vue&type=template&id=7dd8053f&"
import script from "./landing.vue?vue&type=script&lang=ts&"
export * from "./landing.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainLayout: require('/app/build/components/main/MainLayout.vue').default})
