import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { httpDelete } from '~/api/controllers/delete'
import { LoggerFunction } from '~/types/app/Logger'
import {
  IAddressBookAddress,
  IAddressBookApiResponse,
} from '~/types/app/Location'

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  addressId: IAddressBookAddress['id']
}

export default async function deleteAddress({
  client,
  logger,
  locale,
  addressId,
}: IParameters): Promise<IAddressBookApiResponse | null> {
  const headers = {
    locale,
  }
  const url = `/address/${addressId}`

  try {
    const response = await httpDelete<IAddressBookApiResponse>(
      client,
      logger,
      url,
      headers
    )
    return response.data
  } catch (error) {}

  return null
}
