import { render, staticRenderFns } from "./VCloudinaryImage.vue?vue&type=template&id=56818c30&"
import script from "./VCloudinaryImage.vue?vue&type=script&lang=ts&"
export * from "./VCloudinaryImage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports