/**
 * SingleCallDebouncer class ensures only one API call is in progress at a time, with a minimum debounce
 * of CART_LINE_UPDATE_DEBOUNCE_TIME ms between calls. It provides a method callApi() to trigger API calls with optional arguments,
 * cancelling any ongoing call if a new one is made within the debounce period.
 */

const CART_LINE_UPDATE_DEBOUNCE_TIME = 2000

class SingleCallDebouncer {
  constructor(apiFunction) {
    this.pendingCall = null
    this.debounceTimer = null
    this.apiFunction = apiFunction
  }

  callApi(...args) {
    return new Promise((resolve, reject) => {
      if (this.pendingCall) {
        clearTimeout(this.debounceTimer)
        this.pendingCall.reject({
          message: SingleCallDebouncer.ERROR_MESSAGE,
        })
      }

      this.pendingCall = { resolve, reject }
      this.debounceTimer = setTimeout(() => {
        this.executeApiCall(...args)
      }, CART_LINE_UPDATE_DEBOUNCE_TIME)
    })
  }

  executeApiCall(...args) {
    if (!this.pendingCall) return

    // Call the provided API function with arguments
    this.apiFunction(...args)
      .then((result) => {
        this.pendingCall.resolve(result)
        this.pendingCall = null
      })
      .catch((error) => {
        this.pendingCall.reject(error)
        this.pendingCall = null
      })
  }
}

SingleCallDebouncer.ERROR_MESSAGE = 'Cancelled due to new call'

export default SingleCallDebouncer
