/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { ICoordinates } from '~/types/app/Location'
import { LoggerFunction } from '~/types/app/Logger'
import {
  ESchedulingCta,
  ESchedulingIcon,
  ESchedulingOptionType,
  ESchedulingState,
} from '~/types/app/PlannedOrders'

interface ISchedulingResponse {
  state: ESchedulingState
  current_pdt: number
  is_clickable: boolean
  text?: string
  message_box: {
    id: string
    icon: ESchedulingIcon
    title: string
    message: string
    ctas: ESchedulingCta[]
  }
  updated_slot: {
    id: string
    type: ESchedulingOptionType
    start: string
    end: string
  }
}

export default async function getOrderSchedulingHome(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  locale: string,
  hubSlug: string,
  deliveryTierId: string,
  location: ICoordinates,
  token?: string
): Promise<ISchedulingResponse | Record<string, undefined>> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
    'delivery-tier-id': deliveryTierId,
  }
  const { latitude, longitude } = location
  const tokenPart = token ? `&token=${encodeURIComponent(token)}` : ''

  try {
    const response = await get<ISchedulingResponse>(
      client,
      logger,
      `order-scheduling/home?lat=${latitude}&long=${longitude}${tokenPart}`,
      headers
    )
    return response.data
  } catch (error) {
    return {}
  }
}
