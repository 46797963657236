import { IAppState } from '~/types/app/State'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  ECartErrorCode,
  ILateNightConfig,
  PrepareCartForCheckoutError,
} from '~/types/app/Cart'
import { IConflictingCartLine } from '~/api/services/are-all-products-available/are-all-products-available'
import getLateNightConfig from '~/api/services/get-late-night-config/get-late-night-config'
import { prepareCartForCheckout } from '~/api/services/prepare-cart'
import {
  SET_HAD_STORAGE_FEE,
  SET_IS_FEE_OVERVIEW_MODAL_VISIBLE,
  SET_LATE_NIGHT_CONFIG,
  SET_CONFLICTING_LINES,
  SET_CART_UPDATE_CALLS_PENDING,
  SET_PREPARE_CART_FOR_CHECKOUT_ERROR,
  RESET_PREPARE_CART_FOR_CHECKOUT_ERROR,
} from './mutation-types'
import { cartLines } from './helpers'

export interface ICheckoutState {
  hadStorageFee: boolean
  isFeeOverviewModalVisible: boolean
  lateNightConfig: ILateNightConfig
  conflictingCartLines: IConflictingCartLine[]
  cartUpdateCallsPending: number
  prepareCartForCheckoutError: null | PrepareCartForCheckoutError
}

export const state = (): ICheckoutState => ({
  hadStorageFee: false,
  isFeeOverviewModalVisible: false,
  lateNightConfig: {
    enabled: false,
    fees: [],
  },
  conflictingCartLines: [],
  cartUpdateCallsPending: 0,
  prepareCartForCheckoutError: null,
})

export const getters: GetterTree<ICheckoutState, IAppState> = {
  storageFeeAmount: (_, __, rootState) => {
    return rootState.remoteCart?.storage_fee?.amount
  },
  getIsHubClosedCheckoutValidation: (state) =>
    state.prepareCartForCheckoutError?.reason === ECartErrorCode.HUB_CLOSED,
}

export const mutations: MutationTree<ICheckoutState> = {
  [SET_HAD_STORAGE_FEE](state, isVisible) {
    state.hadStorageFee = isVisible
  },
  [SET_IS_FEE_OVERVIEW_MODAL_VISIBLE](state, visible) {
    state.isFeeOverviewModalVisible = visible
  },
  [SET_LATE_NIGHT_CONFIG](state, value) {
    state.lateNightConfig = value
  },
  [SET_CONFLICTING_LINES](state, value) {
    state.conflictingCartLines = value
  },
  [SET_CART_UPDATE_CALLS_PENDING](state, value) {
    state.cartUpdateCallsPending = value
  },
  [SET_PREPARE_CART_FOR_CHECKOUT_ERROR](state, value) {
    if (value === null) {
      state.prepareCartForCheckoutError = null
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status, ...errorRelatedProperties } = value
      state.prepareCartForCheckoutError = errorRelatedProperties
    }
  },
  [RESET_PREPARE_CART_FOR_CHECKOUT_ERROR](state) {
    state.prepareCartForCheckoutError = null
  },
}

export const actions: ActionTree<ICheckoutState, IAppState> = {
  updateHadStorageFee({ state, commit, rootState }, hadStorageFee) {
    if (hadStorageFee === undefined) {
      if (
        !state.hadStorageFee &&
        rootState.remoteCart?.storage_fee &&
        rootState.remoteCart?.storage_fee?.amount > 0
      ) {
        commit(SET_HAD_STORAGE_FEE, true)
      }
    } else {
      commit(SET_HAD_STORAGE_FEE, hadStorageFee)
    }
  },
  toggleFeeOverviewModal({ commit }, visible) {
    commit(SET_IS_FEE_OVERVIEW_MODAL_VISIBLE, visible)
  },
  async loadLateNightConfig({ commit, rootState }) {
    const lateNightConfig: ILateNightConfig | null = await getLateNightConfig({
      client: this.$apiCartV3,
      logger: this.$logger,
      locale: this.$i18n.locale,
      hubSlug: rootState.hub?.slug,
    })

    if (lateNightConfig) {
      commit(SET_LATE_NIGHT_CONFIG, lateNightConfig)
    }
  },
  resetPrepareCartForCheckoutError({ commit }) {
    commit(RESET_PREPARE_CART_FOR_CHECKOUT_ERROR)
  },
  async prepareCartForCheckout({ rootState, commit }) {
    const response = await prepareCartForCheckout({
      client: this.$apiCartV3,
      logger: this.$logger,
      locale: this.$i18n.locale,
      hubSlug: rootState.hub?.slug,
      cartId: rootState.cartId ?? '',
      cartLines: cartLines(rootState.cart),
    })

    if (response?.status === 'success') {
      commit(SET_PREPARE_CART_FOR_CHECKOUT_ERROR, null)
    } else if (response?.status === 'error') {
      commit(SET_PREPARE_CART_FOR_CHECKOUT_ERROR, response)
    }

    return response
  },
}
