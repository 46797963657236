/* eslint-disable camelcase */
import { round } from 'lodash-es'
import { PaymentAction } from '@adyen/adyen-web/dist/types/types'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import transformCheckoutCartResponse from '~/api/transformers/transform-checkout-cart-response'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'

import { IPaymentMethodData } from '~/types/app/Payments'
import IPrice from '~/types/app/Price'
import { CartErrorInfo, TrackingData } from '~/types/app/Cart'
import { AxiosError } from 'axios'

interface IParameters {
  client: NuxtAxiosInstance
  locale: string
  hubSlug?: string
  price: IPrice
  stateData: IPaymentMethodData
  cartId: string
  isWithDetails: boolean
  returnUrl?: string
  paymentMethod?: string | unknown
}

export interface ICheckoutCartResponse {
  id: string
  confirmationData: string
  confirmationNeeded: boolean
  number: string
  token: string
}

export interface ICheckoutCartReturn {
  id: string
  confirmationData?: {
    action?: PaymentAction | undefined
    resultCode?: string | undefined
  }
  confirmationNeeded: boolean
  orderDisplayNumber: string
  orderId: string
}

export interface ICheckoutCartError {
  code: string
  field?: string
  message?: string | null
  rejectionReason?: string | null
  title?: string | undefined | null
  info?: CartErrorInfo | AxiosError
  trackingData?: TrackingData
}

interface IBody {
  amount: number
  token: string
}

interface IPaymentData {
  amount: {
    currency: IPrice['currency']
    value: number
  }
  additionalData: {
    allow3DS2: boolean
  }
  channel: 'Web'
  returnUrl?: string
}

export default async function checkoutCart(
  {
    client,
    locale,
    hubSlug,
    price,
    stateData,
    cartId,
    isWithDetails = false,
    returnUrl,
    paymentMethod,
  }: IParameters,
  logger: LoggerFunction
): Promise<ICheckoutCartReturn | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = isWithDetails
    ? `/cart/${cartId}/checkout/details`
    : `/cart/${cartId}/checkout`

  const paymentData: IPaymentData = {
    ...stateData,
    amount: {
      currency: price.currency,
      value: round(price.amount * 100), // Adyen expects the price in minor units
    },
    additionalData: {
      allow3DS2: true,
    },
    returnUrl,
    channel: 'Web',
  }

  const logSlug = isWithDetails
    ? 'checkout.api.checkout-cart-details'
    : 'checkout.api.checkout-cart'

  try {
    const tokenData = isWithDetails ? stateData : paymentData
    const body: IBody = {
      amount: price.amount,
      token: JSON.stringify(tokenData),
    }

    const response = await post<ICheckoutCartResponse>(
      client,
      logger,
      url,
      headers,
      body
    )

    logger({
      level: ELogLevels.INFO,
      msg: `API Success: Cart checkout successful (details: ${isWithDetails})`,
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.CART,
      func: 'checkoutCart',
      slug: `${logSlug}.success`,
      'flink.payment.type': paymentMethod,
      forceSubmit: true,
    })

    return transformCheckoutCartResponse(response.data)
  } catch (error: any) {
    logger({
      level: ELogLevels.ERROR,
      msg: `API Error: checkoutCart failed (details: ${isWithDetails})`,
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: ELoggers.CART,
      func: 'checkoutCart',
      slug: `${logSlug}.error`,
      'flink.payment.type': paymentMethod,
      forceSubmit: false,
    })

    throw error
  }
}
