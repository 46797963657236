import { ContentfulClientApi, createClient } from 'contentful'
import {
  CONTENTFUL_PREVIEW_QUERY_ID,
  CONTENTFUL_URL_PREVIEW,
  CONTENTFUL_URL,
  CONTENTFUL_ENVIRONMENT_STAGING,
  CONTENTFUL_ENVIRONMENT_MASTER,
} from '../contentful.config'

export default (queryMode: string | (string | null)[]): ContentfulClientApi => {
  const isPreview = queryMode === CONTENTFUL_PREVIEW_QUERY_ID
  const isStaging = queryMode === CONTENTFUL_ENVIRONMENT_STAGING
  const cnftlConfig = {
    environment: isStaging
      ? CONTENTFUL_ENVIRONMENT_STAGING
      : process.env.NUXT_ENV_CONTENTFUL_ENV || CONTENTFUL_ENVIRONMENT_MASTER,
    space: 'irrelevant',
    accessToken: 'redacted',
    baseURL: isPreview ? CONTENTFUL_URL_PREVIEW : CONTENTFUL_URL,
  }

  return createClient(cnftlConfig)
}
