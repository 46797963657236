



















import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    address: { type: String, default: '' },
    showLegend: { type: Boolean, default: true },
    additionalInformation: { type: String, default: '' },
  },
  setup(props) {
    const additionalInformationModel = ref<string>('')

    onMounted(() => {
      additionalInformationModel.value = props.additionalInformation
    })

    return {
      additionalInformationModel,
    }
  },
})
