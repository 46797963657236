import { render, staticRenderFns } from "./StoreBadge.vue?vue&type=template&id=625cabcc&scoped=true&"
import script from "./StoreBadge.vue?vue&type=script&lang=ts&"
export * from "./StoreBadge.vue?vue&type=script&lang=ts&"
import style0 from "./StoreBadge.vue?vue&type=style&index=0&id=625cabcc&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "625cabcc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/app/build/components/ContentfulImage/ContentfulImage.vue').default})
