














































import { defineComponent } from '@nuxtjs/composition-api'
import { ICoordinates } from '~/types/app/Location'
import { round } from 'lodash-es'

export default defineComponent({
  props: {
    userAddress: { type: String, required: true },
    hubAddress: { type: String, required: true },
    userCoordinates: { type: Object as () => ICoordinates, required: true },
    hubCoordinates: { type: Object as () => ICoordinates, required: true },
  },
  setup(props) {
    let pickupDistanceUnit = ''
    let distance = 0
    try {
      const fromLocation = new google.maps.LatLng(
        props.userCoordinates.latitude,
        props.userCoordinates.longitude
      )
      const toLocation = new google.maps.LatLng(
        props.hubCoordinates.latitude,
        props.hubCoordinates.longitude
      )

      const distanceMeters =
        google.maps.geometry.spherical.computeDistanceBetween(
          fromLocation,
          toLocation
        )

      pickupDistanceUnit = distanceMeters > 1000 ? 'km' : 'm'
      distance =
        distanceMeters > 1000
          ? round(distanceMeters / 1000, 1)
          : round(distanceMeters, 0)
    } catch (e) {}

    return {
      distance,
      pickupDistanceUnit,
    }
  },
})
