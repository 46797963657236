import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { isEmpty } from 'lodash-es'
import { ROUTES } from '~/lib/routes'
import { IAppState } from '~/types/app/State'
import {
  ESchedulingIcon,
  ESchedulingCta,
  ESchedulingState,
  ESchedulingOptionType,
  ECheckoutDisplayState,
  ISchedulingOption,
  IDeliveryOption,
} from '~/types/app/PlannedOrders'
import { getOrderSchedulingHome } from '~/api/services/get-order-scheduling-home'
import { getOrderSchedulingCheckout } from '~/api/services/get-order-scheduling-checkout'
import { ICheckoutSchedulingResponse } from '~/api/services/get-order-scheduling-checkout/get-order-scheduling-checkout'
import { getOrderSchedulingOptions } from '~/api/services/get-order-scheduling-options'
import {
  SET_FEATURE_ENABLED,
  SET_MESSAGE,
  RESET_MESSAGE,
  SET_SHOWN_MESSAGE_ID,
  SET_SCHEDULING_STATE,
  SET_DELIVERY_OPTIONS_ON_CHECKOUT,
  SET_SCHEDULING_STATE_ON_CHECKOUT,
  SET_SCHEDULING_TEXT,
  SET_SCHEDULING_CLICKABLE,
  SET_SELECTED_TIMESLOT,
  SET_SCHEDULING_OPTIONS,
  SET_ASAP_PDT,
  SET_ASAP_TOKEN,
  SET_TIMESLOT_START,
  SET_TIMESLOT_END,
  SET_TIMESLOT_TYPE,
} from './mutation-types'

interface IStoreDeliveryOption
  extends Omit<IDeliveryOption, 'fee_description' | 'discount_description'> {
  feeDescription: string | null
  discountDescription: string | null
}

export interface IPlannedOrdersState {
  featureEnabled: boolean
  schedulingState: ESchedulingState | undefined
  isClickable: boolean | undefined
  schedulingText: string | undefined
  selectedTimeSlot: {
    id: string
    type: ESchedulingOptionType
    start: string
    end: string
  }
  messageInfo: {
    shownMessageId: string
    currentId: string
    icon: string
    title: string
    message: string
    ctaText: string
  }
  onCheckout: {
    [ESchedulingOptionType.ASAP]: ICheckoutSchedulingResponse['asap']
    [ESchedulingOptionType.PLANNED]: ICheckoutSchedulingResponse['planned']
    deliveryOptions?: IStoreDeliveryOption[]
  }
  schedulingOptions: ISchedulingOption[]
  asapPdt: number | undefined
  asapToken: string | undefined
}

const schedulingIconMap = {
  [ESchedulingIcon.HIGH_PDT]: 'clock',
  [ESchedulingIcon.NONE]: '',
}

const schedulingCtaMap = {
  [ESchedulingCta.SELECT_DELIVERY_OPTION]:
    'planned_orders_message_box_cta_delivery_options',
}

const emptyMessageBoxState = {
  shownMessageId: '',
  currentId: '',
  title: '',
  message: '',
  icon: '',
  ctaText: '',
}

export const state = (): IPlannedOrdersState => ({
  featureEnabled: false,
  schedulingState: undefined,
  isClickable: false,
  schedulingText: '',
  selectedTimeSlot: {
    id: '',
    type: ESchedulingOptionType.ASAP,
    start: '',
    end: '',
  },
  messageInfo: {
    ...emptyMessageBoxState,
  },
  onCheckout: {
    [ESchedulingOptionType.ASAP]: {
      title: '',
      subtitle: '',
      state: ECheckoutDisplayState.DISABLED,
    },
    [ESchedulingOptionType.PLANNED]: {
      title: '',
      subtitle: '',
      state: ECheckoutDisplayState.DISABLED,
    },
    deliveryOptions: [],
  },
  schedulingOptions: [],
  asapPdt: undefined,
  asapToken: '',
})

export const getters: GetterTree<IPlannedOrdersState, IAppState> = {
  isPickTimeSlotPossible: (state: IPlannedOrdersState): boolean =>
    ESchedulingState.CLOSED !== state.schedulingState,
  isPlannedOrdersStale:
    (state: IPlannedOrdersState) =>
    (currentRoute?: ROUTES): boolean => {
      return currentRoute === ROUTES.HOME || state.schedulingState === undefined
    },
  isAsapSelected: (state: IPlannedOrdersState): boolean => {
    // when feature not enabled, we assume ASAP is selected
    if (!state.featureEnabled) return true

    return (
      !!state.selectedTimeSlot.id &&
      state.selectedTimeSlot.type === ESchedulingOptionType.ASAP
    )
  },
  isPrioSelected: (state: IPlannedOrdersState): boolean => {
    return (
      !!state.selectedTimeSlot.id &&
      state.selectedTimeSlot.type === ESchedulingOptionType.PRIO
    )
  },
  getAsapOrExpressToken: (state: IPlannedOrdersState): string => {
    return (
      state.onCheckout.deliveryOptions?.find((option) =>
        [ESchedulingOptionType.ASAP, ESchedulingOptionType.EXPRESS].includes(
          option.type
        )
      )?.id ??
      state.asapToken ??
      ''
    )
  },
  isPlannedOrder: (state: IPlannedOrdersState): boolean =>
    !!state.selectedTimeSlot.id &&
    state.selectedTimeSlot.type === ESchedulingOptionType.PLANNED,
  isPdtShown: (state: IPlannedOrdersState): boolean =>
    !state.featureEnabled ||
    state.schedulingState === ESchedulingState.SHOW_PDT,
  isServiceInfoVisible: (state: IPlannedOrdersState): boolean =>
    state.messageInfo.currentId !== '' &&
    state.messageInfo.shownMessageId === '',
  hasDeliveryOptionBeenShown:
    (state: IPlannedOrdersState) =>
    (typeShown: ESchedulingOptionType): boolean => {
      const typeIndex =
        state.onCheckout.deliveryOptions?.findIndex(
          ({ type }) => type === typeShown
        ) ?? -1

      return typeIndex > -1
    },
}

export const mutations: MutationTree<IPlannedOrdersState> = {
  [SET_FEATURE_ENABLED](state, enabled) {
    state.featureEnabled = enabled
  },
  [SET_SHOWN_MESSAGE_ID](state, messageId) {
    state.messageInfo.shownMessageId = messageId
  },
  [SET_MESSAGE](state, messagePayload) {
    state.messageInfo.shownMessageId = ''
    state.messageInfo.currentId = messagePayload.id
    state.messageInfo.title = messagePayload.title
    state.messageInfo.message = messagePayload.message
    state.messageInfo.icon =
      schedulingIconMap[messagePayload.icon as ESchedulingIcon] || ''

    if (messagePayload.ctas[0]) {
      state.messageInfo.ctaText =
        schedulingCtaMap[messagePayload.ctas[0] as ESchedulingCta] || ''
    }
  },
  [RESET_MESSAGE](state) {
    state.messageInfo = { ...emptyMessageBoxState }
  },
  [SET_SCHEDULING_STATE](state, schedulingState) {
    state.schedulingState = schedulingState
  },
  [SET_SCHEDULING_STATE_ON_CHECKOUT](
    state,
    schedulingState: ICheckoutSchedulingResponse
  ) {
    state.onCheckout[ESchedulingOptionType.ASAP] = schedulingState.asap
    state.onCheckout[ESchedulingOptionType.PLANNED] = schedulingState.planned
  },
  [SET_SCHEDULING_CLICKABLE](state, isClickable) {
    state.isClickable = isClickable
  },
  [SET_SCHEDULING_TEXT](state, schedulingText) {
    state.schedulingText = schedulingText
  },
  [SET_SELECTED_TIMESLOT](state, id) {
    state.selectedTimeSlot.id = id
  },
  [SET_TIMESLOT_START](state, start) {
    state.selectedTimeSlot.start = start
  },
  [SET_TIMESLOT_END](state, end) {
    state.selectedTimeSlot.end = end
  },
  [SET_TIMESLOT_TYPE](state, type) {
    state.selectedTimeSlot.type = type
  },
  [SET_SCHEDULING_OPTIONS](state, schedulingOptions) {
    state.schedulingOptions = schedulingOptions
  },
  [SET_DELIVERY_OPTIONS_ON_CHECKOUT](
    state,
    schedulingState: ICheckoutSchedulingResponse
  ) {
    /* eslint-disable camelcase */
    state.onCheckout.deliveryOptions = schedulingState.delivery_options?.map(
      ({
        id,
        title,
        subtitle,
        fee_description,
        discount_description,
        state,
        type,
      }) => ({
        id,
        title,
        subtitle,
        feeDescription: fee_description,
        discountDescription: discount_description,
        state,
        type,
      })
    )
  },
  [SET_ASAP_PDT](state, asapPdt) {
    state.asapPdt = asapPdt || undefined
  },
  [SET_ASAP_TOKEN](state, asapToken) {
    state.asapToken = asapToken
  },
}

export const actions: ActionTree<IPlannedOrdersState, IAppState> = {
  async updateSchedulingHome({ commit, dispatch, rootState, state }) {
    if (!rootState.deliveryCoordinates) return

    const schedulingHome = await getOrderSchedulingHome(
      this.$apiOrderExperienceBff,
      this.$logger,
      this.$i18n.locale,
      rootState.hub?.slug || '',
      rootState.deliveryTierId,
      rootState.deliveryCoordinates,
      state.selectedTimeSlot.id
    )

    if (isEmpty(schedulingHome)) {
      return
    }

    commit(SET_FEATURE_ENABLED, true)
    commit(SET_SCHEDULING_STATE, schedulingHome.state)
    commit(SET_SCHEDULING_TEXT, schedulingHome.text)
    commit(SET_SCHEDULING_CLICKABLE, schedulingHome.is_clickable)
    commit(SET_ASAP_PDT, schedulingHome.current_pdt)

    dispatch('selectTimeSlotToken', {
      ...schedulingHome.updated_slot,
    })

    if (
      schedulingHome.message_box &&
      state.messageInfo.shownMessageId !== schedulingHome.message_box.id
    ) {
      commit(SET_MESSAGE, schedulingHome.message_box)
    } else if (!schedulingHome.message_box) {
      commit(RESET_MESSAGE)
    }
  },
  async updateSchedulingCheckout({ commit, dispatch, rootState, state }) {
    if (!rootState.deliveryCoordinates) return

    const schedulingCheckout = await getOrderSchedulingCheckout(
      this.$apiOrderExperienceBffV3,
      this.$logger,
      this.$i18n.locale,
      rootState.hub?.slug || '',
      rootState.deliveryTierId,
      rootState.deliveryCoordinates,
      state.selectedTimeSlot.id,
      rootState.remoteCart?.id
    )

    if (isEmpty(schedulingCheckout)) {
      commit(SET_SCHEDULING_CLICKABLE, false)
      commit(SET_FEATURE_ENABLED, false)
      return
    }

    commit(SET_FEATURE_ENABLED, true)
    commit(
      schedulingCheckout.delivery_options
        ? SET_DELIVERY_OPTIONS_ON_CHECKOUT
        : SET_SCHEDULING_STATE_ON_CHECKOUT,
      schedulingCheckout
    )
    commit(RESET_MESSAGE)
    dispatch('selectTimeSlotToken', {
      ...schedulingCheckout.selected_slot,
    })
  },
  async updateSchedulingOptions({ commit, dispatch, rootState, state }) {
    if (!rootState.deliveryCoordinates) return

    const optionsResponse = await getOrderSchedulingOptions(
      this.$apiOrderExperienceBff,
      this.$logger,
      this.$i18n.locale,
      rootState.hub?.slug || '',
      rootState.deliveryTierId,
      rootState.deliveryCoordinates,
      state.selectedTimeSlot.id
    )

    if (optionsResponse) {
      const currentSelectedTimeslot = optionsResponse.asap_delivery?.is_selected
        ? optionsResponse.asap_delivery
        : optionsResponse.delivery_options?.filter(
            (deliveryOption) => deliveryOption.is_selected
          )[0]

      if (currentSelectedTimeslot) {
        dispatch('selectTimeSlotToken', {
          id: currentSelectedTimeslot?.id,
          ...(currentSelectedTimeslot && { ...currentSelectedTimeslot.meta }),
        })
      }

      commit(SET_SCHEDULING_OPTIONS, optionsResponse.delivery_options || [])
      commit(SET_ASAP_PDT, optionsResponse.asap_delivery?.pdt)
      commit(SET_ASAP_TOKEN, optionsResponse.asap_delivery?.id)
    }
  },
  setShownMessageId({ commit }, messageId) {
    commit(SET_SHOWN_MESSAGE_ID, messageId)
  },
  selectTimeSlotToken({ commit }, { id = '', start, end, type }) {
    commit(SET_SELECTED_TIMESLOT, id)
    if (start && end && type) {
      commit(SET_TIMESLOT_START, start)
      commit(SET_TIMESLOT_END, end)
      commit(SET_TIMESLOT_TYPE, type)
    }
  },
}
