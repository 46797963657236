import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import {
  IPaymentGateway,
  GetOrdersPaymentMethodsResponse,
} from '~/types/app/Payments'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'

import transformPaymentMethodResponse from '~/api/transformers/transform-payment-methods'

type Parameters = {
  client: NuxtAxiosInstance
  locale: string
  hubSlug?: string
}

export default async function getOrdersPaymentMethods(
  { client, locale, hubSlug }: Parameters,
  logger: LoggerFunction
): Promise<IPaymentGateway | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = '/payment-methods/orders'

  try {
    const response = await get<GetOrdersPaymentMethodsResponse>(
      client,
      logger,
      url,
      headers
    )

    logger({
      level: ELogLevels.INFO,
      msg: 'API Success: Get Orders Payment Methods',
      'http.method': EHTTPMethods.GET,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.PAYMENT_BFF,
      func: 'getOrdersPaymentMethods',
      slug: 'checkout.api.getOrdersPaymentMethods.success',
      forceSubmit: false,
    })

    return transformPaymentMethodResponse(response.data)
  } catch (error) {
    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: Get Orders Payment Methods failed',
      'http.method': EHTTPMethods.GET,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: ELoggers.PAYMENT_BFF,
      func: 'getOrdersPaymentMethods',
      slug: 'checkout.api.getOrdersPaymentMethods.error',
      forceSubmit: false,
    })

    return undefined
  }
}
