






















import { ref, computed, onMounted, onUnmounted, useRoute, useStore } from '@nuxtjs/composition-api';
import useModel from '~/hooks/useModel';
import VUnderlay from '~/components/VUnderlay/VUnderlay.vue';
import SearchLabels from './SearchLabels.vue';
import { ROUTES } from '~/lib/routes';
import { FeatureFlags } from '~/types/app/experiments';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({
  model: {
    prop: 'initialSearchText',
    event: 'input'
  }
});

__sfc_main.props = {
  initialSearchText: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: 'search'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const route = useRoute();
  const store = useStore();
  const searchText = useModel(props, 'initialSearchText', 'input');
  const useSearchSuggestions = computed<boolean>(() => store.getters['experiments/isFeatureEnabled'](FeatureFlags.SEARCH_SUGGESTIONS));
  const root = ref<Element>();
  const showList = ref(false);

  const show = () => {
    if (!useSearchSuggestions.value && route.value.name?.startsWith(ROUTES.SEARCH)) return;
    showList.value = true;
  };

  const hide = () => {
    showList.value = false;
  };

  const clickOutside = (event: Event) => {
    if (root.value && root.value !== (event.target as Element) && !root.value.contains((event.target as Element)) || useSearchSuggestions.value && route.value.name?.startsWith(ROUTES.SEARCH) && event.target && (event.target as Element).closest('li.item')) {
      hide();
    }
  };

  function handleEnterKeyPress() {
    hide();
  }

  onMounted(() => {
    document.addEventListener('click', clickOutside);
  });
  onUnmounted(() => {
    document.removeEventListener('click', clickOutside);
  });

  const onFocus = () => {
    show();
    emit('focus');
  };

  const onSearch = (event: string) => {
    show();
    emit('search', event);
  };

  return {
    emit,
    searchText,
    root,
    showList,
    handleEnterKeyPress,
    onFocus,
    onSearch
  };
};

__sfc_main.components = Object.assign({
  SearchLabels,
  VUnderlay
}, __sfc_main.components);
export default __sfc_main;
