import { get } from '~/api/controllers/get'
import { ISubscriptionDetailResponse } from '~/types/app/Subscription'

import { LoggerFunction } from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
}

export default async function getSubscriptionDetail({
  client,
  logger,
  locale,
  hubSlug,
}: IParameters): Promise<ISubscriptionDetailResponse | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = 'subscriptions'

  const response = await get<ISubscriptionDetailResponse>(
    client,
    logger,
    url,
    headers
  )
  return response.data
}
