import { render, staticRenderFns } from "./SearchDropdown.vue?vue&type=template&id=be3d7a74&scoped=true&"
import script from "./SearchDropdown.vue?vue&type=script&lang=ts&"
export * from "./SearchDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./SearchDropdown.vue?vue&type=style&index=0&id=be3d7a74&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be3d7a74",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchControl: require('/app/build/components/Search/SearchControl.vue').default,SearchLabels: require('/app/build/components/Search/SearchLabels.vue').default,VUnderlay: require('/app/build/components/VUnderlay/VUnderlay.vue').default})
