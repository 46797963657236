















import { PropType } from '@nuxtjs/composition-api';
import IPrice from '~/types/app/Price';
const __sfc_main = {};
__sfc_main.props = {
  price: {
    key: "price",
    required: true,
    type: null
  },
  originalPrice: {
    key: "originalPrice",
    required: false,
    type: null
  },
  hideBg: {
    key: "hideBg",
    required: false,
    type: Boolean
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

export default __sfc_main;
