import type { IGroupedCartItems } from '~/types/app/Cart'
import type {
  IAppliedCampaignRaw,
  IAppliedCampaign,
} from '~/types/app/Promotions'
import type IProduct from '~/types/app/Product'

export default function groupCartItemsByPromotions({
  promos = [],
  cartItems = [],
}: {
  promos: IAppliedCampaignRaw[]
  cartItems: IProduct[]
}): IGroupedCartItems {
  cartItems = [...cartItems]

  const enhancedCart: IGroupedCartItems = promos.reduce<IGroupedCartItems>(
    (cart, promoSummary) => {
      const { lineItems, ...promoSummaryWithoutLineItems } = promoSummary
      const promo: IAppliedCampaign = {
        ...promoSummaryWithoutLineItems,
        products: [],
      }

      lineItems.forEach(({ productSku }) => {
        const productDetailsIdx = cartItems.findIndex(
          (i) => i.sku === productSku
        )
        if (productDetailsIdx === -1) return

        const productDetails = cartItems[productDetailsIdx]
        promo.products.push(productDetails)

        cartItems.splice(productDetailsIdx, 1)
      })

      cart.itemsWithPromotions.push(promo)
      return cart
    },
    { itemsWithPromotions: [], itemsWithoutPromotions: [] }
  )

  enhancedCart.itemsWithoutPromotions = [...cartItems]

  return enhancedCart
}
