type CloudinaryOptions = {
  params?: string
  width?: number
  height?: number
}

const isCloudinaryParams = (str: string) =>
  str.includes('w_') || str.includes('h_')

const isTransformParams = (str: string) => str.includes('t_')

const getCloudinaryParams = (options: CloudinaryOptions): string => {
  const params = []

  if (options.width) {
    params.push(`w_${options.width}`)
  }

  if (options.height) {
    params.push(`h_${options.height}`)
  }

  if (options.params) {
    params.push(options.params)
  }

  return params.join(',')
}

export const getCloudinaryUrl = (
  url: string,
  ext: string,
  options: CloudinaryOptions
): string => {
  const transformedURL = new URL(url)
  const pathname = transformedURL.pathname.split('/')
  const uploadIndex = pathname.findIndex((item) => item === 'upload')

  if (uploadIndex === -1) {
    return url
  }

  const imageSizeParameterIndex = uploadIndex + 1
  let imageFormatParameterIndex = uploadIndex + 2

  const maybeCloudinaryParams = pathname[imageSizeParameterIndex]
  const cloudinaryParams = getCloudinaryParams(options)

  if (isTransformParams(maybeCloudinaryParams)) {
    const productImagesIndex = pathname.findIndex((item) =>
      item.startsWith('product-images-')
    )

    if (cloudinaryParams) {
      pathname[productImagesIndex - 1] = cloudinaryParams
    }
    imageFormatParameterIndex = productImagesIndex
  } else if (isCloudinaryParams(maybeCloudinaryParams) && cloudinaryParams) {
    pathname[imageSizeParameterIndex] = cloudinaryParams
  } else if (cloudinaryParams) {
    pathname.splice(imageSizeParameterIndex, 0, cloudinaryParams)
  }

  pathname.splice(imageFormatParameterIndex, 0, `f_${ext}`)

  transformedURL.pathname = pathname.join('/')
  return transformedURL.toString()
}
