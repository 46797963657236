































import { ref, computed, useContext, watch } from '@nuxtjs/composition-api';
import { TranslateResult } from 'vue-i18n';
import { MAP_IDS } from '~/lib/constants';
import { ROUTES } from '~/lib/routes';
import { EOrderDetailsStatus, EDeliveryStatus, IOrderStatus } from '~/types/app/Order';
import useGoogleMapsPrintMarkers from '~/hooks/order-status/useGoogleMapsPrintMarkers';
const __sfc_main = {};
__sfc_main.props = {
  orderStatus: {
    type: (Object as () => IOrderStatus),
    required: true
  },
  hideUserCoordinates: {
    type: Boolean,
    default: false
  },
  hideOverlay: {
    type: Boolean,
    default: false
  },
  hasBorder: {
    type: Boolean,
    default: false
  },
  showHub: {
    type: Boolean,
    default: false
  },
  hasBottomSheet: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    i18n
  } = useContext();
  const map = ref<HTMLDivElement | null>(null);
  const homeRoute = ref<ROUTES>(ROUTES.HOME);
  const bubbleText = computed<string | TranslateResult>(() => {
    if ([EOrderDetailsStatus.ON_ROUTE, EOrderDetailsStatus.ARRIVED, EOrderDetailsStatus.DELIVERED].includes(props.orderStatus.state)) {
      return i18n.t('order_tracking_see_you_soon');
    }

    return props.orderStatus.is_stacked && props.orderStatus.state === EOrderDetailsStatus.RIDER_CLAIMED ? i18n.t('order_tracking_dropping_order_nearby') : '';
  });
  const riderIcon = computed<string>(() => {
    if (props.orderStatus.is_stacked && props.orderStatus.state === EOrderDetailsStatus.RIDER_CLAIMED) {
      return require('~/assets/icons/rider-pin-baloon-grey.svg');
    } else {
      return require('~/assets/icons/rider-pin-baloon-pink.svg');
    }
  });
  const isDelivered = computed<boolean>(() => {
    return ![EDeliveryStatus.ON_THE_WAY, EDeliveryStatus.DELIVERY_SOON].includes(props.orderStatus.delivery_time.status);
  });
  const markers = ref([{
    location: props.orderStatus.hub_coordinate,
    icon: require('~/assets/icons/hub-pin-pink-round.svg'),
    isVisible: props.showHub,
    printBubble: false
  }, {
    location: props.orderStatus.user_coordinate,
    icon: require('~/assets/icons/user-home-pin-baloon.svg'),
    isVisible: !props.hideUserCoordinates,
    printBubble: false
  }, {
    location: props.orderStatus.rider_coordinate,
    icon: riderIcon.value,
    isVisible: props.orderStatus.show_map && !isDelivered.value,
    printBubble: true,
    bubbleText: bubbleText.value
  }]);
  const {
    googleMapsApi,
    initMarkers,
    plottedMarkers,
    updateBounds
  } = useGoogleMapsPrintMarkers(map, markers.value, {
    mapId: MAP_IDS.deliveryMap
  });
  watch(() => props.orderStatus, ({
    state,
    show_map: showMap,
    is_stacked: isStacked
  }, {
    state: previousState,
    show_map: previousShowMap,
    is_stacked: previousIsStacked
  }) => {
    if (!googleMapsApi.value || props.hideUserCoordinates) return;

    if (riderCoordinatesReady.value) {
      if (state !== previousState || showMap !== previousShowMap || isStacked !== previousIsStacked) {
        drawRiderMarker();
      } else {
        plottedMarkers.value[plottedMarkers.value.length - 1].position = {
          lat: props.orderStatus.rider_coordinate.latitude,
          lng: props.orderStatus.rider_coordinate.longitude
        };
        updateBounds();
      }
    }
  });

  function removeRiderMarker(index: number) {
    plottedMarkers.value[index].map = null;
    plottedMarkers.value.splice(index);
  }

  function drawRiderMarker() {
    const expectedMarkers = props.showHub ? 3 : 2;

    if (plottedMarkers.value.length === expectedMarkers) {
      const index = expectedMarkers - 1;
      removeRiderMarker(index);
    }

    initMarkers([{
      icon: riderIcon.value,
      location: props.orderStatus.rider_coordinate,
      isVisible: props.orderStatus.show_map && !isDelivered.value,
      printBubble: true,
      bubbleText: bubbleText.value
    }]);
  }

  const riderCoordinatesReady = computed<boolean>(() => {
    return props.orderStatus.rider_coordinate.latitude !== 0 && props.orderStatus.rider_coordinate.longitude !== 0;
  });
  return {
    EOrderDetailsStatus,
    map,
    homeRoute
  };
};

export default __sfc_main;
