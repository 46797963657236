/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import contentfulClient from '~/lib/contentfulClient'
import getProductsBySku from '~/api/services/get-products-by-sku'
import { ISkuMergeMappingFields } from '~/types/generated/contentful'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'

type Mapping = {
  SKU: string
  merged_SKU: string
}

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  hubSlug: string
  sku: string
}

export default async function getMergedSkuSlug({
  client,
  logger,
  locale,
  hubSlug,
  sku,
}: IParameters): Promise<string | void> {
  try {
    const resp = await contentfulClient('').getEntries<ISkuMergeMappingFields>({
      content_type: 'skuMergeMapping',
      'fields.title': 'sku-merge-mapping',
    })

    const newSku = resp.items[0]?.fields?.skus?.find((element: Mapping) => {
      return element.SKU.toString() === sku.toString()
    })

    if (newSku) {
      const response = await getProductsBySku({
        client,
        logger,
        locale,
        hubSlug,
        skus: [`${newSku.merged_SKU}`],
      })

      if (response && response[0]) {
        const newSlug = `${response[0].slug}-${response[0].sku}`

        logger({
          level: ELogLevels.INFO,
          msg: 'Found new slug for merged SKU',
          'http.method': EHTTPMethods.GET,
          'http.status_code': EStatus.SUCCESSFUL,
          logger: 'API Services',
          func: 'getMergedSkuSlug',
        })

        return newSlug
      }
    }
  } catch (error) {
    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: Can not get skuMergeMapping.',
      'http.method': EHTTPMethods.GET,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: 'API Services',
      func: 'getMergedSkuSlug',
    })
  }
}
