




import { computed } from '@nuxtjs/composition-api';
import IErrorPageProps from '~/types/app/ErrorPage';
import ErrorLayout from '~/components/layout/ErrorLayout/ErrorLayout.vue';
const __sfc_main = {
  name: 'ErrorPageLayout'
};
__sfc_main.props = {
  error: {
    type: (Object as () => IErrorPageProps),
    default: null
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const statusCode = computed<number>(() => {
    return props.error?.statusCode || 404;
  });
  const errorMessage = computed<string>(() => {
    return props.error?.message || '';
  });
  return {
    statusCode,
    errorMessage
  };
};

__sfc_main.components = Object.assign({
  ErrorLayout
}, __sfc_main.components);
export default __sfc_main;
