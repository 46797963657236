
















































import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { ESchedulingOptionType } from '~/types/app/PlannedOrders';
import { IPlannedOrdersState } from '~/store/plannedOrders';
import { theme } from '~/tailwind.config';
const __sfc_main = {};
__sfc_main.props = {
  onCheckoutState: {
    type: (Object as () => IPlannedOrdersState['onCheckout']),
    default: () => ({})
  },
  showSpinnerOnDelivery: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const flinkPink = theme.colors.primary[500];
  const emit = __ctx.emit;
  const iconMap = {
    [ESchedulingOptionType.EXPRESS]: 'star',
    [ESchedulingOptionType.NO_RUSH]: 'bag-flink',
    [ESchedulingOptionType.PRIO]: 'star',
    [ESchedulingOptionType.ASAP]: 'bag-flink',
    [ESchedulingOptionType.PLANNED]: 'clock'
  };

  function emitSelection(type: ESchedulingOptionType, id: string) {
    if (type === ESchedulingOptionType.PLANNED) {
      emit('open-timeslot-selection');
    } else {
      emit('select-option', {
        token: id,
        type
      });
    }
  }

  return {
    ESchedulingOptionType,
    flinkPink,
    emit,
    iconMap,
    emitSelection
  };
};

__sfc_main.components = Object.assign({
  PulseLoader
}, __sfc_main.components);
export default __sfc_main;
