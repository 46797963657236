import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import { LoggerFunction } from '~/types/app/Logger'
import { AddressListItem, IAddressBookApiResponse } from '~/types/app/Location'

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  address: Omit<AddressListItem, 'id'>
}

export default async function createAddress({
  client,
  logger,
  locale,
  address,
}: IParameters): Promise<IAddressBookApiResponse | null> {
  const headers = {
    locale,
  }
  const url = '/address'

  try {
    const response = await post<IAddressBookApiResponse>(
      client,
      logger,
      url,
      headers,
      address
    )
    return response.data
  } catch (error) {}

  return null
}
