import { get } from '~/api/controllers/get'
import { ISubscriptionPaymentMethodsResponse } from '~/types/app/Subscription'
import { LoggerFunction } from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
}

export default async function getSubscriptionPaymentMethods({
  client,
  logger,
  locale,
  hubSlug,
}: IParameters): Promise<ISubscriptionPaymentMethodsResponse | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = 'payment-methods'

  try {
    const response = await get<ISubscriptionPaymentMethodsResponse>(
      client,
      logger,
      url,
      headers
    )
    return response.data
  } catch (error) {
    return undefined
  }
}
