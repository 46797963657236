import { render, staticRenderFns } from "./MainFooter.vue?vue&type=template&id=40481215&scoped=true&"
import script from "./MainFooter.vue?vue&type=script&lang=ts&"
export * from "./MainFooter.vue?vue&type=script&lang=ts&"
import style0 from "./MainFooter.vue?vue&type=style&index=0&id=40481215&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40481215",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImage: require('/app/build/components/ContentfulImage/ContentfulImage.vue').default,StoreBadge: require('/app/build/components/StoreBadge/StoreBadge.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default,Button: require('/app/build/components/Button/Button.vue').default,LocalesSwitchModal: require('/app/build/components/LocalesSwitchModal/LocalesSwitchModal.vue').default})
