















import { useStore } from '@nuxtjs/composition-api';
import IRootState from '~/types/app/State';
import { ROUTES } from '~/lib/routes';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.setup = (__props, __ctx) => {
  const searchRoute = ROUTES.SEARCH;
  const store = useStore<IRootState>();
  const emit = __ctx.emit;

  const onLabelClick = () => {
    emit('onOverlayClose');
  };

  return {
    searchRoute,
    store,
    onLabelClick
  };
};

export default __sfc_main;
