



































































































import { ref, computed, useRoute, useStore, useRouter, useContext } from '@nuxtjs/composition-api';
import { EComponentName, EOriginScreen, EProductPlacement, EScreenName, ETrackingType, getScreenName } from '~/lib/segment';
import { FeatureFlags } from '~/types/app/experiments';
import { ProductViewedTrackingDetails, CollectionPageReferrerType } from '~/store/catalog';
import { ROUTES } from '~/lib/routes';
import IRootState from '~/types/app/State';
import IProduct, { VARIANT_LABEL_AND_BOTTOM_SHEET } from '~/types/app/Product';
import type { ICampaign } from '~/types/app/Promotions';
import { PROMOTION_LABEL_VARIANTS } from '~/lib/constants';
import QuantityControl from '~/components/QuantityControl/QuantityControl.vue';
import useCardSizeFromExperiment from '~/hooks/useCardSizeFromExperiment';
const __sfc_main = {};
__sfc_main.props = {
  product: {
    type: (Object as () => IProduct),
    required: true
  },
  categoryName: {
    type: String,
    default: ''
  },
  categoryId: {
    type: String,
    default: ''
  },
  subCategoryId: {
    type: String,
    default: ''
  },
  subCategoryName: {
    type: String,
    default: ''
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    required: true
  },
  listName: {
    type: String,
    default: ''
  },
  searchQueryId: {
    type: String,
    default: ''
  },
  swimlanePositionFromTop: {
    type: Number,
    default: 0
  },
  positionInGroup: {
    type: Number,
    default: 0
  },
  isRecipes: {
    type: Boolean,
    default: false
  },
  oosProduct: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const store = useStore<IRootState>();
  const router = useRouter();
  const route = useRoute().value;
  const {
    $segmentEvent,
    localePath
  } = useContext();
  const emit = __ctx.emit;
  const size = useCardSizeFromExperiment();
  const {
    slug,
    sku,
    tags,
    context
  } = props.product;
  const productPath = localePath({
    name: ROUTES.PRODUCT,
    params: {
      slug: `${slug}-${sku}`
    }
  });
  const applicablePromotion = computed((): ICampaign | undefined => props.product.promotions?.campaigns?.[0]);
  const showSubstitution = ref(false);
  const promoTrackingProperties = computed((): Pick<ProductViewedTrackingDetails, 'prismCampaignName' | 'prismCampaignId'> => applicablePromotion.value ? {
    prismCampaignId: applicablePromotion.value.id,
    prismCampaignName: applicablePromotion.value.label
  } : {});

  function handleProductClick(): void {
    const {
      listName,
      productPlacement,
      swimlanePositionFromTop,
      positionInGroup,
      searchQueryId
    } = props;
    let productViewedTrackingInfo: ProductViewedTrackingDetails = {
      listName,
      productPlacement,
      listPosition: swimlanePositionFromTop,
      productPosition: positionInGroup,
      productContext: context,
      eventOrigin: ((getScreenName(route) as unknown) as EOriginScreen),
      searchQueryId,
      ...promoTrackingProperties.value
    };
    const {
      collectionPageReferrer
    } = store.state.catalog || {};

    if (collectionPageReferrer?.type === CollectionPageReferrerType.marketingBanner) {
      productViewedTrackingInfo = {
        productPlacement,
        categoryId: collectionPageReferrer.id,
        categoryName: collectionPageReferrer.title,
        listName: (collectionPageReferrer.trackingListName as EProductPlacement),
        eventOrigin: EOriginScreen.marketingBanner,
        ...promoTrackingProperties.value
      };
    }

    if (collectionPageReferrer?.type === CollectionPageReferrerType.collectionCard) {
      productViewedTrackingInfo = {
        productPlacement: EProductPlacement.collectionCard,
        categoryId: collectionPageReferrer.id,
        categoryName: collectionPageReferrer.title,
        listName: (collectionPageReferrer.trackingListName as EProductPlacement),
        eventOrigin: EOriginScreen.collectionCardDetails,
        ...promoTrackingProperties.value
      };
    }

    store.dispatch('catalog/setProductViewedTrackingDetails', productViewedTrackingInfo);

    if (props.isRecipes) {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.click,
        screenName: EScreenName.recipe,
        componentContent: route.path,
        componentName: EComponentName.productRecommendation,
        componentValue: sku
      });
    }

    router.push({
      path: productPath
    });
  }

  const trackingDetails = computed(() => {
    const {
      listName,
      productPlacement,
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName
    } = props;
    const details = {
      listName,
      productPlacement,
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName,
      screenName: '',
      eventOrigin: '',
      ...promoTrackingProperties.value
    };

    if (store.state.catalog?.collectionPageReferrer?.type === CollectionPageReferrerType.marketingBanner) {
      const {
        collectionPageReferrer
      } = store.state.catalog;
      return { ...details,
        listName: collectionPageReferrer.trackingListName,
        productPlacement: props.productPlacement,
        eventOrigin: EOriginScreen.marketingBanner
      };
    }

    if (props.productPlacement === EProductPlacement.oosSubstitutes) {
      details.eventOrigin = props.oosProduct;
    }

    if (store.state.catalog?.collectionPageReferrer?.type === CollectionPageReferrerType.collectionCard) {
      details.screenName = EScreenName.collectionCardDetails;
      details.productPlacement = EProductPlacement.collectionCard;
      details.categoryId = store.state.catalog.collectionPageReferrer.id;
      details.categoryName = store.state.catalog.collectionPageReferrer.title;
      details.listName = store.state.catalog.collectionPageReferrer.trackingListName;
    }

    return details;
  });

  function add(): void {
    if (props.isRecipes) {
      emit('add');
    } else {
      const {
        searchQueryId,
        positionInGroup,
        swimlanePositionFromTop,
        product
      } = props;
      const oosExperimentVariant = product.oosExperimentVariant;

      if (oosExperimentVariant) {
        showSubstitution.value = true;
        $segmentEvent.TRACKING({
          trackingType: ETrackingType.click,
          componentContent: sku,
          componentName: oosExperimentVariant === VARIANT_LABEL_AND_BOTTOM_SHEET ? 'oos_swap_cta' : 'oos_atc_cta'
        });
      } else {
        store.dispatch('addToCart', {
          sku,
          tags,
          searchQueryId,
          productPosition: positionInGroup,
          listPosition: swimlanePositionFromTop,
          ...trackingDetails.value
        });
      }
    }
  }

  function remove(): void {
    props.isRecipes ? emit('remove') : store.dispatch('removeFromCart', {
      sku,
      listPosition: props.swimlanePositionFromTop,
      productPosition: props.positionInGroup,
      ...trackingDetails.value
    });
  }

  return {
    FeatureFlags,
    VARIANT_LABEL_AND_BOTTOM_SHEET,
    PROMOTION_LABEL_VARIANTS,
    size,
    productPath,
    applicablePromotion,
    showSubstitution,
    handleProductClick,
    add,
    remove
  };
};

__sfc_main.components = Object.assign({
  QuantityControl
}, __sfc_main.components);
export default __sfc_main;
