import { Plugin } from '@nuxt/types'
import { NuxtI18nInstance } from '@nuxtjs/i18n/types'
import IPrice, { IPricePerUnit } from '~/types/app/Price'

export function formatCurrency(i18n: NuxtI18nInstance) {
  return function ({ amount, currency, decimals }: IPrice): string {
    return new Intl.NumberFormat(i18n.locale, {
      style: 'currency',
      currency: currency || 'EUR',
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    }).format(amount)
  }
}

export function formatPricePerUnit(
  i18n: NuxtI18nInstance
): (p: IPricePerUnit) => string {
  const formatCurrencyInstance = formatCurrency(i18n)
  return function ({ price, unit }: IPricePerUnit): string {
    return `${formatCurrencyInstance(price)} / ${unit.value}${unit.unit}`
  }
}

const globalHelpers: Plugin = ({ i18n }, inject) => {
  inject('formatCurrency', formatCurrency(i18n))
  inject('formatPricePerUnit', formatPricePerUnit(i18n))
}

export default globalHelpers
