import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { difference } from 'lodash-es'
import { ROUTES } from '~/lib/routes'
import { getRouteName } from '~/lib/helpers'
import { Location } from 'vue-router'
import IAppState from '~/types/app/State'
import {
  IAddressBookAddress,
  IAddressBookApiResponse,
} from '~/types/app/Location'
import { getAddressList } from '~/api/services/get-address-list'
import { createAddress } from '~/api/services/create-address'
import { updateAddress } from '~/api/services/update-address'
import { deleteAddress } from '~/api/services/delete-address'
import { getUserProfile } from '~/api/services/account/get-user-profile'
import type { HelpcenterReportItem } from '~/api/services/create-helpcenter-report'
import {
  areAddressBookAddressesEqual,
  listAddressToShippingData,
  shippingDataToListAddress,
} from '~/lib/addresses-helpers'

import { FeatureFlags } from '~/types/app/experiments'
import { IVoucherWallet } from '~/types/app/VoucherWallet'
import { getVoucherWallet } from '~/api/services/get-voucher-wallet'
import {
  SET_USER,
  SET_USER_DETAILS,
  LOG_OUT,
  SET_AUTH_REDIRECT_TARGET,
  SET_FIRST_AUTH_RAN,
  SET_ADDRESSES,
  SET_ADDRESS_BOOK_VERSION,
  SET_SHOW_ADDRESS_BOOK,
  SET_EMAIL,
  SET_SEGMENT_ANONYMOUS_USER_ID,
  SET_VOUCHER_WALLET,
  ADD_REPORT_ITEM,
  RESET_REPORT,
  SET_DEFAULT_USER_ID,
  SET_ACCESS_TOKEN,
} from './mutation-types'

const redirectRoutes = [ROUTES.LOG_IN, ROUTES.GET_STARTED, ROUTES.SIGN_UP]

interface IReferralProgram {
  code: string
  advocate: string
  friend: string
  mov: string
}

export type FireUser = {
  email: string
  emailVerified: boolean
  displayName: string
  phoneNumber: string
  photoURL: string
  uid: string
  getIdToken: () => Promise<string>
}

export interface User {
  uid?: string
  displayName: string
  firstName?: string
  lastName?: string
  phone?: string
  email: string
  referralInfo?: IReferralProgram
}

export interface IAccountState {
  user: User | null
  accessToken?: string
  addresses: IAddressBookAddress[]
  addressBookVersion?: number
  isAddressBookShown: boolean
  authRedirectTarget: Location
  firstAuthRan: boolean
  segmentAnonymousUserId?: string
  voucherWallet: IVoucherWallet[]
  helpcenterReport: HelpcenterReportItem[]
  defaultUserId?: string
}

export const state = (): IAccountState => ({
  user: null,
  addresses: [],
  addressBookVersion: undefined,
  isAddressBookShown: false,
  authRedirectTarget: {
    name: ROUTES.HOME,
  },
  firstAuthRan: false,
  segmentAnonymousUserId: undefined,
  voucherWallet: [],
  helpcenterReport: [],
})

export const getters: GetterTree<IAccountState, IAppState> = {
  user(state: IAccountState): User | null {
    return state.user
  },

  isHubUser(_, __, ___, rootGetters): boolean {
    return rootGetters['experiments/isFeatureEnabled'](
      FeatureFlags.WEB_HUB_USER
    )
  },

  isUserAuthenticated(state: IAccountState): boolean {
    return !!state.user
  },

  userHasSavedAddresses(state: IAccountState, getters): boolean {
    return getters.isUserAuthenticated && state.addresses.length > 0
  },

  defaultAddress(state: IAccountState): IAddressBookAddress | undefined {
    // eslint-disable-next-line camelcase
    return state.addresses.find(({ is_default }) => is_default)
  },

  orderedAddresses(state: IAccountState, getters): IAddressBookAddress[] {
    const defaultAddress = getters.defaultAddress

    const removeDefaultOrderedByTime = difference(state.addresses, [
      defaultAddress,
    ]).reverse() as IAddressBookAddress[]

    if (defaultAddress) {
      return [defaultAddress].concat(removeDefaultOrderedByTime)
    }

    return removeDefaultOrderedByTime
  },

  nextAddress: (_, getters) => (addressId: IAddressBookAddress['id']) => {
    const orderedAddresses = getters.orderedAddresses as IAddressBookAddress[]
    const idx = orderedAddresses.findIndex(
      (address: IAddressBookAddress) => addressId === address.id
    )

    if (idx !== -1 && idx + 1 < orderedAddresses.length) {
      return orderedAddresses[idx + 1]
    }
    return undefined
  },
}

export const mutations: MutationTree<IAccountState> = {
  [SET_USER](state, user) {
    state.user = user
  },
  [SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken
  },
  [SET_USER_DETAILS](state, user) {
    state.user = {
      ...state.user,
      ...user,
    }
  },
  [LOG_OUT](state) {
    state.user = null
    state.accessToken = undefined
  },
  [SET_ADDRESSES](state, addresses: IAddressBookAddress[]) {
    state.addresses = addresses
  },
  [SET_ADDRESS_BOOK_VERSION](state, version: number) {
    state.addressBookVersion = version
  },
  [SET_SHOW_ADDRESS_BOOK](state, active: boolean) {
    state.isAddressBookShown = active
  },
  [SET_AUTH_REDIRECT_TARGET](state, authRedirectTarget) {
    state.authRedirectTarget = authRedirectTarget
  },
  [SET_FIRST_AUTH_RAN](state) {
    state.firstAuthRan = true
  },
  [SET_SEGMENT_ANONYMOUS_USER_ID](state, id) {
    state.segmentAnonymousUserId = id
  },
  [SET_VOUCHER_WALLET](state, vouchers: IVoucherWallet[]) {
    state.voucherWallet = vouchers
  },
  [ADD_REPORT_ITEM](state, item: HelpcenterReportItem) {
    const itemExists = state.helpcenterReport.findIndex(
      (savedItem) => savedItem.sku === item.sku
    )

    if (itemExists > -1) {
      state.helpcenterReport[itemExists] = item
    } else {
      state.helpcenterReport.push(item)
    }
  },
  [RESET_REPORT](state) {
    state.helpcenterReport = []
  },

  [SET_DEFAULT_USER_ID](state, defaultUserId) {
    state.defaultUserId = defaultUserId
  },
}

export const actions: ActionTree<IAccountState, IAppState> = {
  setFirstAuthRan({ commit }) {
    commit(SET_FIRST_AUTH_RAN)
  },
  async onAuthStateChanged({ commit, dispatch, state }, { authUser }) {
    if (!authUser) {
      commit(LOG_OUT)
      commit(SET_EMAIL, undefined, { root: true })

      dispatch('resetCart', null, { root: true })
      dispatch('subscription/clear', null, { root: true })

      if (state.firstAuthRan) {
        this.app.router?.push(this.localePath(ROUTES.HOME))
      }
      return
    }

    await dispatch('getUserProfile')
    await dispatch('setAccessToken', await authUser.getIdToken())
    await dispatch('getAddressBookAddresses')
    await dispatch('syncCurrentAddressWithAddressBook')

    dispatch('updateUserDetails', { authUser }, { root: true })
    // Only available in the browser for now, maybe it should also be on server
    // but then we need to create a server version of the segment library
    this.$segmentIdentify?.()

    let currentRoute
    if (this.app.router?.currentRoute) {
      currentRoute = getRouteName(this.app.router?.currentRoute) as ROUTES
    }

    if (currentRoute && redirectRoutes.includes(currentRoute)) {
      this.app.router?.push({
        path: this.localePath(state.authRedirectTarget),
        ...(state.authRedirectTarget.params && {
          params: state.authRedirectTarget.params,
        }),
        ...(state.authRedirectTarget.query && {
          query: state.authRedirectTarget.query,
        }),
      })
      dispatch('setAuthRedirectTarget', {
        name: ROUTES.HOME,
      })
    }
  },

  setUser({ commit }, user) {
    commit(SET_USER, user)
  },

  setAccessToken({ commit }, accessToken) {
    commit(SET_ACCESS_TOKEN, accessToken)
  },

  setUserDetails({ commit }, user) {
    commit(SET_USER_DETAILS, user)
  },

  setCookieData({ commit }, defaultUserId) {
    commit(SET_DEFAULT_USER_ID, defaultUserId)
  },

  async getAddressBookAddresses({ getters, dispatch }) {
    if (!getters.isUserAuthenticated) {
      return
    }

    const response = await getAddressList({
      client: this.$apiAddress,
      logger: this.$logger,
      locale: this.$i18n.locale,
    })

    dispatch('updateAddresses', response)
  },

  updateAddresses(
    { commit, dispatch, getters, rootState },
    response: IAddressBookApiResponse | null
  ) {
    if (!response) {
      return
    }

    const { version, address_list: addressList } = response
    commit(SET_ADDRESSES, addressList)
    commit(SET_ADDRESS_BOOK_VERSION, version)

    dispatch(
      'updateShippingAddress',
      { ...rootState.shippingAddress, id: getters.defaultAddress?.id },
      { root: true }
    )
  },

  async getUserProfile({ dispatch }) {
    const response = await getUserProfile(
      { client: this.$apiUsers },
      this.$logger
    )

    dispatch('setUserDetails', response)
  },

  async syncCurrentAddressWithAddressBook({ dispatch, getters, rootGetters }) {
    if (rootGetters.getIsOnboardSuccess) {
      await dispatch('addCurrentAddress')
    } else if (getters.isUserAuthenticated) {
      await dispatch('syncShippingData', getters.defaultAddress)
    }
  },

  async addCurrentAddress({ dispatch, rootState, state }): Promise<void> {
    const { shippingAddress, deliveryCoordinates } = rootState

    if (!shippingAddress || !deliveryCoordinates) {
      return
    }

    const newEntry = shippingDataToListAddress({
      address: shippingAddress,
      coordinates: deliveryCoordinates,
      isDefault: true,
    })

    for (const existingEntry of state.addresses) {
      if (areAddressBookAddressesEqual(newEntry, existingEntry)) {
        dispatch('setDefaultAddress', existingEntry.id)
        return
      }
    }

    await dispatch('addAddress', newEntry)
  },

  async syncShippingData(
    { dispatch },
    addressBookAddress: IAddressBookAddress | undefined
  ) {
    if (!addressBookAddress) {
      return
    }

    const { address: shippingAddress, coordinates } =
      listAddressToShippingData(addressBookAddress)
    await dispatch('updateShippingAddress', shippingAddress, { root: true })
    await dispatch('updateDeliveryCoordinates', coordinates, { root: true })
    await dispatch('updateIsHubUpdateNeeded', true, { root: true })
  },

  async addAddress(
    { getters, dispatch },
    newAddress: Omit<IAddressBookAddress, 'id'>
  ) {
    if (!getters.isUserAuthenticated) {
      return
    }

    const response = await createAddress({
      client: this.$apiAddress,
      logger: this.$logger,
      locale: this.$i18n.locale,
      address: newAddress,
    })

    await dispatch('updateAddresses', response)
  },

  async editAddress({ dispatch }, address: IAddressBookAddress) {
    if (!getters.isUserAuthenticated) {
      return
    }

    const response = await updateAddress({
      client: this.$apiAddress,
      logger: this.$logger,
      locale: this.$i18n.locale,
      address,
    })

    dispatch('updateAddresses', response)
  },

  async deleteAddress(
    { getters, dispatch },
    addressId: IAddressBookAddress['id']
  ) {
    if (!getters.isUserAuthenticated) {
      return
    }

    const response = await deleteAddress({
      client: this.$apiAddress,
      logger: this.$logger,
      locale: this.$i18n.locale,
      addressId,
    })

    dispatch('updateAddresses', response)
  },

  async setDefaultAddress(
    { getters, state, dispatch },
    addressId: IAddressBookAddress['id']
  ) {
    const address = state.addresses.find(({ id }) => id === addressId)
    if (
      !getters.isUserAuthenticated ||
      !address ||
      getters.defaultAddress?.id === address.id
    ) {
      return
    }

    const response = await updateAddress({
      client: this.$apiAddress,
      logger: this.$logger,
      locale: this.$i18n.locale,
      address: { ...address, is_default: true },
    })

    dispatch('updateAddresses', response)
  },

  toggleAddressBook({ commit }, active: boolean) {
    commit(SET_SHOW_ADDRESS_BOOK, active)
  },

  setAuthRedirectTarget({ commit }, authRedirectTarget) {
    commit(SET_AUTH_REDIRECT_TARGET, authRedirectTarget)
  },
  async setVoucherWallet({ commit }, cartId: string) {
    const vouchers = await getVoucherWallet(
      {
        client: this.$apiVouchersWallet,
        locale: this.$i18n.locale,
        cartId,
      },
      this.$logger
    )

    commit(SET_VOUCHER_WALLET, vouchers)
  },
}
