import { get } from '~/api/controllers/get'
import ICart, { ICartV3 } from '~/types/app/Cart'
import {
  EHTTPMethods,
  ELoggers,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'
import handleCartVersionTransformation from '~/api/transformers/transform-cart'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
  cartId: string
}

export default async function getCart({
  client,
  logger,
  locale,
  hubSlug,
  cartId,
}: IParameters): Promise<ICart | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  let cart: ICartV3 | undefined
  const url = `/cart/${cartId}`

  try {
    const response = await get<ICartV3>(client, logger, url, headers)
    cart = response.data
  } catch (error) {}

  logger({
    level: ELogLevels.INFO,
    msg: 'API Success: getCart successful.',
    logger: ELoggers.CART,
    func: 'getCart',
    'http.method': EHTTPMethods.GET,
    'http.url': url,
    'http.status_code': EStatus.SUCCESSFUL,
    slug: 'checkout.api.get-cart.success',
    forceSubmit: true,
  })

  return handleCartVersionTransformation(client.version, cart)
}
