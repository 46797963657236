import { put } from '~/api/controllers/put'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'
import ICartLine from '~/types/app/CartLine'
import {
  ECartErrorCode,
  PrepareCartForCheckoutResponse,
} from '~/types/app/Cart'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
  cartId: string
  cartLines: ICartLine[]
}

interface IBody {
  lines: ICartLine[]
}

export async function prepareCartForCheckout({
  client,
  logger,
  locale,
  hubSlug,
  cartId,
  cartLines,
}: IParameters): Promise<PrepareCartForCheckoutResponse | undefined> {
  const url = `/cart/${cartId}/prepare-cart`
  const headers = { locale, 'hub-slug': hubSlug }
  const body: IBody = { lines: cartLines }

  try {
    const response = await put<PrepareCartForCheckoutResponse>(
      client,
      logger,
      url,
      headers,
      body
    )

    logger({
      level: ELogLevels.INFO,
      msg: 'API Success: prepareCart successful.',
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.CART,
      func: 'prepareCartForCheckout',
      forceSubmit: true,
    })

    return response.data as PrepareCartForCheckoutResponse
  } catch (error) {
    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: prepareCart failed.',
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: ELoggers.CART,
      func: 'prepareCartForCheckout',
      forceSubmit: false,
    })

    return {
      status: 'error',
      reason: ECartErrorCode.INTERNAL_SERVER_ERROR,
    }
  }
}
