

































import { ref, onMounted } from '@nuxtjs/composition-api';
import useModel from '~/hooks/useModel';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({
  model: {
    prop: 'initialSearchText',
    event: 'input'
  }
});

__sfc_main.props = {
  initialSearchText: {
    type: String,
    default: ''
  },
  focusGrow: {
    type: Boolean,
    default: true
  },
  autofocus: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const searchText = useModel(props, 'initialSearchText', 'input');
  const input = ref<HTMLInputElement | null>(null);
  const preventBlur = ref(false);
  onMounted(() => {
    if (props.autofocus) {
      input.value?.focus();
    }
  });

  const handleInputClear = (): void => {
    searchText.value = '';
    preventBlur.value = true;
  };

  const handleControlClick = (evt: MouseEvent): void => {
    if (preventBlur.value) {
      evt.stopImmediatePropagation();
      evt.preventDefault();
    }

    preventBlur.value = false;
  };

  const handleFocus = (e: MouseEvent): void => {
    emit('focus');
    const searchQuery = (searchText.value as string);
    const target = (e.target as HTMLInputElement);

    if (searchQuery) {
      // set cursor to the end of the input in case the searchbar has a search query
      target.setSelectionRange?.(searchQuery.length, searchQuery.length);
    }
  };

  const handleBlur = () => {
    emit('blur');
  };

  return {
    emit,
    searchText,
    input,
    handleInputClear,
    handleControlClick,
    handleFocus,
    handleBlur
  };
};

export default __sfc_main;
