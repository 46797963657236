/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { ICoordinates } from '~/types/app/Location'
import { IHub } from '~/types/app/Hub'
import { LoggerFunction } from '~/types/app/Logger'

export default async function getHub(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  location: ICoordinates
): Promise<IHub | undefined> {
  const headers = {
    'Supports-Planned-Order': 'true',
  }

  let hub: IHub
  const { latitude, longitude } = location

  try {
    const response = await get<IHub>(
      client,
      logger,
      `/locations/hub?lat=${latitude}&long=${longitude}`,
      headers
    )
    hub = response.data
    return hub
  } catch (error) {
    return undefined
  }
}
