




import {
  useMeta,
  useRoute,
  useContext,
  defineComponent,
  getCurrentInstance,
} from '@nuxtjs/composition-api'
import { MainLayout } from '~/components/main'
import { MetaInfo } from 'vue-meta'

export default defineComponent({
  name: 'LandingPageLayout',
  components: { MainLayout },
  setup() {
    const PAGES_WITHOUT_HREFLANG = ['city-slug']
    const HREFLANG_TAG_RELATION = 'alternate'
    const OPENGRAPH_LOCALE_ALTERNATE = 'og:locale:alternate'
    const route = useRoute().value
    const vm = getCurrentInstance()?.proxy
    const { $config } = useContext()

    useMeta(() => {
      const isExcluded = PAGES_WITHOUT_HREFLANG.some((r) =>
        route.name?.startsWith(r)
      )

      let head = vm?.$nuxtI18nHead({ addSeoAttributes: true }) as MetaInfo

      if (isExcluded && head.link && head.meta) {
        head = {
          ...head,
          link: head.link.filter((link) => link.rel !== HREFLANG_TAG_RELATION),
          meta: head.meta.filter(
            (meta) => meta.property !== OPENGRAPH_LOCALE_ALTERNATE
          ),
        }
      }

      head.meta?.push({
        name: 'apple-itunes-app',
        content: `app-id=${$config.appStoreID}`,
      })

      return head
    })
  },
  head: {},
})
