/* eslint-disable camelcase */
import { pick, round } from 'lodash-es'
import { Route } from 'vue-router'
import { Currency } from '~/types/app/Price'
import IProduct from '~/types/app/Product'
import { IHub } from '~/types/app/Hub'
import { IAddress, ICoordinates } from '~/types/app/Location'
import { ROUTES } from '~/lib/routes'
import {
  getRouteName,
  getCountryFromLocale,
  getPrefixedMutation,
  incrementPatchVersion,
  getUserFlinkUID,
} from '~/lib/helpers'
import { EPaymentErrorCode } from '~/components/PaymentForm/payment-form-types'
import { KeysInSnakeCase } from '~/types/generic/Mapped'
import { EOrderDetailsStatus } from '~/types/app/Order'
import { ELocationOnboardingState } from '~/types/app/State'
import {
  ESchedulingState,
  ESchedulingOptionType,
} from '~/types/app/PlannedOrders'
import {
  ACCOUNT_PREFIX,
  SET_SEGMENT_ANONYMOUS_USER_ID,
} from '~/store/mutation-types'
import { ICartFee, EPaymentStatus } from '~/types/app/Cart'
import type { IAppliedCampaignRaw } from '~/types/app/Promotions'
import {
  EPrimeCancelationReason,
  ISubscriptionPlan,
  ISubscriptionPlanShortened,
} from '~/types/app/Subscription'

interface IDeviceDetails {
  width: number
  height: number
}

interface IMapClickedDetails {
  buttonValue: string
}

interface ISegmentUser {
  anonymousId: () => string
}

export enum ETrackingType {
  checkoutViewed = 'checkoutViewed',
  checkoutViewUpdated = 'checkoutViewUpdated',
  paymentStarted = 'paymentStarted',
  click = 'click',
  errorShown = 'errorShown',
  favouritesPaintedDoorClicked = 'favouritesPaintedDoorClicked',
  inAppMessageShown = 'inAppMessageShown',
  nativeUiPromptShown = 'nativeUiPromptShown',
  nativeUiResponseReceived = 'nativeUiResponseReceived',
  screenViewed = 'screenViewed',
  sectionViewed = 'sectionViewed',
  swipe = 'swipe',
  waitlistSignupSelected = 'waitlistSignupSelected',
  responseReceived = 'responseReceived',
  subscriptionCancelRequested = 'subscriptionCancelRequested',
  subscriptionPaymentStarted = 'subscriptionPaymentStarted',
  subscriptionCreated = 'subscriptionCreated',
  roktOfferEngagement = 'roktOfferEngagement',
  roktPositiveEngagement = 'roktPositiveEngagement',
  roktPlacementClosed = 'roktPlacementClosed',
  roktPlacementInteractive = 'roktPlacementInteractive',
  roktPlacementReady = 'roktPlacementReady',
  sponsoredPlacementClick = 'sponsoredPlacementClick',
  sponsoredPlacementImpression = 'sponsoredPlacementImpression',
}

export enum EScreenName {
  accountLogin = 'account_login',
  accountRegistration = 'account_registration',
  addressEdit = 'edit_address',
  addressEditEntrance = 'edit_entrance',
  addressRefinement = 'address_refinement',
  addressSearch = 'address_search',
  cart = 'cart',
  category = 'category',
  checkout = 'checkout',
  cityLanding = 'city-landing',
  deliveryTimeSelection = 'delivery_time_selection',
  favourites = 'favourites',
  home = 'home',
  helpcenterOrders = 'helpcenter_orders',
  helpcenterOrderItems = 'helpcenter_order_items',
  helpcenterItemReasons = 'helpcenter_item_reasons',
  helpcenterItemPhoto = 'helpcenter_item_photo',
  deals = 'deals',
  insideDeliveryArea = 'inside_delivery_area',
  login = 'log_in',
  orderCollect = 'order_collect',
  orderDetails = 'order_details',
  orderDetailsCancelled = 'order_details_cancelled',
  orderTracking = 'order_tracking',
  outsideDeliveryArea = 'outside_delivery_area',
  pdpScreen = 'pdpScreen',
  performancePage = 'performance-page',
  productDetail = 'product_detail',
  productSearch = 'product_search',
  profile = 'profile_overview',
  recipe = 'recipe',
  resetPassword = 'reset_password',
  smsVerification = 'sms_verification',
  voucher_details_page = 'voucher_details_page',
  voucher_wallet = 'voucher_wallet',
  collectionDetails = 'collection_detail',
  collectionCardDetails = 'collection_card_detail',
  marketingBannerDetails = 'marketing_banner_details',
  subscriptionLanding = 'subscription_landing',
  subscriptionPlans = 'subscription_plans',
  subscriptionConfirmation = 'subscription_confirmation',
  subscriptionManagement = 'subscription_management',
  subscriptionCancellation = 'subscription_cancellation',
  getStarted = 'get_started',
  processPayment = 'process_payment',
  substitution = 'oos_substitutes',
  postOrderFeedback = 'post_order_feedback',
}

export enum EComponentName {
  accountSignupErrorEmailExists = 'account_signup_error_email_exists',
  addressLine = 'address_line',
  addressSearchResult = 'address_search_result',
  browseAsGuestButton = 'browse_as_guest_button',
  categoryMenu = 'category_hamburger_menu',
  category = 'category',
  categories = 'categories',
  clickAndCollect = 'click_and_collect',
  confirmationButton = 'confirmation_button',
  confirmLocation = 'confirm_location',
  cta = 'cta',
  currentLocation = 'current_location',
  currentLocationAllowed = 'current_location_allowed',
  currentLocationNotAllowed = 'current_location_notallowed',
  deliveryOption = 'delivery_option',
  deleteAddressDisabled = 'delete_address_disabled',
  deleteAddressEnabled = 'delete_address_enabled',
  deleteButton = 'delete_button',
  dynamicDeliveryFeeInformation = 'dynamic_delivery_fee_information',
  emailAlreadyExists = 'email_already_exists',
  emailInvalid = 'email_invalid',
  entranceConfirmButton = 'entrance_confirm_button',
  exitButton = 'exit_button',
  feeBanner = 'fee_banner',
  feeInformationModal = 'fee_information_modal',
  forgetPassword = 'forget_password',
  generic = 'generic',
  genericError = 'generic_error',
  helpcenterItemCta = 'helpcenter_item_cta',
  highPdtPopup = 'high_pdt_popup',
  highPdtTooltip = 'high_pdt_tooltip',
  inviteFriends = 'invite_friends',
  joinWaitlistSuccess = 'join_waitlist_success',
  laterToday = 'later_today',
  locationPin = 'location_pin',
  locationPinExceededDistanceText = 'location_pin_exceeded_distance_text',
  markEntranceButton = 'mark_entrance_button',
  networkError = 'network_error',
  orderCancellationModal = 'order_cancellation_modal',
  orderCancellationRequestRejected = 'order_cancellation_request_rejected',
  orderIngredients = 'order_ingredients',
  pickTime = 'pick_a_time',
  pdtButton = 'pdt_button',
  productRecommendation = 'product_recommendation',
  recipeRecommendation = 'recipe_recommendation',
  recommendations = 'recommendations',
  saveButton = 'save_button',
  searchAddressLine = 'search_address_line',
  sendCode = 'send_code',
  sendInstructions = 'send_instructions',
  shippingMethod = 'shipping_method',
  signinFirebaseError = 'signin_firebase_error',
  storageFee = 'storage_fee',
  storageFeeInformation = 'storage_fee_information',
  swimlane = 'swimlane',
  userProfile = 'user_profile',
  verificationSuccess = 'verification_success',
  verify = 'verify',
  video = 'video',
  preorderOosPopup = 'preorder_oos_popup',
  flinkLogo = 'flink_logo',
  voucher_wallet_swimlane = 'voucher_wallet_swimlane',
  collection = 'collection',
  collectionCard = 'collection_card',
  marketingBanner = 'marketing_banner',
  subscriptionBanner = 'subscription_banner',
  subscriptionCta = 'subscription_cta',
  addAllItems = 'add_all_items',
  topDealsJustForYou = 'top_deals',
  priorityDelivery = 'priority_delivery',
  cartIssue = 'cart_issue',
}

export enum EOriginScreen {
  cartViewed = 'cartViewed',
  categoryViewed = 'categoryViewed',
  checkoutViewed = 'checkoutViewed',
  collectionCardDetails = 'collection_card_detail',
  customButton = 'custom_button',
  homeViewed = 'homeViewed',
  hubClosed = 'hub_closed',
  loginViewed = 'loginViewed',
  menu = 'menu',
  orderDetailsViewed = 'orderDetailsViewed',
  productDetailsViewed = 'productDetailsViewed',
  recipesViewed = 'recipesViewed',
  searchResultsViewed = 'searchResultsViewed',
  standardButton = 'standard_button',
  userProfile = 'user_profile',
  deepLink = 'deep_link',
  seeAll = 'see_all',
  seeAllProducts = 'see_all_products',
  getStarted = 'get_started',
  processPayment = 'process_payment',
  subscriptionLanding = 'subscription_landing',
  marketingBanner = 'marketing_banner',
}

export enum EComponentContent {
  activeAddressOption = 'active_address_option',
  addNewAddressButton = 'add_new_address_button',
  address = 'address',
  allow = 'allow',
  alternativeAddressOption = 'alternative_address_option',
  back = 'back',
  cancellationConfirmationButton = 'cancellation_confirmation_button',
  cancellationReasonRadiobutton = 'cancellation_reason_radiobutton',
  confirmation = 'confirmation',
  deleteButton = 'delete_button',
  deny = 'deny',
  editButton = 'edit_button',
  keepOrderButton = 'keep_order_button',
  locationPermission = 'location_permission',
  logout = 'logout',
  next = 'next',
  pause = 'pause',
  play = 'play',
  product = 'product',
  refreshCart = 'refresh cart',
  voucher_code = 'voucher_code',
  somethingWentWrong = 'something_went_wrong',
  itemUnavailable = 'item_unavailable',
}

export enum EComponentVariant {
  asapDelivery = 'asap_delivery',
  dynamicDeliveryFee = 'dynamic_delivery_fee',
  plannedOrder = 'planned_order',
  popup = 'popup',
  rafEligible = 'raf_eligible',
  rafNotEligible = 'raf_not_eligible',
  storageFee = 'storage_fee',
  outOfStockVariantItemMissing = 'item_missing',
}

export enum EProductPlacement {
  cart = 'cart',
  category = 'category',
  collection = 'collection',
  favourites = 'favourites',
  lastBought = 'last_bought',
  pdp = 'pdp',
  previousOrder = 'previous_orders',
  recommendation = 'recommendation',
  search = 'search',
  swimlane = 'swimlane',
  collectionCard = 'collection_card',
  marketingBannerDetails = 'marketing_banner',
  topDealsJustForYou = 'deals',
  oosSubstitutes = 'oos_substitutes',
}

enum ESegmentPaymentMethod {
  ApplePay = 'ApplePay',
  CreditCard = 'CreditCard',
  PayPal = 'PayPal',
  Unknown = 'Unknown',
}

export enum ETraceMoment {
  STARTED = 'loadTraceStarted',
  COMPLETED = 'loadTraceCompleted',
}

export enum ETraceName {
  LOAD_CHECKOUT = 'load_checkout',
}

interface IGenericEvent {
  trackingType: ETrackingType
  screenName?: string | EScreenName
  eventOrigin?: string | EOriginScreen
  componentName?: string | EComponentName
  componentContent?: string | number | EComponentContent | string[]
  componentVariant?: string | EComponentVariant | string[]
  componentPosition?: number
  componentValue?: string | number | string[]
  internalCampaign?: string
  internalSource?: string
  internalMedium?: string
  internalDeeplink?: string
  hubSlug?: string
  adDecisionId?: string
  placementId?: string
  placementTitle?: string
  productPlacement?: EProductPlacement
  categoryName?: string
  categoryId?: string
  entryTimestamp?: string
  exitTimestamp?: string
}

export type IGenericPayload = KeysInSnakeCase<
  Omit<IGenericEvent, 'trackingType'>
>

interface IAddressConfirmedDetails {
  hub?: IHub
  deliveryCoordinates?: ICoordinates
  shippingAddress?: IAddress
  deliveryETA?: number
}

interface IProductRemovedDetails {
  cartId?: string
  sku: string
  name: string
  price: number
  productPlacement: EProductPlacement
  listPosition?: number
  listName?: string
  productPosition?: number
  categoryId?: string
  category?: string
  subCategoryId?: string
  subCategoryName?: string
  hubCity?: string
  hubSlug?: string
  deliveryPostCode?: string
  originalPrice: number
  discountApplied: boolean
  screenName: EScreenName
  productContext?: string
  eventOrigin?: string | EOriginScreen
  prismCampaignId?: string
  prismCampaignName?: string
}

interface ISegmentCartProduct extends Pick<IProduct, 'sku' | 'quantity'> {
  price: number
}

interface IFbPixelCartProductData extends Pick<IProduct, 'quantity'> {
  id: string
  item_price: number
}

interface ISegmentCheckoutProduct extends Pick<IProduct, 'quantity'> {
  product_sku: string
  price: number
}

interface ICartViewed {
  cartId?: string
  lastUsedCartId?: string
  hub?: IHub
  postalCode?: string
  currency?: Currency
  cartProducts?: IProduct[]
  totalPrice?: number
  deliveryFee?: number
  shippingMethodId?: string
  appliedPromotions?: IAppliedCampaignRaw[]
  msgDisplayed?: string
}

interface ICancelOrderClicked {
  orderId: string
  screenName: string
}

interface ICheckoutDetails {
  hub?: IHub
  cartId?: string
  postalCode?: string
  totalPrice?: number
  deliveryFee?: number
  currency?: Currency
  cartProducts?: IProduct[]
}

interface ICheckoutFeeDetails {
  cartId: string
  cartProducts: IProduct[]
  deliveryFee: number
  storageFee: number
  discount: number
  hubCity: string
  hubSlug: string
  total: number
  subtotal: number
  deposit: number
  fees: ICartFee[]
  isTokenizedPaymentMethod: boolean
  paymentMethod: string | undefined
}

interface IDeliveryMethod {
  screenName: string
  componentName: string
  componentValue: string
}
interface IFirstOrderPlacedDetails {
  orderId: string
  orderDisplayNumber?: string
  voucherCode?: string
  voucherValue?: number
  postalCode?: string
  deliveryFee?: number
  deliveryETA?: number
  paymentMethod?: 'AdyenPaymentGeneric'
  hub?: IHub
  currency?: Currency
  cartProducts?: IProduct[]
  totalPrice?: number
  predictedUserCategory?: string
}

interface IHighValueFirstOrderPlacedDetails {
  orderDisplayNumber: string
  hub?: IHub
}

interface IOrderCompletedDetails {
  orderId: string
  orderDisplayNumber?: string
  voucherCode?: string
  hub?: IHub
  cartId?: string
  postalCode?: string
  deliveryFee?: number
  currency?: Currency
  cartProducts?: IProduct[]
  deliveryETA?: string
  discount?: number
  isClickAndCollect?: boolean
  shippingMethodId?: string
  anonymousId?: string
  hubSlug?: string
}

interface ICTAClicked {
  componentContent: string
  componentName: string
  componentValue: number
  screenName: string
}

interface IVoucherApplied {
  cartId?: string
  orderId?: string
  coupon?: string
  discount?: number
}

interface IVoucherFailed {
  voucherCode?: string
  errorMessage?: string
}

interface IVoucherRedemptionAttempted {
  voucher_code?: string
}

interface ICheckoutStep1 {
  step?: number
  subtotal?: number
  discount?: number
  fees?: ICartFee[]
  deposit?: number
  riderTip?: number
  total?: number
  isOrderingForSomeoneElse?: boolean
  cartId?: string
  paymentMethod?: string
  hubSlug?: IHub
  isClickAndCollect?: boolean
}

interface ICheckoutStep2To4 {
  trackingEventName?: string
  step?: number
  cartId?: string
  hubSlug?: IHub
  paymentMethod?: string
  isSavePayment?: boolean
  isExpressCheckout?: boolean
}

interface IPaymentStartedDetails {
  isSavePayment: boolean
  isExpressCheckout: boolean
  isOrderingForSomeoneElse: boolean
}

interface ICategorySelectedDetails {
  categoryId: string
  categoryName: string
  subCategoryId?: string
  subCategoryName?: string
}

interface ICategoryClicked {
  componentContent: string
  componentName: string
  componentValue: string
  screenName: string
}

interface IProductAddedDetails {
  cartId: string
  sku: string
  name: string
  price: number
  currency: string
  productPlacement: EProductPlacement
  listPosition?: number
  listName?: string
  productPosition?: number
  categoryId?: string
  category?: string
  subCategoryId?: string
  subCategoryName?: string
  hubCity: string
  hubSlug: string
  deliveryPostcode: string
  originalPrice: number
  discountApplied: boolean
  screenName: EScreenName
  productContext?: string
  searchQueryId?: string
  eventOrigin?: EOriginScreen
  prismCampaignId?: string
  prismCampaignName?: string
  isOutOfStock?: boolean
}

interface IProductDetailsViewed {
  eventOrigin?: EOriginScreen
  screenName: EScreenName
  listName?: string
  productSku: string
  productPlacement?: string
  listPosition?: number
  categoryId?: string
  categoryName?: string
  subCategoryId?: string
  subCategoryName?: string
  hubCity?: IHub['city']
  hubSlug?: IHub['slug']
  postalCode?: string
  productId: string
  productName: string
  productPrice: number
  productPosition?: number
  isOutOfStock: boolean
  searchQueryId?: string
  discountApplied: boolean
  originalPrice?: number
  productContext?: string
  prismCampaignId?: string
  prismCampaignName?: string
}

interface ICartChanged {
  checkoutId?: string
  deliveryPostcode?: string
  hub?: IHub
  revenue?: number
  shipping?: number
  discount?: number
  coupon?: string
  currency?: string
  cartProducts?: IProduct[]
}

interface IProductSearchDetails {
  searchQuery: string
  searchQueryId: string
  searchResults: IProduct[]
  searchLabelUsed?: boolean
}

export type ISegmentTraits = Record<string, unknown>

interface IPaymentFailedDetails {
  error: string
}

interface IAuthFailed {
  error: string
}

interface IRecipeDropdownClicked {
  screenName: string
  componentName: string
}

interface IRecipeVideoClicked {
  componentContent: string
  componentName: string
  componentValue: string
  screenName: string
}

interface IErrorInfo {
  componentName: string
  screenName: string
}

interface ISectionViewed {
  screenName: string
  componentName: string
  componentContent?: string
}

interface ISectionViewedOnCurrentScreen {
  componentName: string
  componentContent?: string
  componentVariant?: string
}

interface ICartUpdated {
  hub?: IHub
  deliveryPostcode?: string
  currency?: string
  subtotal?: number
  deliveryFee?: number
  cartId?: string
  shippingMethodId?: string
  appliedPromotions?: IAppliedCampaignRaw[]
  msgDisplayed?: string
}

interface IInAppMsg {
  screenName?: string
  componentName: string
  componentValue?: string
}

interface IContactCustomerService {
  eventOrigin: string
  screenName?: string
  orderId?: string | null
  orderNumber?: string | null
  orderStatus?: EOrderDetailsStatus | null
  deliveryETA?: number | null
  deliveryPDT?: string | null
}

interface IOrderTrackingViewed {
  screenName?: string
  orderId?: string | null
  orderNumber?: string | null
  orderStatus?: EOrderDetailsStatus | null
  deliveryETA?: number | null
  deliveryPDT?: number | null
}

interface IRiderTipTrackingPayload {
  riderTipValue: number
}

interface IVoucherWalletApplyCodeInput {
  code: string
}

interface IVoucherWalletVoucherInfoViewed {
  code: string
  status: 'active_voucher' | 'inactive_voucher'
}

interface IVoucherWalletNetworkError {
  code: string
}

interface IVoucherWalletIncorrectCode {
  code: string
  errorCode: string
}

interface IVoucherWalletApplyVoucherCard {
  code: string
  screen: EScreenName.voucher_details_page | EScreenName.voucher_wallet
}

export enum VoucherApplyOptions {
  voucher_selection = 'voucher_selection',
  voucher_code_entry = 'voucher_code_entry',
}
interface IVoucherWalletApplyVoucherSuccess {
  code: string
  origin: VoucherApplyOptions
  screen: EScreenName.voucher_details_page | EScreenName.voucher_wallet
}

interface ITracingPayload {
  traceMoment: ETraceMoment
  traceName: ETraceName
  actionId: string | undefined
  startTimestamp: string | undefined
  endTimestamp?: string
}

interface IRoktDetails {
  trackingType: ETrackingType
}

export interface ISegmentEvents {
  // Generic Schema
  TRACKING: (details: IGenericEvent) => void

  // To refactor
  ERROR_SHOWN: (details: IErrorInfo) => void
  DEVICE_MEASURED: (details: IDeviceDetails) => void
  LP_MAP_CLICKED: (details: IMapClickedDetails) => void
  ACCOUNT_LOGIN_CLICKED: () => void
  ACCOUNT_LOGIN_ERROR: (details: IAuthFailed) => void
  ACCOUNT_LOGIN_SUCCESS: () => void
  ACCOUNT_REGISTRATION_CLICKED: () => void
  ACCOUNT_REGISTRATION_SUCCESS: () => void
  ADDRESS_CONFIRMED: (details?: IAddressConfirmedDetails) => void
  CANCEL_ORDER_CLICKED: (details: ICancelOrderClicked) => void
  CART_CHANGED: (details: ICartChanged) => void
  CART_CLICKED: () => void
  CART_EXPANDED: () => void
  CART_VIEWED: (details?: ICartViewed) => void
  CART_UPDATED: (details?: ICartUpdated) => void
  CATEGORY_CLICKED: (details: ICategoryClicked) => void
  CATEGORY_SELECTED: (details: ICategorySelectedDetails) => void
  CHECKOUT_STARTED: (details?: ICheckoutDetails) => void
  CHECKOUT_STEP_COMPLETED_1: (details?: ICheckoutStep1) => void
  CHECKOUT_STEP_COMPLETED_2_TO_4: (details?: ICheckoutStep2To4) => void
  PAYMENT_STARTED: (details: IPaymentStartedDetails) => void
  CHECKOUT_VIEWED: (
    type: ETrackingType.checkoutViewed | ETrackingType.checkoutViewUpdated,
    details: ICheckoutFeeDetails
  ) => void
  CONTACT_CUSTOMER_SERVICE_SELECTED: (details: IContactCustomerService) => void
  DELIVERY_METHOD_CLICKED: (details: IDeliveryMethod) => void
  DELIVERY_TIME_SELECTION_VIEWED: () => void
  FIRST_ORDER_PLACED: (details: IFirstOrderPlacedDetails) => void
  HIGH_VALUE_FIRST_ORDER_PLACED: (
    details: IHighValueFirstOrderPlacedDetails
  ) => void
  CTA_CLICKED: (details: ICTAClicked) => void
  IN_APP_MSG_SHOWN: (details: IInAppMsg) => void
  ORDER_COMPLETED: (details: IOrderCompletedDetails) => void
  ORDER_INGREDIENTS_CLICKED: () => void
  ORDER_SOMEONE_ELSE_CLICKED: (isActive: boolean) => void
  ORDER_TRACKING_VIEWED: (details: IOrderTrackingViewed) => void
  OUTBOUND_APP_CLICKED: () => void
  PAYMENT_FAILED: (details: IPaymentFailedDetails) => void
  PRODUCT_ADDED: (details: IProductAddedDetails) => void
  PRODUCT_DETAILS_VIEWED: (details: IProductDetailsViewed) => void
  PRODUCT_REMOVED: (details: IProductRemovedDetails) => void
  PRODUCT_SEARCH_CLICKED: () => void
  PRODUCT_SEARCH_EXECUTED: (details: IProductSearchDetails) => void
  RECIPE_DROPDOWN_CLICKED: (details: IRecipeDropdownClicked) => void
  RECIPE_VIDEO_CLICKED: (details: IRecipeVideoClicked) => void
  VOUCHER_APPLIED: (details?: IVoucherApplied) => void
  VOUCHER_FAILED: (details?: IVoucherFailed) => void
  VOUCHER_REDEMPTION_ATTEMPTED: (details: IVoucherRedemptionAttempted) => void
  WEB_OPENED: () => void
  SECTION_VIEWED: (details: ISectionViewed) => void
  SECTION_VIEWED_ON_CURRENT_SCREEN: (
    details: ISectionViewedOnCurrentScreen
  ) => void
  RIDER_TIP_CLICKED: (details: IRiderTipTrackingPayload) => void
  RIDER_TIP_SUBMITTED: (details: IRiderTipTrackingPayload) => void
  VOUCHER_WALLET_OPEN_CTA: () => void
  VOUCHER_WALLET_APPLY_CODE_INPUT: (
    details: IVoucherWalletApplyCodeInput
  ) => void
  VOUCHER_WALLET_VOUCHER_INFO_VIEWED: (
    details: IVoucherWalletVoucherInfoViewed
  ) => void
  VOUCHER_WALLET_NETWORK_ERROR: (details: IVoucherWalletNetworkError) => void
  VOUCHER_WALLET_INCORRECT_CODE_ADDED: (
    details: IVoucherWalletIncorrectCode
  ) => void
  VOUCHER_WALLET_CARD_APPLY_VOUCHER: (
    details: IVoucherWalletApplyVoucherCard
  ) => void
  VOUCHER_WALLET_APPLY_VOUCHER_SUCCESS: (
    details: IVoucherWalletApplyVoucherSuccess
  ) => void
  LATENCY_TRACING: (details: ITracingPayload) => void
  DELIVERY_TIER_CHANGED: (eventName: string) => void
  PRIME_JOIN_CLICKED: () => void
  PRIME_CANCELATION_CLICKED: () => void
  PRIME_CANCELATION_SUBMIT: (reason: EPrimeCancelationReason) => void
  PRIME_PAYMENT_STARTED: (plan: ISubscriptionPlan) => void
  PRIME_SUBSCRIPTION_CREATED: (
    plan: ISubscriptionPlanShortened | undefined
  ) => void
  PAYMENT_MODAL_ERROR: (paymentStatus: EPaymentStatus) => void
  GENERIC_ERROR_MODAL_SHOWN: () => void
  ROKT_EVENT: (details: IRoktDetails) => void
}

const formatProduct = (product: IProduct): ISegmentCartProduct => ({
  ...pick(product, ['sku', 'quantity']),
  price: product.price.amount,
})

const formatProductFbPixel = (product: IProduct): IFbPixelCartProductData => ({
  ...pick(product, ['quantity']),
  id: product.sku,
  item_price: product.price.amount,
})

const formatCheckoutProduct = (product: IProduct): ISegmentCheckoutProduct => ({
  ...pick(product, ['quantity']),
  product_sku: product.sku,
  price: product.price.amount,
})

const mapPaymentMethod = (
  paymentMethod: string | undefined
): string | undefined => {
  switch (paymentMethod) {
    case 'scheme':
      return ESegmentPaymentMethod.CreditCard
    default:
      return paymentMethod
  }
}

const getPrismCampaignTrackingProperties = (
  appliedPromotions: IAppliedCampaignRaw[]
): {
  prism_campaign_id?: string
  prism_campaign_name?: string
} => {
  if (!appliedPromotions) return {}

  const prismCampaignIds = []
  const prismCampaignNames = []

  for (const { id, label } of appliedPromotions) {
    prismCampaignIds.push(id)
    prismCampaignNames.push(label)
  }

  return {
    prism_campaign_id: prismCampaignIds.join(','),
    prism_campaign_name: prismCampaignNames.join(','),
  }
}

const {
  HOME,
  ORDER_DETAILS,
  CATEGORY,
  SUB_CATEGORY,
  SEARCH,
  CART,
  PRODUCT,
  CHECKOUT,
  RECIPES,
  LOG_IN,
  PROFILE,
  DEALS,
  COLLECTION,
  GET_STARTED,
  PROCESS_PAYMENT,
  PRIME_JOIN,
  PRODUCT_REPLACEMENT,
} = ROUTES

type AvailableRoutes =
  | typeof HOME
  | typeof ORDER_DETAILS
  | typeof CATEGORY
  | typeof SUB_CATEGORY
  | typeof SEARCH
  | typeof CART
  | typeof PRODUCT
  | typeof CHECKOUT
  | typeof RECIPES
  | typeof LOG_IN
  | typeof GET_STARTED
  | typeof PROCESS_PAYMENT
  | typeof PRIME_JOIN

const originScreens = {
  [HOME]: EOriginScreen.homeViewed,
  [ORDER_DETAILS]: EOriginScreen.orderDetailsViewed,
  [CART]: EOriginScreen.cartViewed,
  [CATEGORY]: EOriginScreen.categoryViewed,
  [SUB_CATEGORY]: EOriginScreen.productDetailsViewed,
  [PRODUCT]: EOriginScreen.searchResultsViewed,
  [SEARCH]: EOriginScreen.searchResultsViewed,
  [CHECKOUT]: EOriginScreen.checkoutViewed,
  [RECIPES]: EOriginScreen.recipesViewed,
  [LOG_IN]: EOriginScreen.loginViewed,
  [GET_STARTED]: EOriginScreen.getStarted,
  [PROCESS_PAYMENT]: EOriginScreen.processPayment,
  [PRIME_JOIN]: EOriginScreen.subscriptionLanding,
}

const screenNames = {
  [HOME]: EScreenName.home,
  [ORDER_DETAILS]: EScreenName.orderDetails,
  [CATEGORY]: EScreenName.category,
  [SUB_CATEGORY]: EScreenName.category,
  [SEARCH]: EScreenName.productSearch,
  [CART]: EScreenName.cart,
  [PRODUCT]: EScreenName.productDetail,
  [CHECKOUT]: EScreenName.checkout,
  [RECIPES]: EScreenName.recipe,
  [LOG_IN]: EScreenName.login,
  [PROFILE]: EScreenName.profile,
  [DEALS]: EScreenName.deals,
  [COLLECTION]: EScreenName.collectionDetails,
  [GET_STARTED]: EScreenName.getStarted,
  [PROCESS_PAYMENT]: EScreenName.processPayment,
  [PRIME_JOIN]: EScreenName.subscriptionLanding,
  [PRODUCT_REPLACEMENT]: EScreenName.substitution,
}

export function getOriginScreen(prevRoute: Route): EOriginScreen {
  const routeName = getRouteName(prevRoute)

  return originScreens[routeName as AvailableRoutes]
}

export function getScreenName(route: Route): EScreenName {
  const routeName = getRouteName(route)

  return screenNames[routeName as AvailableRoutes]
}

const errorCodesMap: Record<string, string> = {
  [EPaymentErrorCode.ERROR_ITEM_NOT_AVAILABLE]: 'product_quantity_check',
  [EPaymentErrorCode.ERROR_CART_NOT_UPDATED]: 'update_cart_data',
  [EPaymentErrorCode.ERROR_CHECKOUT_FAILED]: 'checkout_cart',
  [EPaymentErrorCode.ERROR_CHECKOUT_DETAILS_FAILED]: 'checkout_cart_details',
  [EPaymentErrorCode.ERROR_PAYMENT_TIMEOUT]: 'payment_timeout',
  [EPaymentErrorCode.ERROR_HUB_NOT_DELIVERABLE]: 'hub_connection_error',
  [EPaymentErrorCode.ERROR_PAYMENT_REFUSED]: 'payment_refused',
}

export function buildSegmentIdentify(
  app: Vue
): (traits?: ISegmentTraits) => void {
  return function identify(traits: ISegmentTraits = {}): void {
    if (!window.analytics) return

    const userId = app.store.state.account.user?.uid
    const country_code = getCountryFromLocale(app.i18n.locale) || 'unknown'
    const city = app.store.state.shippingAddress?.city
    const hubSlug = app.store.state.hub?.slug

    window.analytics.identify(
      userId,
      {
        firstName: app.store.state.shippingAddress?.first_name,
        lastName: app.store.state.shippingAddress?.last_name,
        phone: app.store.state.shippingAddress?.phone,
        email: app.store.state.email,
        ...(app.store.getters.getIsOnboardSuccess
          ? {
              address: app.store.state.shippingAddress?.street_address_1,
              city,
            }
          : {}),
        country_code,
        hubSlug,
        delivery_tier_id: app.store.state.deliveryTierId,
        delivery_lat: app.store.state.deliveryCoordinates?.latitude,
        delivery_lng: app.store.state.deliveryCoordinates?.longitude,
        ...traits,
      },
      {
        context: {
          traits: {
            firstName: app.store.state.shippingAddress?.first_name,
            lastName: app.store.state.shippingAddress?.last_name,
            email: app.store.state.email,
            country_code,
            city,
            hubSlug,
            delivery_tier_id: app.store.state.deliveryTierId,
            user_logged_in: app.store.getters['account/isUserAuthenticated'],
            is_hub_user: app.store.getters.getIsKiosk,
            user_tracking_id: getUserFlinkUID(app.$cookies),
            app: {
              version: incrementPatchVersion(app.$config.lastTag),
            },
          },
        },
        integrations: {
          'Google AdWords New': true,
          'Google Tag Manager': true,
          'Google Analytics 4 Web': true,
        },
      }
    )

    window.analytics.ready(() => {
      const user = window.analytics.user() as ISegmentUser
      if (user) {
        app.store.commit(
          getPrefixedMutation(ACCOUNT_PREFIX, SET_SEGMENT_ANONYMOUS_USER_ID),
          user.anonymousId()
        )

        const datadogRum = (window as any).DD_RUM
        datadogRum?.setUserProperty('anonymousId', user.anonymousId())
      }
    })
  }
}

function paymentMethodPrettyName(adyenName: string): string {
  switch (adyenName) {
    case 'scheme':
      return 'CreditCard'
    default:
      return adyenName
  }
}

export function buildSegmentEvents(app: Vue): ISegmentEvents {
  function track(eventName: string, payload = {}, options = {}) {
    window.analytics.track(eventName, payload, {
      ...options,
      traits: {
        firstName: app.store.state.shippingAddress?.first_name,
        lastName: app.store.state.shippingAddress?.last_name,
        email: app.store.state.email,
        country_code: getCountryFromLocale(app.i18n.locale) || 'unknown',
        city: app.store.state.shippingAddress?.city,
        delivery_tier_id: app.store.state.deliveryTierId,
        hubSlug: app.store.state.hub?.slug,
        user_logged_in: app.store.getters['account/isUserAuthenticated'],
        is_hub_user: app.store.getters['account/isHubUser'],
        app: {
          version: incrementPatchVersion(app.$config.lastTag),
        },
        user_tracking_id: getUserFlinkUID(app.$cookies),
      },
      integrations: {
        'Google AdWords New': true,
        'Google Tag Manager': true,
        'Google Analytics 4 Web': true,
      },
    })
  }
  return {
    // GENERIC TRACKING FUNCTION
    // See documentation for trackingTypes: https://goflink.atlassian.net/wiki/spaces/DATA/pages/347210220/Generic+Events
    TRACKING: ({
      trackingType,
      screenName,
      eventOrigin,
      componentName,
      componentContent,
      componentVariant,
      componentPosition,
      componentValue,
      internalCampaign,
      internalSource,
      internalMedium,
      internalDeeplink,
      adDecisionId,
      placementId,
      placementTitle,
      productPlacement,
      categoryName,
      categoryId,
      entryTimestamp,
      exitTimestamp,
    }) => {
      track(trackingType, {
        screen_name: screenName ?? getScreenName(app.router.currentRoute),
        event_origin: eventOrigin,
        component_name: componentName,
        component_content: componentContent,
        component_variant: componentVariant,
        component_position: componentPosition,
        component_value: componentValue,
        internal_campaign: internalCampaign,
        internal_source: internalSource,
        internal_medium: internalMedium,
        internal_deeplink: internalDeeplink,
        hubSlug: app.store.state.hub?.slug,
        ad_decision_id: adDecisionId,
        placement_id: placementId,
        placement_title: placementTitle,
        product_placement: productPlacement,
        category_name: categoryName,
        category_id: categoryId,
        entry_timestamp: entryTimestamp,
        exit_timestamp: exitTimestamp,
      })
    },

    // Generic Schema Events to be refactored
    SECTION_VIEWED: ({ screenName, componentName, componentContent }) => {
      track('sectionViewed', {
        screen_name: screenName,
        component_name: componentName,
        component_content: componentContent,
      })
    },
    SECTION_VIEWED_ON_CURRENT_SCREEN: ({
      componentName,
      componentContent,
      componentVariant,
    }) => {
      track('sectionViewed', {
        screen_name: getScreenName(app.router.currentRoute),
        component_name: componentName,
        component_content: componentContent,
        component_variant: componentVariant,
      })
    },
    RECIPE_DROPDOWN_CLICKED: ({ screenName, componentName }) => {
      track('click', {
        screen_name: screenName,
        component_name: componentName,
      })
    },
    RECIPE_VIDEO_CLICKED: ({
      componentContent,
      componentName,
      componentValue,
      screenName,
    }) => {
      track('click', {
        component_content: componentContent,
        component_name: componentName,
        component_value: componentValue,
        screen_name: screenName,
      })
    },
    CTA_CLICKED({
      componentContent,
      componentName,
      componentValue,
      screenName,
    }) {
      track('click', {
        component_content: componentContent,
        component_name: componentName,
        component_value: componentValue,
        screen_name: screenName,
        hubSlug: app.store.state.hub?.slug,
      })
    },

    // Non-generic events
    ERROR_SHOWN({ componentName, screenName }) {
      track('errorShown', {
        component_name: componentName,
        screen_name: screenName,
        hubSlug: app.store.state.hub?.slug,
      })
    },
    DEVICE_MEASURED({ width, height }) {
      track('deviceCategory', {
        width,
        height,
      })
    },
    LP_MAP_CLICKED({ buttonValue }) {
      track('lpMapClicked', {
        button_value: buttonValue,
      })
    },
    ADDRESS_CONFIRMED({
      hub = app.store.state.hub,
      deliveryCoordinates = app.store.state.deliveryCoordinates,
      shippingAddress = app.store.state.shippingAddress,
      deliveryETA = app.store.state.deliveryETA,
    } = {}) {
      track('addressConfirmed', {
        delivery_pdt: deliveryETA,
        delivery_lat: deliveryCoordinates?.latitude,
        delivery_lng: deliveryCoordinates?.longitude,
        delivery_postcode: shippingAddress?.postal_code,
        hub_city: hub?.city_name,
        hubSlug: hub?.slug,
        user_area_available: true,
      })
    },
    PRODUCT_REMOVED({
      cartId = app.store.state.cartId,
      sku,
      name,
      price,
      productPlacement,
      listPosition,
      listName,
      productPosition,
      categoryId,
      category,
      subCategoryId,
      subCategoryName,
      hubCity = app.store.state.hub?.city_name,
      deliveryPostCode = app.store.state.shippingAddress?.postal_code,
      originalPrice,
      discountApplied,
      productContext,
      screenName,
      eventOrigin,
      prismCampaignId,
      prismCampaignName,
    }) {
      track('productRemovedFromCart', {
        cart_id: cartId,
        product_sku: sku,
        product_name: name,
        product_price: price,
        product_placement: productPlacement,
        list_position: listPosition,
        list_name: listName,
        product_position: productPosition,
        category_id: categoryId,
        category_name: category,
        sub_category_id: subCategoryId,
        sub_category_name: subCategoryName,
        hub_city: hubCity,
        hubSlug: app.store.state.hub?.slug,
        delivery_postcode: deliveryPostCode,
        original_price: originalPrice,
        discount_applied: discountApplied,
        screen_name: screenName ?? getScreenName(app.router.currentRoute),
        product_context: productContext,
        event_origin: eventOrigin,
        ...(prismCampaignId &&
          prismCampaignName && {
            prism_campaign_id: prismCampaignId,
            prism_campaign_name: prismCampaignName,
          }),
      })
    },
    CART_CLICKED() {
      const hub = app.store.state.hub

      track('cartClicked', {
        cart_id: app.store.state.cartId,
        origin_screen: getOriginScreen(app.router.currentRoute),
        delivery_postcode: app.store.state.shippingAddress?.postal_code,
        hub_city: hub?.city_name,
        hubSlug: hub?.slug,
        is_location_deliverable: app.store.getters.getIsOnboardSuccess,
      })
    },

    CART_VIEWED({
      cartId = app.store.state.cartId,
      lastUsedCartId = app.store.state.lastUsedCartId,
      hub = app.store.state.hub,
      postalCode = app.store.state.shippingAddress?.postal_code,
      currency = app.store.state.currency,
      cartProducts = app.store.getters.getCartProducts,
      totalPrice = app.store.getters.getCartSubTotalPrice,
      deliveryFee = app.store.getters.getDeliveryFee,
      shippingMethodId = app.store.state.shippingMethodId,
      appliedPromotions = app.store.state.appliedPromotions,
      msgDisplayed,
    } = {}) {
      track('cartViewed', {
        cart_id: cartId ?? lastUsedCartId,
        screen_name: getScreenName(app.router.currentRoute),
        delivery_postcode: postalCode,
        hubSlug: hub?.slug,
        hub_city: hub?.city_name,
        currency,
        sub_total: totalPrice,
        products: cartProducts.map(formatProduct),
        delivery_fee: deliveryFee,
        msg_displayed: msgDisplayed,
        shipping_method_id: shippingMethodId,
        ...getPrismCampaignTrackingProperties(appliedPromotions),
      })
    },
    CART_EXPANDED() {
      track('cartExpanded')
    },
    CART_UPDATED({
      hub = app.store.state.hub,
      deliveryPostcode = app.store.state.shippingAddress?.postal_code,
      currency = app.store.state.currency,
      subtotal = app.store.getters.getCartSubTotalPrice,
      deliveryFee = app.store.getters.getDeliveryFee,
      cartId = app.store.state.cartId,
      shippingMethodId = app.store.state.shippingMethodId,
      appliedPromotions = app.store.state.appliedPromotions,
      msgDisplayed,
    } = {}) {
      track('cartUpdated', {
        hubSlug: hub?.slug,
        hub_city: hub?.city_name,
        delivery_postcode: deliveryPostcode,
        currency,
        subtotal,
        delivery_fee: deliveryFee,
        msg_displayed: msgDisplayed,
        cart_id: cartId,
        shipping_method_id: shippingMethodId,
        ...getPrismCampaignTrackingProperties(appliedPromotions),
      })
    },
    CHECKOUT_STARTED({
      cartId = app.store.state.cartId,
      hub = app.store.state.hub,
      postalCode = app.store.state.shippingAddress?.postal_code,
      totalPrice = app.store.getters.getCartSubTotalPrice,
      deliveryFee = app.store.getters.getDeliveryFee,
      currency = app.store.state.currency,
      cartProducts = app.store.getters.getCartProducts,
    } = {}) {
      const revenue = round(app.store.getters.getOrderTotalPrice, 2)
      track('Checkout Started', {
        cart_id: cartId,
        hubSlug: hub?.slug,
        hub_city: hub?.city_name,
        delivery_postcode: postalCode,
        value: round(totalPrice, 2),
        revenue,
        delivery_fee: deliveryFee,
        currency,
        products: cartProducts.map(formatProduct),
        event_origin: getOriginScreen(app.router.prevRoute),
      })
    },
    CHECKOUT_VIEWED(
      trackingType,
      {
        cartId,
        cartProducts,
        deliveryFee,
        storageFee,
        hubCity,
        subtotal,
        total,
        discount,
        deposit,
        fees,
        isTokenizedPaymentMethod,
        paymentMethod = 'AdyenPaymentGeneric',
      }
    ) {
      const trackingFees: Record<string, number> = {}
      fees.forEach((fee) => {
        trackingFees[fee.type] = fee.price.amount
      })
      const isPlannedOrder = app.store.getters['plannedOrders/isPlannedOrder']
      const deliveryType = app.store.getters[
        'plannedOrders/hasDeliveryOptionBeenShown'
      ](ESchedulingOptionType.EXPRESS)
        ? app.store.state.plannedOrders?.selectedTimeSlot.type.toLowerCase()
        : undefined

      track(trackingType, {
        event_origin: getOriginScreen(app.router.prevRoute),
        products: cartProducts.map(formatCheckoutProduct),
        storage_fee: storageFee,
        hub_city: hubCity,
        hubSlug: app.store.state.hub?.slug,
        subtotal,
        total,
        discount,
        deposit,
        cart_id: cartId,
        shipping_method_id: app.store.state.shippingMethodId,
        ...trackingFees,
        delivery_fee: deliveryFee,
        timeslot_start: isPlannedOrder
          ? app.store.state.plannedOrders?.selectedTimeSlot.start
          : null,
        timeslot_end: isPlannedOrder
          ? app.store.state.plannedOrders?.selectedTimeSlot.end
          : null,
        is_planned_delivery: isPlannedOrder,
        is_pdt_shown: app.store.getters['plannedOrders/isPdtShown'],
        delivery_pdt: app.store.state.plannedOrders?.asapPdt,
        delivery_type: deliveryType,
        is_tokenized_payment_method: isTokenizedPaymentMethod,
        payment_method: paymentMethodPrettyName(paymentMethod),
      })
    },
    DELIVERY_METHOD_CLICKED({ screenName, componentName, componentValue }) {
      track('click', {
        screen_name: screenName,
        component_name: componentName,
        component_value: componentValue,
      })
    },
    DELIVERY_TIME_SELECTION_VIEWED() {
      track(ETrackingType.screenViewed, {
        screen_name: EScreenName.deliveryTimeSelection,
        component_variant: app.store.getters.getIsHubClosed
          ? 'asap_not_available'
          : 'asap_available',
        component_value: app.store.getters.getDeliveryETAStr,
      })
    },
    FIRST_ORDER_PLACED({
      orderId,
      orderDisplayNumber,
      voucherCode = '',
      voucherValue = 0,
      postalCode = app.store.state.shippingAddress?.postal_code,
      deliveryFee = app.store.getters.getDeliveryFee,
      deliveryETA = app.store.getters.getDeliveryETA,
      paymentMethod = 'AdyenPaymentGeneric',
      hub = app.store.state.hub,
      currency = app.store.state.currency,
      cartProducts = app.store.getters.getCartProducts,
      totalPrice = app.store.getters.getCartSubTotalPrice,
      predictedUserCategory,
    }) {
      track('firstOrderPlaced', {
        order_id: orderId,
        order_number: orderDisplayNumber,
        voucher_code: voucherCode,
        voucher_value: voucherValue,
        delivery_postcode: postalCode,
        delivery_fee: deliveryFee,
        delivery_eta: deliveryETA,
        payment_method: paymentMethod,
        order_token: orderId,
        hub_city: hub?.city_name,
        hubSlug: hub?.slug,
        currency,
        products: cartProducts.map(formatProduct),
        revenue: round(totalPrice, 2),
        value: round(totalPrice, 2),
        ...(predictedUserCategory
          ? { predicted_user_category: predictedUserCategory }
          : {}),
      })
    },
    HIGH_VALUE_FIRST_ORDER_PLACED({
      orderDisplayNumber,
      hub = app.store.state.hub,
    }) {
      track('HVfirstOrderPlaced', {
        order_number: orderDisplayNumber,
        hub_city: hub?.city_name,
        hubSlug: hub?.slug,
      })
    },
    ORDER_COMPLETED({
      orderId,
      orderDisplayNumber,
      voucherCode = '',
      cartId = app.store.state.cartId,
      postalCode = app.store.state.shippingAddress?.postal_code,
      deliveryETA = app.store.getters.getDeliveryETA,
      hub = app.store.state.hub,
      deliveryFee = app.store.getters.getDeliveryFee,
      currency = app.store.state.currency,
      discount = app.store.getters.getDiscount,
      cartProducts = app.store.getters.getCartProducts,
      isClickAndCollect = app.store.getters.getIsClickAndCollect,
      shippingMethodId = app.store.state.remoteCart?.shippingMethodId,
      anonymousId,
      hubSlug,
    }) {
      const products = cartProducts.map(formatProduct)
      const cartSubTotalPrice = round(app.store.getters.getCartSubTotalPrice, 2)
      const revenue = round(app.store.getters.getOrderTotalPrice, 2)
      const isPlannedOrder = app.store.getters['plannedOrders/isPlannedOrder']

      track('orderPlaced', {
        order_id: orderId,
        cart_id: cartId,
        order_number: orderDisplayNumber,
        delivery_postcode: postalCode,
        delivery_eta: deliveryETA,
        hub_city: hub?.city_name, // PLEASE NOTE: hub can be out of sync, e.g. de_all_skus
        hubSlug,
        order_revenue: revenue,
        revenue,
        value: cartSubTotalPrice,
        delivery_fee: deliveryFee,
        tax: 0, // requested by Analytics team, to fix it to 0 while tax not available
        voucher_value: discount,
        voucherCode,
        currency,
        products,
        click_and_collect: isClickAndCollect,
        shipping_method_id: shippingMethodId,
        content_type: ['product', 'local_service_business'],
        contents: cartProducts.map(formatProductFbPixel),
        timeslot_start: isPlannedOrder
          ? app.store.state.plannedOrders?.selectedTimeSlot.start
          : null,
        timeslot_end: isPlannedOrder
          ? app.store.state.plannedOrders?.selectedTimeSlot.end
          : null,
        is_planned_delivery: isPlannedOrder,
        is_eta_shown: app.store.getters['plannedOrders/isPdtShown'],
        ...(anonymousId && { anonymousId }),
      })
    },
    CHECKOUT_STEP_COMPLETED_1({
      subtotal = app.store.getters.getCartSubTotalPrice,
      discount = app.store.getters.getDiscount === 0
        ? 0
        : -app.store.getters.getDiscount,
      fees = app.store.state.remoteCart?.fees,
      deposit = app.store.getters.getTotalDeposit,
      riderTip = app.store.state.remoteCart?.rider_tip?.amount || 0,
      total = app.store.getters.getOrderTotalPrice,
      isOrderingForSomeoneElse = false,
      cartId = app.store.state.cartId,
      step = 1,
      isClickAndCollect = app.store.getters.getCanClickAndCollect,
    } = {}) {
      const feesObj = fees?.reduce(
        (acc, { type, price }) => ({ ...acc, [type]: price.amount }),
        {}
      )

      track('Checkout Step Completed', {
        subtotal,
        discount,
        deposit,
        rider_tip: riderTip,
        total,
        ordering_for_someone_else: isOrderingForSomeoneElse,
        cart_id: cartId,
        step,
        hubSlug: app.store.state.hub?.id,
        click_and_collect: isClickAndCollect,
        ...feesObj,
      })
    },

    CHECKOUT_STEP_COMPLETED_2_TO_4({
      trackingEventName = 'Checkout Step Completed',
      step,
      paymentMethod = 'AdyenPaymentGeneric',
      isSavePayment,
      cartId = app.store.state.cartId,
      isExpressCheckout,
    } = {}) {
      track(trackingEventName, {
        step,
        cart_id: cartId,
        hubSlug: app.store.state.hub?.id,
        payment_method: paymentMethodPrettyName(paymentMethod),
        ...(isSavePayment && { save_payment_method: isSavePayment }),
        is_express_checkout: isExpressCheckout,
      })
    },
    PAYMENT_STARTED({
      isSavePayment,
      isExpressCheckout,
      isOrderingForSomeoneElse,
    }) {
      track(ETrackingType.paymentStarted, {
        cart_id: app.store.state.cartId,
        hubSlug: app.store.state.hub?.id,
        payment_method: app.store.state.paymentMethod
          ? paymentMethodPrettyName(app.store.state.paymentMethod)
          : 'AdyenPaymentGeneric',
        ...(isSavePayment && { save_payment_method: isSavePayment }),
        is_express_checkout: isExpressCheckout,
        subtotal: app.store.getters.getCartSubTotalPrice,
        total: app.store.getters.getOrderTotalPrice,
        discount:
          app.store.getters.getDiscount === 0
            ? 0
            : -app.store.getters.getDiscount,
        delivery_fee: app.store.getters.getDeliveryFee,
        late_night_fee: app.store.state.remoteCart?.lateNightFee?.amount || 0,
        storage_fee: app.store.state.remoteCart?.storage_fee?.amount || 0,
        deposit: app.store.getters.getTotalDeposit,
        ordering_for_someone_else: isOrderingForSomeoneElse,
        rider_tip: app.store.state.remoteCart?.rider_tip?.amount || 0,
      })
    },
    CONTACT_CUSTOMER_SERVICE_SELECTED({
      eventOrigin,
      screenName,
      orderId,
      orderNumber,
      orderStatus,
      deliveryETA,
      deliveryPDT,
    }) {
      track('contactCustomerServiceSelected', {
        screen_name: screenName || getScreenName(app.router.currentRoute),
        event_origin: eventOrigin,
        order_id: orderId,
        order_number: orderNumber,
        order_status: orderStatus,
        delivery_eta: deliveryETA,
        delivery_pdt: deliveryPDT,
      })
    },
    VOUCHER_REDEMPTION_ATTEMPTED({ voucher_code }) {
      track('voucherRedemptionAttempted', { voucher_code })
    },
    VOUCHER_APPLIED({
      cartId = app.store.state.cartId,
      orderId,
      coupon,
      discount,
    } = {}) {
      track('voucherAppliedSucceeded', {
        cart_id: cartId,
        order_id: orderId,
        coupon,
        discount,
      })
    },
    VOUCHER_FAILED({ voucherCode, errorMessage } = {}) {
      track('voucherAppliedFailed', {
        voucher_code: voucherCode,
        error_message: errorMessage,
      })
    },
    CATEGORY_SELECTED({
      categoryId,
      categoryName,
      subCategoryId = null,
      subCategoryName = null,
    }) {
      track('categorySelected', {
        category_id: categoryId,
        category_name: categoryName,
        sub_category_id: subCategoryId,
        sub_category_name: subCategoryName,
      })
    },
    CATEGORY_CLICKED({
      componentContent,
      componentName = app.store.state.catalog.categories,
      componentValue,
      screenName,
    }) {
      track('click', {
        component_content: componentContent,
        component_name: componentName,
        component_value: componentValue,
        screen_name: screenName,
        hubSlug: app.store.state.hub?.slug,
        user_id: app.store.state.account.user?.uid,
      })
    },
    OUTBOUND_APP_CLICKED() {
      track('outboundAppClicked')
    },
    PRODUCT_ADDED({
      cartId,
      sku,
      name,
      price,
      currency,
      isOutOfStock,
      productPlacement,
      listPosition,
      listName,
      productPosition,
      categoryId,
      category,
      subCategoryId,
      subCategoryName,
      hubCity,
      deliveryPostcode,
      originalPrice,
      discountApplied,
      productContext,
      searchQueryId,
      screenName,
      eventOrigin,
      prismCampaignId,
      prismCampaignName,
    }) {
      track('productAddedToCart', {
        cart_id: cartId,
        sku,
        name,
        price,
        currency,
        screen_name: screenName || getScreenName(app.router.currentRoute),
        product_placement: productPlacement,
        list_position: listPosition,
        list_name: listName,
        product_position: productPosition,
        category_id: categoryId,
        category_name: category,
        sub_category_id: subCategoryId,
        sub_category_name: subCategoryName,
        is_out_of_stock: isOutOfStock,
        hub_city: hubCity,
        hubSlug: app.store.state.hub?.slug,
        delivery_postcode: deliveryPostcode,
        user_area_available: app.store.getters.getIsOnboardSuccess,
        original_price: originalPrice,
        discount_applied: discountApplied,
        content_type: 'product,local_service_business',
        contents: [{ id: sku, quantity: 1, item_price: price }],
        value: price,
        product_context: productContext,
        search_query_id: searchQueryId,
        event_origin: eventOrigin,
        ...(prismCampaignId &&
          prismCampaignName && {
            prism_campaign_id: prismCampaignId,
            prism_campaign_name: prismCampaignName,
          }),
      })
    },
    PRODUCT_DETAILS_VIEWED({
      eventOrigin,
      screenName,
      listName,
      productPlacement,
      listPosition,
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName,
      postalCode = app.store.state.shippingAddress?.postal_code,
      productSku,
      productId,
      productName,
      productPrice,
      productPosition,
      isOutOfStock,
      searchQueryId,
      discountApplied,
      originalPrice,
      productContext,
      prismCampaignId,
      prismCampaignName,
    }) {
      track('productDetailsViewed', {
        event_origin: eventOrigin || EOriginScreen.deepLink,
        screen_name: screenName,
        list_name: listName,
        product_placement: productPlacement,
        list_position: listPosition,
        category_id: categoryId,
        category_name: categoryName,
        sub_category_id: subCategoryId,
        sub_category_name: subCategoryName,
        delivery_postcode: postalCode,
        product_sku: productSku,
        product_id: productId,
        product_name: productName,
        product_price: productPrice,
        product_position: productPosition,
        is_out_of_stock: isOutOfStock,
        search_query_id: searchQueryId,
        discount_applied: discountApplied,
        original_price: originalPrice,
        product_context: productContext,
        hub_city: app.store.state.hub?.city,
        hub_slug: app.store.state.hub?.slug,
        currency: app.store.state.currency,
        content_type: 'product,local_service_business',
        contents: [{ id: productSku, quantity: 1, item_price: productPrice }],
        ...(prismCampaignId &&
          prismCampaignName && {
            prism_campaign_id: prismCampaignId,
            prism_campaign_name: prismCampaignName,
          }),
      })
    },
    PRODUCT_SEARCH_CLICKED() {
      track('productSearchClicked', {
        hub_city: app.store.state.hub?.city_name,
        hubSlug: app.store.state.hub?.slug,
        delivery_postcode: app.store.state.shippingAddress?.postal_code,
      })
    },
    PRODUCT_SEARCH_EXECUTED({
      searchQuery,
      searchQueryId,
      searchResults,
      searchLabelUsed = false,
    }) {
      track('productSearchExecuted', {
        search_query: searchQuery,
        search_query_id: searchQueryId,
        search_label_used: searchLabelUsed,
        search_results_total_count: searchResults.length,
        search_results_available_count: searchResults.filter(
          (p) => p.quantity > 0
        ).length,
        search_results_unavailable_count: searchResults.filter(
          (p) => p.quantity <= 0
        ).length,
        product_skus: searchResults.map((product) => product.sku),
      })
    },
    IN_APP_MSG_SHOWN({ screenName, componentName, componentValue }) {
      track('inAppMessageShown', {
        screen_name: screenName || getScreenName(app.router.currentRoute),
        component_name: componentName,
        component_value: componentValue,
      })
    },
    CANCEL_ORDER_CLICKED({ orderId: componentContent, screenName }) {
      track('click', {
        screen_name: screenName,
        component_name: 'order_cancellation_button',
        component_content: componentContent,
      })
    },
    CART_CHANGED({
      checkoutId = app.store.state.cartId,
      deliveryPostcode = app.store.state.shippingAddress?.postal_code,
      hub = app.store.state.hub,
      revenue = app.store.getters.getCartSubTotalPrice,
      shipping = app.store.getters.getDeliveryFee,
      discount = app.store.getters.getDiscount,
      coupon = null,
      currency = app.store.state.currency,
      cartProducts = app.store.getters.getCartProducts,
    }) {
      track('Order Updated', {
        cart_id: checkoutId,
        delivery_postcode: deliveryPostcode,
        hub_city: hub?.city_name,
        hubSlug: hub?.slug,
        revenue,
        shipping,
        discount,
        coupon, // if is already added
        currency,
        products: cartProducts.map(formatProduct),
      })
    },
    PAYMENT_FAILED({ error }) {
      const failedAt = errorCodesMap[error]

      track('paymentFailed', {
        payment_method: mapPaymentMethod(app.store.state.paymentMethod),
        error: error as string,
        failed_at: failedAt,
      })
    },
    WEB_OPENED() {
      const isPlannedOrder = app.store.getters['plannedOrders/isPlannedOrder']
      const { plannedOrders, shippingAddress, hub, locationOnboardingState } =
        app.store.state

      const pdtButtonState = plannedOrders.schedulingState
        ? {
            [ESchedulingState.SHOW_PDT]: 'show_PDT',
            [ESchedulingState.SHOW_TIMESLOT]: 'show_timeslot',
            [ESchedulingState.CTA_PICK_SLOT]: 'cta_pick_slot',
            [ESchedulingState.CTA_CHANGE_SLOT]: 'cta_pick_slot',
            [ESchedulingState.CLOSED]: 'closed',
          }[plannedOrders.schedulingState]
        : ''

      track('webOpened', {
        address: {
          street_address: shippingAddress?.street_address_1,
          postal_code: shippingAddress?.postal_code,
          city: shippingAddress?.city,
        },
        delivery_eta: app.store.getters.getDeliveryETANumber.toString(),
        postal_code: shippingAddress?.postal_code,
        has_selected_address:
          locationOnboardingState !== ELocationOnboardingState.INITIAL,
        hub_city: hub?.city_name,
        hubSlug: hub?.slug,
        timeslot_start: isPlannedOrder
          ? plannedOrders?.selectedTimeSlot.start || null
          : null,
        timeslot_end: isPlannedOrder
          ? plannedOrders?.selectedTimeSlot.end || null
          : null,
        is_planned_delivery: isPlannedOrder,
        is_pdt_shown: app.store.getters['plannedOrders/isPdtShown'],
        pdt_button_state: pdtButtonState,
        screen_name: getScreenName(app.router.currentRoute),
        tooltip_message: app.store.getters['plannedOrders/isServiceInfoVisible']
          ? `${plannedOrders.messageInfo.title} ${plannedOrders.messageInfo.message}`
          : null,
      })
    },
    ACCOUNT_REGISTRATION_CLICKED() {
      track('accountRegistrationClicked')
    },
    ACCOUNT_LOGIN_CLICKED() {
      track('accountLoginClicked')
    },
    ACCOUNT_REGISTRATION_SUCCESS() {
      track('accountRegistrationSucceeded')
    },
    ACCOUNT_LOGIN_SUCCESS() {
      track('accountLoginSucceeded')
    },
    ACCOUNT_LOGIN_ERROR({ error }) {
      track('accountLoginErrorViewed', {
        error,
      })
    },
    ORDER_INGREDIENTS_CLICKED: () => {
      track('orderIngredientsClicked')
    },
    ORDER_SOMEONE_ELSE_CLICKED: (isActive) => {
      track('click', {
        screen_name: getScreenName(app.router.currentRoute),
        component_name: 'ordering_for_someone_else',
        component_value: isActive ? 'select' : 'unselect',
      })
    },
    ORDER_TRACKING_VIEWED: ({
      screenName,
      orderId,
      orderNumber,
      orderStatus,
      deliveryETA,
      deliveryPDT,
    }) => {
      track('orderTrackingViewed', {
        event_origin: screenName || getScreenName(app.router.currentRoute),
        order_id: orderId,
        order_number: orderNumber,
        order_status: orderStatus,
        delivery_eta: deliveryETA,
        delivery_pdt: deliveryPDT,
      })
    },
    RIDER_TIP_CLICKED: ({ riderTipValue }) => {
      track('riderTipClicked', {
        rider_tip_value: riderTipValue,
      })
    },
    RIDER_TIP_SUBMITTED: ({ riderTipValue }) => {
      track('riderTipSubmitted', {
        rider_tip_value: riderTipValue,
      })
    },
    VOUCHER_WALLET_OPEN_CTA: () => {
      track(ETrackingType.click, {
        component_name: 'enter_voucher_wallet',
        screen_name: EScreenName.checkout,
      })
    },
    VOUCHER_WALLET_APPLY_CODE_INPUT: ({ code }) => {
      track(ETrackingType.click, {
        component_name: 'add_voucher_code',
        screen_name: EScreenName.voucher_wallet,
        component_content: code,
      })
    },
    VOUCHER_WALLET_VOUCHER_INFO_VIEWED: ({ code, status }) => {
      track(ETrackingType.sectionViewed, {
        component_name: 'voucher_details_page',
        component_variant: status,
        component_content: code,
        screen_name: EScreenName.voucher_wallet,
      })
    },
    VOUCHER_WALLET_NETWORK_ERROR: ({ code }) => {
      track(ETrackingType.errorShown, {
        component_name: 'apply_available_voucher',
        component_content: code,
        component_variant:
          'Something went wrong. Make sure your phone is connected to your mobile network',
        screen_name: EScreenName.voucher_wallet,
      })
    },
    VOUCHER_WALLET_INCORRECT_CODE_ADDED: ({ code, errorCode }) => {
      track(ETrackingType.errorShown, {
        component_name: 'add_voucher_code',
        component_content: code,
        component_variant: errorCode,
        screen_name: EScreenName.voucher_wallet,
      })
    },
    VOUCHER_WALLET_CARD_APPLY_VOUCHER: ({ code, screen }) => {
      track(ETrackingType.click, {
        component_name: 'apply_available_voucher',
        component_content: code,
        screen_name: screen,
      })
    },
    VOUCHER_WALLET_APPLY_VOUCHER_SUCCESS: ({ code, origin, screen }) => {
      track(ETrackingType.inAppMessageShown, {
        component_name: 'voucher_applied_success',
        component_content: code,
        event_origin: origin,
        screen_name: screen,
      })
    },
    LATENCY_TRACING: ({
      traceMoment,
      traceName,
      actionId,
      startTimestamp,
      endTimestamp,
    }) => {
      track(traceMoment, {
        trace_name: traceName,
        action_id: actionId,
        start_timestamp: startTimestamp,
        ...(endTimestamp && { end_timestamp: endTimestamp }),
      })
    },
    DELIVERY_TIER_CHANGED: (eventName) => {
      if (app.store.getters.getCurrentDeliveryTier?.minimumOrderValue) {
        track(eventName, {
          order_value_threshold:
            app.store.getters.getCurrentDeliveryTier?.minimumOrderValue
              .centAmount / 100,
          delivery_fee: app.store.getters.getDeliveryFee,
          number_of_df_tiers_available:
            app.store.getters.getDeliveryTiers.length,
          number_of_df_tiers_reached:
            app.store.getters.getCurrentDeliveryTierIndex + 1,
          screen_name:
            getScreenName(app.router.currentRoute) || app.router.currentRoute,
        })
      }
    },
    PRIME_JOIN_CLICKED: () => {
      track(ETrackingType.click, {
        screen_name: getScreenName(app.router.currentRoute),
        component_name: 'subscription_cta',
        component_variant: app.store.state.subscription
          .subscriptionStatus as string,
      })
    },
    PRIME_CANCELATION_CLICKED: () => {
      track(ETrackingType.click, {
        screen_name: EScreenName.subscriptionManagement,
        component_name: 'subscription_cancellation',
      })
    },
    PRIME_CANCELATION_SUBMIT: (reason: EPrimeCancelationReason) => {
      track(ETrackingType.subscriptionCancelRequested, { reason })
    },
    PRIME_PAYMENT_STARTED: ({ nameSlug, totalPrice }: ISubscriptionPlan) => {
      track(ETrackingType.subscriptionPaymentStarted, {
        subscription_plan: nameSlug,
        price: totalPrice.centAmount / 100,
      })
    },
    PRIME_SUBSCRIPTION_CREATED: (
      plan: ISubscriptionPlanShortened | undefined
    ) => {
      track(ETrackingType.subscriptionCreated, {
        subscription_plan: plan?.nameSlug,
        price: plan ? plan.totalPrice.centAmount / 100 : undefined,
        currency: plan ? plan.totalPrice.currency : undefined,
      })
    },
    PAYMENT_MODAL_ERROR: (paymentStatus) => {
      const contentMap: Partial<Record<EPaymentStatus, string>> = {
        [EPaymentStatus.SUCCESS]: 'view_order',
        [EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT]: 'got_it',
      }

      const eventOriginMap = (originScreen: EOriginScreen): string => {
        switch (originScreen) {
          case EOriginScreen.checkoutViewed:
          case EOriginScreen.processPayment:
            return 'order_creation_timeout_error'
          default:
            return 'app_opened'
        }
      }
      const originScreen: EOriginScreen = getOriginScreen(app.router.prevRoute)
      const eventOrigin = eventOriginMap(originScreen)

      track(ETrackingType.errorShown, {
        component_name: 'payment_received_successfully',
        component_content: contentMap[paymentStatus],
        screen_name: getScreenName(app.router.currentRoute),
        event_origin: eventOrigin,
      })
    },
    GENERIC_ERROR_MODAL_SHOWN: () => {
      track(ETrackingType.inAppMessageShown, {
        component_name: EComponentName.genericError,
        component_variant: EComponentVariant.popup,
        screen_name: getScreenName(app.router.currentRoute),
      })
    },
    ROKT_EVENT: ({ trackingType }) => {
      track(trackingType)
    },
  }
}
