import { IAddress, ICoordinates, ILocationInput } from '~/types/app/Location'
import { IPaymentGateway } from '~/types/app/Payments'
import { Currency, ICentPrice } from '~/types/app/Price'
import { EProductPlacement, EScreenName, EOriginScreen } from '~/lib/segment'
import { IAccountState } from '~/store/account'
import { ICheckoutState } from '~/store/checkout'
import { ICatalogState } from '~/store/catalog'
import { IContentState } from '~/store/content'
import { IPlannedOrdersState } from '~/store/plannedOrders'
import { ISubscritionState } from '~/store/subscription'
import { ISearchState } from '~/store/search'
import { IExperimentState } from '~/store/experiments'
import ICart, { IPaymentStatus } from '~/types/app/Cart'
import type { IAppliedCampaignRaw } from '~/types/app/Promotions'
import { ITranslationInput } from '~/types/app/I18n'
import {
  EAgeVerificationTag,
  IAgeVerificationState,
} from '~/types/app/AgeVerification'
import {
  IDeliveryTiers,
  IDeliveryTiersDetails,
  IDeliveryTierInfo,
  IHub,
} from '~/types/app/Hub'
import IProduct from './Product'

export interface ICartAddPayload {
  sku: string
  tags?: EAgeVerificationTag[]
  productPlacement: EProductPlacement
  listPosition?: number
  listName?: string
  productPosition?: number
  categoryId?: string
  categoryName?: string
  searchQueryId?: string
  subCategoryId?: string
  subCategoryName?: string
  screenName?: EScreenName
  eventOrigin?: EOriginScreen
  quantity?: number
  prismCampaignId?: string
  prismCampaignName?: string
}

export interface ICartRemovePayload {
  sku: IProduct['sku']
  productPlacement: EProductPlacement
  listPosition?: number
  listName?: string
  productPosition?: number
  categoryId?: string
  categoryName?: string
  subCategoryId?: string
  subCategoryName?: string
  screenName?: EScreenName
  eventOrigin?: EOriginScreen
  quantity?: number
  prismCampaignId?: string
  prismCampaignName?: string
}

export interface IHubPayload {
  hubSlug: string
}

export interface ICartItem {
  sku: string
  quantity: number
}

export interface ICartLineItem {
  // eslint-disable-next-line camelcase
  product_sku: string
  quantity: number
}

export enum ELocationOnboardingState {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  CANCELED = 'CANCELED',
  NOT_IN_DELIVERY_AREA = 'NOT_IN_DELIVERY_AREA',
}

export enum EOnboardingHintType {
  INITIAL_ONBOARDING = 'INITIAL_ONBOARDING',
  HIGH_PDT = 'HIGH_PDT',
  NO_LONGER_IN_DELIVERY = 'NO_LONGER_IN_DELIVERY',
}

export enum EDeliveryAddressHintType {
  INITIAL_ONBOARDING = 'INITIAL_ONBOARDING',
  NO_LONGER_IN_DELIVERY = 'NO_LONGER_IN_DELIVERY',
}

export enum EStoreClosedModalIconType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface IStoreClosedInformation {
  title: string | ITranslationInput
  message: string | ITranslationInput
  buttonTitle: string | ITranslationInput
  icon: {
    type: EStoreClosedModalIconType
    src: string
  }
}

export interface ICurrentLatencyTracing {
  actionId: string | undefined
  startTimestamp: string | undefined
  traceName: string | undefined
}

export type IStoreClosedInformationTranslated = {
  [K in keyof Omit<IStoreClosedInformation, 'icon'>]: string
} & Pick<IStoreClosedInformation, 'icon'>

export type StoreClosedInformation = IStoreClosedInformation | null

export default interface IRootState {
  account?: IAccountState
  catalog?: ICatalogState
  content?: IContentState
  checkout?: ICheckoutState
  cart: ICartItem[]
  appliedPromotions: IAppliedCampaignRaw[]
  previousCart: ICartItem[]
  previousDeliveryTierInfo: null | IDeliveryTierInfo
  discount: number
  discountUnit: string
  voucherCode: string
  freeProductsSku: string[]
  currentLocation: ILocationInput | undefined
  isHubUpdateNeeded: boolean
  hub: IHub | undefined
  deliveryCoordinates: ICoordinates | undefined
  shippingAddress: IAddress | undefined
  email: string | undefined
  deliveryETA: string | undefined
  cartNotes: string | undefined
  geocodeFallbackCoordinates: ICoordinates
  paymentGateway: IPaymentGateway | undefined
  paymentMethod: string | undefined
  cartId: string | undefined
  lastUsedCartId: string | undefined
  currency: Currency
  isInitialOnboardingDone: boolean
  locationOnboardingState: ELocationOnboardingState
  showOnboardingModal: boolean
  showOnboardingHint: boolean
  showCartClearedModal: boolean
  showStoreClosedModal: boolean
  showNoDeliveryToLocationModal: boolean
  shippingMethodId: string
  selectedSubCategory: string
  verifiedAge: string | undefined
  verifiedTobacco: boolean
  ageVerification: IAgeVerificationState
  closingSoonModalShown: boolean
  deliveryTiers: IDeliveryTiers[]
  deliveryTierId: string
  remoteCart?: ICart
  paymentStatus?: IPaymentStatus
  search?: ISearchState
  routerHistory: string[]
  currentLatencyTracing: ICurrentLatencyTracing
  deliveryTiersDetails: IDeliveryTiersDetails[]
  checkoutMinimumOrderValue?: ICentPrice
  smallOrderThreshold?: ICentPrice
}

export interface IAppState extends IRootState {
  account: IAccountState
  catalog: ICatalogState
  checkout: ICheckoutState
  plannedOrders: IPlannedOrdersState
  subscription: ISubscritionState
  experiments: IExperimentState
}
