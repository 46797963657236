/* eslint-disable camelcase */
import { post } from '~/api/controllers/post'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'

import ICart, { ICartV3 } from '~/types/app/Cart'
import ICartLine from '~/types/app/CartLine'
import { ICheckoutPayloadAddress, ICoordinates } from '~/types/app/Location'
import { IPaymentGateway, IPaymentGatewayRaw } from '~/types/app/Payments'
import handleCartVersionTransformation from '~/api/transformers/transform-cart'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  locale: string
  hubSlug?: string
  deliveryCoordinates?: ICoordinates
  shippingAddress?: ICheckoutPayloadAddress
  email?: string
  deliveryETA?: string
  cartLines: ICartLine[]
  notes?: string
  deliveryTierId?: string
  shippingMethodId?: string
}

export interface ICartCreatedResponse {
  id: string
  payment_gateway?: IPaymentGatewayRaw
  payment_gateways?: IPaymentGatewayRaw[]
  cart: ICartV3
}

export interface ICartCreatedReturn {
  id: string
  payment_gateway?: IPaymentGateway
  cart: ICartV3
}

interface IBody {
  shipping_address?: ICheckoutPayloadAddress
  delivery_coordinates?: ICoordinates
  delivery_eta?: string
  email?: string
  lines: ICartLine[]
  notes?: string
  delivery_tier_id?: string
  shipping_method_id?: string
}

export default async function createCart(
  {
    client,
    locale,
    hubSlug,
    deliveryCoordinates,
    shippingAddress,
    email,
    deliveryETA,
    cartLines,
    notes,
    deliveryTierId,
    shippingMethodId,
  }: IParameters,
  logger: LoggerFunction
): Promise<ICart | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = '/cart'

  const body: IBody = {
    shipping_address: shippingAddress,
    delivery_coordinates: deliveryCoordinates,
    delivery_eta: deliveryETA,
    email,
    lines: cartLines,
    notes,
    ...(deliveryTierId &&
      shippingMethodId && {
        delivery_tier_id: deliveryTierId,
        shipping_method_id: shippingMethodId,
      }),
  }

  try {
    const response = await post<ICartCreatedResponse>(
      client,
      logger,
      url,
      headers,
      body
    )

    logger({
      level: ELogLevels.INFO,
      msg: 'API Success: Cart created.',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.CART,
      func: 'createCart',
      slug: 'checkout.api.create-cart.success',
      forceSubmit: true,
    })

    const cart: ICartV3 | undefined = response.data.cart || response.data

    return handleCartVersionTransformation(client.version, cart)
  } catch (error) {
    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: Cart can not be created.',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: ELoggers.CART,
      func: 'createCart',
      slug: 'checkout.api.create-cart.error',
      forceSubmit: false,
    })

    throw error
  }
}
