








































import {
  IPaymentReceipt,
  IPaymentReceiptContent,
  EPaymentReceiptType,
} from '~/types/app/Cart'
import { styles } from './styles'

export default {
  name: 'Receipt',
  props: {
    receipt: {
      type: Object as () => IPaymentReceipt,
      default: {
        type: EPaymentReceiptType.CASHIER,
        contents: [],
      } as IPaymentReceipt,
    },
  },
  data() {
    return { styles }
  },
  computed: {
    filteredContents(this: any): IPaymentReceiptContent[] {
      const receipt = this.receipt as IPaymentReceipt
      return receipt.contents.filter((c: IPaymentReceiptContent) => {
        const urlSearchParams = new URLSearchParams(c.text)
        return (
          urlSearchParams.get('key') === 'filler' || urlSearchParams.get('name')
        )
      })
    },
  },
  methods: {
    getQueryParam(value: string, param: string): string | null {
      return new URLSearchParams(value).get(param)
    },
    isSingleColumn(content: IPaymentReceiptContent): boolean {
      const urlSearchParams = new URLSearchParams(content.text)
      return !!urlSearchParams.get('name') && !urlSearchParams.get('value')
    },
    isFiller(content: IPaymentReceiptContent): boolean {
      return new URLSearchParams(content.text).get('key') === 'filler'
    },
    isBold(content: IPaymentReceiptContent): boolean {
      return content.style === 'BOLD'
    },
    pStyle(content: IPaymentReceiptContent): Record<string, unknown> {
      return this.isBold(content)
        ? { ...styles.p, ...styles.boldText }
        : styles.p
    },
  },
}
