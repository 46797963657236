import {
  ref,
  useContext,
  useRoute,
  Ref,
  computed,
  ComputedRef,
} from '@nuxtjs/composition-api'
import ICart, {
  ECartErrorCode,
  PrepareCartForCheckoutResponse,
  EPaymentStatus,
} from '~/types/app/Cart'
import { SET_PAYMENT_STATUS } from '~/store/mutation-types'
import {
  EComponentContent,
  EComponentName,
  ETrackingType,
  getScreenName,
} from '~/lib/segment'

interface IResultValue {
  isReadyForCheckout: Ref<boolean>
  prepareCartForCheckout: () => Promise<void>
  movNotReached: ComputedRef<boolean>
  areItemsUnavailable: ComputedRef<boolean>
  unknownError: ComputedRef<boolean>
}

export function usePrepareCartForCheckout(): IResultValue {
  const { store, $segmentEvent } = useContext()
  const route = useRoute().value
  const isReadyForCheckout = ref(false)

  const prepareCartForCheckout = async () => {
    const response: PrepareCartForCheckoutResponse | undefined =
      await store.dispatch('checkout/prepareCartForCheckout')

    if (!response || !response.status) {
      isReadyForCheckout.value = false
      return
    }

    if (response.status === 'success') {
      isReadyForCheckout.value = true
      return
    }

    if (response.status === 'error') {
      isReadyForCheckout.value = false
      let cart: ICart

      switch (response.reason) {
        case ECartErrorCode.HUB_CLOSED: {
          await store.dispatch('loadHub')
          store.dispatch('testStoreOpeningHours')
          break
        }

        case ECartErrorCode.ORDER_ALREADY_EXISTS: {
          store.commit(SET_PAYMENT_STATUS, {
            status: EPaymentStatus.SUCCESS,
            action: () => {
              store.dispatch('handleOrderExists', response.order.id)
            },
          })
          break
        }

        case ECartErrorCode.ALREADY_PAID: {
          // Re-fetch the cart and show modal:
          // - If order is included in cart -> Show modal for order exist (handled by async getCart() within store)
          // - If order is not included in cart -> Show modal for order timeout

          cart = await store.dispatch('getCart', store.state.cartId)

          if (!cart?.order) {
            const action = () => {
              store?.commit(SET_PAYMENT_STATUS, undefined)
            }
            store.commit(SET_PAYMENT_STATUS, {
              status: EPaymentStatus.SUCCESS_WITH_ORDER_TIMEOUT,
              action,
            })
            store.dispatch('resetCart')
          }
          break
        }

        case ECartErrorCode.ITEM_UNAVAILABLE: {
          await store.dispatch('removeUnpublishedProducts', response.items)

          $segmentEvent.TRACKING({
            trackingType: ETrackingType.errorShown,
            screenName: getScreenName(route),
            componentName: EComponentName.cartIssue,
            componentContent: EComponentContent.itemUnavailable,
            componentValue: response.trackingData.value,
          })
          break
        }

        // Instead of displaying a popup, we will disable the checkout button.
        case ECartErrorCode.MOV_NOT_REACHED:
          break
        case ECartErrorCode.INTERNAL_SERVER_ERROR:
        case ECartErrorCode.UNKNOWN:
          $segmentEvent.TRACKING({
            trackingType: ETrackingType.errorShown,
            screenName: getScreenName(route),
            componentName: EComponentName.cartIssue,
            componentContent: EComponentContent.somethingWentWrong,
            componentValue: 'PREPARE_CART_SERVER_ERROR',
          })
          break
        default:
          break
      }
    }
  }

  const areItemsUnavailable = computed<boolean>(() => {
    return (
      store.state.checkout.prepareCartForCheckoutError?.reason ===
      ECartErrorCode.ITEM_UNAVAILABLE
    )
  })

  const movNotReached = computed<boolean>(() => {
    return (
      store.state.checkout.prepareCartForCheckoutError?.reason ===
      ECartErrorCode.MOV_NOT_REACHED
    )
  })

  const unknownError = computed<boolean>(() => {
    return (
      store.state.checkout.prepareCartForCheckoutError?.reason ===
        ECartErrorCode.UNKNOWN ||
      store.state.checkout.prepareCartForCheckoutError?.reason ===
        ECartErrorCode.INTERNAL_SERVER_ERROR
    )
  })

  return {
    isReadyForCheckout,
    prepareCartForCheckout,
    areItemsUnavailable,
    movNotReached,
    unknownError,
  }
}
