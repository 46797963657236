









import { defineComponent } from '@nuxtjs/composition-api'
import VIcon from '~/components/VIcon/VIcon.vue'

export default defineComponent({
  components: { VIcon },
})
