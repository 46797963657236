import { render, staticRenderFns } from "./NavbarSecondary.vue?vue&type=template&id=0534a167&"
import script from "./NavbarSecondary.vue?vue&type=script&lang=ts&"
export * from "./NavbarSecondary.vue?vue&type=script&lang=ts&"
import style0 from "./NavbarSecondary.vue?vue&type=style&index=0&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoriesPopover: require('/app/build/components/GlobalHeader/CategoriesPopover.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default,GlobalSearch: require('/app/build/components/Search/GlobalSearch.vue').default})
