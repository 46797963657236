var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasProducts)?_c('VSwimlane',_vm._b({attrs:{"cta-button":_vm.button,"item-gap":_vm.marginRight,"screen-name":_vm.screenName,"component-name":_vm.componentName,"component-variant":_vm.componentVariant},scopedSlots:_vm._u([(_vm.hasAddAll)?{key:"swimlane-footer",fn:function(){return [_c('button',{staticClass:"flink-btn --block",on:{"click":_vm.handleAddAllClick}},[_vm._v("\n      "+_vm._s(_vm.$t('order_again_screen_multi_sku_atc'))+"\n    ")])]},proxy:true}:null],null,true)},'VSwimlane',{ id: _vm.id, title: _vm.title, subtitle: _vm.subtitle, isHighlighted: _vm.isHighlighted, productPlacement: _vm.productPlacement },false),_vm._l((_vm.products),function(product,index){return _c('ProductCard',_vm._b({key:("" + (product.id + _vm.title + index)),staticClass:"swimlane-item",style:({ marginRight: (_vm.marginRight + "px") }),attrs:{"category-id":_vm.id,"category-name":_vm.title,"position-in-group":index + 1,"role":"listitem"},on:{"add":function($event){return _vm.$emit('add', { sku: product.sku })},"remove":function($event){return _vm.$emit('remove', { sku: product.sku })}}},'ProductCard',{
      product: product,
      listName: _vm.listName,
      swimlanePositionFromTop: _vm.swimlanePositionFromTop,
      productPlacement: _vm.productPlacement,
      isRecipes: _vm.isRecipes,
      oosProduct: _vm.oosProduct,
    },false))}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }