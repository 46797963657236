




















import { computed, ref, watch } from '@nuxtjs/composition-api';
interface ILottie {
  play: () => void;
}
const __sfc_main = {};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  },
  hasBackgroundImage: {
    type: Boolean,
    default: true
  },
  message: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    default: ''
  },
  animationName: {
    type: String,
    default: 'success'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const lottieOptions = computed(() => {
    const animationName = props.animationName;
    let animationData;

    if (animationName === 'celebrate-pink') {
      animationData = require('~/assets/animations/celebrate-pink.json');
    } else if (animationName === 'checkmark-pink') {
      animationData = require('~/assets/animations/checkmark-pink.json');
    } else {
      animationData = require('~/assets/animations/success.json');
    }

    return {
      animationData,
      autoplay: false
    };
  });
  const animation = ref<ILottie | undefined>(undefined);
  const ANIMATION_DURATION = 2800;

  function initialize(anim: ILottie) {
    animation.value = anim;
  }

  watch(() => props.shown, shown => {
    setTimeout(() => {
      animation.value?.play();
    }, 1500);

    if (shown) {
      setTimeout(() => {
        emit('on-animation-complete');
      }, ANIMATION_DURATION);
    }
  });
  return {
    lottieOptions,
    initialize
  };
};

export default __sfc_main;
