import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { LoggerFunction } from '~/types/app/Logger'
import { transformSearchLabels } from '../../transformers/transform-search-labels'
export interface SearchLabelsResponse {
  title: string
  // eslint-disable-next-line camelcase
  search_labels: string[]
}

export async function searchLabels(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  locale: string,
  hubSlug: string
): Promise<SearchLabelsResponse> {
  let response = {
    data: {
      title: '',
      search_labels: [] as string[],
    },
  }

  try {
    response = await get<SearchLabelsResponse>(
      client,
      logger,
      '/v2/search/labels',
      {
        locale,
        'hub-slug': hubSlug,
      }
    )

    return transformSearchLabels(response.data)
  } catch (error) {}

  return transformSearchLabels(response.data)
}
