import { categoryGridTransformer } from './category-grid'
import { collectionSectionTransformer } from './collection-section'
import { collectionCardTransformer } from './collection-card'
import { feedbackTransformer } from './feedback'
import { swimlaneTransformer } from './swimlane'
import { enhancedSwimlaneTransformer } from './enhanced-swimlane'
import { deeplinkedMarketingBannerSliderTransformer } from './deep-linked-marketing-banner'

export type { V2HomeRaw, V2HomeSectionRaw } from './types'

export const transformers = {
  categoryGrid: categoryGridTransformer,
  swimlane: swimlaneTransformer,
  collectionSection: collectionSectionTransformer,
  collectionCard: collectionCardTransformer,
  feedback: feedbackTransformer,
  enhancedSwimlane: enhancedSwimlaneTransformer,
  deeplinkedMarketingBannerSlider: deeplinkedMarketingBannerSliderTransformer,
}
