import Vue from 'vue'

if (!window.IntersectionObserver) {
  require('intersection-observer')
}

Vue.prototype.$lazyImageObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.tagName === 'IMG') {
          const image = entry.target as HTMLImageElement
          image.src = (image.dataset.src || image.dataset.url) ?? image.src
        }

        Vue.prototype.$lazyImageObserver.unobserve(entry.target)
      }
    })
  },
  {
    threshold: 0.5,
  }
)
