import { ROUTES } from '~/lib/routes'
import { applyPrefix } from '~/lib/helpers'

export default function ({ redirect, app }) {
  const isUserAuthenticated = app.store.getters['account/isUserAuthenticated']
  if (isUserAuthenticated) {
    const target = app.store.state.account.authRedirectTarget || ROUTES.HOME
    return redirect(applyPrefix(app.localePath(target)))
  }
}
