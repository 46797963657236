


































import { ref, useStore, useFetch, useContext, onMounted } from '@nuxtjs/composition-api';
import { IAppState } from '~/types/app/State';
import { ETrackingType, EProductPlacement } from '~/lib/segment';
import IProduct, { IProductSubstitution } from '~/types/app/Product';
import { getProductsSubstitutes } from '~/api/services/get-products-substitutes';
const __sfc_main = {};
__sfc_main.props = {
  product: {
    type: (Object as () => IProduct),
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const error = ref(false);
  const productsSubstitutions = ref<IProductSubstitution[]>([]);
  const {
    i18n: i18nRef,
    $logger,
    $apiDiscovery,
    $segmentEvent
  } = useContext();
  const store = useStore<IAppState>();

  async function fetchSubstitutions() {
    error.value = false;

    try {
      productsSubstitutions.value = await getProductsSubstitutes({
        client: $apiDiscovery,
        locale: i18nRef.locale,
        hubSlug: store.state.hub?.slug,
        requestedCartLines: [{
          requested_quantity: 1,
          sku: props.product.sku
        }]
      }, $logger);
      const products = productsSubstitutions.value.map(substitution => substitution.substitutes).flat();
      await store.dispatch('catalog/updateCatalog', {
        products
      });
    } catch (e) {
      error.value = true;
    }
  }

  useFetch(fetchSubstitutions);
  onMounted(() => {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.sectionViewed,
      componentContent: props.product.sku,
      componentName: 'oos_substitutes'
    });
  });
  return {
    EProductPlacement,
    emit,
    productsSubstitutions
  };
};

export default __sfc_main;
