








































import { computed, ref, useStore, onMounted, onBeforeUnmount } from '@nuxtjs/composition-api';
import IRootState from '~/types/app/State';
import { debounce } from 'lodash-es';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const container = ref<HTMLDivElement | null>(null);
  const isVisible = ref(false);
  const isScrolledToTop = ref(true);
  const store = useStore<IRootState>();
  const getIsOnboardSuccess = computed<boolean>(() => {
    return store.getters.getIsOnboardSuccess;
  });
  const show = debounce(function () {
    isVisible.value = true;
    isScrolledToTop.value = window.scrollY === 0;
  }, 60);
  onMounted(() => {
    window.addEventListener('scroll', show);
  });
  onBeforeUnmount(() => {
    window.removeEventListener('scroll', show);
  });
  return {
    emit,
    container,
    isVisible,
    isScrolledToTop,
    getIsOnboardSuccess
  };
};

export default __sfc_main;
