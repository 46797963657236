









export default {
  props: {
    url: { type: String, required: true },
  },
  methods: {
    getIcon(url: string): unknown {
      try {
        return url
      } catch (err) {
        return require(`~/assets/icons/_blank.svg?inline`)
      }
    },
  },
}
