import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin'
import { UIElement } from '@adyen/adyen-web/dist/types/components/UIElement'
import { IPaymentMethodData } from '~/types/app/Payments'
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError'

export interface IAdyenDropinState {
  isValid: boolean
  data: {
    paymentMethod: IPaymentMethodData
    storePaymentMethod: boolean
  }
  error?: string
  errorText?: string
  paymentMethod: Record<string, string>
}

export interface IAdyenDropinCallbackParameters {
  state: IAdyenDropinState
  component?: UIElement
  dropin?: DropinElement
}

export interface IAdyenDropinErrorCallbackParameters {
  error: AdyenCheckoutError
  dropin?: DropinElement
}

export enum EPaymentErrorCode {
  ERROR_ITEM_NOT_AVAILABLE = 'ERROR_ITEM_NOT_AVAILABLE',
  ERROR_CART_NOT_UPDATED = 'ERROR_CART_NOT_UPDATED',
  ERROR_PAYMENT_PROVIDER = 'ERROR_PAYMENT_PROVIDER',
  ERROR_DUMMY_EMAIL = 'ERROR_DUMMY_EMAIL',
  ERROR_CHECKOUT_FAILED = 'ERROR_CHECKOUT_FAILED',
  ERROR_CHECKOUT_DETAILS_FAILED = 'ERROR_CHECKOUT_DETAILS_FAILED',
  ERROR_PAYMENT_TIMEOUT = 'ERROR_PAYMENT_TIMEOUT',
  ERROR_HUB_NOT_DELIVERABLE = 'ERROR_HUB_NOT_DELIVERABLE',
  ERROR_PAYMENT_REFUSED = 'ERROR_PAYMENT_REFUSED',
  ERROR_PAYMENT_FAILED = 'ERROR_PAYMENT_FAILED',
  // PLEASE NOTE: Do not change, as this error code is returned by the backend:
  PAYMENT_INTENT_REFUSED = 'PAYMENT_INTENT_REFUSED',
}
