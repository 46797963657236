import { render, staticRenderFns } from "./CartPopover.vue?vue&type=template&id=18f2feea&scoped=true&"
import script from "./CartPopover.vue?vue&type=script&lang=ts&"
export * from "./CartPopover.vue?vue&type=script&lang=ts&"
import style0 from "./CartPopover.vue?vue&type=style&index=0&id=18f2feea&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18f2feea",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/build/components/VIcon/VIcon.vue').default,ClickCollectInput: require('/app/build/components/ClickCollectInput/ClickCollectInput.vue').default,BannerMessage: require('/app/build/components/BannerMessage/BannerMessage.vue').default,ProductListWithPromotions: require('/app/build/components/ProductList/ProductListWithPromotions.vue').default,CartButton: require('/app/build/components/cart/CartButton.vue').default,PrimeCartBanner: require('/app/build/components/PrimeCartBanner/PrimeCartBanner.vue').default,CartEmpty: require('/app/build/components/cart/CartEmpty.vue').default,VPopover: require('/app/build/components/VPopover/VPopover.vue').default})
