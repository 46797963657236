import { ref, Ref, onBeforeUnmount } from '@nuxtjs/composition-api'
import { CssUnitString } from '~/types/generic/Css'

/**
 * @param mediaQuery - a media query that can be consumed by window.matchMedia,
 * e.g., "(min-width: 1200px)"
 */
function useMediaQuery(mediaQuery: string): Ref<boolean> {
  if (process.server) return ref(false)

  const matcher = window.matchMedia(mediaQuery)

  const matches = ref(matcher.matches)

  function onChange() {
    matches.value = matcher.matches
  }

  // for Safari <= 13
  if (matcher.addEventListener) {
    matcher.addEventListener('change', onChange)
  } else {
    matcher.addListener(onChange)
  }

  onBeforeUnmount((): void => {
    // for Safari <= 13
    if (matcher.removeEventListener) {
      matcher.removeEventListener('change', onChange)
    } else {
      matcher.removeListener(onChange)
    }
  })

  return matches
}

/**
 * @param minWidth - min width to be used by a window.matchMedia query
 */
export default function useWindowMinWidth(
  minWidth: CssUnitString
): Ref<boolean> {
  return useMediaQuery(`(min-width: ${minWidth})`)
}
