
































import { computed, useContext, onMounted, useRouter } from '@nuxtjs/composition-api';
import { ROUTES } from '~/lib/routes';
import IProduct from '~/types/app/Product';
import { ETrackingType, EComponentName } from '~/lib/segment';
const __sfc_main = {
  name: 'OutOfStockModal'
};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  },
  updatedProducts: {
    type: (Array as () => IProduct[]),
    default: () => []
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    $segmentEvent,
    store,
    localePath,
    i18n
  } = useContext();
  const router = useRouter();
  const title = computed(() => {
    if (props.updatedProducts.length > 1) {
      return i18n.t('substitutes_popup_title', {
        count: props.updatedProducts.length
      });
    }

    return i18n.t('substitutes_popup_title_single');
  });
  const products = computed<IProduct[]>(() => {
    const products: IProduct[] = [];
    props.updatedProducts.forEach(updatedProduct => {
      const cartProduct = store.getters.getCartProducts.find(({
        id
      }: IProduct) => id === updatedProduct.id); // display current cart state and OOS products

      if (cartProduct) {
        products.push(cartProduct);
      } else if (updatedProduct.maxQuantity === 0) {
        products.push(updatedProduct);
      }
    });
    return products;
  });

  function clickHandler(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentContent: 'remove_and_checkout',
      componentName: 'preorder_oos_popup_dialog'
    });
    emit('refresh');
  }

  function replaceHandler(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentContent: 'replace_items',
      componentName: 'preorder_oos_popup_dialog'
    });
    router.push({
      path: localePath(ROUTES.PRODUCT_REPLACEMENT)
    });
  }

  onMounted(() => {
    $segmentEvent.IN_APP_MSG_SHOWN({
      componentName: EComponentName.preorderOosPopup,
      componentValue: products.value.map(product => product.sku).join(',')
    });
  });
  return {
    title,
    products,
    clickHandler,
    replaceHandler
  };
};

export default __sfc_main;
