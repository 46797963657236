var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-10"},[_c('h4',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('planned_order_checkout_heading')))]),_vm._v(" "),(_vm.showSpinnerOnDelivery)?_c('div',{class:[
      'spinner-wrapper',
      { '--short': _vm.onCheckoutState.deliveryOptions.length !== 3 } ]},[_c('PulseLoader',{attrs:{"loading":true,"color":_vm.flinkPink,"size":"1rem"}})],1):(
      _vm.onCheckoutState.deliveryOptions &&
      _vm.onCheckoutState.deliveryOptions.length
    )?_vm._l((_vm.onCheckoutState.deliveryOptions),function(option){return _c('DeliveryTimeOptionOnCheckout',_vm._b({key:option.type,staticClass:"mb-2",attrs:{"icon":_vm.iconMap[option.type]},on:{"open-timeslot-selection":function($event){return _vm.emitSelection(option.type, option.id)}}},'DeliveryTimeOptionOnCheckout',option,false))}):[_c('DeliveryTimeOptionOnCheckout',_vm._b({staticClass:"mb-2",attrs:{"type":_vm.ESchedulingOptionType.ASAP},on:{"open-timeslot-selection":function($event){return _vm.emit('select-option', {
          token: _vm.$store.state.plannedOrders.asapToken,
          type: _vm.ESchedulingOptionType.ASAP,
        })}}},'DeliveryTimeOptionOnCheckout',_vm.onCheckoutState[_vm.ESchedulingOptionType.ASAP],false)),_vm._v(" "),_c('DeliveryTimeOptionOnCheckout',_vm._b({attrs:{"type":_vm.ESchedulingOptionType.PLANNED},on:{"open-timeslot-selection":function($event){return _vm.emit('open-timeslot-selection')}}},'DeliveryTimeOptionOnCheckout',_vm.onCheckoutState[_vm.ESchedulingOptionType.PLANNED],false))]],2)}
var staticRenderFns = []

export { render, staticRenderFns }