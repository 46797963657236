import createHmac from 'create-hmac'
import { nanoid } from 'nanoid'
import { USER_ID_COOKIE } from '~/lib/constants'

const COOKIE_SEPARATOR = '.'

export const getUserCookie = ($cookies) =>
  $cookies?.get(USER_ID_COOKIE)?.split(COOKIE_SEPARATOR) ?? []

const setUserCookie = ($cookies, userDetails = []) =>
  // Store id in format: "userID.userHash"
  $cookies.set(USER_ID_COOKIE, userDetails.join(COOKIE_SEPARATOR))

export default ({ $config, $cookies, app }) => {
  const hmac = createHmac('sha256', $config.intercomIdentityVerificationSecret)
  const defaultUserId = nanoid(12)
  hmac.update(defaultUserId)
  const defaultUserHash = hmac.digest('hex')

  const userCookie = getUserCookie($cookies)

  if (userCookie.length) {
    setUserCookie($cookies, userCookie)
  } else {
    setUserCookie($cookies, [defaultUserId, defaultUserHash])
  }
  app.store.dispatch('account/setCookieData', defaultUserId)

  return $cookies
}
