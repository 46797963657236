







import { V1Decision } from '@flink/experiment';
import { FeatureFlags } from '~/types/app/experiments';
import { computed, useContext } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  flagKey: {
    type: (String as () => FeatureFlags),
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    store
  } = useContext();
  const isFeatureEnabled = computed<V1Decision>(() => {
    const result = store.getters['experiments/isFeatureEnabled'](props.flagKey);
    emit('decision', result);
    return result;
  });
  return {
    isFeatureEnabled
  };
};

export default __sfc_main;
