import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { LoggerFunction } from '~/types/app/Logger'
import { IParentCategory, IV2CategoryDetailsRaw } from '~/types/app/Category'
import transformV2CategoryDetails from '~/api/transformers/transform-v2-category-details'

interface IGetCategoriesParams {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  hubSlug: string
  slug: string
}

export default async function getCategoryBySlug({
  client,
  logger,
  locale,
  hubSlug,
  slug,
}: IGetCategoriesParams): Promise<IParentCategory> {
  const url = `v2/categories/${slug}`
  const headers = {
    locale,
    'hub-slug': hubSlug,
    'flink-product-images': 'square-thumbnails',
  }
  let category: IParentCategory = {} as IParentCategory

  try {
    const response = await get<IV2CategoryDetailsRaw>(
      client,
      logger,
      url,
      headers
    )

    category = transformV2CategoryDetails(response.data)
  } catch (error) {}

  return category
}
