



























import IProduct from '~/types/app/Product';
import { EProductPlacement } from '~/lib/segment';
import useCardSizeFromExperiment from '~/hooks/useCardSizeFromExperiment';
const __sfc_main = {
  name: 'ProductCardGrid'
};
__sfc_main.props = {
  products: {
    type: (Array as () => IProduct[]),
    required: true
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    required: true
  },
  listName: {
    type: String,
    default: ''
  },
  categoryId: {
    type: String,
    default: ''
  },
  categoryName: {
    type: String,
    default: ''
  },
  subCategoryId: {
    type: String,
    default: ''
  },
  subCategoryName: {
    type: String,
    default: ''
  },
  searchQueryId: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const cardSize = useCardSizeFromExperiment();
  return {
    cardSize
  };
};

export default __sfc_main;
