import {
  computed,
  useContext,
  useStore,
  ComputedRef,
} from '@nuxtjs/composition-api'
import { IAppState } from '~/types/app/State'
import { ICartFee, ICartFeeInfo, ECartFeeType } from '~/types/app/Cart'
import { TranslateResult } from 'vue-i18n'
import { centToEuro } from '~/lib/helpers'
import useCheckout from '~/hooks/checkout/useCheckout'

export default function useFee(): {
  dynamicDeliveryFeeText: ComputedRef<string | undefined>
  lateNightDescription: ComputedRef<TranslateResult>
  showDynamicDeliveryFeeBanner: ComputedRef<boolean>
  feesForFeeInfo: (fees: ICartFee[], depositAmount: number) => ICartFeeInfo[]
} {
  const store = useStore<IAppState>()
  const { i18n, $formatCurrency } = useContext()
  const { cartBlockingMessage } = useCheckout()

  const deliveryFees: string[] = [
    ECartFeeType.DELIVERY_FEE,
    ECartFeeType.PRIORITY,
    ECartFeeType.NO_RUSH,
    ECartFeeType.EXPRESS,
  ]

  const dynamicDeliveryFeeText = computed<string | undefined>(() => {
    const { currency } = store.state
    const {
      getCartSubTotalCentAmount,
      getIsClickAndCollect,
      getSmallOrderThresholdReached,
      getSmallOrderValueRemain,
      getIsKiosk,
      getDeliveryFee,
      getNextDeliveryTier,
    } = store.getters

    if (getIsClickAndCollect || getIsKiosk) {
      return
    }

    if (!getSmallOrderThresholdReached) {
      const valueRemain = $formatCurrency({
        amount: centToEuro(getSmallOrderValueRemain),
        currency,
      })
      return i18n.t('cart_popover_small_order_message', {
        valueRemain,
        smallOrderFee: valueRemain,
      }) as string
    }

    if (getDeliveryFee === 0) {
      return i18n.t(
        store.getters['subscription/isSubscribed']
          ? 'subscriptions_cart_free_delivery'
          : 'cart_delivery_free_tier'
      ) as string
    }

    const currentDeliveryPrice = $formatCurrency({
      amount: getDeliveryFee,
      currency,
    })

    if (!getNextDeliveryTier) {
      return i18n.t('cart_delivery_top_tier', {
        deliveryFee: currentDeliveryPrice,
      }) as string
    }

    const nextDeliveryFee = getNextDeliveryTier.deliveryPrice.centAmount
    const valueRemain =
      getNextDeliveryTier.minimumOrderValue.centAmount -
      getCartSubTotalCentAmount

    const valueRemainPrice = $formatCurrency({
      amount: centToEuro(valueRemain),
      currency,
    })

    if (nextDeliveryFee === 0) {
      return i18n.t(
        store.getters['subscription/isSubscribed']
          ? 'cart_delivery_free_tier_available_prime'
          : 'cart_delivery_free_tier_available_v2',
        {
          remainValue: valueRemainPrice,
        }
      ) as string
    }

    const savePrice = $formatCurrency({
      amount: getDeliveryFee - centToEuro(nextDeliveryFee),
      currency,
    })

    return i18n.t('cart_popover_next_tier_not_met', {
      remainValue: valueRemainPrice,
      saveValue: savePrice,
    }) as string
  })

  const lateNightDescription = computed<TranslateResult>(() => {
    const [fee] = store.state.checkout.lateNightConfig.fees

    if (!fee) {
      return ''
    }

    const { startTime: time, price } = fee
    const { centAmount, currency } = price

    const formattedPrice = $formatCurrency({
      amount: centToEuro(centAmount),
      currency,
    })

    return i18n.t('latenightsurcharge_banner_description', {
      time,
      price: formattedPrice,
    })
  })

  const showDynamicDeliveryFeeBanner = computed<boolean>(() => {
    return (
      !store.getters.getIsKiosk &&
      !store.getters.getIsClickAndCollect &&
      !cartBlockingMessage.value &&
      !!dynamicDeliveryFeeText.value
    )
  })

  function feesForFeeInfo(
    fees: ICartFee[],
    depositAmount: number
  ): ICartFeeInfo[] {
    const hideDeliveryFees =
      store.getters.getIsClickAndCollect || store.getters.getIsKiosk
    const filteredFees = hideDeliveryFees
      ? fees.filter(({ type }) => !deliveryFees.includes(type))
      : fees

    return [
      ...(depositAmount > 0 ? [{ type: ECartFeeType.DEPOSIT }] : []),
      ...filteredFees,
    ]
  }

  return {
    dynamicDeliveryFeeText,
    lateNightDescription,
    showDynamicDeliveryFeeBanner,
    feesForFeeInfo,
  }
}
