


























import { useMeta, useContext, defineComponent } from '@nuxtjs/composition-api'
import { ROUTES } from '~/lib/routes'

export default defineComponent({
  props: {
    statusCode: { type: Number, required: true },
    message: { type: String, required: true },
    title: { type: String, default: '' },
    icon: { type: String, default: '' },
    ctaTitle: { type: String, default: '' },
    ctaDestination: { type: String, default: '' },
  },
  setup(props) {
    const { i18n } = useContext()
    const { message, title, ctaTitle, ctaDestination, statusCode } = props

    const displayMessage = message || i18n.t(`error_page_title_${statusCode}`)
    const displayTitle = title || i18n.t(`error_page_title_${statusCode}`)
    const displayCta = ctaTitle || i18n.t(`error_page_cta_${statusCode}`)
    const ctaTarget = ctaDestination || ROUTES.HOME

    useMeta(() => {
      return {
        title: i18n.t('error_page_meta_title', {
          statusCode: props.statusCode,
        }) as string,
      }
    })

    return {
      displayCta,
      displayTitle,
      displayMessage,
      ctaTarget,
    }
  },
  head: {},
})
