/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { ICoordinates } from '~/types/app/Location'
import { LoggerFunction } from '~/types/app/Logger'
import {
  ESchedulingOptionType,
  ECheckoutDisplayState,
  IDeliveryOption,
} from '~/types/app/PlannedOrders'

export interface ICheckoutSchedulingResponse {
  asap?: {
    title: string
    subtitle?: string
    state: ECheckoutDisplayState
  }
  asap_id?: string
  prio_id?: string
  planned?: {
    title: string
    subtitle?: string
    state: ECheckoutDisplayState
  }
  delivery_options?: Array<IDeliveryOption>
  selected_slot: {
    id: string
    type: ESchedulingOptionType
    start: string
    end: string
  }
}

export default async function getOrderSchedulingCheckout(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  locale: string,
  hubSlug: string,
  deliveryTierId: string,
  location: ICoordinates,
  token?: string,
  cartId?: string
): Promise<ICheckoutSchedulingResponse | Record<string, undefined>> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
    'delivery-tier-id': deliveryTierId,
  }
  const { latitude, longitude } = location
  const tokenPart = token ? `&token=${encodeURIComponent(token)}` : ''
  const cartIdPart = cartId ? `&cart_id=${encodeURIComponent(cartId)}` : ''

  try {
    const response = await get<ICheckoutSchedulingResponse>(
      client,
      logger,
      `order-scheduling/checkout?lat=${latitude}&long=${longitude}${tokenPart}${cartIdPart}`,
      headers
    )
    return response.data
  } catch (error) {
    return {}
  }
}
