import Vue, { VNode } from 'vue'
import { buildParams, addClickHandler } from '~/components/VPopover/directive'

Vue.directive('popover', {
  bind(target: HTMLElement, binding) {
    const params = buildParams(binding)
    addClickHandler(target, params)
  },
  unbind(target) {
    target.$removePopoverClickHandlers?.()
  },
})

Vue.directive('lazyload', {
  inserted(e: HTMLElement, binding, vnode: VNode) {
    if (binding.value === undefined || binding.value) {
      vnode.context?.$lazyImageObserver.observe(e)
    }
  },
  unbind(e: HTMLElement, _, vnode: VNode) {
    vnode.context?.$lazyImageObserver.unobserve(e)
  },
})
