










const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const emptyIcons = ['categories/ice-cream', 'categories/drinks', 'categories/noodles-rice', 'categories/fruit'];
  return {
    emptyIcons
  };
};

export default __sfc_main;
