import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getSubscriptionStatus } from '~/api/services/get-subscription-status'
import { getSubscriptionEligibilityStatus } from '~/api/services/get-subscription-eligibility-status'
import { getSubscriptionDetail } from '~/api/services/get-subscription-detail'
import { IAppState } from '~/types/app/State'
import {
  ISubscriptionPlan,
  ISubscriptionPaymentMethod,
  ESubscriptionStatus,
  ISubscriptionEligibilityStatusResponse,
  ISubscriptionStatusResponse,
  ISubscriptionDetailResponse,
  ISubscriptionPaymentMethodsResponse,
  ISubscriptionPaymentResponse,
  ESubscriptionPaymentAPIResponseErrorCode,
  ESubscriptionPaymentErrorCode,
  ESubscriptionOriginComponent,
  ISubscriptionBillingAddress,
} from '~/types/app/Subscription'
import { getSubscriptionPlans } from '~/api/services/get-subscription-plans'
import { getSubscriptionPaymentMethods } from '~/api/services/get-subscription-payment-methods'
import { createSubscriptionPayment } from '~/api/services/create-subscription-payment'
import { IAdyenDropinState } from '~/components/PaymentForm/payment-form-types'
import { ROUTES } from '~/lib/routes'
import { createReturnUrl } from '~/components/PaymentForm/payment-utils/create-return-url'
import { AxiosError } from 'axios'
import { ICentPrice } from '~/types/app/Price'
import {
  SET_SUBSCRIPTION_ELIGIBLE_STATUS,
  SET_SUBSCRIPTION_MINIMUM_ORDER_VALUE,
  SET_SUBSCRIPTION_STATUS,
  SET_SUBSCRIPTION_DETAIL,
  SET_SUBSCRIPTION_PLANS,
  SET_SUBSCRIPTION_SELECTED_PLAN,
  SET_SUBSCRIPTION_PAYMENT_METHODS,
  SET_SUBSCRIPTION_ORIGIN_COMPONENT,
} from './mutation-types'

export interface ISubscritionState {
  isEligible: boolean | null
  minimumOrderValue: ICentPrice | null
  subscriptionStatus: ESubscriptionStatus | null
  subscriptionDetail: ISubscriptionDetailResponse | null
  plans: ISubscriptionPlan[]
  selectedPlan: string | null
  paymentMethod: ISubscriptionPaymentMethod | null
  originComponent: ESubscriptionOriginComponent | null
}

export const state = (): ISubscritionState => ({
  isEligible: null,
  minimumOrderValue: null,
  subscriptionStatus: null,
  subscriptionDetail: null,
  plans: [],
  selectedPlan: null,
  paymentMethod: null,
  originComponent: null,
})

export const getters: GetterTree<ISubscritionState, IAppState> = {
  isEligible: (state) => {
    return state.isEligible
  },
  isSubscribed: (state) => {
    return state.subscriptionStatus === ESubscriptionStatus.SUBSCRIBED
  },
  isUnsubscribed: (state) => {
    return state.subscriptionStatus === ESubscriptionStatus.UNSUBSCRIBED
  },
  isCanceled: (state) => {
    return !!state.subscriptionDetail?.canceledAt
  },
  selectedPlanObject: (state) => {
    return state.plans?.find((plan) => plan.id === state.selectedPlan)
  },
  freePrimeDeliveryRemain: (_, __, ___, rootGetters) => {
    if (!rootGetters.getFreeDeliveryTier) {
      return 0
    }

    return (
      rootGetters.getFreeDeliveryTier.minimumOrderValue.centAmount -
      rootGetters.getCartSubTotalPrice * 100
    )
  },
  freePrimeDeliveryReached: (_, getters) => {
    return getters.freePrimeDeliveryRemain <= 0
  },
}

export const mutations: MutationTree<ISubscritionState> = {
  [SET_SUBSCRIPTION_ELIGIBLE_STATUS](state, eligible) {
    state.isEligible = eligible
  },
  [SET_SUBSCRIPTION_MINIMUM_ORDER_VALUE](state, value) {
    state.minimumOrderValue = value
  },
  [SET_SUBSCRIPTION_STATUS](state, status) {
    state.subscriptionStatus = status
  },
  [SET_SUBSCRIPTION_DETAIL](state, detail) {
    state.subscriptionDetail = detail
  },
  [SET_SUBSCRIPTION_PLANS](state, plans) {
    state.plans = plans
  },
  [SET_SUBSCRIPTION_SELECTED_PLAN](state, id) {
    state.selectedPlan = id
  },
  [SET_SUBSCRIPTION_PAYMENT_METHODS](state, paymentMethod) {
    state.paymentMethod = paymentMethod
  },
  [SET_SUBSCRIPTION_ORIGIN_COMPONENT](state, component) {
    state.originComponent = component
  },
}

export const actions: ActionTree<ISubscritionState, IAppState> = {
  async getSubscriptionEligibilityStatus(
    { commit, state, rootState, rootGetters },
    { forceReload = false }
  ) {
    if (state.isEligible !== null && !forceReload) {
      return
    }

    if (!rootGetters['account/isUserAuthenticated']) {
      commit(SET_SUBSCRIPTION_ELIGIBLE_STATUS, null)
      return
    }

    const response: ISubscriptionEligibilityStatusResponse | undefined =
      await getSubscriptionEligibilityStatus({
        client: this.$apiSubscriptions,
        logger: this.$logger,
        locale: this.$i18n.locale,
        hubSlug: rootState.hub?.slug,
      })

    commit(SET_SUBSCRIPTION_ELIGIBLE_STATUS, response?.eligible)
    commit(SET_SUBSCRIPTION_MINIMUM_ORDER_VALUE, response?.minimumOrderValue)
  },
  async getSubscriptionStatus(
    { commit, state, rootState, rootGetters },
    { forceReload = false }
  ) {
    if (state.subscriptionStatus !== null && !forceReload) {
      return
    }

    if (!state.isEligible || !rootGetters['account/isUserAuthenticated']) {
      return
    }

    if (!rootState.hub?.slug) {
      return
    }

    const response: ISubscriptionStatusResponse | undefined =
      await getSubscriptionStatus({
        client: this.$apiSubscriptions,
        logger: this.$logger,
        locale: this.$i18n.locale,
        hubSlug: rootState.hub?.slug,
      })

    commit(SET_SUBSCRIPTION_STATUS, response?.status)
  },
  async getSubscriptionDetail({ commit, getters, rootState, rootGetters }) {
    if (!getters.isSubscribed || !rootGetters['account/isUserAuthenticated']) {
      return
    }

    const response: ISubscriptionDetailResponse | undefined =
      await getSubscriptionDetail({
        client: this.$apiSubscriptions,
        logger: this.$logger,
        locale: this.$i18n.locale,
        hubSlug: rootState.hub?.slug,
      })

    if (response) {
      commit(SET_SUBSCRIPTION_DETAIL, response)
      commit(SET_SUBSCRIPTION_STATUS, response.status)
    }
  },
  async getSubscriptionPlans({ commit, state, rootGetters }) {
    const genericError = {
      code: ESubscriptionPaymentErrorCode.GENERIC,
    }
    if (!state.isEligible || !rootGetters['account/isUserAuthenticated']) {
      throw genericError
    }

    try {
      const response: ISubscriptionPlan[] | undefined =
        await getSubscriptionPlans(this.$apiSubscriptions, this.$logger)

      if (response) {
        commit(SET_SUBSCRIPTION_PLANS, response)
      }
    } catch (error: any) {
      throw genericError
    }
  },
  setSelectedPlan({ commit }, id) {
    commit(SET_SUBSCRIPTION_SELECTED_PLAN, id)
  },
  async getPaymentMethods({ commit, state, rootState, rootGetters }) {
    if (!state.isEligible || !rootGetters['account/isUserAuthenticated']) {
      return
    }

    const response: ISubscriptionPaymentMethodsResponse | undefined =
      await getSubscriptionPaymentMethods({
        client: this.$apiSubscriptions,
        logger: this.$logger,
        locale: this.$i18n.locale,
        hubSlug: rootState.hub?.slug,
      })

    if (response) {
      commit(SET_SUBSCRIPTION_PAYMENT_METHODS, response.paymentMethod)
    }
  },
  async createPayment(
    { state, rootState, rootGetters },
    adyenState: IAdyenDropinState
  ) {
    if (
      !state.isEligible ||
      !state.selectedPlan ||
      !rootGetters['account/isUserAuthenticated']
    ) {
      return
    }

    const token = {
      ...adyenState.data,
      returnUrl: createReturnUrl({
        isClientSide: true,
        location: window.location,
        route: this.localeRoute({
          name: ROUTES.PRIME_PROCESS_PAYMENT,
        }) as Vue['$route'],
      }),
      additionalData: {
        allow3DS2: true,
      },
      channel: 'Web',
    }

    try {
      const billingAddress: ISubscriptionBillingAddress = {
        street: rootState.shippingAddress?.street_address_1 || '',
        houseNumber: '',
        postalCode: rootState.shippingAddress?.postal_code || '',
        city: rootState.shippingAddress?.city || '',
        countryCode: rootState.shippingAddress?.country || '',
      }

      const response: ISubscriptionPaymentResponse | undefined =
        await createSubscriptionPayment({
          client: this.$apiSubscriptions,
          logger: this.$logger,
          locale: this.$i18n.locale,
          hubSlug: rootState.hub?.slug,
          planId: state.selectedPlan,
          pspSdkData: JSON.stringify(token),
          billingAddress,
        })
      if (response) {
        return JSON.parse(response['3ds'])
      }
    } catch (e: any) {
      const err = e as AxiosError
      const error = {
        code: ESubscriptionPaymentErrorCode.PAYMENT_FAILED,
      }

      if (
        err.response?.data?.code ===
        ESubscriptionPaymentAPIResponseErrorCode.SUBSCRIPTION_ALREADY_EXISTS
      ) {
        error.code = ESubscriptionPaymentErrorCode.ALREADY_SUBSCRIBED
      }

      if (
        err.response?.data?.code ===
        ESubscriptionPaymentAPIResponseErrorCode.PROCESSING_PAYMENT
      ) {
        error.code = ESubscriptionPaymentErrorCode.PROCESSING_PAYMENT
      }

      throw error
    }
  },
  setOriginComponent({ commit }, component: ESubscriptionOriginComponent) {
    commit(SET_SUBSCRIPTION_ORIGIN_COMPONENT, component)
  },
  resetOriginComponent({ commit }) {
    commit(SET_SUBSCRIPTION_ORIGIN_COMPONENT, null)
  },
  clear({ commit }) {
    commit(SET_SUBSCRIPTION_ELIGIBLE_STATUS, null)
    commit(SET_SUBSCRIPTION_STATUS, null)
    commit(SET_SUBSCRIPTION_DETAIL, null)
    commit(SET_SUBSCRIPTION_SELECTED_PLAN, null)
    commit(SET_SUBSCRIPTION_PLANS, null)
    commit(SET_SUBSCRIPTION_PAYMENT_METHODS, null)
  },
}
