import { EDirection, IPositionParams, IPositionStyle } from './'

enum EPositionX {
  left = 'left',
  center = 'center',
  right = 'right',
}

enum EPositionY {
  top = 'top',
  center = 'center',
  bottom = 'bottom',
}

interface IPosition {
  xWord: EPositionX
  yWord: EPositionY
}
interface IPopoverPosition {
  anchorPosition: string
  style: IPositionStyle
  arrowSpacing?: number
}

const [centerStart, centerMid, centerEnd] = [0.2, 0.6, 0.8]
const isCentered = ({ x, x2 }: { x: number; x2: number }) =>
  x > centerStart && x2 < centerEnd

function getLengthPercentage(
  objCoord: number,
  containerCoord: number,
  containerLen: number
) {
  const step = 1 / containerLen
  const objDist = Math.abs(objCoord - containerCoord)
  return objDist * step
}

function getPosition(targetRect: DOMRect, containerEl: Element): IPosition {
  const { x, right: x2, y, bottom: y2 } = targetRect
  const { clientWidth, clientHeight } = containerEl
  const { x: containerX, y: containerY } = containerEl.getBoundingClientRect()
  // 0 is start edge, 1 is end edge, 0.5 is center
  const [xPoint, x2Point, yPoint, y2Point] = [
    getLengthPercentage(x, containerX, clientWidth),
    getLengthPercentage(x2, containerX, clientWidth),
    getLengthPercentage(y, containerY, clientHeight),
    getLengthPercentage(y2, containerY, clientHeight),
  ]

  const xMid = x2Point - Math.abs(x2Point - xPoint) / 2
  const yMid = y2Point - Math.abs(y2Point - yPoint) / 2

  const xCentered = isCentered({
    x: xPoint,
    x2: x2Point,
  })
  const xPosition = xMid > centerMid ? EPositionX.right : EPositionX.left
  const yPosition = yMid > centerMid ? EPositionY.bottom : EPositionY.top

  return {
    xWord: xCentered ? EPositionX.center : xPosition,
    yWord: yPosition,
  }
}

const getPositionValue = (num: number, offset: number) => `${num + offset}px`

export function getPositionStyle(
  targetRect: DOMRect,
  {
    direction = EDirection.down,
    offsetX = 0,
    offsetY = 0,
  }: IPositionParams = {},
  containerEl: Element | undefined | null,
  arrowAnchor: Element | undefined | null
): IPopoverPosition {
  if (!containerEl) {
    containerEl = document.documentElement
  }
  const { x, y, height, right: targetRight, bottom: targetBottom } = targetRect
  const {
    left: containerLeft,
    right: containerRight,
    bottom: containerBottom,
    top: containerTop,
  } = containerEl.getBoundingClientRect()

  const position = getPosition(targetRect, containerEl)
  const anchorPosition = `${position.yWord}-${position.xWord}`
  const style: IPositionStyle = {}
  let arrowSpacing
  if (position.xWord === EPositionX.center) {
    style.left = '50%'
  } else {
    if (position.xWord === EPositionX.left) {
      style.left = getPositionValue(x - containerLeft, offsetX)
    }
    if (position.xWord === EPositionX.right) {
      style.right = getPositionValue(containerRight - targetRight, offsetX)
    }
  }
  if (direction === EDirection.up) {
    style.bottom =
      position.yWord === EPositionY.bottom
        ? getPositionValue(containerBottom - targetBottom + height, offsetY)
        : 'auto'
  } else {
    style.top =
      position.yWord === EPositionY.top
        ? getPositionValue(y - containerTop + height, offsetY)
        : 'auto'
  }

  if (arrowAnchor) {
    const arrowAnchorRect = arrowAnchor.getBoundingClientRect()

    const accessor = position.xWord === EPositionX.right ? 'right' : 'left'
    const delta = Math.abs(arrowAnchorRect[accessor] - targetRect[accessor])

    arrowSpacing = delta + arrowAnchorRect.width / 2
  }

  return {
    anchorPosition,
    style,
    arrowSpacing,
  }
}
