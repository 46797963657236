export const styles = {
  body: {
    width: '576px',
    height: '900px',
    margin: 0,
    backgroundColor: '#fff',
    fontFamily: "'Lucida Console', monospace",
    fontSize: '18px',
    paddingTop: '10px',
    paddingRight: '10px',
    paddingBottom: '100px',
    paddingLeft: '10px',
    boxSizing: 'border-box',
  },
  p: {
    margin: '3px 0',
    fontSize: '22px',
  },
  table: {
    width: '100%',
  },
  borderTopBottom: {
    borderWidth: '1px 0 1px 0',
    borderStyle: 'dashed',
    borderColor: '#000000',
    margin: '3px 0',
  },
  borderBottom: {
    borderWidth: '1px 0 1px 0',
    borderStyle: 'dashed',
    borderColor: '#000000',
    margin: '3px 0',
    borderTop: 0,
  },
  borderTop: {
    borderWidth: '1px 0 0 0',
    borderStyle: 'dashed',
    borderColor: '#000000',
    margin: '3px 0',
  },
  boldText: {
    fontWeight: '900',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  bottomSpacing: {
    paddingBottom: '20px',
  },
}
