































































import { EComponentContent, EComponentName, ETrackingType, getScreenName } from '~/lib/segment';
import { computed, ref, useContext, useRoute, useRouter, useStore, onMounted, watch } from '@nuxtjs/composition-api';
import { theme } from '~/tailwind.config';
import useWindowMinWidth from '~/hooks/useWindowMinWidth';
import IRootState, { EDeliveryAddressHintType } from '~/types/app/State';
import { ESchedulingState } from '~/types/app/PlannedOrders';
import { eventBus as popoverEventBus } from '~/components/VPopover/event-bus';
import LocationChangeHintPopover from '~/components/LocationChangeHintPopover/LocationChangeHintPopover.vue';
import DeliveryBannerGenericPopover from '~/components/DeliveryBannerGenericPopover/DeliveryBannerGenericPopover.vue';
type PopoverTargets = Record<EDeliveryAddressHintType, HTMLDivElement | null>;
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({
  name: 'DeliveryBannerAddress'
});

__sfc_main.props = {
  deliveryAddress: {
    type: String,
    default: ''
  },
  onboardingHintShown: {
    type: Boolean,
    default: false
  },
  canShowLocationChangeHint: {
    type: Boolean,
    default: true
  },
  showNoDeliveryToLocationPopover: {
    type: Boolean,
    default: false
  },
  schedulingState: {
    type: (String as () => ESchedulingState),
    default: 'CTA_PICK_SLOT'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const onboardingHintDelay = 5000;
  const POPOVER_NAMES = {
    [EDeliveryAddressHintType.INITIAL_ONBOARDING]: 'location-change-hint',
    [EDeliveryAddressHintType.NO_LONGER_IN_DELIVERY]: 'no-longer-in-delivery-tooltip'
  };
  const props = __props;
  const emit = __ctx.emit;
  const {
    $segmentEvent
  } = useContext();
  const store = useStore<IRootState>();
  const route = useRoute();
  const router = useRouter();
  const screenSizeMd = (`${parseInt(theme.screens.md, 10)}px` as `${number}px`);
  const isMd = useWindowMinWidth(screenSizeMd);
  const isAddressDefined = computed<boolean>(() => {
    return store.getters.getIsOnboardSuccess && !!props.deliveryAddress;
  });

  function onDeliveryAddressClicked(): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: getScreenName(route.value),
      componentName: EComponentName.addressLine,
      componentContent: isAddressDefined.value ? EComponentContent.address : undefined
    });
    emit('delivery-banner-clicked');
  }

  const deliveryBannerElement = ref<HTMLDivElement | null>(null);
  const popoverTargets = computed<PopoverTargets>(() => {
    return {
      [EDeliveryAddressHintType.INITIAL_ONBOARDING]: deliveryBannerElement.value,
      [EDeliveryAddressHintType.NO_LONGER_IN_DELIVERY]: deliveryBannerElement.value
    };
  });
  const popoverArrowAnchors = computed<PopoverTargets>(() => {
    return {
      [EDeliveryAddressHintType.INITIAL_ONBOARDING]: isMd.value ? deliveryBannerElement.value : null,
      [EDeliveryAddressHintType.NO_LONGER_IN_DELIVERY]: deliveryBannerElement.value
    };
  });
  const deliveryBannerTargets = computed<string>(() => getTargetData(popoverTargets.value, deliveryBannerElement.value));

  function getTargetData(targets: PopoverTargets, element: HTMLDivElement | null): string {
    if (!element) {
      return '';
    }

    const accTargets = ([] as string[]);
    const entries = (Object.entries(targets) as Array<[EDeliveryAddressHintType, PopoverTargets[keyof PopoverTargets]]>);
    entries.forEach(([key, value]) => {
      if (value === element) {
        accTargets.push(POPOVER_NAMES[key]);
      }
    });
    return accTargets.join(' ');
  }

  const currentOnboardingHint = ref<EDeliveryAddressHintType>();

  function showOnboardingHint(): void {
    if (!props.onboardingHintShown) return;
    setCurrentOnboardingHint();
    const key = currentOnboardingHint.value;

    if (!key) {
      return;
    }

    popoverEventBus.$emit('popover:show', {
      target: popoverTargets.value[key],
      arrowAnchor: popoverArrowAnchors.value[key],
      name: POPOVER_NAMES[key]
    });
    emit('show-onboarding-hint', key);
  }

  function hideOnboardingHint(): void {
    const key = currentOnboardingHint.value;

    if (!key) {
      return;
    }

    popoverEventBus.$emit('popover:hide', {
      name: POPOVER_NAMES[key]
    });
    currentOnboardingHint.value = undefined;
  }

  function setCurrentOnboardingHint(): void {
    if (props.canShowLocationChangeHint) {
      currentOnboardingHint.value = EDeliveryAddressHintType.INITIAL_ONBOARDING;
      return;
    }

    currentOnboardingHint.value = undefined;
  }

  watch(() => props.onboardingHintShown, () => {
    // only handle negative case, positive case needs to wait for experiment to finish
    if (!props.onboardingHintShown) {
      hideOnboardingHint();
    }
  });
  watch(isMd, showOnboardingHint);
  watch(() => props.showNoDeliveryToLocationPopover, showNoLongerInDeliveryPopup);

  function showNoLongerInDeliveryPopup(isVisible: boolean) {
    isVisible && popoverEventBus.$emit('popover:show', {
      target: popoverTargets.value[EDeliveryAddressHintType.NO_LONGER_IN_DELIVERY],
      arrowAnchor: popoverArrowAnchors.value[EDeliveryAddressHintType.NO_LONGER_IN_DELIVERY],
      name: POPOVER_NAMES[EDeliveryAddressHintType.NO_LONGER_IN_DELIVERY]
    });
  }

  function showLocationOnboardingHint() {
    if (!isAddressDefined.value) {
      popoverEventBus.$emit('popover:show', {
        target: popoverTargets.value[EDeliveryAddressHintType.INITIAL_ONBOARDING],
        arrowAnchor: popoverArrowAnchors.value[EDeliveryAddressHintType.INITIAL_ONBOARDING],
        name: POPOVER_NAMES[EDeliveryAddressHintType.INITIAL_ONBOARDING]
      });
    }
  }

  router.afterEach(() => {
    showLocationOnboardingHint();
  });
  onMounted(() => {
    setTimeout(() => {
      showLocationOnboardingHint();
    }, onboardingHintDelay);
  });
  return {
    EDeliveryAddressHintType,
    POPOVER_NAMES,
    emit,
    isAddressDefined,
    onDeliveryAddressClicked,
    deliveryBannerElement,
    deliveryBannerTargets
  };
};

__sfc_main.components = Object.assign({
  LocationChangeHintPopover,
  DeliveryBannerGenericPopover
}, __sfc_main.components);
export default __sfc_main;
