import locale9ec66352 from '../../i18n/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"en","silentTranslationWarn":true,"silentFallbackWarn":true,"dateTimeFormats":{"en":{"short-without-year":{"day":"numeric","month":"short"}},"de-DE":{"short-without-year":{"day":"numeric","month":"short"}},"en-DE":{"short-without-year":{"day":"numeric","month":"short"}},"nl-NL":{"short-without-year":{"day":"numeric","month":"short"}},"en-NL":{"short-without-year":{"day":"numeric","month":"short"}}}},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en","file":"en.json"},{"code":"de-DE","iso":"de-DE","file":"de.json"},{"code":"en-DE","iso":"en-DE","file":"en.json"},{"code":"nl-NL","iso":"nl-NL","file":"nl.json"},{"code":"en-NL","iso":"en-NL","file":"en.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/app/build/i18n",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "https://www.goflink.com",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en","file":"en.json"},{"code":"de-DE","iso":"de-DE","file":"de.json"},{"code":"en-DE","iso":"en-DE","file":"en.json"},{"code":"nl-NL","iso":"nl-NL","file":"nl.json"},{"code":"en-NL","iso":"en-NL","file":"en.json"}],
  localeCodes: ["en","de-DE","en-DE","nl-NL","en-NL"],
  additionalMessages: [],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'de.json': () => import('../../i18n/de.json' /* webpackChunkName: "lang-de.json" */),
  'nl.json': () => import('../../i18n/nl.json' /* webpackChunkName: "lang-nl.json" */),
}
