import { omit } from 'lodash-es'
import { IVoucherWallet, IVoucherWalletRaw } from '~/types/app/VoucherWallet'

export function transformVoucherWallet(
  vouchers: IVoucherWalletRaw[] | undefined
): IVoucherWallet[] {
  if (vouchers === undefined) return []

  return vouchers.map<IVoucherWallet>((voucher) => {
    return {
      ...omit(voucher, 'is_applicable', 'rejection_reason', 'sub_title'),
      isApplicable: voucher.is_applicable,
      rejectionReason: voucher.rejection_reason,
      subTitle: voucher.sub_title,
      expiresAt: voucher.expires_at,
    }
  })
}
