import { BREAKPOINTS } from '~/lib/constants'
import { ISegmentEvents } from '~/lib/segment'

export default ({ $segmentEvent }: { $segmentEvent: ISegmentEvents }): void => {
  const measureDevice = () => {
    $segmentEvent.DEVICE_MEASURED({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }
  measureDevice()
  Object.values(BREAKPOINTS).forEach((breakpoint) => {
    const matcher = window.matchMedia(`(max-width: ${breakpoint}px)`)

    // For legacy browsers which don't supprt MediaQueryList.addEventListener()
    if (matcher?.addEventListener) {
      matcher.addEventListener('change', measureDevice)
    } else {
      matcher.addListener(measureDevice)
    }
  })
}
