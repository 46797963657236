

















import { computed } from '@vue/composition-api';
import { getCloudinaryUrl } from './get-cloudinary-url';
const __sfc_main = {};
__sfc_main.props = {
  src: {
    type: String,
    required: true
  },
  alt: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  params: {
    type: String,
    default: ''
  },
  noLazy: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const opts = {
    width: props.width,
    height: props.height,
    params: props.params
  };
  const png = computed(() => getCloudinaryUrl(props.src, 'png', opts));
  const sources = computed(() => {
    return [{
      srcset: getCloudinaryUrl(props.src, 'heic', opts),
      type: 'image/heic'
    }, {
      srcset: getCloudinaryUrl(props.src, 'webp', opts),
      type: 'image/webp'
    }];
  });
  return {
    png,
    sources
  };
};

export default __sfc_main;
