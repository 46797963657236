import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { LoggerFunction } from '~/types/app/Logger'
import ICategory, { IV2CategoryRaw } from '~/types/app/Category'

import transformV2Categories from '~/api/transformers/transform-v2-categories'

interface IGetCategoriesParams {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  hubSlug: string
}

export default async function getCategories({
  client,
  logger,
  locale,
  hubSlug,
}: IGetCategoriesParams): Promise<ICategory[]> {
  const url = '/v2/categories'
  const headers = { locale, 'hub-slug': hubSlug }

  let categories: ICategory[] = []

  try {
    const response = await get<IV2CategoryRaw>(client, logger, url, headers)
    categories = transformV2Categories(response.data)
  } catch (error) {}

  return categories
}
