/* eslint-disable camelcase */
import IPrice from '~/types/app/Price'
import { IAddress, ICoordinates } from '~/types/app/Location'
import { ICartFee } from '~/types/app/Cart'
import { IOrderDetailsProduct } from './Product'

export enum EOrderDeliveryCase {
  CONFIRMATION = 'CONFIRMATION',
  PACKING = 'PACKING',
  ON_WAY = 'ON_WAY',
  DELIVERED = 'DELIVERED',
}

export enum EOrderDetailsStatus {
  CREATED = 'STATE_CREATED',
  PICKER_ACCEPTED = 'STATE_PICKER_ACCEPTED',
  PACKED = 'STATE_PACKED',
  RIDER_CLAIMED = 'STATE_RIDER_CLAIMED',
  ON_ROUTE = 'STATE_ON_ROUTE',
  ARRIVED = 'STATE_ARRIVED',
  DELIVERED = 'STATE_DELIVERED',
  CANCELLED = 'STATE_CANCELLED',
}

export enum EOrderDeliveryState {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

export interface IOrderDeliveryStatus {
  case: EOrderDeliveryCase
  state: EOrderDeliveryState
  time: string
  extra_message: boolean
}

interface IEvent {
  status: string
  duration: number
}

export interface IOrderDetailsHub {
  slug: string
  city: string
  country: string
}

export enum EShippingMethodName {
  CLICK_AND_COLLECT = 'Click & Collect',
  OTHER = 'Dynamic Country',
}

export interface IOrderDetails {
  id: string
  token: string
  number: string
  created: string
  tracking_client_id: string
  status: EOrderDetailsStatus
  subtotal: IPrice
  shipping_price: IPrice
  discount: IPrice
  total: IPrice
  lines: IOrderDetailsProduct[]
  events: IEvent[]
  shipping_address: IAddress
  shipping_info: {
    shipping_method_name: EShippingMethodName
  }
  hub_details: IOrderDetailsHub
  discounted_delivery_fee?: IPrice
  rider_tip?: IPrice
  refundable: boolean
  storage_fee?: IPrice
  fees: Array<ICartFee>
}

export enum EDeliveryStatus {
  ON_THE_WAY = 'ON_THE_WAY',
  DELIVERY_SOON = 'DELIVERY_SOON',
  DELIVERED = 'DELIVERED',
  DELIVERED_LATE = 'DELIVERED_LATE',
  DELIVERED_FAST = 'DELIVERED_FAST',
}

export interface IOrderStatus {
  hub_coordinate: ICoordinates
  user_coordinate: ICoordinates
  rider_coordinate: ICoordinates
  delivery_time: {
    delivery_status: string
    formatted_time: string
    status: EDeliveryStatus
    time: number
  }
  state: EOrderDetailsStatus
  status: IOrderDeliveryStatus[]
  is_stacked: boolean
  show_map: boolean
  is_cancelled?: boolean
}

export interface IOrderProgress {
  title: string
  subtitle: string
  progress: number
  eta: {
    title: string
    subtitle: string
  }
  is_partner_courier: boolean
}

export interface IOrderStep {
  icon: string
  title: {
    text: string
    color: string
    type: 'NORMAL | BOLD'
  }
  message: {
    text: string
    color: string
    type: 'NORMAL | BOLD'
  }
  time: {
    text: string
    color: string
    type: 'NORMAL | BOLD'
  }
}
