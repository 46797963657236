




































import { useContext, watch } from '@nuxtjs/composition-api';
import { COUNTRIES_DEFAULT_LOCALES } from '~/lib/constants';
import { FALLBACK_LOCALE } from '~/localesConfig';
import { snakeCase } from 'lodash-es';
const __sfc_main = {};
__sfc_main.props = {
  shown: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    i18n
  } = useContext();
  const availableCountries = COUNTRIES_DEFAULT_LOCALES.map(locale => locale.split('-')[1]);

  const getLocalesForCountry = (country: string) => {
    const localisedOptions = COUNTRIES_DEFAULT_LOCALES.filter(locale => locale.includes(country));
    return [...localisedOptions, `${FALLBACK_LOCALE}-${country}`];
  };

  const emit = __ctx.emit;
  watch(() => i18n.locale, () => {
    emit('closeModal');
  });
  return {
    snakeCase,
    availableCountries,
    getLocalesForCountry,
    emit
  };
};

export default __sfc_main;
