import { render, staticRenderFns } from "./GlobalHeader.vue?vue&type=template&id=729385ec&scoped=true&"
import script from "./GlobalHeader.vue?vue&type=script&lang=ts&"
export * from "./GlobalHeader.vue?vue&type=script&lang=ts&"
import style0 from "./GlobalHeader.vue?vue&type=style&index=0&lang=postcss&"
import style1 from "./GlobalHeader.vue?vue&type=style&index=1&id=729385ec&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "729385ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/build/components/VIcon/VIcon.vue').default,Button: require('/app/build/components/Button/Button.vue').default,DeliveryBannerAddress: require('/app/build/components/DeliveryBanner/DeliveryBannerAddress/DeliveryBannerAddress.vue').default,DeliveryBannerETA: require('/app/build/components/DeliveryBanner/DeliveryBannerETA/DeliveryBannerETA.vue').default,DeliveryBanner: require('/app/build/components/DeliveryBanner/DeliveryBanner.vue').default,CartButton: require('/app/build/components/cart/CartButton.vue').default,NavbarSecondary: require('/app/build/components/GlobalHeader/NavbarSecondary.vue').default,CartPopover: require('/app/build/components/cart/CartPopover.vue').default,StoreClosedModal: require('/app/build/components/StoreClosing/StoreClosedModal/StoreClosedModal.vue').default})
