import { EClosureCase, IHubClosure } from '~/types/app/Hub'
import {
  EStoreClosedModalIconType,
  IStoreClosedInformation,
} from '~/types/app/State'
import { ITranslationInput } from '~/types/app/I18n'

export const getDefaultStoreClosedTitle = (
  closureCase: EClosureCase
): ITranslationInput => {
  switch (closureCase) {
    case EClosureCase.TEMPORARY:
      return { key: 'closure_temporary_title' }
    case EClosureCase.REGULAR:
    default:
      return { key: 'closure_regular_title' }
  }
}

export const getDefaultStoreClosedMessage = (
  closureCase: EClosureCase
): ITranslationInput => {
  switch (closureCase) {
    case EClosureCase.TEMPORARY:
      return { key: 'closure_temporary_message' }
    case EClosureCase.REGULAR:
    default:
      return { key: 'closure_regular_message' }
  }
}

export const getDefaultStoreClosedIcon = (
  closureCase: EClosureCase
): string => {
  switch (closureCase) {
    case EClosureCase.TEMPORARY:
      return 'bicycle'
    case EClosureCase.REGULAR:
    default:
      return 'store-closed'
  }
}

export const getDefaultStoreClosedButtonTitle = (
  closureCase: EClosureCase
): ITranslationInput => {
  switch (closureCase) {
    case EClosureCase.TEMPORARY:
      return { key: 'closure_temporary_button_title' }
    case EClosureCase.REGULAR:
    default:
      return { key: 'closure_regular_button_title' }
  }
}

export const getStoreClosedInformation = ({
  case: closureCase,
  title,
  message,
  button_title: buttonTitle,
  icon,
}: IHubClosure): IStoreClosedInformation => {
  return {
    title: title || getDefaultStoreClosedTitle(closureCase),
    message: message || getDefaultStoreClosedMessage(closureCase),
    buttonTitle: buttonTitle || getDefaultStoreClosedButtonTitle(closureCase),
    icon: {
      type: icon
        ? EStoreClosedModalIconType.EXTERNAL
        : EStoreClosedModalIconType.INTERNAL,
      src: icon || getDefaultStoreClosedIcon(closureCase),
    },
  }
}
