





































































import { nanoid } from 'nanoid'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {
  defineComponent,
  computed,
  ref,
  watch,
  useSlots,
  nextTick,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'shown',
    event: 'update',
  },
  props: {
    header: { type: String, default: '' },
    image: { type: String, default: '' },
    hideClose: { type: Boolean, default: false },
    slim: { type: Boolean, default: false },
    long: { type: Boolean, default: false },
    withoutMinHeight: { type: Boolean, default: false },
    withMaxHeight: { type: Boolean, default: false },
    isFullScreen: { type: Boolean, default: false },
    sheetView: { type: Boolean, default: false },
    shown: { type: Boolean, default: false },
    forcedDimensions: {
      type: Object as () => Record<string, string>,
      default: undefined,
    },
  },
  emits: ['hide', 'show'],

  setup(props, { emit }) {
    const uid = 'modal_' + nanoid()
    const visible = ref(props.shown)
    const focusableElements = ref<HTMLElement[]>([])
    const $modal = ref<HTMLElement | null>(null)
    const $body = ref<HTMLElement | null>(null)
    const visibleVH = ref<number>(0)
    const slots = useSlots()

    const hasHeader = computed<boolean>(() => {
      return Boolean(slots?.header?.()?.length || props.header)
    })

    const firstFocusableElement = computed<HTMLElement>(() => {
      return focusableElements.value[0]
    })

    const lastFocusableElement = computed<HTMLElement>(() => {
      return focusableElements.value[focusableElements.value.length - 1]
    })

    const style = computed<Record<string, string>>(() => {
      if (props.forcedDimensions) {
        return props.forcedDimensions
      }
      if (props.isFullScreen) {
        return {
          '--modal-width': '100vw',
          '--modal-min-height': `${visibleVH.value}px`,
          '--rounded': '0px',
        }
      }
      return {
        '--modal-width': props.slim ? '500px' : '700px',
        '--modal-min-height': props.long ? '520px' : '400px',
        '--rounded': '1rem',
      }
    })

    const isVisible = computed({
      get: () => visible.value,
      set: (visibleInput) => {
        visible.value = visibleInput
        emit('update', visibleInput)
      },
    })

    const show = (): void => {
      isVisible.value = true
      nextTick(() => {
        $modal.value?.focus()
        focusableElements.value = $modal.value
          ? Array.from(
              $modal.value.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
              )
            )
          : []
        $body.value && disableBodyScroll($body.value)
        document.addEventListener('keydown', onKeyDown)
        emit('show')
      })
    }

    const hide = (silent = false): void => {
      $body.value && enableBodyScroll($body.value)
      document.removeEventListener('keydown', onKeyDown)
      if (!silent) emit('hide')
      isVisible.value = false
    }

    const onClick = (e: MouseEvent) => {
      e.stopPropagation()
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if ($modal.value && e.key !== 'Tab' && e.key !== 'Escape') return
      if (e.key === 'Escape') {
        e.stopPropagation()
        return hide()
      }
      if (
        e.shiftKey &&
        document.activeElement === firstFocusableElement.value
      ) {
        lastFocusableElement.value?.focus()
        e.preventDefault()
      } else if (document.activeElement === lastFocusableElement.value) {
        firstFocusableElement.value.focus()
        e.preventDefault()
      }
    }

    const adjustViewportHeight = () => {
      visibleVH.value = window.innerHeight
    }

    onMounted(() => {
      window.addEventListener('resize', adjustViewportHeight)
      if (props.shown) {
        show()
      }
    })

    onUnmounted(() => {
      hide(true)
      window.removeEventListener('resize', adjustViewportHeight)
    })

    watch(
      () => props.shown,
      (shownChangedValue) => {
        if (shownChangedValue) {
          show()
        } else {
          hide(true)
        }
      }
    )

    return {
      $modal,
      $body,
      firstFocusableElement,
      focusableElements,
      hide,
      isVisible,
      lastFocusableElement,
      onClick,
      onKeyDown,
      show,
      style,
      uid,
      visible,
      hasHeader,
    }
  },
})
