

























































import { ROUTES } from '~/lib/routes';
import { ref, computed, useContext, useRouter } from '@nuxtjs/composition-api';
import IProduct from '~/types/app/Product';
import { ECartErrorCode, CartErrorInfo } from '~/types/app/Cart';
import { usePrepareCartForCheckout } from '~/hooks/checkout/usePrepareCartForCheckout';
enum IconName {
  cart = 'cart',
  checkout = 'checkout',
}
const __sfc_main = {};
__sfc_main.props = {
  variant: {
    type: String,
    default: 'block'
  },
  hideEmpty: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  payButton: {
    type: Boolean,
    default: false
  },
  navigateToCart: {
    type: Boolean,
    default: false
  },
  icon: {
    type: (String as () => keyof typeof IconName),
    default: IconName.cart
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const props = __props;
  const {
    store,
    $segmentEvent,
    localePath
  } = useContext();
  const router = useRouter();
  const {
    isReadyForCheckout,
    prepareCartForCheckout
  } = usePrepareCartForCheckout();
  const validationError = ref<ECartErrorCode | undefined>();
  const validationErrorInfo = ref<CartErrorInfo | undefined>();
  const showItemsNotAvailableErrorModal = ref<boolean>();
  const conflictingLines = ref<IProduct[]>([]);
  const shouldShow = computed<boolean>(() => props.hideEmpty ? store.getters.getCartProducts.length : true);

  async function handleCartClicked(): Promise<void> {
    if (props.navigateToCart) {
      const cartValidationData = await store.dispatch('validateCartLineAmounts');
      validationError.value = cartValidationData?.error;
      validationErrorInfo.value = cartValidationData?.errorInfo;

      if (validationError.value === ECartErrorCode.ITEM_NOT_AVAILABLE) {
        conflictingLines.value = store.getters['catalog/getProductsBySkus'](validationErrorInfo.value?.conflictingCartLines.map(p => p.sku));
        showItemsNotAvailableErrorModal.value = true;
      } else {
        store.dispatch('checkout/resetPrepareCartForCheckoutError');
        await prepareCartForCheckout();

        if (isReadyForCheckout.value) {
          router.push({
            path: localePath(ROUTES.CHECKOUT)
          });
        }
      }
    }

    emit('click');
    $segmentEvent.CART_CLICKED();
  }

  async function onRefreshCartAmounts(): Promise<void> {
    showItemsNotAvailableErrorModal.value = false;
    await store.dispatch('syncCart', conflictingLines.value);
    await router.push({
      path: localePath({
        name: ROUTES.CHECKOUT
      })
    });
  }

  return {
    store,
    showItemsNotAvailableErrorModal,
    conflictingLines,
    shouldShow,
    handleCartClicked,
    onRefreshCartAmounts
  };
};

export default __sfc_main;
