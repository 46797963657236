




















































































import { ref, Ref, useContext } from '@nuxtjs/composition-api';
import ClickCollectDistance from '~/components/ClickCollectDistance/ClickCollectDistance.vue';
import GetDirections from '~/components/GetDirections/GetDirections.vue';
import PrepTimeHint from '~/components/PrepTimeHint/PrepTimeHint.vue';
import { EShippingMethod } from '~/types/app/Cart';
import { EOrderDeliveryCase, EOrderDeliveryState, EOrderDetailsStatus } from '~/types/app/Order';
import { EComponentName, EScreenName } from '~/lib/segment';
const __sfc_main = {};
__sfc_main.props = {
  additionalInformation: {
    type: String,
    default: ''
  },
  address: {
    type: String,
    default: null
  },
  shippingMethodSelectionOnly: {
    type: Boolean,
    default: false
  },
  showSpinnerOnDelivery: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const {
    store,
    $segmentEvent
  } = useContext();
  const props = __props;
  const emit = __ctx.emit;
  let isLoading = false;
  const additionalInformationModel: Ref<string> = ref(props.additionalInformation);

  const onShippingMethodSelected = async (type: EShippingMethod) => {
    isLoading = true;
    await store.dispatch('updateClickAndCollect', type === EShippingMethod.CLICK_AND_COLLECT);
    await store.dispatch('removeVoucher');
    $segmentEvent.DELIVERY_METHOD_CLICKED({
      screenName: EScreenName.checkout,
      componentName: EComponentName.shippingMethod,
      componentValue: type
    });
    emit('checkout-view-updated');
    isLoading = false;
  };

  const deliveryTimeStaticMapProperties = {
    orderStatus: {
      hub_coordinate: store.state.hub.coordinates,
      user_coordinate: store.state.deliveryCoordinates,
      rider_coordinate: store.state.deliveryCoordinates,
      status: [{
        case: EOrderDeliveryCase.ON_WAY,
        state: EOrderDeliveryState.COMPLETED,
        time: '0001-01-01T00:00:00Z',
        extra_message: false
      }],
      delivery_time: {
        status: EOrderDetailsStatus.DELIVERED,
        time: '11'
      }
    },
    hideUserCoordinates: true,
    showHub: true,
    hasBorder: true
  };
  return {
    EShippingMethod,
    emit,
    isLoading,
    additionalInformationModel,
    onShippingMethodSelected,
    deliveryTimeStaticMapProperties
  };
};

__sfc_main.components = Object.assign({
  PrepTimeHint,
  ClickCollectDistance,
  GetDirections
}, __sfc_main.components);
export default __sfc_main;
