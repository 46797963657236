
















type ColorVariants = 'white' | 'pink';
import { defineComponent } from '@vue/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  active: {
    type: Boolean,
    default: false
  },
  color: {
    type: (String as () => ColorVariants),
    default: 'white'
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  const handleClick = () => {
    emit('click');
  };

  return {
    handleClick
  };
};

export default __sfc_main;
