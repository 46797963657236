import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=40ebcb99&scoped=true&"
import script from "./MainLayout.vue?vue&type=script&lang=ts&"
export * from "./MainLayout.vue?vue&type=script&lang=ts&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&id=40ebcb99&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ebcb99",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainFooter: require('/app/build/components/MainFooter/MainFooter.vue').default})
