import loglevelLogger, { LogLevelDesc } from 'loglevel'
import { Plugin } from '@nuxt/types'
import { ELogLevels, ILogOptions } from '~/types/app/Logger'
import { addStandardDomainAttributes, getLogLevel } from '~/lib/logger'

const LOG_LEVEL_LOCAL_STORAGE_KEY = 'console-log-level'

const loggerPlugin: Plugin = ({ app, $config, i18n, isDev }, inject) => {
  const sendToDatadog = !isDev
  function logger(options: ILogOptions): void {
    let logMsg = JSON.parse(JSON.stringify(options))
    logMsg = addStandardDomainAttributes(
      logMsg,
      app.store?.state,
      app.store?.getters,
      i18n.locale,
      $config.runtimeEnv,
      logMsg.logger
    )

    if (!logMsg['http.useragent'] && typeof navigator !== 'undefined')
      logMsg['http.useragent'] = navigator.userAgent

    const ddLogger = (window as any).DD_LOGS.logger

    // One has ability to overwrite logging level using
    // localStorage key with a specific value e.g. 'info'
    if (logMsg.forceSubmit) {
      loglevelLogger.setLevel('info' as LogLevelDesc)
      ddLogger.setLevel('info' as LogLevelDesc)
    } else {
      const consoleLogLevel = getLogLevel(
        localStorage.getItem(LOG_LEVEL_LOCAL_STORAGE_KEY)
      )
      loglevelLogger.setLevel(consoleLogLevel as LogLevelDesc)
      ddLogger.setLevel(consoleLogLevel)
    }

    switch (logMsg.level) {
      case ELogLevels.INFO:
        loglevelLogger.info(`Info: ${logMsg.msg}`, logMsg)
        sendToDatadog && ddLogger.info(logMsg.msg, logMsg)
        break
      case ELogLevels.WARNING:
        loglevelLogger.warn(`Warning: ${logMsg.msg}`, logMsg)
        sendToDatadog && ddLogger.warn(logMsg.msg, logMsg)
        break
      case ELogLevels.ERROR:
        // We don't want to send a logLevelLogger error message here, because all console log errors will be passed
        // to DataDog automatically, resulting in a duplicate error logging.
        loglevelLogger.warn(`Error: ${logMsg.msg}`, logMsg)
        sendToDatadog && ddLogger.error(logMsg.msg, logMsg)
        break
    }
  }

  inject('logger', logger)
}

export default loggerPlugin
