import { ISegmentEvents } from '~/lib/segment'
import { IDeliveryTierInfo } from '~/types/app/Hub'

export default function trackDeliveryTierChangedHelper(
  prevParams: IDeliveryTierInfo,
  newParams: IDeliveryTierInfo,
  segmentEvent: ISegmentEvents
): void {
  if (!newParams.minOrderReached) {
    return
  }

  if (!prevParams.minOrderReached && newParams.deliveryTierIndex === 0) {
    segmentEvent.DELIVERY_TIER_CHANGED('dfThresholdReachedBaseDelivery')
  } else if (prevParams.deliveryFee !== 0 && newParams.deliveryFee === 0) {
    segmentEvent.DELIVERY_TIER_CHANGED('dfThresholdReachedFreeDelivery')
  } else if (prevParams.deliveryTierIndex !== newParams.deliveryTierIndex) {
    const eventName =
      newParams.deliveryTierIndex === 0
        ? 'dfThresholdReachedBaseDelivery'
        : `dfThresholdReachedDiscount${newParams.deliveryTierIndex}`
    segmentEvent.DELIVERY_TIER_CHANGED(eventName)
  }
}
