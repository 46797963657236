































































import IMenuItem from '~/types/app/MenuItem';
import { ref, computed, useRouter, useContext } from '@nuxtjs/composition-api';
const __sfc_main = {
  name: 'MenuItem'
};
__sfc_main.props = {
  id: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  slug: {
    type: String,
    default: ''
  },
  href: {
    type: String,
    default: ''
  },
  url: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  selected: {
    type: Boolean,
    default: false
  },
  selectedSubItem: {
    type: String,
    default: ''
  },
  links: {
    type: (Array as () => IMenuItem[]),
    default: () => []
  },
  routeName: {
    type: String,
    default: ''
  },
  showChildren: {
    type: Boolean,
    default: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const router = useRouter();
  const collapsed = ref(false);
  const {
    localePath,
    store
  } = useContext();
  const isExpandable = computed<boolean>(() => {
    return !!props.links.length && props.showChildren;
  });
  const isExpanded = computed<boolean>(() => {
    return isExpandable.value && props.selected && !collapsed.value;
  });

  function to(slug: string): string {
    return localePath({
      name: props.routeName,
      params: {
        slug
      }
    });
  }

  function onTopLevelClick(): void {
    collapsed.value = !collapsed.value;
    emit('select-item', {
      id: props.id,
      name: props.name,
      slug: props.slug
    });

    if (props.url) {
      router.push({
        path: localePath(props.url)
      });
    }

    if (props.slug && props.slug !== '') {
      router.push({
        path: to(props.slug)
      });
    }

    if (props.links.length) {
      store.dispatch('selectSubCategory', '');
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  function onSubLevelClick(item: IMenuItem): void {
    emit('select-sub-item', {
      item,
      topLevelItem: {
        id: props.id,
        name: props.name,
        slug: props.slug
      }
    });
  }

  return {
    localePath,
    isExpandable,
    isExpanded,
    to,
    onTopLevelClick,
    onSubLevelClick
  };
};

export default __sfc_main;
