import { datadogRum } from '@datadog/browser-rum'
import { NuxtAppOptions } from '@nuxt/types'
import { ERuntimeEnv } from '~/types/app/Env'
import { getUserFlinkUID, incrementPatchVersion } from '~/lib/helpers'

const envMap = (env: string | undefined): string => {
  switch (env) {
    case ERuntimeEnv.Production:
      return 'prod'
    case ERuntimeEnv.Staging:
      return 'staging'
    default:
      return 'dev'
  }
}

export default ({ store, $config, $cookies }: NuxtAppOptions): void => {
  if (!$config.isDev) {
    datadogRum.init({
      applicationId: $config.datadog.rumApplicationId,
      clientToken: $config.datadog.rumToken,
      site: 'datadoghq.eu',
      service: 'consumer-web',
      env: envMap($config.runtimeEnv),
      version: incrementPatchVersion($config.lastTag),
      sampleRate: 50,
      sessionReplaySampleRate: 0,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackViewsManually: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [$config.consumerApiBaseUrl, $config.apiBaseUrl],
    })

    if (store) {
      datadogRum.setUser({
        id: store.state.account.user?.uid,
        anonymousId:
          store.state.account.segmentAnonymousUserId ||
          $cookies.get('ajs_anonymous_id') ||
          getUserFlinkUID($cookies),
      })
    }

    datadogRum.startSessionReplayRecording()
  }
}
