/* eslint-disable camelcase */
import { put } from '~/api/controllers/put'
import ICartLine from '~/types/app/CartLine'
import { ICheckoutPayloadAddress, ICoordinates } from '~/types/app/Location'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'
import ICart, { ICartV3 } from '~/types/app/Cart'
import { ESchedulingOptionType } from '~/types/app/PlannedOrders'
import handleCartVersionTransformation from '~/api/transformers/transform-cart'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  locale: string
  hubSlug?: string
  cartId: string
  deliveryCoordinates?: ICoordinates
  shippingAddress?: ICheckoutPayloadAddress
  email?: string
  deliveryETA?: string
  cartLines: ICartLine[]
  notes?: string
  deliveryTierId?: string
  selectedTimeSlot?: {
    id: string
    type: ESchedulingOptionType
    start: string
    end: string
  }
  isProrityDelivery?: boolean
}

interface IBody {
  shipping_address?: ICheckoutPayloadAddress
  delivery_coordinates?: ICoordinates
  promised_delivery_time?: number
  email?: string
  lines: ICartLine[]
  notes?: string
  delivery_tier_id?: string
  shipping_method_id?: string
}

export default async function updateCart(
  {
    client,
    locale,
    hubSlug,
    cartId,
    deliveryCoordinates,
    shippingAddress,
    email,
    deliveryETA,
    cartLines,
    notes,
    deliveryTierId,
    selectedTimeSlot,
    isProrityDelivery,
  }: IParameters,
  logger: LoggerFunction
): Promise<ICart | undefined> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const body: IBody = {
    shipping_address: shippingAddress || undefined,
    delivery_coordinates: deliveryCoordinates || undefined,
    promised_delivery_time: deliveryETA ? +deliveryETA : undefined,
    email: email || undefined,
    lines: cartLines || undefined,
    notes: notes || undefined,
    ...(deliveryTierId && {
      delivery_tier_id: deliveryTierId,
    }),
    ...(selectedTimeSlot && { updated_slot: { ...selectedTimeSlot } }),
    ...(isProrityDelivery !== undefined && {
      is_priority_delivery: isProrityDelivery,
    }),
  }

  const url = `/cart/${cartId}`

  try {
    const response = await put<ICartV3>(client, logger, url, headers, body)
    logger({
      level: ELogLevels.INFO,
      msg: 'API Success: updateCart successful.',
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.CART,
      func: 'updateCart',
      forceSubmit: true,
    })

    const cart: ICartV3 | undefined = response.data

    return handleCartVersionTransformation(client.version, cart)
  } catch (error) {
    logger({
      level: ELogLevels.ERROR,
      msg: 'API Error: updateCart failed.',
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: error as string,
      logger: ELoggers.CART,
      func: 'updateCart',
      forceSubmit: false,
    })
    throw error
  }
}
