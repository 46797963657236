module.exports = {
  firebaseStagingConfig: {
    apiKey: 'AIzaSyB9qXJLdHcG2jG4Syixq4GiY8sgYaE1H88',
    authDomain: 'flink-core-staging.firebaseapp.com',
    projectId: 'flink-core-staging',
    storageBucket: 'flink-core-staging.appspot.com',
    messagingSenderId: '216409154112',
    appId: '1:216409154112:web:eb04bd380250ce9bbb35a5',
    measurementId: 'G-65MHE4N14Q',
  },
  firebaseProductionConfig: {
    apiKey: 'AIzaSyAKS_L2A-jbcXbkfSIxsr0ayXIalp_UJ4E',
    authDomain: 'flink-core-prod.firebaseapp.com',
    projectId: 'flink-core-prod',
    storageBucket: 'flink-core-prod.appspot.com',
    messagingSenderId: '946302416611',
    appId: '1:946302416611:web:a04fc5fd7d1b5b9bcb77d6',
    measurementId: 'G-B50JQB0JE2',
  },
}
