import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import IProduct, { IProductRaw } from '~/types/app/Product'
import transformProducts from '~/api/transformers/transform-products'
import { LoggerFunction } from '~/types/app/Logger'

interface IParameters {
  locale: string
  hubSlug?: string
  skus: string[]
  client: NuxtAxiosInstance
  logger: LoggerFunction
}

export default async function getProductsBySku({
  locale,
  hubSlug,
  skus,
  client,
  logger,
}: IParameters): Promise<IProduct[]> {
  const url = '/v3/products'

  const headers = {
    locale,
    'hub-slug': hubSlug,
    'flink-product-images': 'square-thumbnails',
  }

  let products: IProduct[] = []

  try {
    const response = await post<{ products: IProductRaw[] }>(
      client,
      logger,
      url,
      headers,
      { skus }
    )
    products = transformProducts(response.data.products)
  } catch (error) {}

  return products
}
