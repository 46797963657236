
































































































import { useContext, useRouter, useStore } from '@nuxtjs/composition-api';
import { ROUTES } from '~/lib/routes';
import { translateIfNecessary } from '~/lib/helpers';
import { EComponentName, EComponentVariant, EProductPlacement } from '~/lib/segment';
import useFee from '~/hooks/useFee';
import { EBannerMessageVariant } from '~/types/app/BannerMessage';
import useCheckout from '~/hooks/checkout/useCheckout';
import { ESubscriptionOriginComponent } from '~/types/app/Subscription';
import { usePrepareCartForCheckout } from '~/hooks/checkout/usePrepareCartForCheckout';
const __sfc_main = {};
__sfc_main.props = {
  initialVisible: {
    type: Boolean,
    default: false
  },
  offsetY: {
    type: Number,
    default: 0
  },
  offsetX: {
    type: Number,
    default: 0
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const ctx = useContext();
  const store = useStore();
  const router = useRouter();
  const {
    dynamicDeliveryFeeText,
    showDynamicDeliveryFeeBanner
  } = useFee();
  const {
    cartBlockingMessage,
    cartSavingsMessageAsHTML
  } = useCheckout();
  const {
    movNotReached
  } = usePrepareCartForCheckout();
  const productPlacement = EProductPlacement.cart;
  const cartRoute = ROUTES.CART;
  const boundT = ctx.i18n.t.bind(ctx.i18n);

  function handleCartViewed(): void {
    store.dispatch('catalog/loadCartProducts');
    ctx.$segmentEvent.CART_VIEWED({
      msgDisplayed: dynamicDeliveryFeeText.value
    });

    if (showDynamicDeliveryFeeBanner.value) {
      ctx.$segmentEvent.SECTION_VIEWED_ON_CURRENT_SCREEN({
        componentName: EComponentName.feeBanner,
        componentVariant: EComponentVariant.dynamicDeliveryFee,
        componentContent: dynamicDeliveryFeeText.value
      });
    }
  }

  function handleCartExpanded(): void {
    ctx.$segmentEvent.CART_EXPANDED();
  }

  function onPrimeBannerClick(): void {
    store.dispatch('subscription/setOriginComponent', ESubscriptionOriginComponent.CART);
    ctx.$segmentEvent.PRIME_JOIN_CLICKED();
    router.push({
      path: ctx.localePath(ROUTES.PRIME_JOIN)
    });
  }

  return {
    translateIfNecessary,
    EBannerMessageVariant,
    store,
    dynamicDeliveryFeeText,
    showDynamicDeliveryFeeBanner,
    cartBlockingMessage,
    cartSavingsMessageAsHTML,
    movNotReached,
    productPlacement,
    cartRoute,
    boundT,
    handleCartViewed,
    handleCartExpanded,
    onPrimeBannerClick
  };
};

export default __sfc_main;
