import { DEFAULT_HUB_SLUGS } from '~/lib/constants'
import { getCountryFromLocale } from '~/lib/helpers'
import { FALLBACK_LOCALE } from '~/localesConfig'
import { ECountries } from '~/types/app/Countries'

export function getDefaultHubSlug(locale?: string): string {
  const country = getCountryFromLocale(
    locale || FALLBACK_LOCALE
  ).toUpperCase() as ECountries

  return DEFAULT_HUB_SLUGS[country || ECountries.DE]
}
