import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import { LoggerFunction } from '~/types/app/Logger'

interface IGeneratedIntercomHash {
  hash: string
}

export default async function createIntercomHash(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  id: string
): Promise<IGeneratedIntercomHash | null> {
  const url = 'intercom'

  try {
    const response = await post<IGeneratedIntercomHash>(
      client,
      logger,
      url,
      {},
      {
        id,
      }
    )
    return response.data
  } catch (error) {}

  return null
}
