/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import { LoggerFunction } from '~/types/app/Logger'
import {
  IProductSubstitutionRaw,
  IProductSubstitution,
} from '~/types/app/Product'
import { RequestedCartLines } from '~/types/app/CartLine'
import transformProducts, {
  transformProduct,
} from '~/api/transformers/transform-products'

interface IParameters {
  client: NuxtAxiosInstance
  locale: string
  hubSlug?: string
  requestedCartLines: RequestedCartLines[]
}

interface IBody {
  queries: RequestedCartLines[]
}

interface Response {
  data: IProductSubstitutionRaw[]
}

export default async function getProductsSubstitutes(
  { client, locale, hubSlug, requestedCartLines }: IParameters,
  logger: LoggerFunction
): Promise<IProductSubstitution[]> {
  const headers = {
    locale,
    hub: hubSlug,
    'hub-slug': hubSlug,
    'flink-product-images': 'square-thumbnails',
  }

  const body: IBody = {
    queries: requestedCartLines,
  }

  let productsSubstitutions: IProductSubstitution[] = []

  const response = await post<Response>(
    client,
    logger,
    '/v3/products/substitutes',
    headers,
    body
  )

  productsSubstitutions = response.data.data.map((substitution) => ({
    original_product: transformProduct(substitution.original_product),
    substitutes: transformProducts(substitution.substitutes),
  }))

  return productsSubstitutions
}
