/* eslint-disable camelcase */
import ICartLine, { ICartV3Line } from '~/types/app/CartLine'
import { IAddress, IAddressCamelCase, ICoordinates } from '~/types/app/Location'
import { IPaymentGateway } from '~/types/app/Payments'
import IPrice, { ICentPrice } from '~/types/app/Price'
import { IConflictingCartLine } from '~/api/services/are-all-products-available/are-all-products-available'
import { ITranslationInput } from '~/types/app/I18n'
import IProduct from './Product'
import { ICartPromotions, IAppliedCampaign } from './Promotions'

export interface ICartOrder {
  id: string
  number: string
  state: string
  firstOrder: boolean
  isHighValueFirstOrder?: boolean
  userCategory?: string
}

export enum ECartFeeType {
  DELIVERY_FEE = 'delivery_fee',
  STORAGE_FEE = 'storage_fee',
  LATE_NIGHT = 'late_night_fee',
  SUP_FEE = 'sup_fee',
  DEPOSIT = 'deposit',
  NO_RUSH = 'no_rush_delivery',
  EXPRESS = 'express_delivery',
  PRIORITY = 'priority_delivery',
  SMALL_ORDER_FEE = 'small_order',
  SERVICE_FEE = 'service_fee',
}

export enum ECartState {
  ACTIVE = 'Active',
  PAID = 'Paid',
  ORDERED = 'Ordered',
}

export enum ECartAdditionalInfoType {
  SAVED_DELIVERY_FEE_SUBSCRIBER = 'saved_delivery_fee_subscriber',
  SAVED_PRIORITY_FEE_SUBSCRIBER = 'saved_priority_delivery_fee_subscriber',
}

export interface ICartFee {
  type: string
  name?: string
  price: IPrice
}

export interface ICartFeeInfo extends Omit<ICartFee, 'price'> {
  price?: IPrice
}

export default interface ICart {
  id: string
  version: number
  email: string
  lines: ICartLine[]
  promotions?: ICartPromotions
  recycling_deposit: IPrice
  rider_tip: IPrice
  shipping_address: IAddress
  billing_address?: IAddress
  delivery_coordinates?: ICoordinates
  shipping_price?: IPrice
  sub_total_price: IPrice
  total_price: IPrice
  discount: IPrice
  voucher_code?: string
  hub_slug: string
  order?: ICartOrder
  payment_gateway?: IPaymentGateway
  click_and_collect?: boolean
  storage_fee?: IPrice
  lateNightFee?: IPrice
  priorityFee?: IPrice
  fees: ICartFee[]
  savedDeliveryFee?: IPrice | undefined
  savedPriorityFee?: IPrice | undefined
  discountedDeliveryFee?: IPrice | undefined
  shippingMethodId?: string
  deliveryTierId?: string
  state: ECartState
  additionalInfo: ICartFee[] | undefined
}

export interface IGroupedCartItems {
  itemsWithPromotions: IAppliedCampaign[]
  itemsWithoutPromotions: IProduct[]
}

export interface ICartV2PriceBreakdown {
  discount: IPrice
  recyclingDeposit: IPrice
  riderTip: IPrice
  shipping: IPrice
  subTotal: IPrice
  total: IPrice
  storageFee?: IPrice
}

export interface ICartV2 {
  clickAndCollect?: boolean
  id: string
  lines: ICartLine[]
  priceBreakdown: ICartV2PriceBreakdown
}

export interface ICartV3 {
  id: string
  version: number
  shippingAddress: IAddressCamelCase
  email: string
  lines: ICartV3Line[]
  promotions?: ICartPromotions
  fees: ICartFee[]
  riderTip: IPrice
  recyclingDeposit: IPrice
  totalPrice: IPrice
  subTotalPrice: IPrice
  discount: IPrice
  hubSlug: string
  order?: ICartOrder
  clickAndCollect?: boolean
  discountedDeliveryFee?: IPrice
  deliveryCoordinates?: ICoordinates
  voucherCode?: string
  additionalInfo?: ICartFee[] // For now only the type "saved_delivery_fee_subscriber" is stored under additionalInfo
  shippingMethodID?: string
  deliveryTierID?: string
  state: ECartState
}

interface ICartBlockingMessage {
  title?: string | ITranslationInput
  message: string | ITranslationInput
}
export type CartBlockingMessage = ICartBlockingMessage | null

export enum ECartErrorCode {
  GENERIC = 'generic',
  ITEM_NOT_AVAILABLE = 'item_not_available',
  ITEM_UNAVAILABLE = 'item_unavailable',
  MOV_NOT_REACHED = 'mov_not_reached',
  HUB_CLOSED = 'hub_closed',
  ALREADY_PAID = 'already_paid',
  ORDER_ALREADY_EXISTS = 'order_already_exists',
  CART_VALUE_UPDATED = 'cart_value_updated',
  INTERNAL_SERVER_ERROR = 'internal_server_error',
  UNKNOWN = 'unknown',
}

export type TrackingData = {
  value: string
  name?: string
  content?: string
}

export type ICartError = {
  code: ECartErrorCode | string
  title?: string
  message?: string
  rejectionReason?: string
  trackingData?: TrackingData
}

export interface ICartErrorInfoItemNotAvailable {
  conflictingCartLines: IConflictingCartLine[]
  response: Record<string, any>
}

export type CartErrorInfo = ICartErrorInfoItemNotAvailable

export enum EPaymentStatus {
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_ORDER_TIMEOUT = 'SUCCESS_WITH_ORDER_TIMEOUT',
  FAILURE = 'FAILURE',
  REFUSED = 'ERROR_PAYMENT_REFUSED',
}

export enum EPaymentStatusV2 {
  PAID = 'PAID',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface IPaymentStatus {
  status: EPaymentStatus
  action: () => void
}

export enum EPaymentReceiptType {
  CUSTOMER = 'CUSTOMER',
  CASHIER = 'CASHIER',
}

export interface IPaymentReceiptContent {
  style: string
  endOfLineFlag: boolean
  text: string
}

export interface IPaymentReceipt {
  type: EPaymentReceiptType
  contents: IPaymentReceiptContent[]
}
export interface IPaymentStatusV2 {
  status: EPaymentStatusV2
  receipts?: IPaymentReceipt[]
}

export enum EShippingMethod {
  CLICK_AND_COLLECT = 'click_and_collect',
  DELIVERY = 'delivery',
  KIOSK = 'kiosk',
}

export interface IRiderTip {
  emoji: string
  price: ICentPrice
}

export interface ILateNightConfigFee {
  endTime: string
  startTime: string
  price: ICentPrice
}

export interface ILateNightConfig {
  enabled: boolean
  fees: ILateNightConfigFee[]
}

export type PrepareCartForCheckoutError =
  | {
      reason: ECartErrorCode.ITEM_UNAVAILABLE
      items: Array<{
        sku: string
      }>
      trackingData: TrackingData
    }
  | {
      reason: ECartErrorCode.ORDER_ALREADY_EXISTS
      order: {
        id: string
        number: string
      }
    }
  | {
      reason:
        | ECartErrorCode.HUB_CLOSED
        | ECartErrorCode.MOV_NOT_REACHED
        | ECartErrorCode.ALREADY_PAID
        | ECartErrorCode.CART_VALUE_UPDATED
        | ECartErrorCode.INTERNAL_SERVER_ERROR
        | ECartErrorCode.UNKNOWN
    }

export type PrepareCartForCheckoutResponse =
  | {
      status: 'success'
    }
  | ({
      status: 'error'
    } & PrepareCartForCheckoutError)
