























































import { ref, computed, useRoute, useStore, useContext } from '@nuxtjs/composition-api';
import { ROUTES, EXPLORE_PAGES, PROFILE_PAGES } from '~/lib/routes';
import IRootState from '~/types/app/State';
import { getRouteName } from '~/lib/helpers';
import { ETrackingType, getScreenName } from '~/lib/segment';
const __sfc_main = {};
__sfc_main.props = {
  hideSearch: {
    type: Boolean,
    default: false
  },
  hideLinks: {
    type: Boolean,
    default: false
  },
  hideProfile: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const store = useStore<IRootState>();
  const {
    $segmentEvent
  } = useContext();
  const route = useRoute().value;
  const routeName = computed<string>(() => getRouteName(route));
  const isSearchPage = routeName.value === ROUTES.SEARCH;
  const isSearchFocussed = ref(isSearchPage);
  const isUserAuthenticated = computed<boolean>(() => store.getters['account/isUserAuthenticated']);
  const isProfileRoute = computed<boolean>(() => PROFILE_PAGES.includes((routeName.value as ROUTES)));
  const isExplore = computed<boolean>(() => EXPLORE_PAGES.includes((routeName.value as ROUTES)));
  const homeIcon = computed<string>(() => isExplore.value ? 'explore-selected' : 'explore');
  const dealsIcon = computed<string>(() => routeName.value === ROUTES.DEALS ? 'deals-selected' : 'deals');
  const profileIcon = computed<string>(() => isProfileRoute.value ? 'profile-selected' : 'profile');

  function handleSearchFocus() {
    if (!store.state.search?.searchLabels.length) store.dispatch('search/setSearchLabels');
    isSearchFocussed.value = true;
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentVariant: 'search',
      screenName
    });
  }

  function handleSearchBlur() {
    if (!isSearchPage) {
      // the dropdown is shown on textbox:focus
      // need this timeout to capture click before hiding the dropdown
      setTimeout(() => {
        isSearchFocussed.value = false;
      }, 250);
    }
  }

  function trackClick(componentVariant: string) {
    const screenName = getScreenName(route);
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: 'navigation_bar',
      componentVariant,
      screenName
    });
  }

  return {
    ROUTES,
    isSearchFocussed,
    isUserAuthenticated,
    isProfileRoute,
    isExplore,
    homeIcon,
    dealsIcon,
    profileIcon,
    handleSearchFocus,
    handleSearchBlur,
    trackClick
  };
};

export default __sfc_main;
