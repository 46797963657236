import { render, staticRenderFns } from "./ProductListItem.vue?vue&type=template&id=12f9c7b2&scoped=true&"
import script from "./ProductListItem.vue?vue&type=script&lang=ts&"
export * from "./ProductListItem.vue?vue&type=script&lang=ts&"
import style0 from "./ProductListItem.vue?vue&type=style&index=0&id=12f9c7b2&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f9c7b2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductListDetails: require('/app/build/components/ProductList/ProductListDetails.vue').default,PromotionLabel: require('/app/build/components/ProductList/PromotionLabel.vue').default,QuantityControl: require('/app/build/components/QuantityControl/QuantityControl.vue').default,ProductSubstitutionModal: require('/app/build/components/ProductSubstitutionModal/ProductSubstitutionModal.vue').default})
