
































import { ROUTES } from '~/lib/routes';
import { ref, computed, useContext, useRoute, useStore } from '@nuxtjs/composition-api';
import IRootState from '~/types/app/State';
import IMenuItem from '~/types/app/MenuItem';
import { ETrackingType, EComponentName, getScreenName } from '~/lib/segment';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const store = useStore<IRootState>();
  const route = useRoute().value;
  const {
    $segmentEvent
  } = useContext();
  const slug = ref<string>(route.params.slug);
  const isMenuVisible = ref(false);
  const routeName = ROUTES.CATEGORY;
  const categories = computed(() => store.state.catalog?.categories || []);
  const screenName = getScreenName(route);

  function handleShow() {
    isMenuVisible.value = true;
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.categoryMenu,
      screenName
    });
  }

  function onSelectCategory({
    id = '',
    name
  }: IMenuItem, index: number): void {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      componentName: EComponentName.category,
      eventOrigin: EComponentName.categoryMenu,
      componentContent: id,
      componentPosition: index + 1,
      componentVariant: name,
      screenName
    });
  }

  return {
    slug,
    isMenuVisible,
    routeName,
    categories,
    handleShow,
    onSelectCategory
  };
};

export default __sfc_main;
