import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'

import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError'
import { ICheckoutCartError } from '~/api/services/checkout-cart/checkout-cart'
import ICart, { ICartOrder } from '~/types/app/Cart'

export default class CheckoutPageLogger {
  static enterPage(
    logger: LoggerFunction,
    cartId?: string | (string | null)[]
  ): void {
    const logPayload = {
      level: ELogLevels.INFO,
      msg: 'User entered checkout page.',
      'http.method': EHTTPMethods.GET,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.CHECKOUT,
      func: 'checkout.mounted',
      slug: 'checkout.enterPage',
      forceSubmit: true,
    }
    if (cartId) {
      logPayload.msg = `User re-enters checkout page. (cartId=${cartId})`
    }
    logger(logPayload)
  }

  static undefinedRemoteCart(logger: LoggerFunction): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Remote cart is undefined after updating the cart.',
      logger: ELoggers.CHECKOUT,
      func: 'checkout.mounted',
      slug: 'checkout.undefinedCart',
      forceSubmit: false,
    })
  }

  static submitPayment(
    logger: LoggerFunction,
    isExpressCheckout: boolean,
    paymentType?: string | unknown
  ): void {
    logger({
      level: ELogLevels.INFO,
      msg: 'Submitting payment.',
      logger: ELoggers.CHECKOUT,
      func: 'onSubmit',
      slug: 'checkout.submitPayment',
      'flink.payment.type': paymentType,
      'flink.payment.isExpressCheckout': isExpressCheckout,
      forceSubmit: true,
    })
  }

  static submitAdditionalDetails(
    logger: LoggerFunction,
    paymentMethod: string
  ): void {
    logger({
      level: ELogLevels.INFO,
      msg: 'Submitting additional details.',
      logger: ELoggers.CHECKOUT,
      func: 'onAdditionalDetails',
      slug: 'checkout.submitAdditionalDetails',
      'flink.payment.type': paymentMethod,
      forceSubmit: true,
    })
  }

  static paymentProviderError(
    logger: LoggerFunction,
    error: AdyenCheckoutError,
    paymentMethod?: string
  ): void {
    logger({
      level: ELogLevels.ERROR,
      msg: "Dropin's error hook triggered.",
      error: error.message as string,
      logger: ELoggers.PAYMENT,
      func: 'onErrorPaymentProvider',
      slug: 'checkout.paymentProviderError',
      'flink.payment.type': paymentMethod,
      forceSubmit: false,
    })
  }

  static paymentAPIError(
    logger: LoggerFunction,
    error?: ICheckoutCartError,
    paymentMethod?: string
  ): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Payment API request failed',
      error: JSON.stringify(error),
      logger: ELoggers.PAYMENT,
      func: 'onPaymentErrorAPI',
      slug: 'checkout.paymentAPIError',
      'flink.payment.type': paymentMethod,
      forceSubmit: false,
    })
  }

  static orderCreatedAfterRetries(
    logger: LoggerFunction,
    orderVerificationRetryNumber: number,
    cartId: string
  ): void {
    logger({
      level: ELogLevels.WARNING,
      msg: `Order creation successful after ${orderVerificationRetryNumber} retries`,
      warning: `The backend send a failure response for cartId ${cartId}, but the order was created (time lag). Redirecting to order-status page. (Retry #${orderVerificationRetryNumber})`,
      func: 'verifyOrderSuccess',
      logger: ELoggers.PAYMENT,
      slug: 'checkout.orderCreatedAfterRetries',
      forceSubmit: false,
    })
  }

  static orderFailedAfterRetries(
    logger: LoggerFunction,
    orderVerificationRetryNumber: number,
    cartId: string
  ): void {
    logger({
      level: ELogLevels.ERROR,
      msg: `Timeout Error: Order could not be created.`,
      error: `The order for cartId ${cartId} was not created after ${orderVerificationRetryNumber} retries.`,
      func: 'verifyOrderSuccess',
      logger: ELoggers.PAYMENT,
      slug: 'checkout.orderFailedAfterRetries',
      forceSubmit: false,
    })
  }

  static cartReloadFailed(logger: LoggerFunction, error: string): void {
    logger({
      level: ELogLevels.WARNING,
      msg: 'Not able to GET /cart while reloading order status.',
      'http.method': EHTTPMethods.GET,
      'http.status_code': EStatus.FAILED,
      warning: error,
      logger: ELoggers.PAYMENT,
      slug: 'checkout.cartReloadFailed',
      forceSubmit: false,
    })
  }

  static paymentCanceled(logger: LoggerFunction): void {
    logger({
      level: ELogLevels.INFO,
      msg: `Payment canceled.`,
      func: 'onCancel',
      logger: ELoggers.PAYMENT,
      slug: 'checkout.paymentCanceled',
      forceSubmit: false,
    })
  }

  static applyVoucherSuccess(logger: LoggerFunction, code: string): void {
    logger({
      level: ELogLevels.INFO,
      msg: `Voucher applied successfully: ${code}`,
      func: 'applyVoucher',
      logger: ELoggers.CHECKOUT,
      slug: 'checkout.applyVoucherSuccess',
      forceSubmit: false,
    })
  }

  static applyVoucherFailure(logger: LoggerFunction, code: string): void {
    logger({
      level: ELogLevels.INFO,
      msg: `Voucher failed: ${code}`,
      func: 'applyVoucher',
      logger: ELoggers.CHECKOUT,
      slug: 'checkout.applyVoucherFailure',
      forceSubmit: false,
    })
  }

  static paymentSuccess(
    logger: LoggerFunction,
    orderId: string,
    paymentMethod?: string
  ): void {
    logger({
      level: ELogLevels.INFO,
      msg: 'Payment succeeded',
      func: 'onPaymentSuccess',
      logger: ELoggers.CHECKOUT,
      slug: 'checkout.paymentSuccess',
      'flink.payment.type': paymentMethod,
      'flink.order.id': orderId,
      forceSubmit: true,
    })
  }

  static verifyOrderSuccessUndefinedCart(logger: LoggerFunction): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Cart is undefined while verifying order success.',
      func: 'verifyOrderSuccess',
      logger: ELoggers.CHECKOUT,
      slug: 'checkout.verifyOrderSuccessUndefinedCart',
      forceSubmit: false,
    })
  }

  static identityNotAppliedToCart(logger: LoggerFunction): void {
    logger({
      level: ELogLevels.ERROR,
      msg: `API was not able to append the auth'ed user details to the cart.`,
      error: `Checkout cart without user information`,
      func: 'checkoutAsyncData',
      logger: ELoggers.CHECKOUT,
      slug: 'checkout.identityNotAppliedToCart',
      forceSubmit: false,
    })
  }

  static cartWithoutOrderId(logger: LoggerFunction, isInStore: boolean): void {
    logger({
      level: ELogLevels.ERROR,
      msg: `The cart never exposed an order id after payment.  (type=${
        isInStore ? 'in-store' : 'e-commerce'
      })`,
      error: `onPaymentSuccess failed`,
      func: 'onPaymentSuccess',
      logger: ELoggers.PAYMENT,
      slug: 'checkout.cartWithoutOrderId',
      forceSubmit: true,
    })
  }

  static orderAlreadyExistByError(
    logger: LoggerFunction,
    order: ICartOrder
  ): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Order already exist by error',
      func: 'cartErrorHandler',
      logger: ELoggers.CART,
      slug: 'checkout.orderAlreadyExist.error',
      'flink.order.id': order?.id,
    })
  }

  static cartAlreadyPaidByError(logger: LoggerFunction, cart: ICart): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Cart already paid by error',
      func: 'cartErrorHandler',
      logger: ELoggers.CART,
      slug: 'checkout.cartAlreadyPaid.error',
      'flink.cart.id': cart?.id,
    })
  }

  static orderAlreadyExistByGetCart(
    logger: LoggerFunction,
    order: ICartOrder
  ): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Order already exist by GET cart',
      func: 'getCart',
      logger: ELoggers.CART,
      slug: 'checkout.orderAlreadyExist.getCart',
      'flink.order.id': order?.id,
    })
  }

  static cartAlreadyPaidByGetCart(logger: LoggerFunction, cart: ICart): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Cart already paid by GET cart',
      func: 'getCart',
      logger: ELoggers.CART,
      slug: 'checkout.cartAlreadyPaid.getCart',
      'flink.cart.id': cart?.id,
    })
  }

  static checkoutWithDummyEmailAddress(logger: LoggerFunction): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'User tried to checkout with dummy email address.',
      logger: ELoggers.CHECKOUT,
      func: 'checkout.asyncData',
      slug: 'checkout.checkoutWithDummyEmailAddress',
      forceSubmit: true,
    })
  }

  static checkoutWithEmptyCart(logger: LoggerFunction): void {
    logger({
      level: ELogLevels.ERROR,
      msg: 'Checkout with empty cart.',
      logger: ELoggers.CHECKOUT,
      func: 'checkout.asyncData',
      slug: 'checkout.checkoutWithEmptyCart',
      forceSubmit: true,
    })
  }
}
