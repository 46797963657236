/* eslint-disable camelcase */
export interface IVoucherWalletRaw {
  code: string
  is_applicable: boolean
  rejection_reason?: string
  sub_title: string
  terms: string
  title: string
  expires_at?: string
}

export interface IVoucherWallet {
  code: string
  isApplicable: boolean
  rejectionReason?: string
  subTitle: string
  terms: string
  title: string
  expiresAt?: string
  is_oos?: boolean
}

/**
 * Type guard definition for IVoucherWallet and Raw variant
 * based on required shared fields changing snake_case and camelCase
 * @param voucherWallet object of voucher wallet type variants
 * @returns type
 */
export function isVoucherWallet(
  voucherWallet: IVoucherWallet | IVoucherWalletRaw
): voucherWallet is IVoucherWallet {
  return (
    (voucherWallet as IVoucherWallet).isApplicable !== undefined &&
    (voucherWallet as IVoucherWallet).subTitle !== undefined
  )
}
