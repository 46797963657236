






































































import { computed } from '@nuxtjs/composition-api';
import { VARIANT_LABEL_AND_BOTTOM_SHEET } from '~/types/app/Product';
const __sfc_main = {
  name: 'QuantityControl'
};
__sfc_main.props = {
  quantity: {
    type: Number,
    default: 0
  },
  maxQuantity: {
    type: Number,
    required: true
  },
  initialTextOnly: {
    type: Boolean,
    default: false
  },
  fillParent: {
    type: Boolean,
    default: false
  },
  productName: {
    type: String,
    required: true
  },
  oosExperimentVariant: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isOutOfStock = computed<boolean>(() => {
    return props.maxQuantity === 0 && !props.oosExperimentVariant;
  });
  const isFullWidth = computed<boolean>(() => {
    return props.fillParent && (isOutOfStock.value || props.quantity > 0);
  });
  const showSwap = computed<boolean>(() => {
    return props.oosExperimentVariant === VARIANT_LABEL_AND_BOTTOM_SHEET && props.quantity === 0;
  });
  const isDisabled = computed<boolean>(() => {
    // if maxQuantity is undefined / null / empty string, do not disable
    return isOutOfStock.value || !!props.maxQuantity && props.quantity >= props.maxQuantity;
  });
  const initialTextOnlyAndNotInCart = computed<boolean>(() => {
    return props.initialTextOnly && props.quantity === 0;
  });

  function increment(): void {
    emit('add');
  }

  function decrement(): void {
    emit('remove');
  }

  return {
    isOutOfStock,
    isFullWidth,
    showSwap,
    isDisabled,
    initialTextOnlyAndNotInCart,
    increment,
    decrement
  };
};

export default __sfc_main;
