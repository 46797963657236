import { Plugin } from '@nuxt/types'
import { axiosOnRequest } from '~/plugins/axios'
import { NuxtAxiosInstance } from '@nuxtjs/axios'

export enum EApiVersion {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3',
}

export interface IConsumerApi extends NuxtAxiosInstance {
  version?: EApiVersion
}

enum EServicePath {
  Address = 'address',
  PostOrder = 'post-order',
  OrderExperienceBff = 'order-experience-bff',
  Cart = 'cart',
  Subscriptions = 'subscriptions-service',
  PaymentBff = 'payment-bff',
  Homescreen = 'homescreen',
  Deals = 'deals',
  VouchersWallet = 'voucher',
  Discovery = 'discovery',
  SearchDiscovery = 'search-discovery/search-http',
  Users = 'users',
  AdService = 'adtech/ad-service',
  HubLocator = 'hub-locator/hub-locator',
}

const createAxiosInstance = (
  $axios: NuxtAxiosInstance,
  app: any,
  baseURL: string,
  version?: EApiVersion
): IConsumerApi => {
  const axiosInstance: IConsumerApi = $axios.create()
  axiosInstance.setBaseURL(baseURL)
  axiosInstance.onRequest((config) => {
    axiosOnRequest(config, app)
  })
  axiosInstance.version = version ?? EApiVersion.V1
  return axiosInstance
}

const injectService = (
  inject: (key: string, value: any) => void,
  $axios: NuxtAxiosInstance,
  app: any,
  apiUrl: string,
  servicePath: string,
  injectName: string,
  version?: EApiVersion
) => {
  let baseURL = `${apiUrl}${servicePath}`
  if (version) {
    baseURL += `/${version}`
  }
  baseURL += '/'
  inject(injectName, createAxiosInstance($axios, app, baseURL, version))
}

const consumerApiPlugin: Plugin = ({ $axios, $config, app }, inject) => {
  const services = [
    { path: EServicePath.Address, version: EApiVersion.V1, name: 'apiAddress' },
    {
      path: EServicePath.PostOrder,
      version: EApiVersion.V1,
      name: 'apiOrders',
    },
    {
      path: EServicePath.PostOrder,
      version: EApiVersion.V2,
      name: 'apiOrdersV2',
    },
    {
      path: EServicePath.OrderExperienceBff,
      version: EApiVersion.V1,
      name: 'apiOrderExperienceBff',
    },
    {
      path: EServicePath.OrderExperienceBff,
      version: EApiVersion.V2,
      name: 'apiOrderExperienceBffV2',
    },
    {
      path: EServicePath.OrderExperienceBff,
      version: EApiVersion.V3,
      name: 'apiOrderExperienceBffV3',
    },
    { path: EServicePath.Cart, version: EApiVersion.V3, name: 'apiCartV3' },
    {
      path: EServicePath.Subscriptions,
      version: EApiVersion.V1,
      name: 'apiSubscriptions',
    },
    {
      path: EServicePath.PaymentBff,
      version: EApiVersion.V1,
      name: 'apiPaymentBff',
    },
    {
      path: EServicePath.Homescreen,
      name: 'apiHomescreen',
    },
    { path: EServicePath.Deals, version: EApiVersion.V2, name: 'apiDeals' },
    {
      path: EServicePath.VouchersWallet,
      version: EApiVersion.V1,
      name: 'apiVouchersWallet',
    },
    {
      path: EServicePath.Discovery,
      name: 'apiDiscovery',
    },
    {
      path: EServicePath.SearchDiscovery,
      name: 'apiSearchDiscovery',
      apiUrl: $config.apiBaseUrl,
    },
    { path: EServicePath.Users, version: EApiVersion.V1, name: 'apiUsers' },
    {
      path: EServicePath.AdService,
      version: EApiVersion.V1,
      name: 'apiAdTech',
      apiUrl: $config.apiBaseUrl,
    },
    {
      path: EServicePath.HubLocator,
      version: EApiVersion.V1,
      name: 'hubLocator',
      apiUrl: $config.apiBaseUrl,
    },
  ]

  services.forEach((service) => {
    injectService(
      inject,
      $axios,
      app,
      service.apiUrl || $config.apiUrl,
      service.path,
      service.name,
      service.version
    )
  })

  const printApi: NuxtAxiosInstance = $axios.create()
  printApi.setBaseURL('http://localhost:5050/api/printerstar/')
  inject('printApi', printApi)
}

export default consumerApiPlugin
