var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:( _obj = {
    'out-of-stock':
      _vm.product.maxQuantity === 0 && !_vm.product.oosExperimentVariant
  }, _obj[("--" + _vm.size)] = true, _obj ),attrs:{"title":_vm.product.name,"aria-label":_vm.product.name}},[_c('div',{staticClass:"thumbnail"},[_c('FeatureFlag',{attrs:{"flag-key":_vm.FeatureFlags.WEB_HIDE_DISCOUNT_LABELS},scopedSlots:_vm._u([{key:"off",fn:function(){return [(_vm.product.discount)?_c('DiscountLabel',{attrs:{"discount":_vm.product.discount}}):_vm._e()]},proxy:true}])}),_vm._v(" "),(_vm.product.thumbnail)?_c('VCloudinaryImage',{attrs:{"src":_vm.product.thumbnail,"alt":_vm.product.name,"title":_vm.product.name}}):_vm._e(),_vm._v(" "),_c('noscript',{inlineTemplate:{render:function(){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product.thumbnail)?_c('VCloudinaryImage',{attrs:{"src":_vm.product.thumbnail,"alt":_vm.product.name,"title":_vm.product.name,"width":300,"height":300,"params":"c_fill,g_south","no-lazy":""}}):_vm._e()},staticRenderFns:[]}}),_vm._v(" "),_c('QuantityControl',{attrs:{"product-name":_vm.product.name,"quantity":_vm.$store.getters.getCartProductQuantity(_vm.product.sku),"max-quantity":_vm.product.maxQuantity,"oos-experiment-variant":_vm.product.oosExperimentVariant,"fill-parent":""},on:{"add":_vm.add,"remove":_vm.remove}})],1),_vm._v(" "),_c('div',{staticClass:"content"},[_c('PriceTag',{attrs:{"price":_vm.product.price,"original-price":_vm.product.originalPrice}}),_vm._v(" "),(
        _vm.product.oosExperimentVariant &&
        _vm.product.oosExperimentVariant === _vm.VARIANT_LABEL_AND_BOTTOM_SHEET
      )?_c('PromotionLabel',{attrs:{"variant":_vm.PROMOTION_LABEL_VARIANTS.OOS,"label":_vm.$t('oos_indicator_tag'),"data-testid":"oos-label"}}):(_vm.applicablePromotion && _vm.product.maxQuantity)?_c('PromotionLabel',{attrs:{"label":_vm.applicablePromotion.label}}):_vm._e(),_vm._v(" "),_c('p',{staticClass:"title line-clamp-2",attrs:{"data-testid":"product-title"},on:{"click":function($event){$event.stopPropagation();return _vm.handleProductClick($event)}}},[_c('nuxt-link',{staticClass:"focus:after:outline-ring",attrs:{"to":_vm.productPath,"event":""}},[_vm._v("\n        "+_vm._s(_vm.product.name)+"\n      ")])],1),_vm._v(" "),(_vm.product.pricePerUnit)?_c('p',{staticClass:"base-price"},[_vm._v("\n      "+_vm._s(_vm.$formatPricePerUnit(_vm.product.pricePerUnit))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.product.deposit)?_c('p',{staticClass:"base-price -mt-1"},[_vm._v("\n      "+_vm._s(_vm.$t('recycling_deposit_excluded', {
          recycling_deposit: _vm.$formatCurrency(_vm.product.deposit),
        }))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.product.packagingFee)?_c('p',{staticClass:"base-price -mt-1"},[_vm._v("\n      "+_vm._s(_vm.$t('sup_deposit_excluded', {
          plastic_packaging_fee: _vm.$formatCurrency(_vm.product.packagingFee),
        }))+"\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.showSubstitution)?_c('ProductSubstitutionModal',{attrs:{"product":_vm.product},on:{"hide":function($event){_vm.showSubstitution = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }