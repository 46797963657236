import { onMounted, Ref, ref, useContext } from '@nuxtjs/composition-api'
import { ICoordinates } from '~/types/app/Location'
import { TranslateResult } from 'vue-i18n'

interface IMarkerParameters {
  location: ICoordinates
  icon: string
  clickable?: boolean
  isVisible?: boolean
  printBubble?: boolean
  bubbleText?: TranslateResult
}

export default function useGoogleMapsPrintMarkers(
  map: Ref<HTMLDivElement | null>,
  markersData: IMarkerParameters[],
  mapOptions: google.maps.MapOptions = {}
): {
  googleMapsApi: Ref<typeof google.maps | undefined>
  initMarkers: (markersData: IMarkerParameters[]) => void
  mapHandle: Ref<google.maps.Map | undefined>
  plottedMarkers: Ref<google.maps.marker.AdvancedMarkerElement[]>
  updateBounds: () => void
} {
  const { $google } = useContext()

  const googleMapsApi = ref<typeof google.maps>()
  const mapHandle = ref<google.maps.Map>()
  const plottedMarkers = ref<google.maps.marker.AdvancedMarkerElement[]>([])
  const advancedMarkerElementRef =
    ref<typeof google.maps.marker.AdvancedMarkerElement>()

  const defaultCoordinates: google.maps.LatLngLiteral = {
    lat: 0,
    lng: 0,
  }

  onMounted(async (): Promise<void> => {
    const { maps } = await $google
    googleMapsApi.value = maps
    const { AdvancedMarkerElement } = (await googleMapsApi.value.importLibrary(
      'marker'
    )) as google.maps.MarkerLibrary
    advancedMarkerElementRef.value = AdvancedMarkerElement
    initMap()
    initMarkers(markersData)
  })

  function initMap(): void {
    if (!googleMapsApi.value) return

    mapHandle.value = new googleMapsApi.value.Map(map.value as HTMLDivElement, {
      center: new googleMapsApi.value.LatLng(defaultCoordinates),
      disableDefaultUI: true,
      draggableCursor: 'default',
      gestureHandling: 'none',
      keyboardShortcuts: false,
      ...mapOptions,
    })
  }

  function createMarker({
    location,
    icon,
  }: IMarkerParameters): google.maps.marker.AdvancedMarkerElement | undefined {
    const AdvancedMarkerElementConstructor = advancedMarkerElementRef.value
    if (!googleMapsApi.value || !AdvancedMarkerElementConstructor)
      return undefined

    const iconElement = document.createElement('img')
    iconElement.src = icon
    return new AdvancedMarkerElementConstructor({
      content: iconElement,
      position: {
        lat: location.latitude,
        lng: location.longitude,
      },
      map: mapHandle.value,
    })
  }

  function initMarkers(markersData: IMarkerParameters[]): void {
    let infoWindow: google.maps.InfoWindow

    markersData.forEach((marker: IMarkerParameters): void => {
      if (!marker.isVisible) {
        return
      }

      const plottedMarker = createMarker({
        location: marker.location,
        icon: marker.icon,
      })

      if (marker.bubbleText && marker.printBubble && googleMapsApi.value) {
        infoWindow = new googleMapsApi.value.InfoWindow({
          content: `<p class="text-heading-6" style="max-width: 180px;font-family: 'Flink';">${marker.bubbleText}</p>
            <style>.gm-ui-hover-effect { display: none !important; }</style>`,
        })
        infoWindow.open({ anchor: plottedMarker, map: mapHandle.value })
      }

      plottedMarker && plottedMarkers.value.push(plottedMarker)
    })

    updateBounds()
  }

  function updateBounds(): void {
    if (!googleMapsApi.value) return
    const visibleMarkers: Array<google.maps.marker.AdvancedMarkerElement> = []

    const bounds = new googleMapsApi.value.LatLngBounds()

    plottedMarkers.value.forEach(
      (marker: google.maps.marker.AdvancedMarkerElement) => {
        bounds.extend(marker.position ?? defaultCoordinates)
        visibleMarkers.push(marker)
      }
    )

    if (visibleMarkers.length > 1) {
      mapHandle.value?.fitBounds(bounds)
      mapHandle.value?.panToBounds(bounds)
    } else {
      mapHandle.value?.setCenter(
        visibleMarkers[0]?.position ?? defaultCoordinates
      )
      if (!mapOptions.zoom) {
        mapHandle.value?.setZoom(15)
      }
    }
  }

  return {
    googleMapsApi,
    mapHandle,
    plottedMarkers,
    updateBounds,
    initMarkers,
  }
}
