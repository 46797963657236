import transformProducts from '~/api/transformers/transform-products'
import { EProductPlacement } from '~/lib/segment'
import { ISubCollectionRaw } from '~/types/app/Collection'
import { SectionTransformResult } from '../shared'
import { CollectionSectionRaw, V2HomeSectionRaw } from './types'

const check = (section: V2HomeSectionRaw): section is CollectionSectionRaw =>
  'collectionSection' in section

const transform = (section: CollectionSectionRaw): SectionTransformResult => {
  const MINIMUM_PRODUCTS_FOR_SWIMLANE = 4
  const type = 'collectionSection'

  const { title, collections, thumbnailTitleColor, thumbnailBackgroundColor } =
    section.collectionSection

  // normally collections shouldn't add swimlanes at all. Swimlanes should added through
  // 'swimlane' section but this is temporary fix populate swimlanes on the homepage
  const subCollections = collections
    .flatMap((c) => c.subCollections ?? [])
    .filter((item): item is ISubCollectionRaw => !!item)

  const swimlanes = subCollections
    .filter(
      (subCollection) =>
        subCollection &&
        subCollection.products.products.length >= MINIMUM_PRODUCTS_FOR_SWIMLANE
    )
    .map((subCollection) => ({
      type: 'swimlane' as const,
      id: subCollection.id,
      productPlacement: EProductPlacement.swimlane,
      title: subCollection.title,
      subtitle: '',
      products: transformProducts(subCollection.products.products),
    }))

  return {
    type,
    title,
    collections,
    thumbnailTitleColor,
    thumbnailBackgroundColor,
    swimlanes,
  }
}

export const collectionSectionTransformer = { check, transform }
