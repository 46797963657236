import {
  ICheckoutCartResponse,
  ICheckoutCartReturn,
} from '~/api/services/checkout-cart/checkout-cart'

export default function transformCheckoutCartResponse(
  checkoutCartResponse: ICheckoutCartResponse
): ICheckoutCartReturn {
  const { id, confirmationData, confirmationNeeded, number, token } =
    checkoutCartResponse

  return {
    id,
    confirmationData: confirmationData && JSON.parse(confirmationData),
    confirmationNeeded,
    orderDisplayNumber: number,
    orderId: token,
  }
}
