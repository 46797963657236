







import { defineComponent, computed } from '@nuxtjs/composition-api'
import VIcon from '~/components/VIcon/VIcon.vue'
import { GET_DIRECTIONS_URL } from '~/lib/constants'
import { ICoordinates } from '~/types/app/Location'

export default defineComponent({
  components: { VIcon },
  props: {
    hubCoordinates: { type: Object as () => ICoordinates, required: true },
    userCoordinates: { type: Object as () => ICoordinates, required: true },
  },
  setup(props) {
    const handleDirections = computed((): string => {
      return `${GET_DIRECTIONS_URL}${props.userCoordinates.latitude},${props.userCoordinates.longitude}&destination=${props.hubCoordinates.latitude},${props.hubCoordinates.longitude}`
    })

    return {
      handleDirections,
    }
  },
})
