import ICategory, { IV2CategoryRaw } from '~/types/app/Category'

export default function transformV2Categories(
  categoriesRaw: IV2CategoryRaw
): ICategory[] {
  return categoriesRaw.categories.categories.map((category) => {
    return {
      ...category,
      links: category.subCategories,
    }
  })
}
