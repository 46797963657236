import { render, staticRenderFns } from "./SearchLabels.vue?vue&type=template&id=0e72dc2e&"
import script from "./SearchLabels.vue?vue&type=script&lang=ts&"
export * from "./SearchLabels.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VChip: require('/app/build/components/VChip/VChip.vue').default})
