import {
  computed,
  getCurrentInstance,
  WritableComputedRef,
} from '@nuxtjs/composition-api'

/**
 * When passing state through v-model directive, the child component must create
 * an internal state for updating this state (as changing props directly is not
 * allowed). When updating this internal state, it must also emit a fitting
 * update event (e.g., update:name for v-model:name). This hook returns such an
 * internal state.
 *
 * Careful: You still have to reference the update event in defineEmits. Also,
 * to properly use named v-modal, we have to update to Nuxt3. Until then, you
 * need to manually listen to the event in the parent
 *
 * @param props - the pros object returned form defineProps
 * @param propName - the name of the prop that is used as v-model
 */
export default function useModel<T>(
  props: Record<string, any>,
  propName: string,
  eventName: string
): WritableComputedRef<T> {
  const vm = getCurrentInstance()?.proxy
  if (!vm) throw new Error('useModel must be used inside Vue instance!')

  return computed({
    get(): T {
      return props[propName]
    },
    set(value: T) {
      vm.$emit(eventName, value)
    },
  })
}
