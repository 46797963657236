import { SectionTransformResult } from '../shared'
import { FeedbackSectionRaw, V2HomeSectionRaw } from './types'

export const check = (
  section: V2HomeSectionRaw
): section is FeedbackSectionRaw => 'feedback' in section

export const transform = (
  _section: FeedbackSectionRaw
): SectionTransformResult => {
  return {
    type: 'feedback',
  }
}

export const feedbackTransformer = { check, transform }
