import { DirectiveBinding } from 'vue/types/options'
import { eventBus } from './event-bus'
import { EDirection } from './'

interface IDirectiveValue {
  direction?: EDirection
  offsetX?: number
  offsetY?: number
}

export interface IDirectiveParams {
  name: string
  direction: EDirection
  value?: IDirectiveValue
}

declare global {
  interface HTMLElement {
    $removePopoverClickHandlers: () => void
  }
}

/**
 * Build popover parameters
 * @param args
 * @param args.arg - popover name
 * @param args.modifiers - popover direction (e.g. up/down)
 * @param args.value - popover options
 * @returns {IDirectiveParams} directive parameters with popover name, direction and its options
 *
 * @example
 * <button v-popover:name.modifier="value">click</button>
 */
export function buildParams({
  arg = '',
  modifiers = {},
  value = {},
}: DirectiveBinding): IDirectiveParams {
  const { name = arg } = value
  const [direction] = Object.keys(modifiers) as [EDirection]
  return { name, direction, value }
}

export function addClickHandler(
  target: HTMLElement,
  params: IDirectiveParams
): void {
  function onClick(evt: MouseEvent) {
    eventBus.$emit('popover:toggle', {
      ...params,
      target,
      evt,
    })
  }

  target.addEventListener('click', onClick)
  target.dataset.popoverTarget = params.name

  target.$removePopoverClickHandlers = () => {
    target.removeEventListener('click', onClick)
  }
}
