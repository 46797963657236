import acceptLanguageParser from 'accept-language-parser'
import { COUNTRIES_DEFAULT_LOCALES } from '@/lib/constants'
import { hasLocalePrefix, applyPrefix } from '@/lib/helpers'
import { FALLBACK_LOCALE } from '~/localesConfig'
import { uniq, intersection } from 'lodash-es'
import { ELogLevels } from '~/types/app/Logger'

const permanentRedirects = [
  { from: '/datenschutz', to: '/de-DE/privacy/' },
  { from: '/impressum', to: '/de-DE/imprint/' },
  { from: '/sitemap.xml', to: '/sitemapindex.xml' },
]

const excludeFromLocale = ['health']

async function geocodeRequest(req, route, key, logger) {
  const ipAddress = req?.headers['x-forwarded-for']?.split(',')[0] ?? ''
  const fallback = {
    countryCode: null,
  }

  try {
    const res = await fetch(
      `https://extreme-ip-lookup.com/json/${ipAddress}?key=${key}`
    )
    const result = await res.json()

    if (result.status === 'fail') {
      logger({
        level: ELogLevels.ERROR,
        msg: result.message,
        'http.url': route.path,
      })
      logger({
        level: ELogLevels.INFO,
        msg: `ip lookup failed, received headers: ${JSON.stringify(
          req?.headers ?? {}
        )}`,
        'http.url': route.path,
      })
    }
    return result
  } catch {
    return fallback
  }
}

export default async function ({ redirect, req, route, app }) {
  const redirectObj = permanentRedirects.find((r) => r.from === route.path)
  if (redirectObj) {
    return redirect(301, applyPrefix(redirectObj.to))
  }

  const [, firstSlug] = route.path.split('/')

  if (hasLocalePrefix(firstSlug) || excludeFromLocale.includes(firstSlug)) {
    return
  }

  // Detect country by IP
  const { countryCode } = await geocodeRequest(
    req,
    route,
    app.$config.extremeIpApiKey,
    app.$logger
  )

  const acceptLanguage = req?.headers['accept-language'] || FALLBACK_LOCALE
  const acceptedLanguages = acceptLanguageParser
    .parse(acceptLanguage)
    .filter(({ code }) => code !== '*')

  const defaultCountryByLanguage = acceptLanguageParser.pick(
    COUNTRIES_DEFAULT_LOCALES,
    acceptLanguage
  )

  const exactMatchingLocales = acceptedLanguages.reduce((acc, cur) => {
    if (cur.code && cur.region) {
      return [...acc, `${cur.code}-${cur.region}`]
    }
    return acc
  }, [])
  const defaultLocaleFromAcceptLanguage = countryCode
    ? []
    : [defaultCountryByLanguage]
  const acceptLocalesWithDetectedCountryCode = acceptedLanguages.reduce(
    (acc, { code }) => {
      if (countryCode) {
        acc.push(`${code}-${countryCode}`)
      }

      return acc
    },
    []
  )
  const defaultDetectedCountryLocale = [FALLBACK_LOCALE, countryCode]
    .filter(Boolean)
    .join('-')

  const acceptedLocales = uniq([
    ...exactMatchingLocales,
    ...defaultLocaleFromAcceptLanguage,
    ...acceptLocalesWithDetectedCountryCode,
    defaultDetectedCountryLocale,
  ])

  const availableLocales = app.i18n.localeCodes.filter(
    (x) => x !== FALLBACK_LOCALE
  )

  const [chosenLocale] = intersection(acceptedLocales, availableLocales)

  if (chosenLocale) {
    // Case: We can find a suitable locale for the user based on country and language settings.
    return redirect(302, applyPrefix(`/${chosenLocale}${route.path}`))
  }
}
