const CONTENTFUL_SPACE_ID = '1d3t0zyvn9za'

module.exports = {
  CONTENTFUL_PREVIEW_QUERY_ID: 'preview',
  CONTENTFUL_SPACE_ID,
  CONTENTFUL_URL: 'https://cntfl.goflink.com/delivery/',
  CONTENTFUL_URL_PREVIEW: 'https://cntfl.goflink.com/preview/',
  CONTENTFUL_ENVIRONMENT_MASTER: 'master',
  CONTENTFUL_ENVIRONMENT_STAGING: 'staging',
  CONTENTFUL_ENVIRONMENT_DEVELOPMENT: 'development',
}
