var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{staticClass:"quantity-control",class:{
      '--quantity-set': _vm.quantity && !_vm.isOutOfStock,
    },style:(_vm.isFullWidth && {
        '--quantity-control-width': '100%',
        '--max-control-width': '100%',
      })},[(_vm.isOutOfStock)?_c('div',{staticClass:"oos-indicator-wrapper"},[_c('div',{staticClass:"oos-indicator",attrs:{"data-testid":"oos-indicator"}},[_vm._v("\n        "+_vm._s(_vm.$t('product_out_of_stock'))+"\n      ")])]):_c('div',{staticClass:"add-product-controls",class:{
        '--quantity-set': _vm.quantity,
      }},[(_vm.quantity)?_c('button',{staticClass:"button button-like",attrs:{"type":"button","title":_vm.$t('product_btn_remove', { productName: _vm.productName }),"aria-label":_vm.$t('product_btn_remove', { productName: _vm.productName }),"data-testid":"removeProduct"},on:{"click":function($event){$event.stopPropagation();return _vm.decrement()}}},[_c('VIcon',{staticClass:"quantity-icon",attrs:{"name":"minus"}})],1):_vm._e(),_vm._v(" "),(_vm.quantity)?_c('span',{staticClass:"quantity",attrs:{"role":"note","title":_vm.$t('product_quantity', { productName: _vm.productName }),"aria-label":_vm.$t('product_quantity', { productName: _vm.productName })}},[_vm._v("\n        "+_vm._s(_vm.quantity)+"\n        "),_vm._t("default")],2):_vm._e(),_vm._v(" "),_c('button',{class:[
          'button',
          'flink-btn',
          { 'text-only': _vm.initialTextOnlyAndNotInCart } ],attrs:{"type":"button","disabled":_vm.isDisabled,"title":_vm.$t('product_btn_add', { productName: _vm.productName }),"aria-label":_vm.$t('product_btn_add', { productName: _vm.productName }),"data-testid":"addProduct"},on:{"click":function($event){$event.stopPropagation();return _vm.increment()}}},[(_vm.initialTextOnlyAndNotInCart)?_c('span',{staticClass:"button-text"},[_vm._v(_vm._s(_vm.$t('button_title')))]):_vm._e(),_vm._v(" "),_c('VIcon',{staticClass:"quantity-icon",attrs:{"name":_vm.showSwap ? 'swap' : 'plus'}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }