
















import FlinkPrimeLogo from '~/assets/logo-prime.svg?inline';
import { EComponentName } from '~/lib/segment';
import { useContext, onMounted, useStore } from '@nuxtjs/composition-api';
import { IAppState } from '~/types/app/State';
const __sfc_main = {};
__sfc_main.props = {
  buttonText: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const {
    $segmentEvent
  } = useContext();
  const store = useStore<IAppState>();
  onMounted(() => {
    $segmentEvent.SECTION_VIEWED_ON_CURRENT_SCREEN({
      componentName: EComponentName.subscriptionBanner,
      componentVariant: (store.state.subscription.subscriptionStatus as string)
    });
  });
  return {
    emit
  };
};

__sfc_main.components = Object.assign({
  FlinkPrimeLogo
}, __sfc_main.components);
export default __sfc_main;
