import { ROUTES } from '~/lib/routes'
import { applyPrefix } from '~/lib/helpers'
import { ROUTE_LOCALE_SEPARATOR } from '~/lib/constants'
import { isUserSMSVerified } from '~/api/services/account/get-user-phone-verification-status'
import { EOriginScreen, ETraceMoment, ETraceName } from '~/lib/segment'

// only use this middleware in checkout related pages
export default async function ({ redirect, app, route }) {
  const isUserAuthenticated = app?.store?.getters['account/isUserAuthenticated']
  let redirectTarget = ROUTES.GET_STARTED

  if (isUserAuthenticated) {
    const status = await isUserSMSVerified(
      { client: app.$apiUsers },
      app.$logger
    )
    if (status) {
      app.store.dispatch('setLatencyTracing', {
        traceName: ETraceName.LOAD_CHECKOUT,
        traceMoment: ETraceMoment.STARTED,
      })
      return
    }
    redirectTarget = ROUTES.PHONE_VERIFICATION
  }
  const isCheckoutRedirect = route.query.cartId
  if (!!isCheckoutRedirect) return

  const targetRoute = {
    name: route.name?.split(ROUTE_LOCALE_SEPARATOR)[0] || ROUTES.HOME,
  }
  app.store.dispatch('account/setAuthRedirectTarget', targetRoute)

  return redirect(
    applyPrefix(
      app.localePath({
        name: redirectTarget,
        query: { intent: targetRoute.name },
      })
    )
  )
}
