import {
  IAddress,
  IAddressBookAddress,
  ICoordinates,
  ILocationInput,
} from '~/types/app/Location'
import { isEqual, pick } from 'lodash-es'

function getAddressComponentObject(
  type: string,
  place?: google.maps.GeocoderResult
): google.maps.GeocoderAddressComponent | undefined {
  return place?.address_components?.find(
    (ac: google.maps.GeocoderAddressComponent) => ac.types.includes(type)
  )
}

export function getAddressComponent(
  type: string,
  place?: google.maps.GeocoderResult,
  useShortName = false
): string {
  const value = getAddressComponentObject(type, place)

  if (!value) return ''
  return value[useShortName ? 'short_name' : 'long_name'] ?? ''
}

export function setAddressComponent(
  newValue: string,
  type: string,
  place?: google.maps.GeocoderResult
): void {
  const value = getAddressComponentObject(type, place)
  if (value) {
    value.short_name = newValue
    value.long_name = newValue
  }
}

interface IGeocodeLatLngInput {
  geocoder: google.maps.Geocoder
  location: google.maps.LatLng
  initialAddress?: string
}

export async function geocodeLatLng({
  geocoder,
  location,
  initialAddress = '',
}: IGeocodeLatLngInput): Promise<ILocationInput> {
  const coordinates = {
    latitude: location.lat(),
    longitude: location.lng(),
  }

  try {
    const geocoded = await geocoder.geocode({
      location,
    })
    const place = geocoded.results[0]
    const streetAddress =
      initialAddress === ''
        ? `${getAddressComponent('route', place)} ${getAddressComponent(
            'street_number',
            place
          )}`
        : undefined
    const address: IAddress = {
      street_address_1: streetAddress,
      city: getAddressComponent('locality', place),
      country: getAddressComponent('country', place),
      country_code: getAddressComponent('country', place, true),
      postal_code: getAddressComponent('postal_code', place),
    }
    const geocodedAddress = geocoded.results[0].formatted_address
    return { coordinates, geocodedAddress, address }
  } catch (e) {
    return { coordinates, geocodedAddress: '', address: undefined }
  }
}

interface IShippingData {
  address: IAddress
  coordinates: ICoordinates
}

export function shippingDataToListAddress({
  address,
  coordinates,
  isDefault = false,
}: {
  isDefault: boolean
} & IShippingData): Omit<IAddressBookAddress, 'id'> {
  return {
    latitude: coordinates.latitude,
    longitude: coordinates.longitude,
    street_address: address.street_address_1 ?? '',
    post_code: address.postal_code ?? '',
    city: address.city ?? '',
    country_code: address.country ?? '',
    comment: address.street_address_2 ?? '',
    is_default: isDefault,
  }
}

export function listAddressToShippingData(
  addressBookAddress: IAddressBookAddress
): IShippingData {
  const address: IAddress = {
    id: addressBookAddress.id,
    city: addressBookAddress.city,
    country: addressBookAddress.country_code,
    postal_code: addressBookAddress.post_code,
    street_address_1: addressBookAddress.street_address,
    street_address_2: addressBookAddress.comment,
  }

  const coordinates: ICoordinates = {
    latitude: addressBookAddress.latitude,
    longitude: addressBookAddress.longitude,
  }

  return {
    address,
    coordinates,
  }
}

type IAddressBookAddressPartial = Partial<IAddressBookAddress>

export function areAddressBookAddressesEqual(
  address1: IAddressBookAddressPartial,
  address2: IAddressBookAddressPartial
): boolean {
  const addressComparable1 = getAddressBookAddressComparisonFields(address1)
  const addressComparable2 = getAddressBookAddressComparisonFields(address2)

  return isEqual(addressComparable1, addressComparable2)
}

function getAddressBookAddressComparisonFields(
  address: IAddressBookAddressPartial
) {
  return pick(address, [
    'street_address',
    'city',
    'country_code',
    'post_code',
    'comment',
  ])
}
