import { NuxtAxiosInstance } from '@nuxtjs/axios'
import {
  EHTTPMethods,
  ELoggers,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'
import { ICartV2 } from '~/types/app/Cart'
import { AxiosError } from 'axios'
import { put } from '~/api/controllers/put'

interface IPayload {
  cartId: string
  centAmount: number
  currency: string
}

interface IResponse {
  cart: ICartV2
}

export default async function addRiderTip(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  { cartId, centAmount, currency }: IPayload
): Promise<ICartV2> {
  const url = `/cart/${cartId}/rider-tip`

  const body = {
    riderTip: {
      centAmount,
      currency,
    },
  }

  try {
    const { data } = await put<IResponse>(client, logger, url, {}, body)
    return data.cart
  } catch (error) {
    const e = error as AxiosError
    logger({
      level: ELogLevels.ERROR,
      msg: `API Error: Adding rider tip failed.`,
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: e.response?.data as string,
      logger: ELoggers.CART,
      func: 'addRiderTip',
      slug: 'checkout.api.add-rider-tip.error',
      forceSubmit: false,
    })

    throw error
  }
}
