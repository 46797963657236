import { render, staticRenderFns } from "./ProductListWithPromotions.vue?vue&type=template&id=7d097c2b&"
import script from "./ProductListWithPromotions.vue?vue&type=script&lang=ts&"
export * from "./ProductListWithPromotions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromotionProductList: require('/app/build/components/ProductList/PromotionProductList.vue').default,ProductList: require('/app/build/components/ProductList/ProductList.vue').default})
