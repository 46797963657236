export default (_, inject) => {
  inject('structuredData', {
    script(structuredData) {
      if (!structuredData) return {}
      return {
        hid: 'structured-data',
        type: 'application/ld+json',
        json: structuredData,
      }
    },
  })
}
