

















































































import { getCountryFromLocale } from '~/lib/helpers';
import VueI18n from 'vue-i18n';
import { ref, useStore, useFetch, computed, useContext } from '@nuxtjs/composition-api';
import FlinkLogo from '~/assets/logo-color.svg?inline';
import VIcon from '~/components/VIcon/VIcon.vue';
import { ILpComponentFooterFields } from 'types/generated/contentful.d';
import { IAppState } from '~/types/app/State';
const __sfc_main = {
  name: 'MainFooter'
};
__sfc_main.props = {
  showLanguageSwitch: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const isModalShown = ref<boolean>(false);
  const {
    i18n,
    query,
    req
  } = useContext();
  const store = useStore<IAppState>();
  const footerData = computed<ILpComponentFooterFields | undefined>(() => store.state.content?.footerContent);
  const mainFooter = computed(() => ({ ...footerData.value
  }));
  const linkTitle = computed<VueI18n.TranslateResult>(() => {
    const country = getCountryFromLocale(i18n.locale);
    return country ? (i18n.t(`footer_country_locale_switch_${country.toLowerCase()}`) as string) : i18n.t('footer_country_locale_switch_en');
  });
  const currentYear = computed<number>(() => {
    return new Date().getFullYear();
  });
  useFetch(async () => {
    await store.dispatch('content/loadFooterContent', {
      query,
      req
    });
  });
  return {
    isModalShown,
    mainFooter,
    linkTitle,
    currentYear
  };
};

__sfc_main.components = Object.assign({
  FlinkLogo,
  VIcon
}, __sfc_main.components);
export default __sfc_main;
