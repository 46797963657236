import { render, staticRenderFns } from "./LocalesSwitchModal.vue?vue&type=template&id=6ee20326&scoped=true&"
import script from "./LocalesSwitchModal.vue?vue&type=script&lang=ts&"
export * from "./LocalesSwitchModal.vue?vue&type=script&lang=ts&"
import style0 from "./LocalesSwitchModal.vue?vue&type=style&index=0&id=6ee20326&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee20326",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VIcon: require('/app/build/components/VIcon/VIcon.vue').default,VModal: require('/app/build/components/VModal/VModal.vue').default})
