import { render, staticRenderFns } from "./DeliveryBanner.vue?vue&type=template&id=76eb173e&scoped=true&"
import script from "./DeliveryBanner.vue?vue&type=script&lang=ts&"
export * from "./DeliveryBanner.vue?vue&type=script&lang=ts&"
import style0 from "./DeliveryBanner.vue?vue&type=style&index=0&id=76eb173e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76eb173e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/app/build/components/Button/Button.vue').default,VIcon: require('/app/build/components/VIcon/VIcon.vue').default,AddAddressBanner: require('/app/build/components/AddAddressBanner/AddAddressBanner.vue').default,LocationChangeHintPopover: require('/app/build/components/LocationChangeHintPopover/LocationChangeHintPopover.vue').default,HighDeliveryTimeTooltip: require('/app/build/components/HighDeliveryTimeTooltip/HighDeliveryTimeTooltip.vue').default,DeliveryBannerGenericPopover: require('/app/build/components/DeliveryBannerGenericPopover/DeliveryBannerGenericPopover.vue').default})
