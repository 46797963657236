

















































import { computed, watch, useContext, useStore } from '@nuxtjs/composition-api';
import { EComponentName, ETrackingType } from '~/lib/segment';
import IRootState from '~/types/app/State';
import { ECheckoutDisplayState, ESchedulingOptionType } from '~/types/app/PlannedOrders';
import { defineComponent } from '@nuxtjs/composition-api';
import IPrice from '~/types/app/Price';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  icon: {
    type: String,
    default: 'clock'
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  feeDescription: {
    type: String,
    default: ''
  },
  discountDescription: {
    type: String,
    default: ''
  },
  state: {
    type: (String as () => ECheckoutDisplayState),
    default: ECheckoutDisplayState.ENABLED
  },
  type: {
    type: (String as () => ESchedulingOptionType),
    default: ESchedulingOptionType.ASAP
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const {
    $segmentEvent
  } = useContext();
  const store = useStore<IRootState>();
  const isSelected = computed<boolean>(() => props.state === ECheckoutDisplayState.SELECTED);
  const isDisabled = computed<boolean>(() => props.state === ECheckoutDisplayState.DISABLED);
  const isCta = computed<boolean>(() => props.state === ECheckoutDisplayState.CTA);
  const isPriority = computed<boolean>(() => props.type === ESchedulingOptionType.PRIO);
  const isPlanned = computed<boolean>(() => props.type === ESchedulingOptionType.PLANNED);
  const emit = __ctx.emit;

  function onClick() {
    !isDisabled.value && emit('open-timeslot-selection');

    if (isPriority.value) {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.click,
        componentName: EComponentName.priorityDelivery,
        componentContent: store.state.cartId
      });
    }
  }

  watch([isPriority, isDisabled], () => {
    if (isPriority.value) {
      $segmentEvent.SECTION_VIEWED_ON_CURRENT_SCREEN({
        componentName: EComponentName.priorityDelivery,
        componentVariant: isDisabled.value ? 'booked-out' : 'available',
        componentContent: store.state.cartId
      });
    }
  }, {
    immediate: true
  });
  return {
    ESchedulingOptionType,
    isSelected,
    isDisabled,
    isCta,
    isPriority,
    isPlanned,
    onClick
  };
};

export default __sfc_main;
