import ICategory from '~/types/app/Category'
import { SectionTransformResult } from '../shared'
import { CategoryGridSectionRaw, V2HomeSectionRaw } from './types'

const check = (section: V2HomeSectionRaw): section is CategoryGridSectionRaw =>
  'categoryGrid' in section

const transform = (section: CategoryGridSectionRaw): SectionTransformResult => {
  const title = section.categoryGrid.title
  const items: ICategory[] = section.categoryGrid.categories.categories.map(
    (category) => ({
      id: category.id,
      name: category.name,
      slug: category.slug,
      icon: category.icon,
      backgroundImage: category.backgroundImage,
      links: category.subCategories,
    })
  )

  return {
    type: 'categories',
    title,
    categories: items,
  }
}

export const categoryGridTransformer = { check, transform }
