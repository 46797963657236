export enum EDirection {
  up = 'up',
  down = 'down',
}

export interface IPositionStyle {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

export interface IPositionParams {
  direction?: keyof typeof EDirection
  offsetX?: number
  offsetY?: number
}
