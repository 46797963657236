






















































































import { ref, watch, computed, onMounted, onUnmounted, useContext, onUpdated } from '@nuxtjs/composition-api';
import { EComponentContent, EComponentName, EScreenName, ETrackingType, EProductPlacement } from '~/lib/segment';
import { ROUTES } from '~/lib/routes';
import { ICTAButton, CtaTarget } from '~/types/app/CTAButton';
// need this for default export to be detected
import { defineComponent } from '@nuxtjs/composition-api';
import { nanoid } from 'nanoid';
import { debounce } from 'lodash';

const __sfc_main = defineComponent({
  name: 'VSwimlane'
});

__sfc_main.props = {
  id: {
    type: String,
    default: ''
  },
  recipeRecs: {
    type: Boolean,
    default: false,
    required: false
  },
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: false,
    default: ''
  },
  ctaButton: {
    type: (Object as () => ICTAButton),
    default: () => ({
      text: 'See all'
    })
  },
  itemGap: {
    type: Number,
    default: 0
  },
  componentPosition: {
    type: Number,
    default: 0
  },
  componentName: {
    type: String,
    default: EComponentName.swimlane
  },
  screenName: {
    type: String,
    default: EScreenName.home
  },
  componentVariant: {
    type: String,
    required: false,
    default: ''
  },
  intersectSelector: {
    type: String,
    required: false,
    default: ''
  },
  isHighlighted: {
    type: Boolean,
    default: false,
    required: false
  },
  showTitleBig: {
    type: Boolean,
    default: false,
    required: false
  },
  showMarginArrows: {
    type: Boolean,
    default: false,
    required: false
  },
  intersectionOptions: {
    type: (Object as () => Partial<IntersectionObserverInit>),
    required: false,
    default: () => ({
      threshold: 0.5
    })
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    default: EProductPlacement.swimlane
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emits = __ctx.emit;
  const slider = ref<HTMLDivElement | null>(null);
  const uid = 'swimlane_' + nanoid();
  const {
    $segmentEvent,
    localePath
  } = useContext();
  const elementWidth = computed(() => {
    const firstSlide = (slider.value?.firstChild as HTMLInputElement);

    if (props.recipeRecs) {
      return firstSlide.clientWidth;
    } else {
      return firstSlide.clientWidth + props.itemGap;
    }
  });
  const totalWidth = computed(() => {
    return itemsCount.value * elementWidth.value;
  });
  const showControls = computed(() => {
    return !nextDisabled.value || !prevDisabled.value;
  });
  const nextDisabled = ref(false);
  const prevDisabled = ref(false);
  const itemsCount = ref(getItemsCount());
  const wasClicked = ref(false);
  const observer = ref<IntersectionObserver | undefined>(undefined);
  const scrollPos = ref(0);
  const debouncedSetPrevNextDisabledState = debounce(setPrevNextDisabledState, 60);
  const debouncedHandleScroll = debounce(event => handleScrollTracking(event), 100);
  const btnTarget = computed(() => {
    if (props.ctaButton?.onTapAction?.type === CtaTarget.navigateToBuyAgain || props.ctaButton?.onTapAction?.type === CtaTarget.navigateToLastBought) {
      return localePath(ROUTES.LAST_BOUGHT);
    } else if (props.ctaButton?.onTapAction?.type === CtaTarget.navigateToDeals) {
      return localePath(ROUTES.DEALS);
    }

    return localePath({
      name: ROUTES.COLLECTION,
      params: {
        slug: props.id
      }
    });
  });
  onMounted(() => {
    itemsCount.value = getItemsCount();
    /**
     * Setup the tracking event on sectionViewed only
     * when required prop for feature is being sent
     */

    if (props.intersectSelector) {
      observer.value = new IntersectionObserver(handleObserved, props.intersectionOptions);
      const intersectWith = document.querySelectorAll(props.intersectSelector);
      intersectWith.forEach(node => {
        observer.value?.observe(node);
      });
    }
  });
  onUnmounted(() => {
    if (slider.value) {
      slider.value.removeEventListener('scroll', debouncedSetPrevNextDisabledState);
      window.removeEventListener('resize', debouncedSetPrevNextDisabledState);
    } // unobserve elements intersection only if intersectSelector is defined


    if (props.intersectSelector) {
      const intersectWith = document.querySelectorAll(props.intersectSelector);
      intersectWith.forEach(node => {
        observer.value?.unobserve(node);
      });
    }
  });
  onUpdated(() => {
    itemsCount.value = getItemsCount();
  });
  watch(() => itemsCount.value, (value: number) => {
    if (value && slider.value) {
      slider.value.addEventListener('scroll', debouncedSetPrevNextDisabledState);
      setPrevNextDisabledState();
      window.addEventListener('resize', debouncedSetPrevNextDisabledState);
    }
  }, {
    flush: 'post'
  });

  function getItemsCount(): number {
    return slider.value?.children.length || 0;
  }

  function maximumVisible() {
    return slider.value ? Math.floor(slider.value.clientWidth / elementWidth.value) : 0;
  }

  function setPrevNextDisabledState(): void {
    if (slider.value) {
      prevDisabled.value = slider.value.scrollLeft === 0;
      nextDisabled.value = slider.value.clientWidth + slider.value.scrollLeft >= totalWidth.value;
    }
  }

  function nextSet(): void {
    wasClicked.value = true;

    if (slider.value) {
      slider.value.scroll({
        left: slider.value.scrollLeft + maximumVisible() * elementWidth.value,
        behavior: 'smooth'
      });
      setPrevNextDisabledState();
    }

    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: props.screenName,
      componentName: props.componentName,
      componentContent: EComponentContent.next,
      componentVariant: props.componentVariant ?? props.title
    });
  }

  function prevSet(): void {
    wasClicked.value = true;

    if (slider.value) {
      const currentScrollLeft = slider.value.scrollLeft - maximumVisible() * elementWidth.value;
      slider.value.scroll({
        left: Math.floor(currentScrollLeft / elementWidth.value) * elementWidth.value,
        behavior: 'smooth'
      });
      setPrevNextDisabledState();
    }

    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: props.screenName,
      componentName: props.componentName,
      componentContent: EComponentContent.back,
      componentVariant: props.componentVariant ?? props.title
    });
  }

  function handleScrollTracking(event: Event): void {
    if (wasClicked.value) {
      wasClicked.value = false;
      return;
    }

    const {
      target
    } = event;
    if (!target) return;

    if ((target as HTMLDivElement).scrollLeft > scrollPos.value) {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.swipe,
        screenName: props.screenName,
        componentName: props.componentName,
        componentContent: EComponentContent.next,
        componentVariant: props.componentVariant || props.productPlacement || props.title
      });
    } else {
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.swipe,
        screenName: props.screenName,
        componentName: props.componentName,
        componentContent: EComponentContent.back,
        componentVariant: props.componentVariant || props.productPlacement || props.title
      });
    }

    scrollPos.value = (target as HTMLDivElement).scrollLeft;
  }

  function handleObserved(entries: IntersectionObserverEntry[]): void {
    entries.forEach(({
      isIntersecting,
      intersectionRatio,
      target
    }) => {
      if (isIntersecting && intersectionRatio >= Number(props.intersectionOptions?.threshold)) {
        const index = Number(target.getAttribute('index'));
        emits('onIntersect', index);
      }
    });
  }

  function trackClick(componentName = 'outside_see_all') {
    $segmentEvent.TRACKING({
      trackingType: ETrackingType.click,
      screenName: props.screenName,
      componentVariant: props.productPlacement,
      componentName
    });
  }

  return {
    EComponentName,
    slider,
    uid,
    showControls,
    nextDisabled,
    prevDisabled,
    itemsCount,
    debouncedHandleScroll,
    btnTarget,
    nextSet,
    prevSet,
    trackClick
  };
};

export default __sfc_main;
