


















































import { EProductPlacement, ETrackingType } from '~/lib/segment';
import type { ICampaign } from '~/types/app/Promotions';
import { computed, ref, useContext } from '@nuxtjs/composition-api';
import QuantityControl from '~/components/QuantityControl/QuantityControl.vue';
import IProduct, { VARIANT_LABEL_AND_BOTTOM_SHEET } from '~/types/app/Product';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  product: {
    type: (Object as () => IProduct),
    required: true
  },
  productPositionInGroup: {
    type: Number,
    required: true
  },
  staticQuantity: {
    type: Boolean,
    default: false
  },
  allowClick: {
    type: Boolean,
    default: false
  },
  productPlacement: {
    type: (String as () => EProductPlacement),
    default: EProductPlacement.swimlane
  },
  searchQueryId: {
    type: String,
    default: ''
  },
  hasAnAppliedPromotion: {
    type: Boolean,
    default: false
  },
  displayInlinePromotionLabel: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    $segmentEvent
  } = useContext();
  const showSubstitution = ref(false);
  const promotionDetails = computed((): ICampaign | undefined => props.product.promotions?.campaigns?.[0]);
  const displayPromotionLabel = computed(() => props.displayInlinePromotionLabel && promotionDetails.value);

  function add(): void {
    const {
      product,
      searchQueryId,
      productPlacement,
      productPositionInGroup
    } = props;
    const oosExperimentVariant = product.oosExperimentVariant;

    if (oosExperimentVariant) {
      showSubstitution.value = true;
      $segmentEvent.TRACKING({
        trackingType: ETrackingType.click,
        componentContent: product.sku,
        componentName: oosExperimentVariant === VARIANT_LABEL_AND_BOTTOM_SHEET ? 'oos_swap_cta' : 'oos_atc_cta'
      });
    } else {
      emit('add-to-cart', {
        searchQueryId,
        productPlacement,
        sku: product.sku,
        tags: product.tags,
        productPosition: productPositionInGroup
      });
    }
  }

  return {
    showSubstitution,
    promotionDetails,
    displayPromotionLabel,
    add
  };
};

__sfc_main.components = Object.assign({
  QuantityControl
}, __sfc_main.components);
export default __sfc_main;
