
















































import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { theme } from '~/tailwind.config'
import IProduct from '~/types/app/Product'
import { ROUTES } from '~/lib/routes'
import { EProductPlacement } from '~/lib/segment'
import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    PulseLoader,
  },
  props: {
    query: {
      type: String,
      default: '',
    },
    searchQueryId: {
      type: String,
      default: '',
    },
    results: {
      type: Array as () => IProduct[],
      required: true,
      default: () => [],
    },
    grid: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    maxVisibleResults: {
      type: Number,
      default: 5,
    },
    limitScroll: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const searchRoute = ROUTES.SEARCH
    const productPlacement = EProductPlacement.search
    const flinkPink = theme.colors.primary[500]

    const trimmedResults = computed((): IProduct[] => {
      return props.results.slice(0, props.maxVisibleResults)
    })

    const showViewMore = computed((): boolean => {
      return props.results.length > props.maxVisibleResults
    })

    const preventBlurEvent = (evt: MouseEvent): void => {
      evt.preventDefault()
      evt.stopImmediatePropagation()
    }

    return {
      flinkPink,
      searchRoute,
      productPlacement,
      trimmedResults,
      showViewMore,
      preventBlurEvent,
    }
  },
})
