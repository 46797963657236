//
//
//
//
//
//
//
//

import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'LazyLottie',
  props: {
    options: { type: Object, required: false, default: () => ({}) },
  },
  emits: ['animCreated'],
  setup(props) {
    const opts = ref(props.options)

    return {
      LazyLottie: () => import('vue-lottie/src/lottie.vue'),
      opts,
    }
  },
})
