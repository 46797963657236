import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { LoggerFunction } from '~/types/app/Logger'
import {
  HomeV2TransformResult,
  transformV2Home,
  V2HomeRaw,
} from '~/api/transformers/transform-v2-home'

interface IGetHomeParams {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  hubSlug: string
}

export default async function getHome({
  client,
  logger,
  locale,
  hubSlug,
}: IGetHomeParams): Promise<HomeV2TransformResult | void> {
  const url = 'v2/home'
  const headers = { locale, 'hub-slug': hubSlug }

  try {
    const response = await get<V2HomeRaw>(client, logger, url, headers)

    return transformV2Home(response.data)
  } catch (error) {}
}
