import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b36fdf7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4381780d = () => interopDefault(import('../pages/recipes/index.vue' /* webpackChunkName: "pages/recipes/index" */))
const _d94749b8 = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _b0774cf0 = () => interopDefault(import('../pages/account/forgot-password.vue' /* webpackChunkName: "pages/account/forgot-password" */))
const _0e68878d = () => interopDefault(import('../pages/account/get-started.vue' /* webpackChunkName: "pages/account/get-started" */))
const _86ba02a8 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _1b324b32 = () => interopDefault(import('../pages/account/phone-verification.vue' /* webpackChunkName: "pages/account/phone-verification" */))
const _1b0ada2c = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _7e6deea6 = () => interopDefault(import('../pages/account/reset-password.vue' /* webpackChunkName: "pages/account/reset-password" */))
const _2416c3e5 = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _5eb9ec7d = () => interopDefault(import('../pages/p/waitlist.vue' /* webpackChunkName: "pages/p/waitlist" */))
const _22a76636 = () => interopDefault(import('../pages/prime/join.vue' /* webpackChunkName: "pages/prime/join" */))
const _6dd47ae0 = () => interopDefault(import('../pages/prime/manage.vue' /* webpackChunkName: "pages/prime/manage" */))
const _20066d3d = () => interopDefault(import('../pages/prime/process-payment.vue' /* webpackChunkName: "pages/prime/process-payment" */))
const _02688018 = () => interopDefault(import('../pages/prime/success.vue' /* webpackChunkName: "pages/prime/success" */))
const _71fc65be = () => interopDefault(import('../pages/shop/cart.vue' /* webpackChunkName: "pages/shop/cart" */))
const _620f7c38 = () => interopDefault(import('../pages/shop/checkout.vue' /* webpackChunkName: "pages/shop/checkout" */))
const _74146159 = () => interopDefault(import('../pages/shop/deals.vue' /* webpackChunkName: "pages/shop/deals" */))
const _080a3258 = () => interopDefault(import('../pages/shop/last-bought.vue' /* webpackChunkName: "pages/shop/last-bought" */))
const _4fe8e90c = () => interopDefault(import('../pages/shop/process-payment.vue' /* webpackChunkName: "pages/shop/process-payment" */))
const _bbd5bbf4 = () => interopDefault(import('../pages/shop/product-replacement.vue' /* webpackChunkName: "pages/shop/product-replacement" */))
const _f8243a74 = () => interopDefault(import('../pages/shop/search.vue' /* webpackChunkName: "pages/shop/search" */))
const _7955ae06 = () => interopDefault(import('../pages/account/request-an-invoice/webview/_slug.vue' /* webpackChunkName: "pages/account/request-an-invoice/webview/_slug" */))
const _bfc15e3c = () => interopDefault(import('../pages/account/help-center/_slug/index.vue' /* webpackChunkName: "pages/account/help-center/_slug/index" */))
const _468f227e = () => interopDefault(import('../pages/account/order-details/_slug.vue' /* webpackChunkName: "pages/account/order-details/_slug" */))
const _94ffe6da = () => interopDefault(import('../pages/account/request-an-invoice/_slug.vue' /* webpackChunkName: "pages/account/request-an-invoice/_slug" */))
const _59af55d7 = () => interopDefault(import('../pages/shop/category/_slug.vue' /* webpackChunkName: "pages/shop/category/_slug" */))
const _6f069412 = () => interopDefault(import('../pages/shop/collection/_slug.vue' /* webpackChunkName: "pages/shop/collection/_slug" */))
const _0fce74f8 = () => interopDefault(import('../pages/shop/order-collect/_slug.vue' /* webpackChunkName: "pages/shop/order-collect/_slug" */))
const _6546d96a = () => interopDefault(import('../pages/shop/order-status/_slug.vue' /* webpackChunkName: "pages/shop/order-status/_slug" */))
const _7f63d2bc = () => interopDefault(import('../pages/shop/product/_slug.vue' /* webpackChunkName: "pages/shop/product/_slug" */))
const _a30ecdbe = () => interopDefault(import('../pages/account/help-center/_slug/photo-upload/_sku.vue' /* webpackChunkName: "pages/account/help-center/_slug/photo-upload/_sku" */))
const _ab2bcc44 = () => interopDefault(import('../pages/account/help-center/_slug/reasons/_sku.vue' /* webpackChunkName: "pages/account/help-center/_slug/reasons/_sku" */))
const _30604b36 = () => interopDefault(import('../pages/shop/category/_category/_slug.vue' /* webpackChunkName: "pages/shop/category/_category/_slug" */))
const _4d999f38 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _7662abde = () => interopDefault(import('../pages/city/_slug.vue' /* webpackChunkName: "pages/city/_slug" */))
const _76742144 = () => interopDefault(import('../pages/collection/_slug.vue' /* webpackChunkName: "pages/collection/_slug" */))
const _37fcb2e0 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _0fe3f28f = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _41cad6c5 = () => interopDefault(import('../pages/recipes/_slug.vue' /* webpackChunkName: "pages/recipes/_slug" */))
const _19805caf = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de-DE/",
    component: _1b36fdf7,
    pathToRegexpOptions: {"strict":true},
    name: "index___de-DE"
  }, {
    path: "/en-DE/",
    component: _1b36fdf7,
    pathToRegexpOptions: {"strict":true},
    name: "index___en-DE"
  }, {
    path: "/en-NL/",
    component: _1b36fdf7,
    pathToRegexpOptions: {"strict":true},
    name: "index___en-NL"
  }, {
    path: "/en/",
    component: _1b36fdf7,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/nl-NL/",
    component: _1b36fdf7,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl-NL"
  }, {
    path: "/de-DE/recipes/",
    component: _4381780d,
    pathToRegexpOptions: {"strict":true},
    name: "recipes___de-DE"
  }, {
    path: "/de-DE/shop/",
    component: _d94749b8,
    pathToRegexpOptions: {"strict":true},
    name: "shop___de-DE"
  }, {
    path: "/en-DE/recipes/",
    component: _4381780d,
    pathToRegexpOptions: {"strict":true},
    name: "recipes___en-DE"
  }, {
    path: "/en-DE/shop/",
    component: _d94749b8,
    pathToRegexpOptions: {"strict":true},
    name: "shop___en-DE"
  }, {
    path: "/en-NL/recipes/",
    component: _4381780d,
    pathToRegexpOptions: {"strict":true},
    name: "recipes___en-NL"
  }, {
    path: "/en-NL/shop/",
    component: _d94749b8,
    pathToRegexpOptions: {"strict":true},
    name: "shop___en-NL"
  }, {
    path: "/en/recipes/",
    component: _4381780d,
    pathToRegexpOptions: {"strict":true},
    name: "recipes___en"
  }, {
    path: "/en/shop/",
    component: _d94749b8,
    pathToRegexpOptions: {"strict":true},
    name: "shop___en"
  }, {
    path: "/nl-NL/recipes/",
    component: _4381780d,
    pathToRegexpOptions: {"strict":true},
    name: "recipes___nl-NL"
  }, {
    path: "/nl-NL/shop/",
    component: _d94749b8,
    pathToRegexpOptions: {"strict":true},
    name: "shop___nl-NL"
  }, {
    path: "/de-DE/account/forgot-password/",
    component: _b0774cf0,
    pathToRegexpOptions: {"strict":true},
    name: "account-forgot-password___de-DE"
  }, {
    path: "/de-DE/account/get-started/",
    component: _0e68878d,
    pathToRegexpOptions: {"strict":true},
    name: "account-get-started___de-DE"
  }, {
    path: "/de-DE/account/login/",
    component: _86ba02a8,
    pathToRegexpOptions: {"strict":true},
    name: "account-login___de-DE"
  }, {
    path: "/de-DE/account/phone-verification/",
    component: _1b324b32,
    pathToRegexpOptions: {"strict":true},
    name: "account-phone-verification___de-DE"
  }, {
    path: "/de-DE/account/profile/",
    component: _1b0ada2c,
    pathToRegexpOptions: {"strict":true},
    name: "account-profile___de-DE"
  }, {
    path: "/de-DE/account/reset-password/",
    component: _7e6deea6,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password___de-DE"
  }, {
    path: "/de-DE/account/signup/",
    component: _2416c3e5,
    pathToRegexpOptions: {"strict":true},
    name: "account-signup___de-DE"
  }, {
    path: "/de-DE/p/waitlist/",
    component: _5eb9ec7d,
    pathToRegexpOptions: {"strict":true},
    name: "p-waitlist___de-DE"
  }, {
    path: "/de-DE/prime/join/",
    component: _22a76636,
    pathToRegexpOptions: {"strict":true},
    name: "prime-join___de-DE"
  }, {
    path: "/de-DE/prime/manage/",
    component: _6dd47ae0,
    pathToRegexpOptions: {"strict":true},
    name: "prime-manage___de-DE"
  }, {
    path: "/de-DE/prime/process-payment/",
    component: _20066d3d,
    pathToRegexpOptions: {"strict":true},
    name: "prime-process-payment___de-DE"
  }, {
    path: "/de-DE/prime/success/",
    component: _02688018,
    pathToRegexpOptions: {"strict":true},
    name: "prime-success___de-DE"
  }, {
    path: "/de-DE/shop/cart/",
    component: _71fc65be,
    pathToRegexpOptions: {"strict":true},
    name: "shop-cart___de-DE"
  }, {
    path: "/de-DE/shop/checkout/",
    component: _620f7c38,
    pathToRegexpOptions: {"strict":true},
    name: "shop-checkout___de-DE"
  }, {
    path: "/de-DE/shop/deals/",
    component: _74146159,
    pathToRegexpOptions: {"strict":true},
    name: "shop-deals___de-DE"
  }, {
    path: "/de-DE/shop/last-bought/",
    component: _080a3258,
    pathToRegexpOptions: {"strict":true},
    name: "shop-last-bought___de-DE"
  }, {
    path: "/de-DE/shop/process-payment/",
    component: _4fe8e90c,
    pathToRegexpOptions: {"strict":true},
    name: "shop-process-payment___de-DE"
  }, {
    path: "/de-DE/shop/product-replacement/",
    component: _bbd5bbf4,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-replacement___de-DE"
  }, {
    path: "/de-DE/shop/search/",
    component: _f8243a74,
    pathToRegexpOptions: {"strict":true},
    name: "shop-search___de-DE"
  }, {
    path: "/en-DE/account/forgot-password/",
    component: _b0774cf0,
    pathToRegexpOptions: {"strict":true},
    name: "account-forgot-password___en-DE"
  }, {
    path: "/en-DE/account/get-started/",
    component: _0e68878d,
    pathToRegexpOptions: {"strict":true},
    name: "account-get-started___en-DE"
  }, {
    path: "/en-DE/account/login/",
    component: _86ba02a8,
    pathToRegexpOptions: {"strict":true},
    name: "account-login___en-DE"
  }, {
    path: "/en-DE/account/phone-verification/",
    component: _1b324b32,
    pathToRegexpOptions: {"strict":true},
    name: "account-phone-verification___en-DE"
  }, {
    path: "/en-DE/account/profile/",
    component: _1b0ada2c,
    pathToRegexpOptions: {"strict":true},
    name: "account-profile___en-DE"
  }, {
    path: "/en-DE/account/reset-password/",
    component: _7e6deea6,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password___en-DE"
  }, {
    path: "/en-DE/account/signup/",
    component: _2416c3e5,
    pathToRegexpOptions: {"strict":true},
    name: "account-signup___en-DE"
  }, {
    path: "/en-DE/p/waitlist/",
    component: _5eb9ec7d,
    pathToRegexpOptions: {"strict":true},
    name: "p-waitlist___en-DE"
  }, {
    path: "/en-DE/prime/join/",
    component: _22a76636,
    pathToRegexpOptions: {"strict":true},
    name: "prime-join___en-DE"
  }, {
    path: "/en-DE/prime/manage/",
    component: _6dd47ae0,
    pathToRegexpOptions: {"strict":true},
    name: "prime-manage___en-DE"
  }, {
    path: "/en-DE/prime/process-payment/",
    component: _20066d3d,
    pathToRegexpOptions: {"strict":true},
    name: "prime-process-payment___en-DE"
  }, {
    path: "/en-DE/prime/success/",
    component: _02688018,
    pathToRegexpOptions: {"strict":true},
    name: "prime-success___en-DE"
  }, {
    path: "/en-DE/shop/cart/",
    component: _71fc65be,
    pathToRegexpOptions: {"strict":true},
    name: "shop-cart___en-DE"
  }, {
    path: "/en-DE/shop/checkout/",
    component: _620f7c38,
    pathToRegexpOptions: {"strict":true},
    name: "shop-checkout___en-DE"
  }, {
    path: "/en-DE/shop/deals/",
    component: _74146159,
    pathToRegexpOptions: {"strict":true},
    name: "shop-deals___en-DE"
  }, {
    path: "/en-DE/shop/last-bought/",
    component: _080a3258,
    pathToRegexpOptions: {"strict":true},
    name: "shop-last-bought___en-DE"
  }, {
    path: "/en-DE/shop/process-payment/",
    component: _4fe8e90c,
    pathToRegexpOptions: {"strict":true},
    name: "shop-process-payment___en-DE"
  }, {
    path: "/en-DE/shop/product-replacement/",
    component: _bbd5bbf4,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-replacement___en-DE"
  }, {
    path: "/en-DE/shop/search/",
    component: _f8243a74,
    pathToRegexpOptions: {"strict":true},
    name: "shop-search___en-DE"
  }, {
    path: "/en-NL/account/forgot-password/",
    component: _b0774cf0,
    pathToRegexpOptions: {"strict":true},
    name: "account-forgot-password___en-NL"
  }, {
    path: "/en-NL/account/get-started/",
    component: _0e68878d,
    pathToRegexpOptions: {"strict":true},
    name: "account-get-started___en-NL"
  }, {
    path: "/en-NL/account/login/",
    component: _86ba02a8,
    pathToRegexpOptions: {"strict":true},
    name: "account-login___en-NL"
  }, {
    path: "/en-NL/account/phone-verification/",
    component: _1b324b32,
    pathToRegexpOptions: {"strict":true},
    name: "account-phone-verification___en-NL"
  }, {
    path: "/en-NL/account/profile/",
    component: _1b0ada2c,
    pathToRegexpOptions: {"strict":true},
    name: "account-profile___en-NL"
  }, {
    path: "/en-NL/account/reset-password/",
    component: _7e6deea6,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password___en-NL"
  }, {
    path: "/en-NL/account/signup/",
    component: _2416c3e5,
    pathToRegexpOptions: {"strict":true},
    name: "account-signup___en-NL"
  }, {
    path: "/en-NL/p/waitlist/",
    component: _5eb9ec7d,
    pathToRegexpOptions: {"strict":true},
    name: "p-waitlist___en-NL"
  }, {
    path: "/en-NL/prime/join/",
    component: _22a76636,
    pathToRegexpOptions: {"strict":true},
    name: "prime-join___en-NL"
  }, {
    path: "/en-NL/prime/manage/",
    component: _6dd47ae0,
    pathToRegexpOptions: {"strict":true},
    name: "prime-manage___en-NL"
  }, {
    path: "/en-NL/prime/process-payment/",
    component: _20066d3d,
    pathToRegexpOptions: {"strict":true},
    name: "prime-process-payment___en-NL"
  }, {
    path: "/en-NL/prime/success/",
    component: _02688018,
    pathToRegexpOptions: {"strict":true},
    name: "prime-success___en-NL"
  }, {
    path: "/en-NL/shop/cart/",
    component: _71fc65be,
    pathToRegexpOptions: {"strict":true},
    name: "shop-cart___en-NL"
  }, {
    path: "/en-NL/shop/checkout/",
    component: _620f7c38,
    pathToRegexpOptions: {"strict":true},
    name: "shop-checkout___en-NL"
  }, {
    path: "/en-NL/shop/deals/",
    component: _74146159,
    pathToRegexpOptions: {"strict":true},
    name: "shop-deals___en-NL"
  }, {
    path: "/en-NL/shop/last-bought/",
    component: _080a3258,
    pathToRegexpOptions: {"strict":true},
    name: "shop-last-bought___en-NL"
  }, {
    path: "/en-NL/shop/process-payment/",
    component: _4fe8e90c,
    pathToRegexpOptions: {"strict":true},
    name: "shop-process-payment___en-NL"
  }, {
    path: "/en-NL/shop/product-replacement/",
    component: _bbd5bbf4,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-replacement___en-NL"
  }, {
    path: "/en-NL/shop/search/",
    component: _f8243a74,
    pathToRegexpOptions: {"strict":true},
    name: "shop-search___en-NL"
  }, {
    path: "/en/account/forgot-password/",
    component: _b0774cf0,
    pathToRegexpOptions: {"strict":true},
    name: "account-forgot-password___en"
  }, {
    path: "/en/account/get-started/",
    component: _0e68878d,
    pathToRegexpOptions: {"strict":true},
    name: "account-get-started___en"
  }, {
    path: "/en/account/login/",
    component: _86ba02a8,
    pathToRegexpOptions: {"strict":true},
    name: "account-login___en"
  }, {
    path: "/en/account/phone-verification/",
    component: _1b324b32,
    pathToRegexpOptions: {"strict":true},
    name: "account-phone-verification___en"
  }, {
    path: "/en/account/profile/",
    component: _1b0ada2c,
    pathToRegexpOptions: {"strict":true},
    name: "account-profile___en"
  }, {
    path: "/en/account/reset-password/",
    component: _7e6deea6,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password___en"
  }, {
    path: "/en/account/signup/",
    component: _2416c3e5,
    pathToRegexpOptions: {"strict":true},
    name: "account-signup___en"
  }, {
    path: "/en/p/waitlist/",
    component: _5eb9ec7d,
    pathToRegexpOptions: {"strict":true},
    name: "p-waitlist___en"
  }, {
    path: "/en/prime/join/",
    component: _22a76636,
    pathToRegexpOptions: {"strict":true},
    name: "prime-join___en"
  }, {
    path: "/en/prime/manage/",
    component: _6dd47ae0,
    pathToRegexpOptions: {"strict":true},
    name: "prime-manage___en"
  }, {
    path: "/en/prime/process-payment/",
    component: _20066d3d,
    pathToRegexpOptions: {"strict":true},
    name: "prime-process-payment___en"
  }, {
    path: "/en/prime/success/",
    component: _02688018,
    pathToRegexpOptions: {"strict":true},
    name: "prime-success___en"
  }, {
    path: "/en/shop/cart/",
    component: _71fc65be,
    pathToRegexpOptions: {"strict":true},
    name: "shop-cart___en"
  }, {
    path: "/en/shop/checkout/",
    component: _620f7c38,
    pathToRegexpOptions: {"strict":true},
    name: "shop-checkout___en"
  }, {
    path: "/en/shop/deals/",
    component: _74146159,
    pathToRegexpOptions: {"strict":true},
    name: "shop-deals___en"
  }, {
    path: "/en/shop/last-bought/",
    component: _080a3258,
    pathToRegexpOptions: {"strict":true},
    name: "shop-last-bought___en"
  }, {
    path: "/en/shop/process-payment/",
    component: _4fe8e90c,
    pathToRegexpOptions: {"strict":true},
    name: "shop-process-payment___en"
  }, {
    path: "/en/shop/product-replacement/",
    component: _bbd5bbf4,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-replacement___en"
  }, {
    path: "/en/shop/search/",
    component: _f8243a74,
    pathToRegexpOptions: {"strict":true},
    name: "shop-search___en"
  }, {
    path: "/nl-NL/account/forgot-password/",
    component: _b0774cf0,
    pathToRegexpOptions: {"strict":true},
    name: "account-forgot-password___nl-NL"
  }, {
    path: "/nl-NL/account/get-started/",
    component: _0e68878d,
    pathToRegexpOptions: {"strict":true},
    name: "account-get-started___nl-NL"
  }, {
    path: "/nl-NL/account/login/",
    component: _86ba02a8,
    pathToRegexpOptions: {"strict":true},
    name: "account-login___nl-NL"
  }, {
    path: "/nl-NL/account/phone-verification/",
    component: _1b324b32,
    pathToRegexpOptions: {"strict":true},
    name: "account-phone-verification___nl-NL"
  }, {
    path: "/nl-NL/account/profile/",
    component: _1b0ada2c,
    pathToRegexpOptions: {"strict":true},
    name: "account-profile___nl-NL"
  }, {
    path: "/nl-NL/account/reset-password/",
    component: _7e6deea6,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password___nl-NL"
  }, {
    path: "/nl-NL/account/signup/",
    component: _2416c3e5,
    pathToRegexpOptions: {"strict":true},
    name: "account-signup___nl-NL"
  }, {
    path: "/nl-NL/p/waitlist/",
    component: _5eb9ec7d,
    pathToRegexpOptions: {"strict":true},
    name: "p-waitlist___nl-NL"
  }, {
    path: "/nl-NL/prime/join/",
    component: _22a76636,
    pathToRegexpOptions: {"strict":true},
    name: "prime-join___nl-NL"
  }, {
    path: "/nl-NL/prime/manage/",
    component: _6dd47ae0,
    pathToRegexpOptions: {"strict":true},
    name: "prime-manage___nl-NL"
  }, {
    path: "/nl-NL/prime/process-payment/",
    component: _20066d3d,
    pathToRegexpOptions: {"strict":true},
    name: "prime-process-payment___nl-NL"
  }, {
    path: "/nl-NL/prime/success/",
    component: _02688018,
    pathToRegexpOptions: {"strict":true},
    name: "prime-success___nl-NL"
  }, {
    path: "/nl-NL/shop/cart/",
    component: _71fc65be,
    pathToRegexpOptions: {"strict":true},
    name: "shop-cart___nl-NL"
  }, {
    path: "/nl-NL/shop/checkout/",
    component: _620f7c38,
    pathToRegexpOptions: {"strict":true},
    name: "shop-checkout___nl-NL"
  }, {
    path: "/nl-NL/shop/deals/",
    component: _74146159,
    pathToRegexpOptions: {"strict":true},
    name: "shop-deals___nl-NL"
  }, {
    path: "/nl-NL/shop/last-bought/",
    component: _080a3258,
    pathToRegexpOptions: {"strict":true},
    name: "shop-last-bought___nl-NL"
  }, {
    path: "/nl-NL/shop/process-payment/",
    component: _4fe8e90c,
    pathToRegexpOptions: {"strict":true},
    name: "shop-process-payment___nl-NL"
  }, {
    path: "/nl-NL/shop/product-replacement/",
    component: _bbd5bbf4,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-replacement___nl-NL"
  }, {
    path: "/nl-NL/shop/search/",
    component: _f8243a74,
    pathToRegexpOptions: {"strict":true},
    name: "shop-search___nl-NL"
  }, {
    path: "/de-DE/account/request-an-invoice/webview/:slug?/",
    component: _7955ae06,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-webview-slug___de-DE"
  }, {
    path: "/en-DE/account/request-an-invoice/webview/:slug?/",
    component: _7955ae06,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-webview-slug___en-DE"
  }, {
    path: "/en-NL/account/request-an-invoice/webview/:slug?/",
    component: _7955ae06,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-webview-slug___en-NL"
  }, {
    path: "/en/account/request-an-invoice/webview/:slug?/",
    component: _7955ae06,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-webview-slug___en"
  }, {
    path: "/nl-NL/account/request-an-invoice/webview/:slug?/",
    component: _7955ae06,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-webview-slug___nl-NL"
  }, {
    path: "/de-DE/account/help-center/:slug?/",
    component: _bfc15e3c,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug___de-DE"
  }, {
    path: "/de-DE/account/order-details/:slug?/",
    component: _468f227e,
    pathToRegexpOptions: {"strict":true},
    name: "account-order-details-slug___de-DE"
  }, {
    path: "/de-DE/account/request-an-invoice/:slug?/",
    component: _94ffe6da,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-slug___de-DE"
  }, {
    path: "/de-DE/shop/category/:slug?/",
    component: _59af55d7,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-slug___de-DE"
  }, {
    path: "/de-DE/shop/collection/:slug?/",
    component: _6f069412,
    pathToRegexpOptions: {"strict":true},
    name: "shop-collection-slug___de-DE"
  }, {
    path: "/de-DE/shop/order-collect/:slug?/",
    component: _0fce74f8,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-collect-slug___de-DE"
  }, {
    path: "/de-DE/shop/order-status/:slug?/",
    component: _6546d96a,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-status-slug___de-DE"
  }, {
    path: "/de-DE/shop/product/:slug?/",
    component: _7f63d2bc,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-slug___de-DE"
  }, {
    path: "/en-DE/account/help-center/:slug?/",
    component: _bfc15e3c,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug___en-DE"
  }, {
    path: "/en-DE/account/order-details/:slug?/",
    component: _468f227e,
    pathToRegexpOptions: {"strict":true},
    name: "account-order-details-slug___en-DE"
  }, {
    path: "/en-DE/account/request-an-invoice/:slug?/",
    component: _94ffe6da,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-slug___en-DE"
  }, {
    path: "/en-DE/shop/category/:slug?/",
    component: _59af55d7,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-slug___en-DE"
  }, {
    path: "/en-DE/shop/collection/:slug?/",
    component: _6f069412,
    pathToRegexpOptions: {"strict":true},
    name: "shop-collection-slug___en-DE"
  }, {
    path: "/en-DE/shop/order-collect/:slug?/",
    component: _0fce74f8,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-collect-slug___en-DE"
  }, {
    path: "/en-DE/shop/order-status/:slug?/",
    component: _6546d96a,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-status-slug___en-DE"
  }, {
    path: "/en-DE/shop/product/:slug?/",
    component: _7f63d2bc,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-slug___en-DE"
  }, {
    path: "/en-NL/account/help-center/:slug?/",
    component: _bfc15e3c,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug___en-NL"
  }, {
    path: "/en-NL/account/order-details/:slug?/",
    component: _468f227e,
    pathToRegexpOptions: {"strict":true},
    name: "account-order-details-slug___en-NL"
  }, {
    path: "/en-NL/account/request-an-invoice/:slug?/",
    component: _94ffe6da,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-slug___en-NL"
  }, {
    path: "/en-NL/shop/category/:slug?/",
    component: _59af55d7,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-slug___en-NL"
  }, {
    path: "/en-NL/shop/collection/:slug?/",
    component: _6f069412,
    pathToRegexpOptions: {"strict":true},
    name: "shop-collection-slug___en-NL"
  }, {
    path: "/en-NL/shop/order-collect/:slug?/",
    component: _0fce74f8,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-collect-slug___en-NL"
  }, {
    path: "/en-NL/shop/order-status/:slug?/",
    component: _6546d96a,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-status-slug___en-NL"
  }, {
    path: "/en-NL/shop/product/:slug?/",
    component: _7f63d2bc,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-slug___en-NL"
  }, {
    path: "/en/account/help-center/:slug?/",
    component: _bfc15e3c,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug___en"
  }, {
    path: "/en/account/order-details/:slug?/",
    component: _468f227e,
    pathToRegexpOptions: {"strict":true},
    name: "account-order-details-slug___en"
  }, {
    path: "/en/account/request-an-invoice/:slug?/",
    component: _94ffe6da,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-slug___en"
  }, {
    path: "/en/shop/category/:slug?/",
    component: _59af55d7,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-slug___en"
  }, {
    path: "/en/shop/collection/:slug?/",
    component: _6f069412,
    pathToRegexpOptions: {"strict":true},
    name: "shop-collection-slug___en"
  }, {
    path: "/en/shop/order-collect/:slug?/",
    component: _0fce74f8,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-collect-slug___en"
  }, {
    path: "/en/shop/order-status/:slug?/",
    component: _6546d96a,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-status-slug___en"
  }, {
    path: "/en/shop/product/:slug?/",
    component: _7f63d2bc,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-slug___en"
  }, {
    path: "/nl-NL/account/help-center/:slug?/",
    component: _bfc15e3c,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug___nl-NL"
  }, {
    path: "/nl-NL/account/order-details/:slug?/",
    component: _468f227e,
    pathToRegexpOptions: {"strict":true},
    name: "account-order-details-slug___nl-NL"
  }, {
    path: "/nl-NL/account/request-an-invoice/:slug?/",
    component: _94ffe6da,
    pathToRegexpOptions: {"strict":true},
    name: "account-request-an-invoice-slug___nl-NL"
  }, {
    path: "/nl-NL/shop/category/:slug?/",
    component: _59af55d7,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-slug___nl-NL"
  }, {
    path: "/nl-NL/shop/collection/:slug?/",
    component: _6f069412,
    pathToRegexpOptions: {"strict":true},
    name: "shop-collection-slug___nl-NL"
  }, {
    path: "/nl-NL/shop/order-collect/:slug?/",
    component: _0fce74f8,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-collect-slug___nl-NL"
  }, {
    path: "/nl-NL/shop/order-status/:slug?/",
    component: _6546d96a,
    pathToRegexpOptions: {"strict":true},
    name: "shop-order-status-slug___nl-NL"
  }, {
    path: "/nl-NL/shop/product/:slug?/",
    component: _7f63d2bc,
    pathToRegexpOptions: {"strict":true},
    name: "shop-product-slug___nl-NL"
  }, {
    path: "/de-DE/account/help-center/:slug?/photo-upload/:sku/",
    component: _a30ecdbe,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-photo-upload-sku___de-DE"
  }, {
    path: "/de-DE/account/help-center/:slug?/reasons/:sku/",
    component: _ab2bcc44,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-reasons-sku___de-DE"
  }, {
    path: "/en-DE/account/help-center/:slug?/photo-upload/:sku/",
    component: _a30ecdbe,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-photo-upload-sku___en-DE"
  }, {
    path: "/en-DE/account/help-center/:slug?/reasons/:sku/",
    component: _ab2bcc44,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-reasons-sku___en-DE"
  }, {
    path: "/en-NL/account/help-center/:slug?/photo-upload/:sku/",
    component: _a30ecdbe,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-photo-upload-sku___en-NL"
  }, {
    path: "/en-NL/account/help-center/:slug?/reasons/:sku/",
    component: _ab2bcc44,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-reasons-sku___en-NL"
  }, {
    path: "/en/account/help-center/:slug?/photo-upload/:sku/",
    component: _a30ecdbe,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-photo-upload-sku___en"
  }, {
    path: "/en/account/help-center/:slug?/reasons/:sku/",
    component: _ab2bcc44,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-reasons-sku___en"
  }, {
    path: "/nl-NL/account/help-center/:slug?/photo-upload/:sku/",
    component: _a30ecdbe,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-photo-upload-sku___nl-NL"
  }, {
    path: "/nl-NL/account/help-center/:slug?/reasons/:sku/",
    component: _ab2bcc44,
    pathToRegexpOptions: {"strict":true},
    name: "account-help-center-slug-reasons-sku___nl-NL"
  }, {
    path: "/de-DE/shop/category/:category?/:slug?/",
    component: _30604b36,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-category-slug___de-DE"
  }, {
    path: "/en-DE/shop/category/:category?/:slug?/",
    component: _30604b36,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-category-slug___en-DE"
  }, {
    path: "/en-NL/shop/category/:category?/:slug?/",
    component: _30604b36,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-category-slug___en-NL"
  }, {
    path: "/en/shop/category/:category?/:slug?/",
    component: _30604b36,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-category-slug___en"
  }, {
    path: "/nl-NL/shop/category/:category?/:slug?/",
    component: _30604b36,
    pathToRegexpOptions: {"strict":true},
    name: "shop-category-category-slug___nl-NL"
  }, {
    path: "/de-DE/category/:slug?/",
    component: _4d999f38,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___de-DE"
  }, {
    path: "/de-DE/city/:slug?/",
    component: _7662abde,
    pathToRegexpOptions: {"strict":true},
    name: "city-slug___de-DE"
  }, {
    path: "/de-DE/collection/:slug?/",
    component: _76742144,
    pathToRegexpOptions: {"strict":true},
    name: "collection-slug___de-DE"
  }, {
    path: "/de-DE/p/:slug?/",
    component: _37fcb2e0,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___de-DE"
  }, {
    path: "/de-DE/product/:slug?/",
    component: _0fe3f28f,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___de-DE"
  }, {
    path: "/de-DE/recipes/:slug/",
    component: _41cad6c5,
    pathToRegexpOptions: {"strict":true},
    name: "recipes-slug___de-DE"
  }, {
    path: "/en-DE/category/:slug?/",
    component: _4d999f38,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___en-DE"
  }, {
    path: "/en-DE/city/:slug?/",
    component: _7662abde,
    pathToRegexpOptions: {"strict":true},
    name: "city-slug___en-DE"
  }, {
    path: "/en-DE/collection/:slug?/",
    component: _76742144,
    pathToRegexpOptions: {"strict":true},
    name: "collection-slug___en-DE"
  }, {
    path: "/en-DE/p/:slug?/",
    component: _37fcb2e0,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___en-DE"
  }, {
    path: "/en-DE/product/:slug?/",
    component: _0fe3f28f,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en-DE"
  }, {
    path: "/en-DE/recipes/:slug/",
    component: _41cad6c5,
    pathToRegexpOptions: {"strict":true},
    name: "recipes-slug___en-DE"
  }, {
    path: "/en-NL/category/:slug?/",
    component: _4d999f38,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___en-NL"
  }, {
    path: "/en-NL/city/:slug?/",
    component: _7662abde,
    pathToRegexpOptions: {"strict":true},
    name: "city-slug___en-NL"
  }, {
    path: "/en-NL/collection/:slug?/",
    component: _76742144,
    pathToRegexpOptions: {"strict":true},
    name: "collection-slug___en-NL"
  }, {
    path: "/en-NL/p/:slug?/",
    component: _37fcb2e0,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___en-NL"
  }, {
    path: "/en-NL/product/:slug?/",
    component: _0fe3f28f,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en-NL"
  }, {
    path: "/en-NL/recipes/:slug/",
    component: _41cad6c5,
    pathToRegexpOptions: {"strict":true},
    name: "recipes-slug___en-NL"
  }, {
    path: "/en/category/:slug?/",
    component: _4d999f38,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___en"
  }, {
    path: "/en/city/:slug?/",
    component: _7662abde,
    pathToRegexpOptions: {"strict":true},
    name: "city-slug___en"
  }, {
    path: "/en/collection/:slug?/",
    component: _76742144,
    pathToRegexpOptions: {"strict":true},
    name: "collection-slug___en"
  }, {
    path: "/en/p/:slug?/",
    component: _37fcb2e0,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___en"
  }, {
    path: "/en/product/:slug?/",
    component: _0fe3f28f,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___en"
  }, {
    path: "/en/recipes/:slug/",
    component: _41cad6c5,
    pathToRegexpOptions: {"strict":true},
    name: "recipes-slug___en"
  }, {
    path: "/nl-NL/category/:slug?/",
    component: _4d999f38,
    pathToRegexpOptions: {"strict":true},
    name: "category-slug___nl-NL"
  }, {
    path: "/nl-NL/city/:slug?/",
    component: _7662abde,
    pathToRegexpOptions: {"strict":true},
    name: "city-slug___nl-NL"
  }, {
    path: "/nl-NL/collection/:slug?/",
    component: _76742144,
    pathToRegexpOptions: {"strict":true},
    name: "collection-slug___nl-NL"
  }, {
    path: "/nl-NL/p/:slug?/",
    component: _37fcb2e0,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___nl-NL"
  }, {
    path: "/nl-NL/product/:slug?/",
    component: _0fe3f28f,
    pathToRegexpOptions: {"strict":true},
    name: "product-slug___nl-NL"
  }, {
    path: "/nl-NL/recipes/:slug/",
    component: _41cad6c5,
    pathToRegexpOptions: {"strict":true},
    name: "recipes-slug___nl-NL"
  }, {
    path: "/de-DE/:slug/",
    component: _19805caf,
    pathToRegexpOptions: {"strict":true},
    name: "slug___de-DE"
  }, {
    path: "/en-DE/:slug/",
    component: _19805caf,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en-DE"
  }, {
    path: "/en-NL/:slug/",
    component: _19805caf,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en-NL"
  }, {
    path: "/en/:slug/",
    component: _19805caf,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en"
  }, {
    path: "/nl-NL/:slug/",
    component: _19805caf,
    pathToRegexpOptions: {"strict":true},
    name: "slug___nl-NL"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
