import { SearchLabelsResponse } from '../services/search-labels'

export function transformSearchLabels(
  searchLabels: SearchLabelsResponse
): SearchLabelsResponse {
  return {
    ...searchLabels,
    search_labels: searchLabels.search_labels.filter(
      (label: string) => label.length
    ),
  }
}
