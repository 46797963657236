import { AxiosError } from 'axios'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'

interface IParameters {
  client: NuxtAxiosInstance
}

interface Response {
  code?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

export default async function getUserProfile(
  { client }: IParameters,
  logger: LoggerFunction
): Promise<Response> {
  const url = '/me'
  try {
    const { data } = await get<Response>(client, logger, url)
    return data
  } catch (error) {
    const msg = error instanceof Error ? error.message : String(error)
    const code = `${(error as AxiosError).response?.status}`

    logger({
      level: ELogLevels.ERROR,
      msg,
      'http.method': EHTTPMethods.GET,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      logger: 'API Services',
      func: 'getUserProfile',
    })
    return {
      code,
    }
  }
}
