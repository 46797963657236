










import { computed } from '@nuxtjs/composition-api';
import { theme } from '../../tailwind.config';
const __sfc_main = {};
__sfc_main.props = {
  src: {
    type: String,
    default: '',
    required: true
  },
  title: {
    type: String,
    default: '',
    required: false
  },
  alt: {
    type: String,
    default: '',
    required: false
  },
  widths: {
    type: Array,
    default: () => []
  },
  imageFormat: {
    type: String,
    default: 'webp'
  },
  lazy: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const breakpoints = Object.values(theme.screens).map(width => parseInt(width));
  const props = __props;
  const sourceSet = computed<string>(() => (props.widths.length ? props.widths : breakpoints).map(limit => `${props.src}?w=${limit}&fm=${props.imageFormat} ${limit}w`).join(','));
  return {
    sourceSet
  };
};

export default __sfc_main;
