













import { computed } from '@nuxtjs/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  id: {
    key: "id",
    required: false,
    type: String
  },
  to: {
    key: "to",
    required: false,
    type: String
  },
  href: {
    key: "href",
    required: false,
    type: String
  },
  external: {
    key: "external",
    required: false,
    type: Boolean
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean
  },
  buttonType: {
    key: "buttonType",
    required: false,
    type: String,
    default: 'primary'
  },
  variant: {
    key: "variant",
    required: false,
    type: String,
    default: 'filled'
  },
  size: {
    key: "size",
    required: false,
    type: String,
    default: 'full-width'
  },
  btnClass: {
    key: "btnClass",
    required: false,
    type: String
  },
  fullWidth: {
    key: "fullWidth",
    required: false,
    type: Boolean
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const isNuxtLink = computed(() => !!props.to && !props.external);
  const isExternalLink = computed(() => !!props.href && props.external);
  const linkAttributes = computed(() => {
    if (isNuxtLink.value) {
      return {
        to: props.to
      };
    }

    if (isExternalLink.value) {
      return {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer'
      };
    }

    return {
      disabled: props.disabled
    };
  });

  const handleClick = (event: Event) => {
    if (props.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      emit('click', event);
    }
  };

  const computedClass = computed(() => {
    return ['flink-button', `flink-button--${props.buttonType}`, `flink-button--${props.variant}`, `flink-button--${props.size}`, {
      'is-disabled': props.disabled
    }, props.btnClass];
  });
  return {
    isNuxtLink,
    linkAttributes,
    handleClick,
    computedClass
  };
};

export default __sfc_main;
