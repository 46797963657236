import {
  SET_DELIVERY_COORDINATES,
  SET_SHIPPING_ADDRESS,
  SET_PAYMENT_METHOD,
  SET_REMOTE_CART,
  SET_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_USER_ONBOARDING_STATE,
  UPDATE_USER_DETAILS,
  SET_USER,
  SET_USER_DETAILS,
  SET_ACCESS_TOKEN,
} from '~/store/mutation-types'

import { SET_SUBSCRIPTION_PAYMENT_METHODS } from './mutation-types'

/*
 In order to persist a state, you first need to define the paths,
 which shall be persisted.
 Afterwards you need to define the subscribers (in this case mutations),
 when the persist functionality is triggered.
 Some states need to be available on server side, some are only necessary on
 client.
 All Server persisted states will be stored as a cookie, client states in localStorage.

 !!! Please be careful with the size of the data to be stored. !!!
*/

/**
 * SERVER AND CLIENT SIDE PERSISTENCE
 * Persistence of states, that have to be available on server- and client-side.
 */
export const statePathsServer = [
  // Location onboarding
  'deliveryCoordinates',
  'shippingAddress',
  'cartId',
  'lastUsedCartId',
  'locationOnboardingState',
  'email',
  'account.user',
  'account.accessToken',
]

export const subscribersServer = [
  // Location onboarding
  SET_USER_ONBOARDING_STATE,
  SET_DELIVERY_COORDINATES,
  SET_SHIPPING_ADDRESS,
  SET_REMOTE_CART,
  SET_USER,
  SET_USER_DETAILS,
  SET_ACCESS_TOKEN,
  UPDATE_USER_DETAILS,
]

/**
 * CLIENT SIDE ONLY PERSISTENCE
 * Persistence of states, that have to be available on client only.
 */

export const statePathsClient = [
  // Location onboarding
  'paymentMethod',
  'subscription.paymentMethod',
  'cart',
]

export const subscribersClient = [
  // Location onboarding
  SET_PAYMENT_METHOD,
  SET_SUBSCRIPTION_PAYMENT_METHODS,
  SET_REMOTE_CART,
  SET_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
]
