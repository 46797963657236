export enum ROUTES {
  CART = 'shop-cart',
  CATEGORY = 'shop-category-slug',
  CHECKOUT = 'shop-checkout',
  DEALS = 'shop-deals',
  FORGOT_PASSWORD = 'account-forgot-password',
  GET_STARTED = 'account-get-started',
  HOME = 'shop',
  HELP_CENTER = 'account-help-center-slug',
  HELP_CENTER_REASONS = 'account-help-center-slug-reasons-sku',
  HELP_CENTER_PHOTO_UPLOAD = 'account-help-center-slug-photo-upload-sku',
  IMPRINT = '/imprint',
  LOG_IN = 'account-login',
  LP_HOME = 'index',
  ORDER_COLLECT = 'shop-order-collect-slug',
  ORDER_DETAILS = 'account-order-details-slug',
  ORDER_STATUS = 'shop-order-status-slug',
  PHONE_VERIFICATION = 'account-phone-verification',
  PRIVACY = '/privacy',
  PROCESS_PAYMENT = 'shop-process-payment',
  PRODUCT = 'shop-product-slug',
  PROFILE = 'account-profile',
  RECIPES = 'recipes-slug',
  REQUEST_INVOICE = 'account-request-an-invoice-slug',
  SEARCH = 'shop-search',
  SIGN_UP = 'account-signup',
  SUB_CATEGORY = 'shop-category-category-slug',
  TERMS = '/agb',
  COLLECTION = 'shop-collection-slug',
  PRIME_JOIN = 'prime-join',
  PRIME_PROCESS_PAYMENT = 'prime-process-payment',
  PRIME_SUCCESS = 'prime-success',
  PRIME_MANAGE = 'prime-manage',
  PRIME_TERMS = '/prime-agb',
  PRIME_FAQ = '/prime-faq',
  LAST_BOUGHT = 'shop-last-bought',
  PRODUCT_REPLACEMENT = 'shop-product-replacement',
}

export enum PROFILE_PAGE_TABS {
  MY_ACCOUNT = 'my-account',
  ORDER_HISTORY = 'order-history',
  HELP = 'need-help',
  INVITE_FRIENDS = 'invite-friends',
}

export const EXPLORE_PAGES = [
  ROUTES.HOME,
  ROUTES.PRODUCT,
  ROUTES.CATEGORY,
  ROUTES.COLLECTION,
  ROUTES.LAST_BOUGHT,
]

export const PROFILE_PAGES = [
  ROUTES.PROFILE,
  ROUTES.GET_STARTED,
  ROUTES.LOG_IN,
  ROUTES.SIGN_UP,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.PHONE_VERIFICATION,
]
