import '@datadog/browser-logs/bundle/datadog-logs'
import { ERuntimeEnv } from '~/types/app/Env'

interface IRuntimeConfig {
  datadog: {
    clientToken: string
  }
  runtimeEnv: ERuntimeEnv
}

const envMap = (env: string | undefined): string => {
  switch (env) {
    case ERuntimeEnv.Production:
      return 'prod'
    case ERuntimeEnv.Staging:
      return 'staging'
    default:
      return 'dev'
  }
}

export default ({ $config }: { $config: IRuntimeConfig }): void => {
  ;(window as any).DD_LOGS.init({
    clientToken: $config.datadog.clientToken,
    site: 'datadoghq.eu',
    service: 'website',
    env: envMap($config.runtimeEnv),
    forwardErrorsToLogs: [ERuntimeEnv.Production, ERuntimeEnv.Staging].includes(
      $config.runtimeEnv
    ),
    sampleRate: 100,
  })
}
