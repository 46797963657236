import { Plugin } from '@nuxt/types'
import { createClient } from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { IPageContentAreaFields } from '~/types/generated/contentful'
import { IPageContentItem } from '~/types/contentful'
import { ELogLevels } from '~/types/app/Logger'
import {
  CONTENTFUL_PREVIEW_QUERY_ID,
  CONTENTFUL_URL_PREVIEW,
  CONTENTFUL_URL,
} from '~/contentful.config'

interface IPageContentAreaLocaleFields
  extends Omit<IPageContentAreaFields, 'title' | 'body'> {
  title: { [locale: string]: IPageContentAreaFields['title'] }
  body: { [locale: string]: IPageContentAreaFields['body'] }
}

export interface IPageContentConfig {
  hubSlug: string
  categorySlug?: string
  productSku?: string
  collectionSlug?: string
}
const contentfulPlugin: Plugin = ({ query, req, i18n, env, app }, inject) => {
  const isPreview = query.mode === CONTENTFUL_PREVIEW_QUERY_ID
  const baseURL = isPreview ? CONTENTFUL_URL_PREVIEW : CONTENTFUL_URL

  const environment =
    req?.headers?.['x-contentful-env'] ||
    query.CONTENTFUL_ENV ||
    env.NUXT_ENV_CONTENTFUL_ENV ||
    'master'

  const cfConfig = {
    baseURL,
    space: 'irrelevant',
    accessToken: 'redacted',
    environment,
  }

  const contentfulClient = createClient(cfConfig)

  function getEntries<T>(searchQuery = {}) {
    return contentfulClient.getEntries<T>({
      locale: i18n.locale,
      ...searchQuery,
    })
  }

  async function getPageContent({
    hubSlug,
    categorySlug,
    productSku,
  }: IPageContentConfig): Promise<IPageContentItem[]> {
    if (!hubSlug) return []
    const searchQuery = {
      // get all locales and manually select the translation fields to avoid
      // fallbacks to default locale by Contentful
      // https://www.contentful.com/developers/docs/tutorials/general/setting-locales/#custom-fallback-locales
      locale: '*',
      content_type: 'pageContentArea',
      'fields.categorySlug': categorySlug,
      'fields.categorySlug[exists]': !!categorySlug,
      'fields.productSku': productSku,
      'fields.productSku[exists]': !!productSku,
    }

    try {
      const { items } =
        await contentfulClient.getEntries<IPageContentAreaLocaleFields>({
          ...searchQuery,
        })

      return items.reduce((acc, { fields }) => {
        const title = fields.title[i18n.locale]
        const body = fields.body[i18n.locale]

        if (title && body) {
          acc.push({
            title,
            body: documentToHtmlString(body, {
              renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node) => {
                  const title = node.data.target.fields.title[i18n.locale]
                  const description =
                    node.data.target.fields.description[i18n.locale]
                  // Image assets can share the default locale for different
                  // locales contexts they are used in.
                  const file =
                    node.data.target.fields.file[i18n.locale] ||
                    node.data.target.fields.file[i18n.defaultLocale]
                  return `
                    <figure>
                      <img
                        src="${file.url}"
                        alt="${title}"
                        width="${file.details.image.width}"
                        height="${file.details.image.height}"
                      >
                      ${
                        description && `<figcaption>${description}</figcaption>`
                      }
                    </figure>
                  `
                },
              },
            }),
          })
        }

        return acc
      }, [] as IPageContentItem[])
    } catch (error) {
      app.$logger({
        level: ELogLevels.ERROR,
        'http.url': baseURL,
        msg: `Unable to get Contentful entries. Hub: ${hubSlug}`,
        logger: 'contentfulPlugin',
        func: 'getPageContent',
      })
      return []
    }
  }

  inject('contentful', {
    getEntries,
    getPageContent,
  })
}

export default contentfulPlugin
