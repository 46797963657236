import { omit } from 'lodash-es'
import { ROUTES } from '~/lib/routes'
import { ROUTE_LOCALE_SEPARATOR } from '~/lib/constants'

export default async function ({ redirect, app, route }) {
  const isUserAuthenticated = app?.store?.getters['account/isUserAuthenticated']
  if (isUserAuthenticated) {
    await app.store.dispatch(
      'subscription/getSubscriptionEligibilityStatus',
      { forceReload: true },
      { root: true }
    )
    await app.store.dispatch(
      'subscription/getSubscriptionStatus',
      { forceReload: true },
      { root: true }
    )
  }
}
