export default class ErrorLog {
  error: Error

  constructor(error: Error | string) {
    if (typeof error === 'string') {
      this.error = new Error(error)
    } else {
      this.error = error
    }
  }

  log() {
    if (['staging', 'production'].includes(process.env.NODE_ENV || '')) {
      // TODO: add real error loggin mechanism / TW-475
      // eslint-disable-next-line no-console
      console.error(this.error.message)
    } else {
      throw this.error
    }
  }
}
