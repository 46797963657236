/* eslint-disable camelcase */
export enum ESchedulingState {
  SHOW_PDT = 'SHOW_PDT',
  SHOW_TIMESLOT = 'SHOW_TIMESLOT',
  CTA_PICK_SLOT = 'CTA_PICK_SLOT',
  CTA_CHANGE_SLOT = 'CTA_CHANGE_SLOT',
  CLOSED = 'CLOSED',
}

export enum ECheckoutDisplayState {
  ENABLED = 'ENABLED',
  SELECTED = 'SELECTED',
  DISABLED = 'DISABLED',
  CTA = 'CTA',
}

export enum ESchedulingOptionType {
  PRIO = 'PRIORITY',
  ASAP = 'ASAP',
  PLANNED = 'PLANNED',
  NO_RUSH = 'NO_RUSH',
  EXPRESS = 'EXPRESS',
  CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
}

export enum ESchedulingCta {
  SELECT_DELIVERY_OPTION = 'SELECT_DELIVERY_OPTION',
}

export enum ESchedulingIcon {
  NONE = 'NONE',
  HIGH_PDT = 'HIGH_PDT',
}

export interface ISchedulingOption {
  id: string
  title: string
  is_selected: boolean
  available: boolean
  meta: {
    type: ESchedulingOptionType
    start: string
    end: string
  }
}

export interface IDeliveryOption {
  id: string | null
  title: string
  subtitle: string
  fee_description: string | null
  discount_description: string | null
  state: ECheckoutDisplayState
  type: ESchedulingOptionType
}
