
































import { ref, computed, useStore, useRoute, useRouter, useContext, watch } from '@nuxtjs/composition-api';
import { getSearchSuggestions, ISearchSuggestion } from '~/api/services/get-search-suggestions';
import { debounce } from 'lodash-es';
import { ROUTES } from '~/lib/routes';
import IRootState from '~/types/app/State';
import IProduct from '~/types/app/Product';
import { FeatureFlags } from '~/types/app/experiments';
import { search as searchProducts } from '~/api/services/search';
import SearchSuggestions from './SearchSuggestions.vue';
import SearchResults from './SearchResults.vue';
import { mapActions } from 'vuex';
const __sfc_main = {
  methods: { ...mapActions(['addToCart', 'removeFromCart'])
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const MAX_ITEMS_ON_SEARCH_POPOVER = 6;
  const emit = __ctx.emit;
  const {
    i18n,
    $logger,
    localePath,
    $apiDiscovery,
    $segmentEvent,
    $apiSearchDiscovery
  } = useContext();
  const store = useStore<IRootState>();
  const route = useRoute();
  const router = useRouter();
  const searchQuery = ref<string>('');
  const searchQueryId = ref<string>('');
  const searchResults = ref<IProduct[]>([]);
  const isLoading = ref<boolean>(false);
  const searchSuggestions = ref<ISearchSuggestion[]>([]);
  const products = computed<IProduct[]>(() => searchResults.value.map(product => ({ ...product,
    quantity: store.getters.getCartProductQuantity(product.sku)
  })));
  const isSearchPage = computed<boolean>(() => !!route.value.name?.startsWith(ROUTES.SEARCH));
  const useSearchSuggestions = computed<boolean>(() => store.getters['experiments/isFeatureEnabled'](FeatureFlags.SEARCH_SUGGESTIONS));

  function handleFocus() {
    emit('focus');

    if (isSearchPage.value) {
      onSearch(searchQuery.value);
    }
  }

  function handleSearch(query: string) {
    isLoading.value = true;
    searchQuery.value = query;
    onSearchDebounced(query);
  }

  const handleSubmit = (event: Event): void => {
    const form = (event?.target as HTMLFormElement);
    const element = (form.elements?.namedItem('q') as HTMLInputElement);
    const query = element.value;
    goToSearchPage(query);
  };

  const goToSearchPage = (query: string): void => {
    router.push({
      path: localePath(ROUTES.SEARCH),
      query: {
        q: query
      }
    });
  };

  const onSearch = async (query: string): Promise<void> => {
    if (isSearchPage.value && !useSearchSuggestions.value) {
      goToSearchPage(query);
      return;
    }

    if (!query) {
      searchSuggestions.value = [];
      searchQuery.value = '';
      searchQueryId.value = '';
      return;
    }

    isLoading.value = true;

    if (useSearchSuggestions.value) {
      const response = await getSearchSuggestions({
        client: $apiDiscovery,
        logger: $logger,
        locale: i18n.locale,
        hubSlug: (store.state.hub?.slug as string),
        query
      });
      searchSuggestions.value = response.suggestions;
      store.dispatch('catalog/setSuggestionQueryId', response.query_id);
    } else {
      await triggerSearch(query);
    }

    isLoading.value = false;
    $segmentEvent.PRODUCT_SEARCH_EXECUTED({
      searchQuery: query,
      searchResults: [],
      searchQueryId: searchQueryId.value
    });
  };

  async function triggerSearch(query = '') {
    const response = await searchProducts({
      client: $apiSearchDiscovery,
      logger: $logger,
      locale: i18n.locale,
      hubSlug: (store.state.hub?.slug as string),
      query,
      pageSize: MAX_ITEMS_ON_SEARCH_POPOVER
    });
    searchResults.value = response.products ?? [];
    searchQueryId.value = response.queryId;

    if (searchResults.value.length) {
      await store.dispatch('catalog/updateCatalog', {
        products: searchResults.value
      });
      searchResults.value = store.getters['catalog/getProductsBySkus'](searchResults.value.map(p => p.sku));
    }
  }

  const onSearchDebounced = debounce(onSearch, 300);

  const handleRouteSearchQuery = (value: string): void => {
    searchQuery.value = value;
  };

  watch(() => (route.value.query.q as string), handleRouteSearchQuery, {
    immediate: true
  });
  return {
    searchQuery,
    searchQueryId,
    isLoading,
    searchSuggestions,
    products,
    useSearchSuggestions,
    handleFocus,
    handleSearch,
    handleSubmit,
    triggerSearch
  };
};

__sfc_main.components = Object.assign({
  SearchResults,
  SearchSuggestions
}, __sfc_main.components);
export default __sfc_main;
