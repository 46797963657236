import { ActionTree, GetterTree, MutationTree } from 'vuex'
import IRootState from '~/types/app/State'
import ICategory from '~/types/app/Category'
import IProduct from '~/types/app/Product'
import getHome from '~/api/services/get-home'
import getCategories from '~/api/services/get-categories'
import getProductsBySku from '~/api/services/get-products-by-sku'
import { HomeV2Section } from '~/api/transformers/transform-v2-home'
import { EOriginScreen } from '~/lib/segment'
import {
  SET_CATEGORIES,
  SET_PRODUCTS,
  ADD_PRODUCTS,
  SET_CATEGORIES_LAST_FETCHED,
  SET_HOMEPAGE_SECTIONS,
  SET_COLLECTION_PAGE_REFFER,
  SET_PRODUCT_VIEWED_TRACKING_DETAILS,
  SET_SUGGESTION_CLICK_POSITION,
  SET_SUGGESTION_QUERY_ID,
} from './mutation-types'
import { updateProducts } from './helpers'
import { getDefaultHubSlug } from './helpers/get-default-hub-slug'

export type ProductViewedTrackingDetails = {
  listName?: string
  productPlacement?: string
  listPosition?: number
  productPosition?: number
  searchQueryId?: string
  productContext?: string
  eventOrigin?: EOriginScreen
  categoryId?: string
  categoryName?: string
  prismCampaignId?: string
  prismCampaignName?: string
}

export enum CollectionPageReferrerType {
  marketingBanner = 'marketingBanner',
  collectionCard = 'collectionCard',
}

type CollectionPageReferrer = {
  type: CollectionPageReferrerType
  trackingListName: string
  title: string
  id: string
}

export interface ICatalogState {
  categories: ICategory[]
  products: Map<string, IProduct>
  homepageSections: HomeV2Section[]
  collectionPageReferrer: CollectionPageReferrer | null
  productViewedTrackingDetails: ProductViewedTrackingDetails
  categoriesFetchedTimestamp?: number
  suggestionClickPosition?: number
  searchSuggetionQueryId?: string
}

export const state = (): ICatalogState => ({
  categories: [],
  products: new Map(),
  homepageSections: [],
  collectionPageReferrer: null,
  productViewedTrackingDetails: {},
  categoriesFetchedTimestamp: undefined,
  suggestionClickPosition: undefined,
  searchSuggetionQueryId: undefined,
})

export const getters: GetterTree<ICatalogState, IRootState> = {
  getProductBySlug:
    (state: ICatalogState) =>
    (slug: string): IProduct | undefined => {
      if (!slug) return undefined

      return [...state.products.values()].find((p) => p.slug === slug)
    },
  getProductBySKU:
    (state: ICatalogState) =>
    (sku: string): IProduct | undefined => {
      if (!sku) return undefined

      return state.products.get(sku)
    },
  getProductsBySkus:
    (state: ICatalogState) =>
    (skus: string[]): (IProduct | undefined)[] => {
      if (!skus || !skus.length) return []

      return skus
        .map((sku) => state.products.get(sku))
        .filter((p) => p !== undefined)
    },
  getHomepageSections: (state: ICatalogState) => state.homepageSections ?? [],
  getSearchSuggetsionInfo: (state: ICatalogState) => {
    return {
      suggestionQueryId: state.searchSuggetionQueryId,
      suggestionPosition: state.suggestionClickPosition,
    }
  },
}

export const mutations: MutationTree<ICatalogState> = {
  [SET_CATEGORIES](state, categoriesData: ICategory[]): void {
    state.categories = categoriesData
  },
  [SET_PRODUCTS](
    state,
    productsData: IProduct[] | Map<string, IProduct>
  ): void {
    state.products = Array.isArray(productsData)
      ? new Map(productsData.map((p) => [p.sku, p]))
      : productsData
  },
  [ADD_PRODUCTS](state, productsData: IProduct[]) {
    if (!state.products.size) {
      state.products = new Map(productsData.map((p) => [p.sku, p]))
      return
    }

    for (const p of productsData) {
      state.products.set(p.sku, p)
    }
  },
  [SET_CATEGORIES_LAST_FETCHED](state, timestamp: number): void {
    state.categoriesFetchedTimestamp = timestamp
  },
  [SET_HOMEPAGE_SECTIONS](state, sections: HomeV2Section[]): void {
    state.homepageSections = sections
  },
  [SET_PRODUCT_VIEWED_TRACKING_DETAILS](
    state,
    data: ProductViewedTrackingDetails
  ): void {
    state.productViewedTrackingDetails = data
  },
  [SET_COLLECTION_PAGE_REFFER](state, data: CollectionPageReferrer): void {
    state.collectionPageReferrer = data
  },
  [SET_SUGGESTION_CLICK_POSITION](state, position: number): void {
    state.suggestionClickPosition = position
  },
  [SET_SUGGESTION_QUERY_ID](state, queryId: string): void {
    state.searchSuggetionQueryId = queryId
  },
}

export const actions: ActionTree<ICatalogState, IRootState> = {
  resetHomepage({ commit }) {
    commit(SET_HOMEPAGE_SECTIONS, [])
  },
  async loadHomepageData({ commit, rootState }) {
    const home = await getHome({
      client: this.$apiHomescreen,
      locale: this.$i18n.locale,
      logger: this.$logger,
      hubSlug: rootState.hub?.slug || getDefaultHubSlug(this.$i18n.locale),
    })

    if (!home) return

    commit(SET_CATEGORIES, home.categories)
    commit(ADD_PRODUCTS, home.products)
    commit(SET_HOMEPAGE_SECTIONS, home.sections)
  },
  async loadCategories({ commit, rootState }): Promise<void> {
    const categories = await getCategories({
      client: this.$apiDiscovery,
      logger: this.$logger,
      locale: this.$i18n.locale,
      hubSlug: rootState.hub?.slug || getDefaultHubSlug(this.$i18n.locale),
    })

    commit(SET_CATEGORIES, categories)
    commit(SET_CATEGORIES_LAST_FETCHED, Date.now())
  },
  async loadProductsBySku(
    { commit, rootState },
    { skus }: { skus: string[] }
  ): Promise<void> {
    if (!skus || !skus.length) return

    const response = await getProductsBySku({
      client: this.$apiDiscovery,
      logger: this.$logger,
      locale: this.$i18n.locale,
      hubSlug: rootState.hub?.slug || getDefaultHubSlug(this.$i18n.locale),
      skus,
    })
    commit(ADD_PRODUCTS, response)
  },
  async updateCatalog(
    { commit, dispatch, state },
    { products }: { products: IProduct[] },
    loadCategories = true
  ) {
    if (loadCategories && !state.categoriesFetchedTimestamp) {
      await dispatch('loadCategories')
    }

    if (products && products.length) {
      commit(ADD_PRODUCTS, products)
    }
  },
  setProducts({ commit }, products: IProduct[]): void {
    commit(SET_PRODUCTS, products)
  },
  addProducts({ commit }, products: IProduct[]): void {
    commit(ADD_PRODUCTS, products)
  },
  setProductViewedTrackingDetails(
    { commit },
    data: ProductViewedTrackingDetails
  ): void {
    commit(SET_PRODUCT_VIEWED_TRACKING_DETAILS, data)
  },
  syncProducts({ state, commit }, updatedProducts) {
    commit(
      SET_PRODUCTS,
      updateProducts({
        oldProducts: [...state.products.values()],
        updatedProducts,
      })
    )
  },
  async loadCartProducts({ rootState, dispatch, state }) {
    const skus = rootState.cart.map((cartItem) => cartItem.sku)
    const skusNotInCatalog = skus.filter((sku) => !state.products.get(sku))

    if (skusNotInCatalog.length === 0) return
    const payload = { skus: skusNotInCatalog }
    await dispatch('loadProductsBySku', payload)
  },
  setSuggestionClickPosition({ commit }, position: number): void {
    commit(SET_SUGGESTION_CLICK_POSITION, position)
  },
  setSuggestionQueryId({ commit }, queryId: number): void {
    commit(SET_SUGGESTION_QUERY_ID, queryId)
  },
}
