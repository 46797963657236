var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('div',{staticClass:"v-modal",class:{
    '--is-sheet': _vm.sheetView,
    '--is-full-screen': _vm.isFullScreen,
  },style:(_vm.style),on:{"click":_vm.onClick}},[_c('div',{ref:"$modal",staticClass:"modal focus-visible:outline-ring",class:{
      '--has-image': !!_vm.image,
      '--has-min-height': !_vm.withoutMinHeight,
      '--has-max-height': _vm.withMaxHeight,
      '--has-close-button': !_vm.hideClose,
      '--has-fullscreen': _vm.isFullScreen,
    },attrs:{"role":"dialog","aria-modal":"true","aria-describedby":(_vm.uid + "_description"),"aria-labelledby":(_vm.uid + "_label"),"tabindex":"-1"}},[(!_vm.hideClose)?_c('button',{staticClass:"button-like",attrs:{"data-testid":"modal-close-button"},on:{"click":function () {
          _vm.hide(false)
        }}},[_c('VIcon',{attrs:{"name":"close"}})],1):_vm._e(),_vm._v(" "),(_vm.image)?_c('div',{staticClass:"image",style:({ backgroundImage: ("url(" + _vm.image + ")") })}):_vm._e(),_vm._v(" "),_vm._t("aside"),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{staticClass:"header",class:{ '--is-hidden': !_vm.hasHeader },attrs:{"id":(_vm.uid + "_label")}},[_vm._t("header",[_c('h3',[_vm._v(_vm._s(_vm.header))])])],2),_vm._v(" "),_c('div',{staticClass:"above-body",attrs:{"id":(_vm.uid + "_description")}},[_vm._t("above-body")],2),_vm._v(" "),_c('div',{ref:"$body",staticClass:"body"},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"footer"},[_vm._t("footer")],2)])],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }