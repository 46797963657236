

















































import {
  defineComponent,
  computed,
  ref,
  watch,
  useContext,
} from '@nuxtjs/composition-api'
import { nextTick } from '@vue/runtime-dom'
import { nanoid } from 'nanoid'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false },
    iconsRounded: { type: Boolean, default: false },
    label: { type: String, default: '' },
    leadingIcon: { type: String, default: '' },
    max: { type: String, default: '' },
    min: { type: String, default: '' },
    name: { type: String, default: '' },
    noAutocomplete: { type: Boolean, default: false },
    preventValidation: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array as () => any[], default: () => [] },
    trailingIcon: { type: String, default: '' },
    type: { type: String, default: 'text' },
    useTextArea: { type: Boolean, default: false },
    value: { type: String, default: '' },
    successMessage: { type: String, default: '' },
  },
  emits: ['blur', 'focus', 'input', 'keydown', 'paste', 'mousedown', 'mouseup'],

  setup(props, { emit, listeners }) {
    const { i18n } = useContext()

    const error = ref('')
    const hasMouseDown = ref(false)
    const isFocused = ref(false)
    const lazyVal = ref(props.value)
    const uid = 'textfield_' + nanoid()
    const $inputRef = ref<HTMLElement | null>(null)
    const successText = ref<string>('')

    watch(
      () => props.value,
      (v) => {
        lazyVal.value = v
      }
    )

    const internalValue = computed({
      get: () => lazyVal.value,
      set: (v) => {
        lazyVal.value = v
        emit('update', v)

        if (error.value || !v) {
          validateInput()
        }
      },
    })

    const classes = computed<Record<string, boolean>>(() => {
      return {
        '--disabled': props.disabled,
        '--focused': isFocused.value,
        '--has-error': !!error.value,
        '--hide-label': props.hideLabel,
        '--is-valid-pre-filled': !error.value && !!internalValue.value,
        '--is-valid': !error.value && !!internalValue.value,
        '--show-valid': !props.preventValidation,
      }
    })

    const inputProps = computed<Record<string, any>>(() => {
      const shared = {
        'aria-labelledby': uid,
        autocomplete: props.noAutocomplete ? 'off' : undefined,
        disabled: props.disabled,
        name: props.name,
        placeholder: props.label,
        readonly: props.readonly,
        tabindex: props.readonly ? -1 : 0,
      }
      return props.useTextArea
        ? shared
        : {
            ...shared,
            type: props.type,
            min: props.min,
            max: props.max,
          }
    })

    const inputHandlers = computed<Record<string, any>>(() => {
      return {
        blur: onBlur,
        focus: onFocus,
        input: onInput,
        keydown: onKeyDown,
        paste: onPaste,
      }
    })

    const getTrailingIcon = computed<string>(() => {
      if (
        isFocused.value ||
        (!internalValue.value && !error.value) ||
        props.preventValidation
      ) {
        return props.trailingIcon || ''
      }

      return error.value
    })

    const iconIsClickable = computed<boolean>(() => {
      if (isFocused.value || (!internalValue.value && !error.value)) {
        return !!listeners['click:icon']
      }
      return false
    })

    const validationRules = computed<Array<any>>(() => {
      const rules = [...props.rules]
      if (props.required) {
        rules.push((v: string) => !!v || i18n.t('input_validation_required'))
      }
      return rules
    })

    const validateInput = () => {
      successText.value = ''
      error.value = ''

      if (props.preventValidation) {
        return true
      }

      const allPassed = validationRules.value.every((rule) => {
        const result = rule(internalValue.value)
        if (result !== true) {
          error.value = result || i18n.tc('input_validation_unknown')
          return false
        }
        return true
      })

      if (internalValue.value && allPassed) {
        successText.value = props.successMessage
        return true
      }

      return false
    }

    const focus = () => $inputRef.value && $inputRef.value.focus()
    const onClickIcon = (e: Event) => {
      // we had prevent default not invoke form submit etc.
      e.preventDefault()

      if (iconIsClickable.value) {
        e.stopPropagation()
        emit('click:icon')
      }
    }

    const onBlur = (e: FocusEvent) => {
      if (isFocused.value && !hasMouseDown.value) {
        isFocused.value = false
        e &&
          nextTick(() => {
            validateInput()
            emit('blur', e)
          })
      }
    }

    const onFocus = (e: FocusEvent) => {
      if (!props.readonly && !isFocused.value) {
        isFocused.value = true
        e && emit('focus', e)
      }
    }

    const onInput = (e: Event) => {
      emit('input', e)
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') emit('change', internalValue.value)
      emit('keydown', e)
    }

    const onPaste = (e: ClipboardEvent) => {
      emit('paste', e)
    }

    const onMouseDown = (e: Event) => {
      if (e.target !== $inputRef.value) {
        e.preventDefault()
        e.stopPropagation()
      }
      hasMouseDown.value = true
      emit('mousedown', e)
    }

    const onMouseUp = (e: Event) => {
      if (hasMouseDown.value) $inputRef.value && $inputRef.value.focus()
      hasMouseDown.value = false
      emit('mouseup', e)
    }

    return {
      $inputRef,
      classes,
      emit,
      error,
      focus,
      getTrailingIcon,
      hasMouseDown,
      iconIsClickable,
      inputHandlers,
      inputProps,
      internalValue,
      isFocused,
      onClickIcon,
      onMouseDown,
      onMouseUp,
      uid,
      validateInput,
      validationRules,
      successText,
    }
  },
})
