const plugin = require('tailwindcss/plugin')

module.exports = {
  mode: 'jit',
  purge: [
    './components/**/*.{vue,js}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.{js,ts}',
    './nuxt.config.{js,ts}',
  ],
  darkMode: false,
  theme: {
    colors: {
      inherit: 'inherit',
      transparent: 'transparent',
      primary: {
        100: '#FDE9F1',
        200: '#FDD8E9',
        300: '#FCB2D3',
        400: '#F97FB5',
        500: '#E2186F',
      },
      secondary: {
        100: '#F4F7FA',
        200: '#EAEFF6',
        300: '#91A9C3',
        400: '#335377',
        500: '#002855',
      },
      neutral: {
        100: '#FFFFFF',
        200: '#F8F8F8',
        300: '#E6E6E6',
        400: '#CCCCCC',
        500: '#B3B3B3',
        600: '#686868',
        700: '#4E4E4E',
        800: '#353535',
        900: '#1C1C1C',
      },
      error: {
        100: '#FFEAEA',
        200: '#FFCCCC',
        300: '#FF6666',
        400: '#E00000',
      },
      success: {
        100: '#E5F9E5',
        200: '#CCF2CC',
        300: '#33AF75',
        400: '#008040',
      },
      info: {
        100: '#EBEEFB',
        200: '#AEBBF1',
        300: '#5D77E2',
        400: '#3455DB',
      },
      warning: {
        100: '#FDF5E5',
        200: '#FBEBCC',
        300: '#EC9900',
        400: '#CB4900',
      },
      'brand-blue': {
        100: '#E1EEFA',
        200: '#ADD0F0',
        300: '#69AAE4',
        400: '#0073DC',
      },
      'brand-green': {
        100: '#ECF6DF',
        200: '#CBE8A6',
        300: '#A2D45E',
        400: '#4EA525',
      },
      'brand-orange': {
        100: '#FFEFE0',
        200: '#FED2A5',
        300: '#FFB063',
        400: '#FF8A17',
      },
      'brand-purple': {
        100: '#EFEAF8',
        200: '#D4C5EC',
        300: '#AE95DA',
        400: '#8554DB',
      },
      'brand-teal': {
        100: '#E0F4F1',
        200: '#ACE1D9',
        300: '#66C8BA',
        400: '#009E88',
      },
      'brand-yellow': {
        100: '#FFF9E1',
        200: '#FEED9F',
        300: '#FEE367',
        400: '#FFD600',
      },
    },
    columns: {
      narrow: '940px',
    },
    screens: {
      sm: '320px',
      burgerMenu: '430px',
      md: '600px',
      lg: '960px',
      xl: '1120px',
      xxl: '1440px',
    },
    fontFamily: {
      sans: ['Flink', 'sans-serif'],
    },
    fontWeight: {
      light: '300',
      normal: '400',
      medium: '500',
      bold: '700',
    },
    container: {
      center: true,
    },
    extend: {
      minHeight: {
        input: '53px',
      },
      transitionDuration: {
        default: '300ms',
      },
      boxShadow: {
        surround: '0 5px 20px #0001',
        'surround-lg': '0 0 25px 3px rgba(0, 0, 0, 0.1)',
      },
      keyframes: {
        'rise-centered': {
          '0%': { transform: 'translate(-50%, 3rem)' },
          '100%': { transform: 'translate(-50%, 0)' },
        },
        pulse: {
          '0%': { transform: 'scale(.8)' },
          '50%': { transform: 'scale(1.0)' },
          '100%': { transform: 'scale(.8)' },
        },
      },
      animation: {
        'rise-centered': 'rise-centered 0.5s 1',
        pulse: 'pulse 1s infinite',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    plugin(function ({ addUtilities }) {
      addUtilities({
        '.text-heading-0': {
          '@apply font-bold text-4xl': {},
        },
        '.text-heading-1': {
          '@apply font-bold text-[2rem] leading-9': {},
        },
        '.text-heading-2': {
          '@apply font-bold text-2xl leading-7': {},
        },
        '.text-heading-3': {
          '@apply font-bold text-xl leading-6': {},
        },
        '.text-heading-4': {
          '@apply font-medium text-base leading-5': {},
        },
        '.text-heading-5': {
          '@apply font-bold text-sm leading-[1.125rem] md:text-base md:leading-5':
            {},
        },
        '.text-heading-6': {
          '@apply font-medium text-xs md:text-sm md:leading-[1.125rem]': {},
        },
        '.text-body-xl': {
          '@apply font-normal text-base leading-5': {},
        },
        '.text-body-l': {
          '@apply font-normal text-sm leading-[1.125rem]': {},
        },
        '.text-body-m': {
          '@apply font-normal text-xs md:text-sm md:leading-[1.125rem]': {},
        },
        '.text-body-s': {
          '@apply font-normal text-[0.625rem] leading-[0.875rem] md:text-xs':
            {},
        },
        '.text-body-xs': {
          '@apply font-normal text-[0.5rem] leading-[0.75rem] md:text-[0.625rem] md:leading-[0.875rem]':
            {},
        },
        '.text-button-l': {
          '@apply font-medium text-base': {},
        },
        '.text-button-m': {
          '@apply font-medium text-xs md:text-sm md:leading-[1.125rem]': {},
        },
        '.text-button-s': {
          '@apply font-medium text-[0.625rem] leading-[0.875rem]': {},
        },
      })
    }),
  ],
}
