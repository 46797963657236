import createHash from 'create-hash'
import IRootState from '~/types/app/State'
import { ELoggers, ILogOptions } from '~/types/app/Logger'
import { ERuntimeEnv } from '~/types/app/Env'

const DEFAULT_LOG_LEVEL = 'warn'

export const getLogLevel = (consoleLogLevel: string | null): string => {
  if (consoleLogLevel && ['info', 'warn', 'error'].includes(consoleLogLevel)) {
    return consoleLogLevel
  }

  return DEFAULT_LOG_LEVEL
}

const hostMap = (env: string | undefined): string => {
  switch (env) {
    case ERuntimeEnv.Production:
      return 'goflink.com'
    case ERuntimeEnv.Staging:
      return 'staging.goflink.com'
    default:
      return 'localhost'
  }
}

export const addStandardDomainAttributes = (
  options: ILogOptions,
  state: IRootState,
  getters: any,
  locale: string,
  runtimeEnv: string,
  logger?: ELoggers
): ILogOptions => {
  const hash = createHash('md5')
  const email = state.email ? hash.update(state.email).digest('hex') : ''
  return {
    ...options,
    'flink.user.country_code': state.shippingAddress?.country,
    'flink.user.locale': locale,
    'flink.user.email_hash': email as string,
    'flink.cart.id': state.cartId,
    'flink.cart.shipping_method': getters.getShippingMethod,
    'flink.hub.id': state.hub?.slug,
    host: hostMap(runtimeEnv),
    logger: { name: logger },
  }
}
