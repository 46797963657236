import { render, staticRenderFns } from "./ProductListDetails.vue?vue&type=template&id=42f4480d&scoped=true&"
import script from "./ProductListDetails.vue?vue&type=script&lang=ts&"
export * from "./ProductListDetails.vue?vue&type=script&lang=ts&"
import style0 from "./ProductListDetails.vue?vue&type=style&index=0&id=42f4480d&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42f4480d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DiscountLabel: require('/app/build/components/DiscountLabel/DiscountLabel.vue').default,FeatureFlag: require('/app/build/components/FeatureFlag/FeatureFlag.vue').default,VCloudinaryImage: require('/app/build/components/VCloudinaryImage/VCloudinaryImage.vue').default,PriceTag: require('/app/build/components/PriceTag/PriceTag.vue').default,PromotionLabel: require('/app/build/components/ProductList/PromotionLabel.vue').default})
