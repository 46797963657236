import { applyPrefix } from '~/lib/helpers'

export default function ({ redirect, route }) {
  if (route.path.endsWith('.html')) {
    return
  }

  // Redirect to page with trailing slash if neccesary!
  // We want to have either with or without trailing slash. Not providing both patterns for SEO reasons.
  if (!route.path.includes('.') && !route.path.endsWith('/')) {
    const newPath = applyPrefix(route.path + '/')
    redirect(301, newPath)
  }
}
