















import { IGroupedCartItems } from '~/types/app/Cart';
const __sfc_main = {};
__sfc_main.props = {
  cartItems: {
    type: (Object as () => IGroupedCartItems),
    default: () => ({})
  }
};

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

export default __sfc_main;
