/* eslint-disable camelcase */
import ICart, {
  ECartFeeType,
  ECartAdditionalInfoType,
  ICartFee,
  ICartV3,
} from '~/types/app/Cart'
import { setPriceAmountFromCentAmount } from '~/store/helpers'
import { EApiVersion } from '~/plugins/consumerApi'
import IPrice from '~/types/app/Price'

export function transformV3Cart(v3cart: ICartV3): ICart {
  const {
    id,
    version,
    email,
    hubSlug,
    lines,
    promotions,
    shippingAddress,
    riderTip,
    recyclingDeposit,
    totalPrice,
    subTotalPrice,
    discount,
    clickAndCollect,
    discountedDeliveryFee,
    deliveryCoordinates,
    order,
    voucherCode,
    additionalInfo,
    shippingMethodID,
    deliveryTierID,
    state,
  } = v3cart

  const savedDeliveryFee = additionalInfo?.find(
    ({ type }) => type === ECartAdditionalInfoType.SAVED_DELIVERY_FEE_SUBSCRIBER
  )?.price

  const savedPriorityFee = additionalInfo?.find(
    ({ type }) => type === ECartAdditionalInfoType.SAVED_PRIORITY_FEE_SUBSCRIBER
  )?.price

  const cart: ICart = {
    id,
    email,
    version,
    order,
    lines: lines.map((line) => {
      return {
        quantity: line.quantity,
        product_sku: line.productSku,
      }
    }),
    promotions,
    discount: setPriceAmountFromCentAmount(discount),
    hub_slug: hubSlug,
    shipping_address: {
      address_id: shippingAddress.addressId,
      id: shippingAddress.id,
      city: shippingAddress.city,
      company_name: shippingAddress.companyName,
      country: shippingAddress.country,
      country_code: shippingAddress.countryCode,
      first_name: shippingAddress.firstName,
      last_name: shippingAddress.lastName,
      phone: shippingAddress.phone,
      postal_code: shippingAddress.postalCode,
      street_address_1: shippingAddress.streetAddress1,
      street_address_2: shippingAddress.streetAddress2,
    },
    rider_tip: setPriceAmountFromCentAmount(riderTip),
    recycling_deposit: setPriceAmountFromCentAmount(recyclingDeposit),
    sub_total_price: setPriceAmountFromCentAmount(subTotalPrice),
    total_price: setPriceAmountFromCentAmount(totalPrice),
    click_and_collect: clickAndCollect || false,
    delivery_coordinates: deliveryCoordinates,
    voucher_code: voucherCode,
    fees: v3cart.fees?.map((fee: ICartFee) => {
      fee.price = setPriceAmountFromCentAmount(fee.price)
      return fee
    }),
    savedDeliveryFee: savedDeliveryFee
      ? setPriceAmountFromCentAmount(savedDeliveryFee)
      : undefined,
    savedPriorityFee: savedPriorityFee
      ? setPriceAmountFromCentAmount(savedPriorityFee)
      : undefined,
    discountedDeliveryFee: discountedDeliveryFee
      ? setPriceAmountFromCentAmount(discountedDeliveryFee)
      : undefined,
    shippingMethodId: shippingMethodID,
    deliveryTierId: deliveryTierID,
    state,
    additionalInfo,
  }

  cart.shipping_price = cart.fees.find(
    (fee) => fee.type === ECartFeeType.DELIVERY_FEE
  )?.price as IPrice

  cart.storage_fee = cart.fees.find(
    (fee) => fee.type === ECartFeeType.STORAGE_FEE
  )?.price as IPrice

  cart.lateNightFee = cart.fees.find(
    (fee) => fee.type === ECartFeeType.LATE_NIGHT
  )?.price as IPrice

  cart.priorityFee = cart.fees.find((fee) => fee.type === ECartFeeType.PRIORITY)
    ?.price as IPrice

  return cart
}

export default function handleCartVersionTransformation(
  apiVersion: EApiVersion | undefined,
  cart: ICart | ICartV3 | undefined
): ICart | undefined {
  if (!cart) return cart
  switch (apiVersion) {
    case EApiVersion.V3:
      return transformV3Cart(cart as ICartV3)
    default:
      return cart as ICart
  }
}
