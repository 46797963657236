import { getRouteName } from '~/lib/helpers'
import { ROUTES } from '~/lib/routes'

export default function ({ app }: { app: Vue }): void {
  app.router.beforeEach(async (to, from, next) => {
    // only trigger on navigation between pages
    if (!to.name || !from.name) {
      next()
      return
    }

    const routeName = getRouteName(to)
    const datadogRum = (window as any).DD_RUM
    datadogRum.startView({
      name: routeName,
    })

    if (routeName === ROUTES.CHECKOUT) {
      await app.store.dispatch('reloadHub')
      if (app.store.getters.getIsHubClosed) {
        next(false)
        return
      }
    } else if (routeName === ROUTES.HOME) {
      await app.store.dispatch('reloadHub')
    }
    next()
  })
}
