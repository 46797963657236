






import { Portal } from 'portal-vue';
import { defineComponent } from '@vue/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  showUnderlay: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;

  const handleClick = () => {
    emit('on-click');
  };

  return {
    handleClick
  };
};

__sfc_main.components = Object.assign({
  Portal
}, __sfc_main.components);
export default __sfc_main;
