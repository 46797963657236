






















import VPopover from '~/components/VPopover/VPopover.vue';
import VIcon from '~/components/VIcon/VIcon.vue';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  name: {
    type: String,
    required: true
  },
  initialVisible: {
    type: Boolean,
    default: false
  },
  offsetY: {
    type: Number,
    default: 0
  },
  offsetX: {
    type: Number,
    default: 0
  },
  arrowSpacing: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  return {
    emit
  };
};

__sfc_main.components = Object.assign({
  VPopover,
  VIcon
}, __sfc_main.components);
export default __sfc_main;
