/* eslint-disable camelcase */
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { get } from '~/api/controllers/get'
import { LoggerFunction } from '~/types/app/Logger'
import { IAddressBookApiResponse } from '~/types/app/Location'

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
}

export default async function getAddressList({
  client,
  logger,
  locale,
}: IParameters): Promise<IAddressBookApiResponse | null> {
  const headers = {
    locale,
  }
  const url = '/address/list'

  try {
    const response = await get<IAddressBookApiResponse>(
      client,
      logger,
      url,
      headers
    )
    return response.data
  } catch (error) {}

  return null
}
