/* eslint-disable camelcase */
import { get } from '~/api/controllers/get'
import { LoggerFunction } from '~/types/app/Logger'
import { IConsumerApi } from '~/plugins/consumerApi'

interface IParameters {
  client: IConsumerApi
  logger: LoggerFunction
  locale: string
  hubSlug?: string
  query: string
}

export interface ISearchSuggestion {
  text: string
}

interface ISearchSuggestionsResponse {
  suggestions: ISearchSuggestion[]
  query_id: string
}

export default async function getSearchSuggestions({
  client,
  logger,
  locale,
  hubSlug,
  query,
}: IParameters): Promise<ISearchSuggestionsResponse> {
  const headers = {
    locale,
    'hub-slug': hubSlug,
  }

  const url = `/v1/search/suggestions?query=${encodeURIComponent(query)}`

  try {
    const response = await get<ISearchSuggestionsResponse>(
      client,
      logger,
      url,
      headers
    )
    return response.data
  } catch (error) {}

  return {
    suggestions: [],
    query_id: '',
  }
}
