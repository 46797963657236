import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { put } from '~/api/controllers/put'
import { LoggerFunction } from '~/types/app/Logger'
import { AddressListItem, IAddressBookApiResponse } from '~/types/app/Location'

interface IParameters {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
  address: AddressListItem
}

export default async function updateAddress({
  client,
  logger,
  locale,
  address,
}: IParameters): Promise<IAddressBookApiResponse | null> {
  const headers = {
    locale,
  }
  const url = '/address'

  try {
    const response = await put<IAddressBookApiResponse>(
      client,
      logger,
      url,
      headers,
      address
    )
    return response.data
  } catch (error) {}

  return null
}
