import createPersistedState from 'vuex-persistedstate'
import {
  statePathsServer,
  subscribersServer,
  statePathsClient,
  subscribersClient,
} from '~/store/persist'
import * as Cookies from 'js-cookie'
import cookie from 'cookie'
import * as shvl from 'shvl'
import { pick } from 'lodash-es'

const pickByStatePaths = (cookie, statePaths, isString = true) => {
  try {
    let cookieObject = {}
    let parsedCookiesJson = isString ? JSON.parse(cookie) : cookie
    for (let path of statePaths) {
      const pathValue = shvl.get(parsedCookiesJson, path)
      if (pathValue === undefined || pathValue === null) continue
      shvl.set(cookieObject, path, pathValue)
    }
    return isString ? JSON.stringify(cookieObject) : cookieObject
  } catch (e) {
    return isString ? '' : {}
  }
}

export default ({ store, req }) => {
  // This persisted state will be available on server and client side
  createPersistedState({
    key: 'flinkStoreServer',
    paths: statePathsServer,
    filter: (mutation) => {
      return subscribersServer.indexOf(mutation.type) >= 0
    },
    // some states do not make sense on rehydration, transform them in reducer
    // before they are written into cookie
    reducer: (state, paths) => {
      return {
        ...pick(state, paths),
      }
    },
    storage: {
      getItem: (key) => {
        if (process.server) {
          if (!req.headers.cookie) return
          let parsedCookies = cookie.parse(req.headers.cookie)
          if (!parsedCookies.hasOwnProperty(key)) return {}
          // Only rehydrate the values, which are defined in the statePath variable
          parsedCookies[key] = pickByStatePaths(
            parsedCookies[key],
            statePathsServer
          )

          return parsedCookies[key]
        } else {
          let cookieString = Cookies.get(key)
          cookieString = pickByStatePaths(cookieString, statePathsServer)
          return cookieString
        }
      },
      setItem: (key, value) => {
        Cookies.set(key, value, { expires: 365, secure: false })
      },
      removeItem: (key) => Cookies.remove(key),
    },
  })(store)

  // This persisted state will be available on client side only.
  createPersistedState({
    key: 'flinkStoreClient',
    paths: statePathsClient,
    filter: (mutation) => {
      return subscribersClient.indexOf(mutation.type) >= 0
    },
    storage: {
      getItem: (key) => {
        if (!process.server) {
          let locallyStored = localStorage.getItem(key)
          locallyStored = pickByStatePaths(locallyStored, statePathsClient)
          return locallyStored
        }
      },
      setItem: (key, value) => {
        if (!process.server) {
          localStorage.setItem(key, value)
        }
      },
      removeItem: (key) => {
        if (!process.server) {
          localStorage.removeItem(key)
        }
      },
    },
  })(store)
}
