

































































import { computed, ref, useContext, onBeforeUnmount, onMounted } from '@nuxtjs/composition-api';
import { ESchedulingState } from '~/types/app/PlannedOrders';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({
  name: 'DeliveryBannerETA'
});

__sfc_main.props = {
  deliveryAddress: {
    type: String,
    default: ''
  },
  deliveryEta: {
    type: String,
    default: ''
  },
  openingHours: {
    type: String,
    default: ''
  },
  schedulingState: {
    type: (String as () => ESchedulingState),
    default: ESchedulingState.SHOW_PDT
  },
  schedulingText: {
    type: String,
    default: ''
  },
  popoverName: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const {
    i18n,
    $segmentIdentify,
    store
  } = useContext();
  const unsubscribe = ref<() => void>();
  onMounted(() => {
    unsubscribe.value = store.subscribeAction({
      after: action => {
        if (action.type === 'updateDeliveryTiers') {
          $segmentIdentify();
        }
      }
    });
  });
  onBeforeUnmount(() => {
    if (unsubscribe.value) unsubscribe.value();
  });
  const isAddressDefined = computed<boolean>(() => {
    return store.getters.getIsOnboardSuccess && !!props.deliveryAddress;
  });
  const isPickTimeButtonAvailable = computed<boolean>(() => {
    return [ESchedulingState.CTA_PICK_SLOT, ESchedulingState.CTA_CHANGE_SLOT].includes(props.schedulingState);
  });
  const isShowTimeSlot = computed<boolean>(() => {
    return !!props.schedulingText && props.schedulingState === ESchedulingState.SHOW_TIMESLOT;
  });
  const isOpen = computed<boolean>(() => {
    return props.schedulingState !== ESchedulingState.CLOSED;
  });
  const openingHoursLabel = computed<string>(() => {
    return isOpen.value ? `${i18n.t('generic_open')} ${props.openingHours}` : '';
  });
  const isClickable = computed<boolean>(() => !isPickTimeButtonAvailable.value && isOpen.value && store.state.plannedOrders?.isClickable);
  const deliveryEtaFallback = computed<string>(() => {
    return `${props.deliveryEta} ${i18n.t('generic_minutes')}`;
  });
  return {
    emit,
    store,
    isAddressDefined,
    isPickTimeButtonAvailable,
    isShowTimeSlot,
    isOpen,
    openingHoursLabel,
    isClickable,
    deliveryEtaFallback
  };
};

export default __sfc_main;
