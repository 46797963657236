import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosError } from 'axios'
import { fixAPILocale } from '~/lib/helpers'
import {
  EHTTPMethods,
  ELoggers,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'

export default async function httpDelete<ResponseType = undefined>(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  url: string,
  headers?: Record<string, string | false | undefined>
): Promise<AxiosResponse<ResponseType>> {
  if (headers) {
    Object.keys(headers).forEach((header) =>
      client.setHeader(
        header,
        header === 'locale' ? fixAPILocale(headers[header]) : headers[header]
      )
    )
  }

  try {
    const response: AxiosResponse<ResponseType> = await client.delete(url)

    logger({
      level: ELogLevels.INFO,
      msg: `DELETE Call successful: ${url}`,
      'http.method': EHTTPMethods.DELETE,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.API_CONTROLLERS,
      func: 'httpDelete',
      slug: 'api.delete.success',
    })

    return response
  } catch (error) {
    const statusCode = (error as AxiosError).response?.status || EStatus.FAILED

    logger({
      level: ELogLevels.ERROR,
      msg: `DELETE Call failed: ${url}`,
      'http.method': EHTTPMethods.DELETE,
      'http.url': url,
      'http.status_code': statusCode as string,
      error: error as string,
      logger: ELoggers.API_CONTROLLERS,
      func: 'httpDelete',
      slug: 'api.delete.error',
    })

    throw error
  }
}
