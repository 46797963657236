import { ROUTES } from '~/lib/routes'
import { DEFAULT_HUB_SLUGS } from '~/lib/constants'
import getCategoryBySlug from '~/api/services/get-category-by-slug'
import { getCountryFromLocale, applyPrefix } from '~/lib/helpers'
import { Context } from '@nuxt/types'
import { ECountries } from '~/types/app/Countries'
import { ELogLevels, ELoggers } from '~/types/app/Logger'

export default async function ({
  redirect,
  app,
  route,
  store,
}: Context): Promise<ReturnType<typeof redirect>> {
  const locale = app.i18n.locale
  const country = getCountryFromLocale(locale) as ECountries
  const hubSlug = DEFAULT_HUB_SLUGS[country]
  const categoryId = route.params.slug
  let redirectPath = app.localePath(ROUTES.HOME)

  let categorySlug

  try {
    if (!categoryId) {
      throw new Error('Category ID was not passed as slug')
    }

    const category = await getCategoryBySlug({
      client: app.$apiDiscovery,
      logger: app.$logger,
      locale,
      hubSlug: store.state.hub?.slug || hubSlug,
      slug: categoryId,
    })

    categorySlug = category?.slug

    if (!categorySlug) {
      throw new Error('Category slug could not be found')
    }
  } catch (error) {
    app.$logger({
      level: ELogLevels.ERROR,
      msg: 'Deeplink Redirect Error',
      error,
      logger: ELoggers.CATEGORY,
      func: 'deeplinkRedirectCategory',
    })
  }

  if (categorySlug) {
    redirectPath = app.localePath({
      name: ROUTES.CATEGORY,
      params: { slug: categorySlug },
    })
  }

  return redirect(applyPrefix(redirectPath))
}
