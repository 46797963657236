






import { ref, watch, onMounted } from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
const __sfc_main = {
  name: 'VIcon'
};
__sfc_main.props = {
  name: {
    type: String,
    required: true
  },
  lazy: {
    type: Boolean,
    default: false
  },
  iconRounded: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const elementRef = ref<HTMLElement | null>(null);
  const icon = ref<unknown>(null);

  function getScrollParent(element: HTMLElement): HTMLElement | null {
    const hasScrollableContent = element.scrollHeight > element.clientHeight;

    if (hasScrollableContent) {
      return element;
    } else if (element.parentElement) {
      return getScrollParent(element.parentElement);
    } else {
      return null;
    }
  }

  function isInView(container: HTMLElement, element: HTMLElement): boolean {
    // Get container properties
    const cTop = container.scrollTop;
    const cBottom = cTop + container.clientHeight; // Get element properties

    const eTop = element.offsetTop;
    const eBottom = eTop + element.clientHeight;
    const preLoad = 150; // Return outcome

    return eTop >= cTop && eBottom <= cBottom + preLoad;
  }

  function loadIfInView(container: HTMLElement, element: HTMLElement) {
    if (isInView(container, element)) {
      importIcon();
    }
  }

  onMounted(() => {
    if (props.lazy && elementRef.value) {
      const scrollParent = getScrollParent(elementRef.value);

      if (scrollParent) {
        loadIfInView(scrollParent, elementRef.value);
        const debouncedScrollHandler = debounce(() => {
          if (elementRef.value) {
            loadIfInView(scrollParent, elementRef.value);
          }
        }, 100);
        scrollParent.addEventListener('scroll', debouncedScrollHandler);
        return;
      }
    }

    importIcon();
  });
  watch(() => props.name, importIcon);

  function importIcon(): void {
    try {
      icon.value = require(`~/assets/icons/${props.name}.svg?inline`);
    } catch (err) {
      icon.value = require(`~/assets/icons/_blank.svg?inline`);
    }
  }

  return {
    elementRef,
    icon
  };
};

export default __sfc_main;
